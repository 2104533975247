import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';
import { motion } from 'framer-motion';
import { Button, Typography } from '@mui/material';
import ClickableTypography from '../ClickableTypography/ClickableTypography';
import { DoneAll } from '@mui/icons-material';

const cornerRadius = 10;
const borderRadius = 10;

/*
const chatBubbleTail = (side: 'right' | 'left', color: string) => {
    if (side === 'right') {
        return {
            content: '""',
            width: '0px',
            height: '0px',
            position: 'absolute',
            borderRight: '15px solid transparent',
            borderBottom: `15px solid ${color}`,
            right: '-9px',
            bottom: '0px',
        };
    }
    return {
        content: '""',
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderLeft: '15px solid transparent',
        borderRight: `15px solid ${color}`,
        borderTop: '15px solid transparent',
        borderBottom: `15px solid ${color}`,
        left: '-9px',
        bottom: '0px',
    };
};
*/

export const LeftSideRow = styled('div')(() => ({
    position: 'relative',
    textAlign: 'left',
}));

export const BubbleRow = styled('div', { shouldForwardProp: (prop) => prop !== 'side' })<{
    side: 'left' | 'right';
}>(({ side }) => ({
    textAlign: side,
    float: side,
    width: '66%',
}));

export const RightSideRow = styled('div')(() => ({
    textAlign: 'right',
}));

export const TimeStamp = styled('p')(() => ({
    fontSize: '12px',
    margin: '30px 0 30px 0',
    height: '5px',
    textAlign: 'center',
    width: '100%',
    zIndex: 1,
}));

export const MessageContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    flex: 'nowrap',
    overflowY: 'auto',
}));

export const MessageBubble = styled(motion.div, { shouldForwardProp: (prop) => prop !== 'side' })<{
    side: 'left' | 'right';
}>(({ theme, side }) => ({
    position: 'relative',
    fontSize: '14px',
    borderRadius,
    display: 'inline-block',
    wordBreak: 'break-word',
    maxWidth: '100%',
    borderTopRightRadius: cornerRadius,
    borderBottomRightRadius: cornerRadius,
    backgroundColor: side === 'left' ? theme.palette.background.default : theme.palette.primary.main,
    /*  '&:first-of-type::before': chatBubbleTail(side, side === 'left' ? theme.palette.background.default : theme.palette.primary.main),
    '&:last-of-type': {
        ...(side === 'left' && { borderTopLeftRadius: '15px' }),
        ...(side === 'right' && { borderTopRightRadius: '15px' }),
    }, */
}));

export const ChatBubbleTimeStamp = styled('span')(() => ({
    fontSize: '10px',
    display: 'inline-block',
    '&.left': {
        color: grey[500],
    },
    '&.right': {
        color: grey[300],
    },
}));

export const ReadAtIcon = styled(DoneAll)(() => ({
    fontSize: '16px',
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
    '&.left': {
        color: grey[500],
    },
    '&.right': {
        color: grey[300],
    },
}));

export const ChatHeader = styled(Box)(() => ({
    padding: '10px',
    top: '0',
    boxShadow: '0px 2px 8px 1px rgba(0, 0, 0, .12);',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 2,
}));

export const StyledAvatar = styled(Avatar)(() => ({
    height: 25,
    width: 25,
    marginLeft: '10px',
}));

export const MessageContent = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
}));

export const ListingButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    background: theme.palette.primary.light,
    padding: '0',
}));

export const TextContent = styled(ClickableTypography, { shouldForwardProp: (prop) => prop !== 'side' })<{
    side: 'left' | 'right';
}>(({ theme, side }) => ({
    textAlign: 'left',
    padding: '0px 10px',
    display: 'inline-block',
    color: side === 'left' && theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
}));
