import { SxProps, padding } from '@mui/system';
import React, { ReactNode } from 'react';
import { headerHeightPX, footerHeightPX } from '../../constants';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { NavigationList } from '../../routes/NavigationList';
import { RouteConfiguration } from '../../routes/types';
import { resolveConfigValue } from './ResponsivePage';
import { OutletContainer, OutletContent } from './ResponsivePage.components';
import { Box } from '@mui/material';

const NARROW_WIDTH = 60;
const MEDIUM_WIDTH = 240;

type ResponsiveContainerProps = {
    routeConfig: RouteConfiguration;
    sx?: SxProps;
    children: ReactNode;
};

const calculatePaddingTop = (routeConfig: RouteConfiguration, isDesktop: boolean) => {
    const { hideTopbar, disablePadding } = routeConfig;

    const resolvedDisablePadding = resolveConfigValue(disablePadding, isDesktop);

    const addSafeArea = (padding: number) => `calc(${padding}px + env(safe-area-inset-top))`;

    if (resolvedDisablePadding) {
        return 0;
    }

    const resolvedHideTopbar = resolveConfigValue(hideTopbar, isDesktop);

    if (resolvedHideTopbar) {
        return addSafeArea(0);
    }

    return addSafeArea(headerHeightPX);
};

const calculatePaddingBottom = (routeConfig: RouteConfiguration, isDesktop: boolean) => {
    const { hideFooter, disablePadding } = routeConfig;

    const resolvedDisablePadding = resolveConfigValue(disablePadding, isDesktop);

    const addSafeArea = (padding: number) => `calc(${padding}px + env(safe-area-inset-bottom))`;

    if (resolvedDisablePadding) {
        return 0;
    }

    const resolvedHideFooter = isDesktop ? true : resolveConfigValue(hideFooter, isDesktop);

    if (resolvedHideFooter) {
        return addSafeArea(0);
    }

    return addSafeArea(footerHeightPX);
};

export const ResponsiveContainer = React.forwardRef<HTMLDivElement, ResponsiveContainerProps>(({ routeConfig, children, sx }, ref) => {
    const isDesktop = useBreakpoint('sm');
    const isNarrow = useBreakpoint('lg', 'down');
    const navWidth = isNarrow ? NARROW_WIDTH : MEDIUM_WIDTH;
    const { hideNavigation, ...rest } = routeConfig;

    const paddingTop = calculatePaddingTop(rest, isDesktop);
    const paddingBottom = calculatePaddingBottom(rest, isDesktop);

    if (isDesktop) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '100%',
                    paddingTop,
                    paddingBottom,
                }}
            >
                {!hideNavigation && <NavigationList isNarrow={isNarrow} />}

                <OutletContainer sx={{ width: `calc(100% - ${navWidth}px)` }}>
                    <OutletContent ref={ref} sx={sx}>
                        {children}
                    </OutletContent>
                </OutletContainer>
            </Box>
        );
    }

    return (
        <Box
            ref={ref}
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                paddingTop,
                paddingBottom,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
});
