import { styled } from '@mui/material';
import { motion } from 'framer-motion';

export const CustomToolbar = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    width: '100%',
    zIndex: 900,
    paddingTop: 'env(safe-area-inset-top)',
}));

export const ToolbarContent = styled(motion.div, { shouldForwardProp: (prop) => prop !== 'isScrolling' })<{ isScrolling: boolean }>(
    ({ theme, isScrolling }) => ({
        ...(isScrolling && { boxShadow: theme.palette.boxShadow.medium }),
    }),
);
