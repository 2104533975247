import React, { MutableRefObject, useEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useIsPresent } from 'framer-motion';

interface ScrollRestoreProps {
    isPresent?: boolean; // If component is not present in the react tree, it's currently animating out -> do not restore scroll
    containerRef: MutableRefObject<HTMLDivElement> | null;
    includeSearch?: boolean; // include search parameters in path equality check?
}

const pathMap = new Map<string, number>();

export const clearStoredPaths = () => pathMap.clear();

/**
 * Replacement for RRDv6's ScrollRestoration component that does not rely on window scroll, but instead
 * uses individual containers' scroll position to restore it.
 */
export const ScrollRestore: React.FC<ScrollRestoreProps> = ({ containerRef, includeSearch = true }) => {
    const { pathname, search } = useLocation();
    const isPresent = useIsPresent();
    const navigationType = useNavigationType();

    const path = includeSearch ? `${pathname}${search}` : pathname;

    useEffect(() => {
        if (!isPresent || !containerRef?.current) {
            return;
        }
        const div = containerRef.current;

        if (pathMap.has(path) && navigationType === NavigationType.Pop) {
            div.scrollTo(0, pathMap.get(path) as number);
        } else {
            pathMap.set(path, 0);
        }
    }, [path, containerRef, isPresent, navigationType]);

    useEffect(() => {
        if (!isPresent || !containerRef?.current) {
            return () => null;
        }

        const div = containerRef.current;

        const fn = () => pathMap.set(path, div.scrollTop);

        div.addEventListener('scroll', fn, { passive: true });
        return () => div.removeEventListener('scroll', fn);
    }, [path, containerRef, isPresent]);

    return null;
};
