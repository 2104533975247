import React from 'react';
import { NavigateFunction, matchPath, useLocation, useParams } from 'react-router-dom';
import { StyledAppHeader } from './Header.components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAppDispatch } from '../../../../store/hooks';
import { SearchBar } from '../../../../views/Listings/Search/SearchBar';
import useLocationParams from '../../../../hooks/useLocationParams';
import { useHomePage } from '../../../../queries/useHomePage';
import { getPathConfig } from '../../../../routes/helpers';
import { ReactFCWithChildren } from '../../../../types/types';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { TopbarPrefix } from './TopbarPrefix';
import { TopbarSuffix } from './TopbarSuffix';
import { useArticle } from '../../../../views/Article/useArticle';
import { setDrawerState } from '../../../../store/listingsReducer';

interface HeaderProps {
    navigate: NavigateFunction;
}

export const SecondaryHeaderContent: ReactFCWithChildren = () => {
    const locationParams = useLocationParams();
    const { selectedCategory } = locationParams;
    const { data } = useHomePage();

    const getCategoryLabel = () => {
        let label = '';

        if (!selectedCategory || !data?.components) {
            return label;
        }

        for (const component of data.components) {
            if (component.type === 'collection' && component.id === parseInt(selectedCategory, 10)) {
                label = component.label;
            }
        }

        return label;
    };

    return (
        <>
            {selectedCategory && (
                <Typography
                    variant="h6"
                    sx={{
                        marginLeft: '-40px',
                        width: '100%',
                        padding: '10px 10px 10px 14px',
                        zIndex: 11, // This is to prevent MUI ripple showing over this (it has zIndex 10),
                    }}
                >
                    {getCategoryLabel()}
                </Typography>
            )}
        </>
    );
};

export const AppHeader: ReactFCWithChildren<HeaderProps> = ({ navigate }) => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const locationParams = useLocationParams();

    const { id: articleId } = useParams();
    const { data: article } = useArticle(articleId);

    const { selectedCategory } = locationParams;
    const { data } = useHomePage();
    const { label, hideTopbarPrefix, hideTopbarSuffix, hideSearchbar } = getPathConfig(pathname);
    const isDesktop = useBreakpoint('sm');

    const isSearchbarVisible = () => {
        if (!isDesktop) {
            const allowedPaths = ['/', '/search', '/listings', '/listings/search'];
            return allowedPaths.includes(pathname) && !selectedCategory;
        }

        return !Boolean(hideSearchbar);
    };

    const showSearchbar = isSearchbarVisible();

    const showFilterIcon = isDesktop && pathname === '/listings';

    const handleSetDrawerState = () => {
        dispatch(setDrawerState('allFilters'));
    };

    const getTopbarMode = () => {
        if (selectedCategory) {
            return 'category';
        }

        if (matchPath('/article/:id', pathname)) {
            return 'article';
        }

        if (!selectedCategory && !showSearchbar) {
            return 'route';
        }

        return isDesktop ? 'route' : 'back-button-only';
    };

    const topbarMode = getTopbarMode();

    const getLabel = () => {
        if (topbarMode === 'category' && data?.components) {
            let label = '';

            for (const component of data.components) {
                if (component.type === 'collection' && component.id === parseInt(selectedCategory, 10)) {
                    label = component.label;
                }
            }

            return label;
        }
        if (topbarMode === 'article' && article?.title) {
            return article.title;
        }

        return label || '';
    };

    const routeLabel = getLabel();

    return (
        <>
            <StyledAppHeader
                sx={{
                    ...(isDesktop && {
                        borderBottom: (theme) => `1px solid ${theme.palette.border.lighter}`,
                        padding: '0 20px',
                    }),
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexGrow: 1, alignItems: 'center' }}>
                    <TopbarPrefix mode={topbarMode} showSearchbar={showSearchbar} backButtonVisible={!hideTopbarPrefix} routeLabel={routeLabel} />
                    {showSearchbar && !selectedCategory && (
                        <Box sx={{ flex: '1 1 0', flexGrow: 1, mx: '8px', maxWidth: '600px' }}>
                            <SearchBar />
                        </Box>
                    )}

                    <TopbarSuffix
                        showMessageIcon={!hideTopbarSuffix}
                        showFilterIcon={showFilterIcon}
                        onClickMessage={() => navigate('/messages')}
                        onClickFilter={handleSetDrawerState}
                    />
                </Box>
            </StyledAppHeader>
        </>
    );
};
