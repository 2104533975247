import { ChevronRight, DoneAll } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { InfiniteData } from '@tanstack/react-query';

import { assertIsDefined } from '../../helpers/commonHelpers';
import { formatFlexibleDateTime, formatLongDateAndTime, formatTime, getHoursDifference } from '../../helpers/dateAndTimeHelpers';
import { Message } from '../../messages/apiTypes';
import { useMarkAsSeen } from '../../messages/hooks/useMarkAsSeen';
import { ReactFCWithChildren } from '../../types/types';
import { closeDrawer } from '../ResponsiveDrawer/drawerUtils';
import {
    ChatBubbleTimeStamp,
    TimeStamp,
    ListingButton,
    MessageBubble,
    BubbleRow,
    TextContent,
    MessageContent,
    ReadAtIcon,
} from './ChatMessages.components';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useHasPermission } from '../../user/hooks/usePermissions';
import { Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';

interface MessageItemProps {
    message: Message;
    previousMsgDate: string | undefined;
    side: 'left' | 'right';
    showReadIcon: boolean;
    markAsSeen: (messageId: string) => void;
}

export const MessageItem: ReactFCWithChildren<MessageItemProps> = React.memo(({ message, previousMsgDate, markAsSeen, side, showReadIcon }) => {
    const { createdAt, _id, content, readAt, listingData } = message;
    const shouldShowTimeStamp = previousMsgDate ? getHoursDifference(createdAt, previousMsgDate) > 2 : false;
    const shouldShowReadIcon = Boolean(side === 'right' && showReadIcon && readAt);
    const { ref, inView } = useInView({ triggerOnce: true, delay: 300, skip: !!readAt || side === 'right' });
    const navigate = useSafeNavigate();

    useEffect(() => {
        if (inView) {
            markAsSeen(_id);
        }
    }, [inView, _id, markAsSeen]);

    const handleNavigate = useCallback(() => {
        assertIsDefined(listingData?.id, 'listingId missing?');

        closeDrawer();
        navigate(`/listing/${listingData.id}`);
    }, [listingData, navigate]);

    return (
        <motion.div
            ref={ref}
            style={{ width: '100%', margin: '2px 0' }}
            initial={{ y: 40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 35 }}
        >
            {shouldShowTimeStamp && <TimeStamp>{formatFlexibleDateTime(createdAt)}</TimeStamp>}

            <BubbleRow side={side}>
                <MessageBubble side={side}>
                    <MessageContent>
                        {listingData && (
                            <>
                                <ListingButton onClick={handleNavigate} variant="outlined">
                                    <img
                                        loading="lazy"
                                        src={listingData.imageUrl}
                                        alt={listingData.title}
                                        style={{ height: '45px', width: '45px', padding: '2px', borderRadius: 10, objectFit: 'cover' }}
                                    />
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            padding: '0 10px',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            opacity: 0.8,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {listingData.title}
                                    </Typography>
                                    <ChevronRight style={{ width: '30px' }} />
                                </ListingButton>
                            </>
                        )}

                        <div style={{ display: 'inline-block', padding: '15px 10px' }}>
                            <TextContent side={side} variant="body2" text={content} />
                            <ChatBubbleTimeStamp className={side}>{formatTime(createdAt)}</ChatBubbleTimeStamp>
                            {shouldShowReadIcon && (
                                <Tooltip title={<Trans i18nKey="messageReadTooltip" values={{ time: formatLongDateAndTime(readAt) }} />}>
                                    <ReadAtIcon className={side} />
                                </Tooltip>
                            )}
                        </div>
                    </MessageContent>
                </MessageBubble>
            </BubbleRow>
        </motion.div>
    );
});

export interface ChatMessagesProps {
    messageHistory: InfiniteData<Message[]> | undefined;
    queryKey: [string, { conversationId: string | undefined }];
    userId: string;
}

export const ChatMessages: ReactFCWithChildren<ChatMessagesProps> = ({ messageHistory, queryKey, userId }) => {
    const flattenedMessages = messageHistory?.pages.flat() || [];
    const markAsSeen = useMarkAsSeen(queryKey);
    const showReadIcon = useHasPermission('view_read_at_timestamps');

    return (
        <>
            {flattenedMessages.map((msg, i) => (
                <MessageItem
                    key={msg.createdAt}
                    message={msg}
                    previousMsgDate={flattenedMessages[i + 1]?.createdAt}
                    markAsSeen={markAsSeen}
                    showReadIcon={showReadIcon}
                    side={msg.senderId === userId ? 'right' : 'left'}
                />
            ))}
        </>
    );
};
