import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Badge, Box, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { RobesLogoFull } from '../components/RobesLogo/RobesLogoFull';
import { NavigationListContainer } from './NavigationList.components';
import { useLocation } from 'react-router-dom';
import { Home, CheckroomOutlined, AddCircleOutline, DoorSlidingOutlined, AccountCircle } from '@mui/icons-material';
import { RLogo } from '../components/RobesLogo/RLogo';
import { NavigationPreventedModal } from '../components/RootContainer/Footer/NavigationPreventedModal';
import { useTranslation } from 'react-i18next';
import { useActionRequiredTxCount } from '../transactions/hooks/useActionRequiredTxCount';
import { useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { selectToken } from '../store/userReducer';
import { StyledLink } from '../components/Styled/Styled.components';
import { useSafeNavigate } from '../hooks/useSafeNavigate';

const NavigationListFooter: React.FC<{ isNarrow: boolean }> = ({ isNarrow }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!isNarrow) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.8rem', padding: '15px', gap: '5px' }}>
                <StyledLink to="/privacy-policy">{t('privacyPolicy')}</StyledLink>
                <StyledLink to="/terms-of-service">{t('termsOfService')}</StyledLink>
            </div>
        );
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
                aria-controls={open ? 'more-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link variant="body2" sx={{ color: (t) => t.palette.text.primary, textDecoration: 'none' }} href="/privacy-policy">
                        {t('privacyPolicy')}
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link variant="body2" sx={{ color: (t) => t.palette.text.primary, textDecoration: 'none' }} href="/terms-of-service">
                        {t('termsOfService')}
                    </Link>
                </MenuItem>
            </Menu>
        </Box>
    );
};

interface NavigationListItem {
    label: React.ReactNode;
    value: string;
    icon: React.ReactNode;
    isProtected?: boolean;
    showBadge?: boolean;
}

export const NavigationList: React.FC<{ isNarrow: boolean }> = ({ isNarrow }) => {
    const [preventedRoute, setPreventedRoute] = useState<string | null>(null);
    const { t } = useTranslation();
    const { data } = useActionRequiredTxCount();
    const userToken = useAppSelector(selectToken);

    const location = useLocation();
    const navigate = useSafeNavigate();
    const navWidth = isNarrow ? 60 : 240;

    const handleClickListItem = (route: NavigationListItem) => {
        if (route.isProtected && !userToken) {
            setPreventedRoute(route.value);
        } else {
            navigate(route.value);
        }
    };

    const handleLogoClick = () => {
        if (location.pathname === '/') {
            return;
        }
        navigate('/');
    };

    const listItems: NavigationListItem[] = [
        { label: t('home'), value: '/', icon: <Home fontSize="small" /> },
        { label: t('allListings'), value: '/listings', icon: <CheckroomOutlined fontSize="small" /> },
        { label: t('addListing'), value: '/add-listing', isProtected: true, icon: <AddCircleOutline fontSize="small" /> },
        { label: t('wardrobe'), value: '/wardrobe', isProtected: true, icon: <DoorSlidingOutlined fontSize="small" />, showBadge: Boolean(data) },
        { label: t('profile'), value: '/profile', isProtected: true, icon: <AccountCircle fontSize="small" /> },
    ];

    return (
        <NavigationListContainer sx={{ width: navWidth }}>
            <Box onClick={handleLogoClick} sx={{ p: 2, '&:hover': { cursor: 'pointer' } }}>
                {isNarrow ? <RLogo height={30} containerStyle={{ justifyContent: 'center' }} /> : <RobesLogoFull height={50} />}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <>
                    <List>
                        {listItems.map((item) => (
                            <ListItem key={item.value} disableGutters>
                                {isNarrow ? (
                                    <ListItemButton onClick={() => handleClickListItem(item)} disableGutters sx={{ justifyContent: 'center' }}>
                                        {item.showBadge ? (
                                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                                <Badge badgeContent={data} color="primary" variant="dot">
                                                    {item.icon}
                                                </Badge>
                                            </ListItemIcon>
                                        ) : (
                                            <ListItemIcon sx={{ justifyContent: 'center' }}>{item.icon}</ListItemIcon>
                                        )}
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton selected={location.pathname === item.value} onClick={() => handleClickListItem(item)}>
                                        {item.showBadge ? (
                                            <ListItemIcon>
                                                <Badge badgeContent={data} color="primary" variant="dot">
                                                    {item.icon}
                                                </Badge>
                                            </ListItemIcon>
                                        ) : (
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                        )}
                                        <ListItemText
                                            primary={item.label}
                                            sx={{ fontSize: '0.8rem' }}
                                            primaryTypographyProps={{ fontSize: '0.8rem' }}
                                        />
                                    </ListItemButton>
                                )}
                            </ListItem>
                        ))}
                    </List>

                    <NavigationPreventedModal preventedRoute={preventedRoute} onClose={() => setPreventedRoute(null)} />
                </>
            </Box>

            <NavigationListFooter isNarrow={isNarrow} />
        </NavigationListContainer>
    );
};
