import { styled } from '@mui/material';
import { motion } from 'framer-motion';

const fullScreenModalZIndex = 10000;

export const FullScreenModal = styled(motion.div)(({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: fullScreenModalZIndex,
    pointerEvents: 'auto',
}));

export const FullScreenTopbar = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    padding: '10px',
    top: 'env(safe-area-inset-top, 0px)',
    zIndex: fullScreenModalZIndex + 1,
}));

export const FullScreenFooter = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    padding: '10px',
    bottom: 'env(safe-area-inset-bottom, 0px)',
    zIndex: fullScreenModalZIndex + 1,
}));
