import React from 'react';
import { AnimationProps, LayoutProps, motion, MotionStyle, Transition, Variants } from 'framer-motion';

interface AnimatedContainerProps {
    children: React.ReactNode;
    variants: Variants;
    style?: MotionStyle;
    custom?: any;
    initial?: AnimationProps['initial'];
    animate?: AnimationProps['animate'];
    transition?: Transition;
    layout?: LayoutProps['layout'];
    onAnimationComplete?: () => void;
}

export const AnimatedContainer = React.forwardRef<HTMLDivElement, AnimatedContainerProps>(
    ({ children, style, variants, custom, animate, initial, transition, layout, onAnimationComplete }, ref) => (
        <motion.div
            layout={layout}
            ref={ref}
            variants={variants}
            initial={initial || 'initial'}
            animate={animate || 'in'}
            exit="out"
            style={style}
            custom={custom}
            transition={transition}
            onAnimationComplete={onAnimationComplete}
        >
            {children}
        </motion.div>
    ),
);
