import { Camera } from '@capacitor/camera';
import { useState } from 'react';
import { imageApiClient } from '../../services/sharetribe/apiClients';
import { AxiosRequestConfig } from 'axios';
import { Image } from '../../types/apiTypes';
import { UnifiedImage } from '../ImageUploader/ImageUploader.types';
import { assertIsDefined, isAxiosError } from '../../helpers/commonHelpers';
import { Toast } from '@capacitor/toast';
import { useTranslation } from 'react-i18next';

const uploadFn = async (image: UnifiedImage, onProgress: (progress: number) => void): Promise<Image> => {
    const formData = new FormData();

    assertIsDefined(image.file, 'Trying to upload an already uploaded image');

    formData.append('file', image.file, image.file.name);

    const config: AxiosRequestConfig = {
        timeout: 2 * 60 * 1000,
        onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            return onProgress(percentCompleted);
        },
    };

    const { data: uploadedImage } = await imageApiClient.post<{ data: { data: { id: { uuid: string } } } }>('/upload', formData, config);

    return {
        uuid: uploadedImage.data.data.id.uuid,
        url: image.url,
    };
};

export const useImageUpload = (uploadedImages: Image[], maxImages: number) => {
    const [images, setImages] = useState<UnifiedImage[]>(uploadedImages.map((img) => ({ id: img.uuid!, url: img.url, uploaded: true })));
    const [progress, setProgress] = useState<number>(0);

    const { t } = useTranslation();

    const pickImages = async () => {
        if (images.length >= maxImages) {
            // Handle max limit reached
            return;
        }

        try {
            const { photos: pickedImages } = await Camera.pickImages({
                limit: maxImages - images.length,
                quality: 90,
            });

            const msNow = new Date().getTime();

            const newImages = await Promise.all(
                pickedImages.map(async (image, index) => {
                    const imageInTempStorage = await fetch(image.webPath!);
                    const blob = await imageInTempStorage.blob();
                    const file = new File([blob], `image_${msNow + index}.jpeg`, { type: 'image/jpeg' });

                    return {
                        id: `image_${msNow + index}`,
                        url: URL.createObjectURL(file),
                        file,
                    };
                }),
            );

            if (images.length + newImages.length > maxImages) {
                // Slice the newImages to fit limit
                const slicedNewImages = newImages.slice(0, maxImages - images.length);
                setImages([...images, ...slicedNewImages]);
            } else {
                setImages([...images, ...newImages]);
            }
        } catch (e) {
            const permissionStatus = await Camera.checkPermissions();

            if (permissionStatus.photos === 'denied') {
                const text = t('imagePermissionsNotGranted');
                await Toast.show({ text });
            }

            console.error(e);
        }
    };

    const uploadImages = async () => {
        const unUploadedImages = images.filter((img) => !img.uploaded);

        const uploadPromises = unUploadedImages.map((image, index) => {
            return new Promise<Image>((resolve, reject) => {
                if (image.uploadError === 'image-max-size-exceeded' || image.uploadError === 'image-invalid-content') {
                    return;
                }

                uploadFn(image, (progress) => {
                    // Calculate progress for each image and aggregate
                    const totalProgress = ((index + progress / 100) / images.length) * 100;
                    setProgress(totalProgress);
                })
                    .then((uploadedImage) => {
                        setImages((prevImages) => {
                            const updatedImages = [...prevImages];
                            updatedImages[index].uploaded = true;
                            return updatedImages;
                        });

                        resolve(uploadedImage);
                    })
                    .catch((err) => {
                        if (isAxiosError(err)) {
                            const message = err.response?.data?.error.message;

                            setImages((prevImages) => {
                                const updatedImages = [...prevImages];
                                updatedImages[index].uploadError = message;
                                return updatedImages;
                            });
                        }

                        reject(err);
                    });
            });
        });

        const results = await Promise.all(uploadPromises);

        return results;
    };

    return {
        pickImages,
        setImages,
        uploadImages,
        images,
        progress,
    };
};
