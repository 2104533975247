import React, { useRef, useState } from 'react';
import { FieldError, FieldErrors, useFormContext } from 'react-hook-form';
import { useDrawer } from '../../context/drawer';
import { assertIsDefined, noop } from '../../helpers/commonHelpers';
import { Box, Button } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormCheckbox } from './components/FormCheckbox';
import { FormPhoneField } from './components/FormPhoneField';
import { FormTimeField } from './components/FormTimeField';
import { FormField, FormFieldActions } from './FormRenderer.types';
import { FormTextField } from './components/FormTextField';
import { FormSelect } from './components/FormSelect';
import { FormSelectMenu } from './components/FormSelectMenu';

interface FormRendererProps {
    formFields: FormField[];
    fieldName?: string; // Used for nested forms, gathers all values under this field name;
    onSelect?: (drawerName: string) => void;
}

const renderErrorMessage = (t: TFunction, errors: FieldErrors, controlName: string) => {
    const error = errors[controlName] as FieldError;

    if (!error) return false;

    switch (error.type) {
        case 'pattern': {
            return t('invalidFormat');
        }

        case 'required': {
            return t('requiredField');
        }

        default: {
            return error.message;
        }
    }
};

export const FormRenderer: React.FC<FormRendererProps> = ({ formFields, fieldName }) => {
    const {
        register,
        control,
        setValue,
        trigger,
        getValues,
        formState: { errors },
    } = useFormContext();

    const { t } = useTranslation();

    const initialFormState = useRef<Record<string, unknown>>(fieldName ? { [fieldName]: getValues(fieldName) } : getValues());

    // Temporary state for storing unconfirmed values
    const [tempFormState, setTempFormState] = useState<Record<string, unknown>>(initialFormState.current);

    const handleFieldChange = (name: string, value: unknown) => {
        setTempFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const onConfirm = async () => {
        const fieldNames = Object.keys(tempFormState);

        // Trigger validation only for these fields
        const result = await trigger(fieldNames);

        if (result) {
            const formData = fieldNames.reduce<Record<string, unknown>>((acc, fieldName) => {
                acc[fieldName] = getValues(fieldName);
                return acc;
            }, {});

            Object.entries(formData).forEach(([key, value]) => {
                setValue(key, value, { shouldValidate: true });
            });

            initialFormState.current = { ...tempFormState };

            if (fieldName) {
                closeDrawers(fieldName);
            }
        }
    };

    const onCancel = () => {
        setTempFormState(initialFormState.current);
    };

    const isControlled = formFields.some((field) => field.type === 'actions');

    const { closeDrawers } = useDrawer();

    return (
        <>
            {formFields.map((field) => {
                switch (field.type) {
                    case 'select':
                        const handleOnSelect = () => {
                            if (field.closeDrawerOnSelect) {
                                const controlName = field.parentControl ? field.parentControl : field.controlName;
                                return closeDrawers([controlName]);
                            } else {
                                return noop;
                            }
                        };

                        return <FormSelect key={field.controlName} {...field} onSelect={handleOnSelect} />;

                    case 'select-menu':
                        return <FormSelectMenu key={field.controlName} {...field} />;

                    case 'text':
                        return (
                            <FormTextField
                                t={t}
                                type="text"
                                key={field.controlName}
                                control={control}
                                field={field}
                                isControlled={isControlled}
                                fieldName={fieldName}
                                errors={errors}
                                tempFormState={tempFormState}
                                handleFieldChange={handleFieldChange}
                                register={register}
                                renderErrorMessage={renderErrorMessage}
                            />
                        );

                    case 'number':
                        return (
                            <FormTextField
                                t={t}
                                type="number"
                                key={field.controlName}
                                control={control}
                                field={field}
                                isControlled={isControlled}
                                fieldName={fieldName}
                                errors={errors}
                                tempFormState={tempFormState}
                                handleFieldChange={handleFieldChange}
                                register={register}
                                renderErrorMessage={renderErrorMessage}
                            />
                        );

                    case 'time':
                        return <FormTimeField key={field.controlName} control={control} field={field} closeDrawers={closeDrawers} />;

                    case 'checkbox':
                        return (
                            <FormCheckbox
                                t={t}
                                key={field.controlName}
                                control={control}
                                errors={errors}
                                tempFormState={tempFormState}
                                register={register}
                                isControlled={isControlled}
                                fieldName={fieldName}
                                field={field}
                                handleFieldChange={handleFieldChange}
                                renderErrorMessage={renderErrorMessage}
                            />
                        );

                    case 'phone':
                        return (
                            <FormPhoneField
                                t={t}
                                key={field.controlName}
                                control={control}
                                field={field}
                                isControlled={isControlled}
                                fieldName={fieldName}
                                errors={errors}
                                tempFormState={tempFormState}
                                handleFieldChange={handleFieldChange}
                                register={register}
                                renderErrorMessage={renderErrorMessage}
                            />
                        );

                    case 'actions':
                        const handleActionClick = (action: FormFieldActions['actions'][number]) => {
                            const parentControlName = field.parentControl;

                            assertIsDefined(parentControlName, 'parentControl must be defined when using FormFieldActions');

                            if (action.type === 'cancel') {
                                onCancel();
                                closeDrawers(parentControlName);
                            } else if (action.type === 'confirm') {
                                onConfirm();
                            }
                        };

                        return (
                            <Box sx={{ display: 'flex', width: '100%', gap: '10px', ...field.sx }} key={field.type}>
                                {field.actions.map((action, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            fullWidth
                                            variant={action.variant}
                                            color="primary"
                                            onClick={() => handleActionClick(action)}
                                        >
                                            {action.label}
                                        </Button>
                                    );
                                })}
                            </Box>
                        );

                    default:
                        return null;
                }
            })}
        </>
    );
};
