import { Box, useTheme } from '@mui/material';
import React, { CSSProperties } from 'react';
import RLogoBlack from '../../assets/images/r-logo.png';
import RLogoWhite from '../../assets/images/r-logo-white.png';

export const RLogo: React.FC<{ containerStyle?: CSSProperties; height: number | string }> = ({ containerStyle, height }) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', ...containerStyle }}>
            <img src={theme.palette.mode === 'light' ? RLogoBlack : RLogoWhite} height={height} alt="robes-logo" />
        </Box>
    );
};
