import { Box, Typography } from '@mui/material';
import React from 'react';
import { Transition } from '../../../transactions/types';
import { TransitionName } from '../../../transactions/constants';
import { formatDistanceNow, formatLongDateAndYear } from '../../../helpers/dateAndTimeHelpers';
import { useTranslation } from 'react-i18next';
import { Timeline } from '@mantine/core';
import { Done } from '@mui/icons-material';
import { formatDistance, isAfter, isBefore } from 'date-fns';

const stepConfig: Record<TransitionName, { nextSteps: TransitionName[] }> = {
    [TransitionName.ACCEPT]: {
        nextSteps: [TransitionName.COMPLETE],
    },
    [TransitionName.COMPLETE]: {
        nextSteps: [],
    },
    [TransitionName.CANCEL]: {
        nextSteps: [],
    },
    [TransitionName.CUSTOMER_DECLINE_BEFORE_CONFIRM_PAYMENT]: {
        nextSteps: [],
    },
    [TransitionName.CUSTOMER_CANCEL]: {
        nextSteps: [],
    },
    [TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT]: {
        nextSteps: [],
    },
    [TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT]: {
        nextSteps: [],
    },
    [TransitionName.OPERATOR_DECLINE]: {
        nextSteps: [],
    },
    [TransitionName.OPERATOR_DECLINE_NO_REFUND]: {
        nextSteps: [],
    },
    [TransitionName.CANCEL_NO_REFUND]: {
        nextSteps: [],
    },
    [TransitionName.CONFIRM_PAYMENT]: {
        nextSteps: [TransitionName.ACCEPT, TransitionName.COMPLETE],
    },
    [TransitionName.DECLINE]: {
        nextSteps: [],
    },
    [TransitionName.REQUEST_PAYMENT]: {
        nextSteps: [TransitionName.CONFIRM_PAYMENT, TransitionName.ACCEPT, TransitionName.COMPLETE],
    },
    [TransitionName.REVIEW_1_BY_CUSTOMER]: {
        nextSteps: [TransitionName.REVIEW_2_BY_PROVIDER],
    },
    [TransitionName.REVIEW_1_BY_PROVIDER]: {
        nextSteps: [TransitionName.REVIEW_2_BY_CUSTOMER],
    },
    [TransitionName.REVIEW_2_BY_CUSTOMER]: {
        nextSteps: [TransitionName.COMPLETE],
    },
    [TransitionName.REVIEW_2_BY_PROVIDER]: {
        nextSteps: [TransitionName.COMPLETE],
    },
};

export const TransitionTimeline: React.FC<{
    transitions: Transition[];
    lastTransition: TransitionName;
    bookingStart: string;
    bookingEnd: string;
}> = ({ transitions, lastTransition, bookingStart, bookingEnd }) => {
    const { t } = useTranslation();
    const { nextSteps } = stepConfig[lastTransition];
    const now = new Date();
    const bookingStartDate = new Date(bookingStart);
    const bookingEndDate = new Date(bookingEnd);

    const steps: (TransitionName | { label: string; description: string })[] = [];

    transitions.forEach((transition) => {
        if (transition.transition === TransitionName.REQUEST_PAYMENT) {
            return;
        }

        const label =
            transition.transition === TransitionName.CONFIRM_PAYMENT ? `${t(`history-${transition.transition}`)}` : t(transition.transition);

        steps.push({
            label,
            description: `${t(transition.by)}, ${formatLongDateAndYear(transition.createdAt)}`,
        });
    });

    const getActiveStep = () => {
        let activeStep = transitions.length;

        if (transitions.findIndex((el) => el.transition === TransitionName.REQUEST_PAYMENT) > -1) {
            activeStep--;
        }

        return activeStep;
    };

    const activeStep = getActiveStep();

    // If rental can be completed succesfully (nextSteps includes COMPLETE) and the transaction is accepted by the provider,
    // display additional steps that are not listed in transition history but are useful for the user to know what
    // is the current state of the transaction
    if (nextSteps.includes(TransitionName.COMPLETE) && lastTransition === TransitionName.ACCEPT) {
        const rentalNotStarted = isBefore(now, bookingStartDate);
        const rentalOngoing = isAfter(now, bookingStartDate) && isBefore(now, bookingEndDate);

        if (rentalNotStarted) {
            steps.push({ label: t('rentalNotStartedYet'), description: t('startsIn', { time: formatDistanceNow(bookingStart) }) });
        }

        if (rentalOngoing) {
            steps.push({ label: t('rentalOngoing'), description: t('rentalEndsIn', { time: formatDistance(now, bookingEndDate) }) });
        }
    }

    nextSteps.forEach((step) => {
        steps.push({ label: t(step), description: '' });
    });

    return (
        <Box sx={{ my: 3, width: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                {t('rentalTimeline')}
            </Typography>

            <Timeline active={activeStep} reverseActive color="orange">
                {steps.reverse().map((step, i) => {
                    if (typeof step === 'string') {
                        return (
                            <Timeline.Item
                                key={step}
                                title={
                                    <Typography variant="body1" color="text.primary">
                                        {t(step)}
                                    </Typography>
                                }
                                color="text.primary"
                            />
                        );
                    }

                    return (
                        <Timeline.Item
                            key={step.label}
                            {...(steps.length - i <= activeStep && { bullet: <Done sx={{ fontSize: '0.8rem' }} /> })}
                            title={
                                <Typography variant="body1" color="text.primary">
                                    {step.label}
                                </Typography>
                            }
                        >
                            <Typography variant="body2" sx={{ opacity: 0.8 }} color="text.primary">
                                {step.description}
                            </Typography>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </Box>
    );
};
