import React from 'react';
import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { ErrorElement, StyledSpan } from '../../App.components';
import { logger } from '../../helpers/logger';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { sendToSentry } from '../../helpers/commonHelpers';

export const ErrorFallback: React.FC = () => {
    const error = useRouteError();
    logger.error(error);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: (t) => t.palette.text.primary,
            }}
        >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                <Trans i18nKey="somethingWentWrong" />
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
                <Trans i18nKey="returnHomeText">
                    Press
                    <StyledSpan
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        here
                    </StyledSpan>
                    to return to home page
                </Trans>
            </Typography>

            <Typography variant="body2" sx={{ mt: 2, maxWidth: '70%', textAlign: 'center' }}>
                <Trans i18nKey="ifProblemPersists">
                    If the problem persists, please contact us at <StyledSpan> hello@robesrental.com </StyledSpan>, and we will contact you as soon as
                    possible.
                </Trans>
            </Typography>
        </Box>
    );
};

const handleError = (error: Error, info: React.ErrorInfo) => {
    sendToSentry(error, { componentStack: info.componentStack });
};

export const ErrorBoundary: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <ReactErrorBoundary FallbackComponent={ErrorElement} onError={(error, info) => handleError(error, info)}>
        {children}
    </ReactErrorBoundary>
);
