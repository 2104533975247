export const supportedCountries = [
    {
        // Australia
        code: 'AU',
        currency: 'AUD',
        accountConfig: {
            bsb: true,
            accountNumber: true,
        },
    },
    {
        // Austria
        code: 'AT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Belgium
        code: 'BE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Bulgaria
        code: 'BG',
        currency: 'BGN',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Canada
        code: 'CA',
        currency: 'CAD',
        accountConfig: {
            transitNumber: true,
            institutionNumber: true,
            accountNumber: true,
        },
    },
    {
        // Cyprus
        code: 'CY',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Czech Republic
        code: 'CZ',
        currency: 'CZK',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Denmark
        code: 'DK',
        currency: 'DKK',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Estonia
        code: 'EE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Finland
        code: 'FI',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // France
        code: 'FR',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Germany
        code: 'DE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Greece
        code: 'GR',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Hong Kong
        code: 'HK',
        currency: 'HKD',
        accountConfig: {
            clearingCode: true,
            branchCode: true,
            accountNumber: true,
        },
    },
    {
        // Ireland
        code: 'IE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Italy
        code: 'IT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Japan
        code: 'JP',
        currency: 'JPY',
        accountConfig: {
            bankName: true,
            branchName: true,
            bankCode: true,
            branchCode: true,
            accountNumber: true,
            accountOwnerName: true,
        },
    },
    {
        // Latvia
        code: 'LV',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Lithuania
        code: 'LT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Luxembourg
        code: 'LU',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Malta
        code: 'MT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Netherlands
        code: 'NL',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // New Zealand
        code: 'NZ',
        currency: 'NZD',
        accountConfig: {
            accountNumber: true,
        },
    },
    {
        // Norway
        code: 'NO',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Poland
        code: 'PL',
        currency: 'PLN',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Portugal
        code: 'PT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Romania
        code: 'RO',
        currency: 'RON',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Slovakia
        code: 'SK',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Slovenia
        code: 'SI',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Spain
        code: 'ES',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Sweden
        code: 'SE',
        currency: 'SEK',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Switzerland
        code: 'CH',
        currency: 'CHF',
        accountConfig: {
            iban: true,
        },
    },
    {
        // United Kingdom
        code: 'GB',
        currency: 'GBP',
        accountConfig: {
            sortCode: true,
            accountNumber: true,
        },
    },
    {
        // United States
        code: 'US',
        currency: 'USD',
        accountConfig: {
            routingNumber: true,
            accountNumber: true,
        },
    },
];
