import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { fadeInOutScale } from '../../../animations/constants';
import { SuccessSVG } from '../../../components/SuccessSvg/SuccessSvg';
import { RobesLogoFull } from '../../../components/RobesLogo/RobesLogoFull';

export const StripeVerifiedLogo: React.FC = () => {
    const { t } = useTranslation();

    return (
        <AnimatedContainer variants={fadeInOutScale} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />

            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                <SuccessSVG variant="xs" />
                <Typography variant="body2" sx={{ mt: 2 }}>
                    {t('stripeUpToDate')}
                </Typography>
            </Box>
        </AnimatedContainer>
    );
};
