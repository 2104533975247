import { ThemeOptions } from '@mui/material/styles';

export const darkModeTheme: ThemeOptions = {
    palette: {
        mode: 'dark' as const,
        primary: {
            main: '#EC630B',
            light: '#ff7961',
            dark: '#c8642e',
        },
        secondary: {
            main: '#7eb5dd',
        },
        common: {
            white: '#fff',
        },
        error: {
            main: '#B00020',
        },
        background: {
            default: '#181A1B',
            paper: '#1b1d1e',
        },
        spinner: {
            background: '#282a2b',
        },
        action: {
            active: '#fff',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#ffffff99',
        },
        wardrobe: {
            emptyListing: '#363535',
        },
        svgIcon: {
            default: '#e3e3e3',
        },
        boxShadow: {
            lighter: '0 1px 3px rgb(0 0 0 / 0.27%)',
            light: '0 2px 4px rgb(0 0 0 / 8%)',
            default: '0 3px 6px rgb(0 0 0 / 10%)',
            medium: '0 5px 10px rgb(0 0 0 / 15%)',
            input: '0 0 0 0.2rem rgb(255 55 0 / 25%)',

            ['elevation-3']: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
            ['elevation-12']: '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
        },
        border: {
            light: '#8080803d',
            lighter: '#a3a3a324',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 820,
            lg: 1240,
            xl: 1920,
        },
    },
    zIndex: {
        drawer: 1000,
        appBar: 1100,
    },
    typography: {
        fontFamily: 'Open Sans',
    },
};

export const lightModeTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#EC630B',
            light: '#ff7961',
            dark: '#c8642e',
        },
        secondary: {
            main: '#000',
        },

        common: {
            white: '#fff',
        },
        error: {
            main: 'rgb(235, 0, 20)',
        },
        background: {
            default: '#f2f2f2',
            paper: '#FFFFFF',
        },
        spinner: {
            background: '#FFFFFF',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
        },
        text: {
            primary: '#323232',
            secondary: 'rgba(32, 33, 37, 0.4)',
        },
        wardrobe: {
            emptyListing: '#e9e6e6',
        },
        svgIcon: {
            default: '#525151',
        },
        boxShadow: {
            lighter: '0 1px 3px rgb(0 0 0 / 0.27%)',
            light: '0 2px 4px rgb(0 0 0 / 8%)',
            default: '0 3px 6px rgb(0 0 0 / 10%)',
            medium: '0 5px 10px rgb(0 0 0 / 15%)',
            input: '0 0 0 0.2rem rgb(255 55 0 / 25%)',

            ['elevation-3']: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
            ['elevation-12']: '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
        },
        border: {
            light: '#2c2a2a59',
            lighter: '#2c2a2a42',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 820,
            lg: 1240,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
};
