import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { strapiApiClient } from '../../../services/sharetribe/apiClients';
import { ListingSearchConfiguration, MemberSearchConfiguration } from '../../../strapi/types';
import { SearchMode } from './useSearch';
import { assertNever } from '../../../helpers/commonHelpers';

interface ConfigurationTypes {
    listing: ListingSearchConfiguration;
    user: MemberSearchConfiguration;
}

export const useSearchConfiguration = <T extends SearchMode>(mode: T): UseQueryResult<ConfigurationTypes[T]> => {
    const url = `${mode}-search-configuration`;

    const getConfiguration = async () => {
        if (mode === 'listing') {
            const { data } = await strapiApiClient.get<ListingSearchConfiguration>(url);
            return data;
        }
        if (mode === 'user') {
            const { data } = await strapiApiClient.get<MemberSearchConfiguration>(url);
            return data;
        }

        assertNever(mode);
    };

    return useQuery([url], getConfiguration);
};
