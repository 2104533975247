import React, { CSSProperties, useMemo } from 'react';
import { Box } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { easeInOutCirc, stationary } from '../../animations/constants';
import { ErrorElement } from '../../App.components';
import { ReactFCWithChildren } from '../../types/types';
import { AnimatedDiv, CustomSpinner, SpinnerBackground } from './ContentLoader.components';

export interface ContentLoaderProps {
    status: 'idle' | 'loading' | 'success' | 'error';
    children: React.ReactNode;
    loadingText?: string | undefined;
    hideLoader?: boolean;
    flex?: boolean;
    error?: unknown;
    style?: CSSProperties;
    skeleton?: JSX.Element;
}

export interface AnimatedContainerProps {
    centered?: boolean;
    flex?: boolean;
    variants: Variants;
    style?: CSSProperties;
}

const AnimatedContainer: ReactFCWithChildren<AnimatedContainerProps> = ({ children, centered, variants, style, flex }) => (
    <AnimatedDiv centered={centered} flex={flex} initial="initial" animate="in" exit="out" variants={variants} style={style}>
        {children}
    </AnimatedDiv>
);

export const Loader: ReactFCWithChildren = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', padding: '30px' }}>
        <SpinnerBackground />
        <motion.svg width="40" height="40" viewBox="0 0 40 40" initial="hidden" animate="visible" style={{ zIndex: 2 }}>
            <CustomSpinner
                cx="20"
                cy="20"
                r="10"
                animate={{
                    pathLength: [0, 1, 0],
                    pathOffset: [0, 1],
                    rotate: 720,
                }}
                transition={{
                    rotate: {
                        repeat: Infinity,
                        duration: 2,
                        ease: 'linear',
                    },
                    pathLength: {
                        repeat: Infinity,
                        duration: 2,
                        ease: 'linear',
                    },
                    pathOffset: {
                        repeat: Infinity,
                        ease: easeInOutCirc,
                        duration: 2,
                    },
                }}
            />
        </motion.svg>
    </Box>
);

export const ContentLoader: ReactFCWithChildren<ContentLoaderProps> = ({
    status,
    children,
    hideLoader,
    loadingText,
    style,
    skeleton,
    error,
    flex = true,
}) => {
    const { t } = useTranslation();
    const showLoader = useMemo(() => status === 'loading' && !hideLoader, [hideLoader, status]);
    const showError = useMemo(() => status === 'error', [status]);

    return (
        <>
            {showLoader && (
                <>
                    {skeleton && <>{skeleton}</>}

                    {!skeleton && (
                        <AnimatedContainer centered key="loader-div" variants={stationary}>
                            <Loader />
                            {loadingText && <p>{t(loadingText)}</p>}
                        </AnimatedContainer>
                    )}
                </>
            )}
            {showError && (
                <AnimatedContainer centered key="error-div" variants={stationary} style={style}>
                    <ErrorElement error={error} />
                </AnimatedContainer>
            )}
            {!showLoader && !showError && (
                <>
                    <AnimatedContainer flex={flex} key="content-div" variants={stationary} style={style}>
                        {children}
                    </AnimatedContainer>
                </>
            )}
        </>
    );
};
