import { ChevronLeft } from '@mui/icons-material';
import { TextField, InputAdornment, IconButton, Divider, Box } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { ListingSearchResults } from '../Listings/Search/ListingSearchResults';
import { UserSearchResults } from '../Listings/Search/UserSearchResults';
import { useSearch } from '../Listings/Search/useSearch';
import { EndAdornmentWrapper, FullScreenDiv, SearchBarContainer, TabsContainer } from './SearchView.components';
import { SwipeableTabs } from '../../components/SwipeableTabs/SwipeableTabs';
import { useTranslation } from 'react-i18next';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { createSearchParams, useLocation } from 'react-router-dom';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { RedirectToHome } from '../../components/RedirectToHome/RedirectToHome';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetFilter, selectFilters } from '../../store/listingsReducer';
import { filtersToQueryString } from '../../helpers/urlHelpers';

export const SearchView: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState(new URLSearchParams(window.location.search).get('keywords') || '');
    const [mode, setMode] = useState<string>(new URLSearchParams(window.location.search).get('mode') || 'listing');

    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const isDesktop = useBreakpoint('sm');
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);

    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useSafeNavigate();

    const { data: listingSearchResults, isLoading: listingSearchLoading } = useSearch(debouncedSearchTerm, 'listing');
    const { data: userSearchResults, isLoading: userSearchLoading } = useSearch(debouncedSearchTerm, 'user');

    const isSearching = debouncedSearchTerm !== searchTerm && !!searchTerm;

    const tabs = [
        {
            label: t('listings'),
            value: 'listing',
            content: (
                <ListingSearchResults results={listingSearchResults || []} searchTerm={searchTerm} loading={listingSearchLoading || isSearching} />
            ),
        },
        {
            label: t('users'),
            value: 'user',
            content: <UserSearchResults results={userSearchResults || []} searchTerm={searchTerm} loading={userSearchLoading || isSearching} />,
        },
    ];

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleClickBackButton = () => {
        navigate(-1);
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    useEffect(() => {
        const { pathname, search } = location;

        // User navigated out of search page
        if (!pathname.includes('/search')) {
            return;
        }

        const params = new URLSearchParams(search);
        const keywords = params.get('keywords');

        const getQueryString = () => {
            const shouldRemoveKeywords = !debouncedSearchTerm && keywords !== '';

            if (shouldRemoveKeywords) {
                params.delete('keywords');
            }

            if (debouncedSearchTerm) {
                params.set('keywords', debouncedSearchTerm);
            }

            params.set('mode', mode);

            const queryStr = params.toString();

            return queryStr ? `?${queryStr}` : '';
        };

        const queryString = getQueryString();

        if (queryString !== search) {
            navigate(`${pathname}${queryString}`, { replace: true });
        }
    }, [debouncedSearchTerm, location, mode]);

    const handleChangeMode = (index: number) => {
        setMode(index === 0 ? 'listing' : 'user');
    };

    const handleSearchListings = (searchTerm: string) => {
        if (mode === 'user') {
            return;
        }

        if (!searchTerm) {
            dispatch(resetFilter('keywords'));
            navigate('/listings');
        } else {
            const queryString = filtersToQueryString({ ...filters, keywords: searchTerm });
            navigate(`/listings?${queryString}`);
        }
    };

    if (isDesktop) {
        return <RedirectToHome />;
    }

    return (
        <FullScreenDiv>
            <SearchBarContainer>
                <Box sx={{ p: 1 }}>
                    <TextField
                        fullWidth
                        value={searchTerm}
                        autoFocus
                        onChange={handleSearchChange}
                        placeholder={t('searchRobes')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchListings(searchTerm);
                            }
                        }}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ChevronLeft onClick={handleClickBackButton} fontSize="large" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <EndAdornmentWrapper>
                                    <IconButton onClick={clearSearch} sx={{ visibility: searchTerm ? 'visible' : 'hidden', height: '40px' }}>
                                        <ClearIcon />
                                    </IconButton>
                                </EndAdornmentWrapper>
                            ),
                        }}
                    />
                </Box>
                <Divider sx={{ width: '100%' }} />
            </SearchBarContainer>

            <TabsContainer>
                <SwipeableTabs tabs={tabs} onChange={handleChangeMode} initialTab={mode === 'listing' ? 0 : 1} />
            </TabsContainer>
        </FullScreenDiv>
    );
};
