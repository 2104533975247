import React from 'react';
import { AddedCredits } from '../../views/UserProfile/hooks/useRedeemPromotionalCode';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { AnimatedCounter } from '../../animations/components/AnimatedCounter';
import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { formatMoney } from '../../helpers/commonHelpers';
import { formatLongDateAndYear } from '../../helpers/dateAndTimeHelpers';
import { SuccessSVG } from '../SuccessSvg/SuccessSvg';

interface PromotionalCodeSubmitSuccessProps {
    addedCredits: AddedCredits;
    currency: string;
    children?: React.ReactNode;
}

export const PromotionalCodeSubmitSuccess: React.FC<PromotionalCodeSubmitSuccessProps> = ({ addedCredits, currency, children }) => {
    return (
        <StaggerList
            style={{ padding: '100px 16px 20px', display: 'flex', gap: '32px', flexDirection: 'column' }}
            custom={{ stagger: 0.3, delay: 0.3 }}
        >
            <StaggerListElement style={{ textAlign: 'center' }}>
                <SuccessSVG variant="xs" />
            </StaggerListElement>

            <StaggerListElement>
                <Typography variant="h6" textAlign="center" sx={{ fontVariationSettings: "'wght' 600" }}>
                    <Trans
                        i18nKey="creditsAddSuccessTitle"
                        components={{
                            counter: (
                                <AnimatedCounter from={0} to={addedCredits.amount} formatFn={(value) => formatMoney(value, currency)} delay={200} />
                            ),
                        }}
                    />
                </Typography>
            </StaggerListElement>

            <StaggerListElement>
                <Typography variant="body2" textAlign="center">
                    <Trans
                        i18nKey={'creditsAddSuccessBody'}
                        values={{ date: formatLongDateAndYear(addedCredits.expiresAt) }}
                        components={{ b: <strong /> }}
                    />
                </Typography>
            </StaggerListElement>

            {children && <StaggerListElement>{children}</StaggerListElement>}
        </StaggerList>
    );
};
