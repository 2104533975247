import React, { useMemo } from 'react';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useListings } from '../../queries/useListings';
import { useAppSelector } from '../../store/hooks';
import { ListingCards, ListingsDesktop } from './ListingCards/ListingCards';
import { useLocation } from 'react-router-dom';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { selectCompactMode } from '../../store/settingsReducer';

import { ListingCardsSkeleton } from './ListingCards/ListingCards.skeleton';
import { filterResultsCountHeightPX, footerHeightPX, headerHeightPX } from '../../constants';

// Because we are using react-virtual and its paddingStart prop (number) instead of css, we need to get the safe area inset from the css variable set in index.css and coerce it to a number
const calcPaddingStart = () =>
    headerHeightPX * 2 + filterResultsCountHeightPX + parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-t'), 10);

const calcPaddingBottom = () => footerHeightPX + parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-b'), 10);

export const Listings: React.FC = () => {
    const { search } = useLocation();

    const compactMode = useAppSelector(selectCompactMode);
    const isDesktop = useBreakpoint('sm');

    const { status, data, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useListings(search);

    const totalItems = useMemo(() => data?.pages[0].meta.totalItems || 0, [data]);

    const handleRefresh = async () => {
        await refetch();
    };

    const paddingStart = calcPaddingStart();
    const paddingBottom = calcPaddingBottom();

    return (
        <>
            <ContentLoader
                status={!data ? status : 'idle'}
                {...(!isDesktop && { skeleton: <ListingCardsSkeleton compactMode={compactMode} paddingStart={paddingStart} /> })}
            >
                {isDesktop ? (
                    <ListingsDesktop
                        listingData={data}
                        hasNextPage={!!hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        noItems={totalItems === 0}
                    />
                ) : (
                    <ListingCards
                        onRefresh={handleRefresh}
                        listingData={data}
                        paddingStart={paddingStart}
                        paddingEnd={paddingBottom}
                        hasNextPage={!!hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        noItems={totalItems === 0}
                    />
                )}
            </ContentLoader>
        </>
    );
};
