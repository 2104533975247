import { ButtonBase, styled } from '@mui/material';

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
    padding: '16px',
    width: '100%',
    display: 'flex',
    minHeight: '30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
        opacity: 0.8,
    },
}));
