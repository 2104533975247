import React, { useState } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import WebSearchBar from './WebSearch';
import { MobileSearchBar } from './MobileSearch';
import { ReactFCWithChildren } from '../../../types/types';

interface SearchContext {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchContext = React.createContext<SearchContext>({ isOpen: false, setIsOpen: () => null });

const SearchProvider: ReactFCWithChildren = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const value = {
        isOpen,
        setIsOpen,
    };

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearchContext = () => {
    const context = React.useContext(SearchContext);

    if (context === undefined) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }

    return context;
};

export const SearchBar: React.FC = () => {
    const isDesktop = useBreakpoint('sm');

    return <SearchProvider>{isDesktop ? <WebSearchBar /> : <MobileSearchBar />}</SearchProvider>;
};
