import { Checkroom } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ExtendedListing } from '../../types/apiTypes';
import { ChatWithUserButton } from '../../components/ChatWithUserButton/ChatWithUserButton';
import { SwipeableDrawer } from '../../components/SwipeableDrawer/SwipeableDrawer';
import { ShowroomDetails } from '../../components/ShowroomDetails/ShowroomDetails';
import { useState } from 'react';
import { StyledSpan } from '../../App.components';
import { IconSection } from '../../components/IconSection.tsx/IconSection';
import { useCurrentUserId } from '../../user/hooks/useUser';

interface ShowroomDrawerProps {
    open: boolean;
    onClose: () => void;
}

const ShowroomDrawer: React.FC<ShowroomDrawerProps> = ({ open, onClose }) => {
    return (
        <SwipeableDrawer open={open} onClose={onClose} fillHeight={false}>
            <Box sx={{ p: 4 }}>
                <ShowroomDetails />
            </Box>
        </SwipeableDrawer>
    );
};

interface TryonSectionProps {
    listing: ExtendedListing;
    isOwnListing: boolean;
}

export const TryonSection: React.FC<TryonSectionProps> = ({ listing, isOwnListing }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const { t } = useTranslation();
    const currentUserId = useCurrentUserId();
    const isPresentInShowroom = listing.publicData.presentInShowroom;
    const offersTryon = listing.publicData.offerTryon;
    const ownerId = listing.author.id;
    const showChatWithOwner = !isPresentInShowroom && currentUserId && !isOwnListing;

    const handleClickShowroom = () => {
        setDrawerOpen(true);
    };

    return (
        <>
            <IconSection
                icon={<Checkroom color="primary" sx={{ mt: '1px' }} />}
                title={
                    <Typography variant="body1" fontWeight="bold">
                        {t('tryonOptionsSectionTitle')}
                    </Typography>
                }
            >
                <Typography variant="body2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                    {!isPresentInShowroom && offersTryon && <Trans i18nKey={'tryonSectionContent'} />}
                    {!isPresentInShowroom && !offersTryon && <Trans i18nKey={'tryOnSectionContentDoesNotOfferTryon'} />}
                    {isPresentInShowroom && (
                        <Trans
                            i18nKey={'tryonSectionPresentInShowroomContent'}
                            components={{
                                showroom: <StyledSpan onClick={handleClickShowroom} />,
                                underline: <span style={{ textDecoration: 'underline' }} />,
                            }}
                        />
                    )}
                </Typography>
            </IconSection>

            <ShowroomDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

            {showChatWithOwner && <ChatWithUserButton listingId={listing.id} userId={ownerId} buttonText={t('chatWithOwner')} sx={{ mb: 1 }} />}
        </>
    );
};
