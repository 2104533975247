import React, { useCallback, useEffect, useRef } from 'react';
import { animate, motion, MotionStyle, PanInfo, useMotionValue, ValueAnimationTransition } from 'framer-motion';
import { Page } from './Page';

interface VirtualizedPageProps {
    currentPage: number;
    pageTotal: number;
    infiniteSwipe?: boolean;
    children: (props: { index: number }) => JSX.Element;
    paginate: (page: number) => void;
}

const containerStyle: MotionStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
};

const transition: ValueAnimationTransition<number> = {
    type: 'spring',
    stiffness: 200,
    damping: 30,
    bounce: 0,
};

export const VirtualizedPage: React.FC<VirtualizedPageProps> = React.memo(({ children, currentPage, paginate, pageTotal, infiniteSwipe = false }) => {
    const initialX = -currentPage * (window.innerWidth || 0);
    const x = useMotionValue(initialX);

    const containerRef = useRef<HTMLDivElement>(null);

    const calculateNewX = useCallback(() => {
        const val = -currentPage * (containerRef.current?.getBoundingClientRect().width || 0);
        return val;
    }, [currentPage]);

    const handleEndDrag = useCallback(
        (_e: Event, dragProps: PanInfo) => {
            const clientWidth = containerRef.current?.clientWidth || 0;

            const { offset, velocity } = dragProps;

            // Skip swipe if at the boundaries and infinite swipe is disabled
            if (!infiniteSwipe && pageTotal) {
                if ((currentPage === 0 && offset.x > 0) || (currentPage === pageTotal - 1 && offset.x < 0)) {
                    animate(x, calculateNewX(), transition);
                    return;
                }
            }

            if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
                animate(x, calculateNewX(), transition);
                return;
            }

            if (offset.x > clientWidth / 7) {
                paginate(-1);
            } else if (offset.x < -clientWidth / 7) {
                paginate(1);
            } else {
                animate(x, calculateNewX(), transition);
            }
        },
        [calculateNewX, x, paginate],
    );

    useEffect(() => {
        const controls = animate(x, calculateNewX(), transition);
        return controls.stop;
    }, [calculateNewX, x]);

    const getRange = () => {
        let range = [-1, 0, 1];

        // If infinite swipe is disabled, do not show pages that are out of bounds
        if (!infiniteSwipe && pageTotal) {
            range = range.filter((rangeValue) => {
                const pageIndex = rangeValue + currentPage;
                return pageIndex >= 0 && pageIndex < pageTotal;
            });
        }

        return range;
    };

    return (
        <motion.div ref={containerRef} style={containerStyle}>
            {getRange().map((rangeValue) => (
                <Page
                    key={rangeValue + currentPage}
                    x={x}
                    onDragEnd={handleEndDrag}
                    pageTotal={pageTotal}
                    infinite={infiniteSwipe}
                    index={rangeValue + currentPage}
                    currentPage={currentPage}
                    renderPage={children}
                />
            ))}
        </motion.div>
    );
});
