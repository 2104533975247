import React, { CSSProperties, MutableRefObject, useRef, useState } from 'react';
import { AnimatePresence, motion, useMotionValue, useTransform } from 'framer-motion';
import { IndicatorContainer, PullToRefreshContainer, RefreshIndicator, RefreshSvg } from './PullToRefresh.components';
import { INDICATOR_SIZE_PX } from './PullToRefresh.constants';
import { ScrollRestore } from '../ScrollRestore/ScrollRestore';

interface PullToRefreshProps {
    onRefresh?: () => Promise<void>;
    children: React.ReactNode;
    offsetY?: number;
    containerStyle?: CSSProperties;
    contentStyle?: CSSProperties;
}

// Max scroll top value for the container to be considered at the top
const SCROLL_TOP_MAX = 50;
// Minimum distance the user must pull down for the refresh indicator to appear
const MINIMUM_PULL_DISTANCE_PX = 50;
// Distance the user must pull down for the refresh to trigger
const REFRESH_THRESHOLD_PX = 250;

/**
 * Emulates native mobile browser pull to refresh functionality (Capacitor does not provide this out of the box)
 */
export const PullToRefresh = React.forwardRef<HTMLDivElement, PullToRefreshProps>(
    ({ onRefresh, children, offsetY = 0, containerStyle, contentStyle }, forwardedRef) => {
        const [isRefreshing, setIsRefreshing] = useState(false);
        const y = useMotionValue(0);
        const containerRef = useRef<HTMLDivElement>(null);
        const indicatorOpacity = useTransform(y, [MINIMUM_PULL_DISTANCE_PX, 100, REFRESH_THRESHOLD_PX], [0, 0.7, 1]);
        const indicatorYPosition = useTransform(y, [MINIMUM_PULL_DISTANCE_PX, REFRESH_THRESHOLD_PX], [0 + offsetY, 50 + offsetY]);
        const indicatorRotation = useTransform(y, [MINIMUM_PULL_DISTANCE_PX, REFRESH_THRESHOLD_PX], [0, 540]);
        const indicatorColor = useTransform(y, [MINIMUM_PULL_DISTANCE_PX, REFRESH_THRESHOLD_PX], ['#ffffff4f', '#EC630B']);
        const pathLength = useTransform(y, [MINIMUM_PULL_DISTANCE_PX, REFRESH_THRESHOLD_PX], [0, 1]);

        const startYRef = useRef<number | null>(null);

        // If the user is using a custom ref, use that instead of the internal one
        const ref = (typeof forwardedRef !== 'function' && forwardedRef) || containerRef;

        const handleTouchStart = (event: React.TouchEvent) => {
            startYRef.current = event.touches[0].clientY;
        };

        const handleTouchMove = (event: React.TouchEvent) => {
            if (!isRefreshing && startYRef.current !== null && ref.current) {
                const isScrollTop = ref.current.scrollTop <= SCROLL_TOP_MAX;

                const deltaY = event.touches[0].clientY - startYRef.current;

                if (deltaY > 0 && isScrollTop) {
                    ref.current.style.overflow = 'hidden';
                    y.set(deltaY);
                } else {
                    startYRef.current = null;
                }
            }
        };

        const handleTouchEnd = async () => {
            if (startYRef.current !== null) {
                startYRef.current = null;
                if (y.get() >= REFRESH_THRESHOLD_PX) {
                    setIsRefreshing(true);
                    if (onRefresh) {
                        // Typically you should pass a callback that uses useQuery's refetch method to refresh the data
                        await onRefresh();
                        setIsRefreshing(false);
                        y.set(0);
                    } else {
                        // Refresh feels more natural if we wait a bit before reloading
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                } else {
                    y.set(0);
                }
            } else {
                y.set(0);
            }

            if (ref.current) {
                ref.current.style.overflow = 'auto';
            }
        };

        return (
            <PullToRefreshContainer style={containerStyle}>
                <AnimatePresence>
                    {isRefreshing ? (
                        <IndicatorContainer
                            key="refresh-indicator"
                            style={{ y: 50 + offsetY }}
                            initial={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{ stiffness: 150, damping: 20 }}
                        >
                            <RefreshIndicator size={INDICATOR_SIZE_PX} />
                        </IndicatorContainer>
                    ) : (
                        <IndicatorContainer style={{ opacity: indicatorOpacity, y: indicatorYPosition }}>
                            <RefreshSvg
                                viewBox="0 0 50 50"
                                style={{
                                    rotate: indicatorRotation,
                                }}
                            >
                                <motion.polyline
                                    points="5,6 14,6 14,15 "
                                    fill="none"
                                    stroke="black"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeMiterlimit={10}
                                    style={{ stroke: indicatorColor }}
                                />
                                <motion.path
                                    d="M13.545,7.396C7.799,11.143,4,17.628,4,25c0,11.598,9.402,21,21,21s21-9.402,21-21S36.598,4,25,4"
                                    fill="none"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeMiterlimit={10}
                                    style={{ pathLength, stroke: indicatorColor }}
                                />
                            </RefreshSvg>
                        </IndicatorContainer>
                    )}
                </AnimatePresence>

                <ScrollRestore isPresent containerRef={ref as MutableRefObject<HTMLDivElement>} />

                <motion.div
                    ref={ref}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        zIndex: 1,
                        overflow: 'auto',
                        ...contentStyle,
                    }}
                >
                    {children}
                </motion.div>
            </PullToRefreshContainer>
        );
    },
);
