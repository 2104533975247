import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import { fetchProfile, handleAuthRedirect } from '../../helpers/authHelpers';
import { userApiClient } from '../../services/sharetribe/apiClients';
import { useAppDispatch } from '../../store/hooks';
import { storeToken } from '../../store/userReducer';
import { User } from '../../types/apiTypes';
import { useSafeNavigate } from '../useSafeNavigate';

interface EmailLoginData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

interface IdpLoginData extends Omit<EmailLoginData, 'password'> {
    idpId: string;
    idpToken: string;
    idpClientId: string;
}

const isIdpSignup = (data: EmailLoginData | IdpLoginData): data is IdpLoginData => 'idpId' in data && 'idpToken' in data && 'idpClientId' in data;

export const useSignUp = (): UseMutationResult<User, unknown, EmailLoginData | IdpLoginData> => {
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const { t } = useTranslation();

    const signUpFn = async (signupData: EmailLoginData | IdpLoginData) => {
        const url = isIdpSignup(signupData) ? '/idp' : '/';
        const { data } = await userApiClient.post<User>(url, signupData);

        return data;
    };

    const queryClient = useQueryClient();

    return useMutation(signUpFn, {
        onSuccess: async (res: User) => {
            const token = JSON.stringify(res);
            dispatch(storeToken(token));

            const { response } = await fetchProfile(token);
            if (response) {
                queryClient.setQueryData(['current-user'], () => response.data);

                handleAuthRedirect(response.data, navigate);
            }
        },

        onError: async (error: AxiosError) => {
            const userExists = error.response?.status === 409;
            const msg = userExists ? t('userExistsError') : t('submitError');
            toast.error(msg);
        },
    });
};
