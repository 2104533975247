import { styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(() => ({
    pointerEvents: 'none',
}));

export const ImageSliderContainer = styled('div')(() => ({
    height: '100%',
    position: 'relative',
}));
