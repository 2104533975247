import React, { useMemo } from 'react';
import { Divider, IconButton, useTheme } from '@mui/material';
import { DragContainer } from '../DragContainer/DragContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCompactMode, toggleCompactMode } from '../../store/settingsReducer';
import { selectChangedStateKeys, setDrawerState } from '../../store/listingsReducer';
import { getFilterBarItems } from './FilterBar.helpers';
import { FilterBarButton } from './FilterBarButton';
import { useTranslation } from 'react-i18next';

export const FilterBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const compactMode = useAppSelector(selectCompactMode);
    const selectedFilters = useAppSelector(selectChangedStateKeys);
    const filtersChanged = selectedFilters.length > 0;

    const { t } = useTranslation();
    const theme = useTheme();

    const filterBarItems = useMemo(() => getFilterBarItems(compactMode, selectedFilters, t), [compactMode, selectedFilters, t]);

    const handleClickItem = (value: string) => {
        switch (value) {
            case 'allFilters':
            case 'category':
            case 'size':
            case 'color':
            case 'brand':
            case 'bodytype':
            case 'delivery':
            case 'price':
            case 'sort':
                dispatch(setDrawerState(value));
                break;
            case 'layout':
                dispatch(toggleCompactMode());
                break;
            default:
                console.error('Unknown filter bar item: ', value);
                break;
        }
    };

    return (
        <DragContainer carouselStyles={{ background: theme.palette.background.paper, padding: '0px 10px 10px' }} gap={8}>
            {filterBarItems.map((item, index) => (
                <React.Fragment key={item.type !== 'divider' ? item.value : index}>
                    {item.type === 'divider' && <Divider key={index} orientation="vertical" flexItem variant="middle" />}
                    {item.type === 'icon' && (
                        <IconButton key={item.value} onClick={() => handleClickItem(item.value)}>
                            {item.icon}
                        </IconButton>
                    )}
                    {item.type === 'button' && (
                        <FilterBarButton
                            key={item.value}
                            item={item}
                            onClick={handleClickItem}
                            selected={selectedFilters.includes(item.value) || (item.value === 'allFilters' && filtersChanged)}
                        />
                    )}
                </React.Fragment>
            ))}
        </DragContainer>
    );
};
