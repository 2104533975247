import { useEffect } from 'react';
import { isNil, noop } from '../helpers/commonHelpers';

export interface Listener {
    addEventListener(name: string, handler: (event?: unknown) => void, opts?: boolean | { [key: string]: unknown }): void;
    removeEventListener(name: string, handler: (event?: unknown) => void, opts?: boolean | { [key: string]: unknown }): void;
}

export type UseEventOptions<T extends Listener> = Parameters<T['addEventListener']>[2];

export function useEvent<T extends Listener>(
    evt: Parameters<T['addEventListener']>[0],
    handler: Parameters<T['addEventListener']>[1],
    target: null | T | Window = window,
    opts: UseEventOptions<T> = {},
): void {
    useEffect(() => {
        if (isNil(target)) {
            return noop;
        }

        target.addEventListener(evt, handler, opts);

        return () => {
            target.removeEventListener(evt, handler, opts);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evt, handler, target, JSON.stringify(opts)]);
}
