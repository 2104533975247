import React from 'react';
import { Modal } from '../../Modal/Modal';
import { Box, Typography, styled } from '@mui/material';
import ShowCase from '../../../assets/images/onboarding/Showcase.png';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { itemVariantsScale } from '../../../animations/constants';
import { useTranslation } from 'react-i18next';
import Profiles from '../../../assets/images/onboarding/Profiles.png';
import { SignupLink } from '../../SignUpLink/SignUpLink';

const ACTIONS_HEIGHT = 80;

const ModalContainer = styled(Box)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    '& .MuiTypography-root': { fontFamily: 'Inter Tight' },
}));

const ModalContent = styled(Box)(() => ({
    width: '100%',
    height: `calc(100% - ${ACTIONS_HEIGHT}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    textAlign: 'center',
}));

const ModalActions = styled(Box)(() => ({
    width: '100%',
    height: ACTIONS_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const AddListingContent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <StaggerList
            custom={{ stagger: 0.3, delay: 0.3 }}
            style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
            <StaggerListElement variants={itemVariantsScale} transition={{ type: 'spring', damping: 9, stiffness: 40 }}>
                <img src={ShowCase} style={{ objectFit: 'contain', width: '100%', maxWidth: '250px' }} />
            </StaggerListElement>

            <StaggerListElement>
                <Typography variant="h6" fontWeight="bold">
                    {t('lendingStepTitle')}
                </Typography>
            </StaggerListElement>

            <StaggerListElement>
                <Typography variant="subtitle2">{t('lendingStepDescription')}</Typography>
            </StaggerListElement>
        </StaggerList>
    );
};

const ProfileContent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <StaggerList
            custom={{ stagger: 0.3 }}
            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
            <StaggerListElement style={{ maxHeight: '70%' }}>
                <img
                    src={Profiles}
                    style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '20px',
                        objectFit: 'contain',
                    }}
                />
            </StaggerListElement>

            <StaggerListElement style={{ flex: '1 0 auto' }}>
                <Typography variant="h6" fontWeight="bold">
                    {t('profileStepTitle')}
                </Typography>
            </StaggerListElement>

            <StaggerListElement>
                <Typography variant="subtitle2">{t('profileStepDescription')}</Typography>
            </StaggerListElement>
        </StaggerList>
    );
};

export const NavigationPreventedModal: React.FC<{ preventedRoute: string | null; onClose: () => void }> = ({ preventedRoute, onClose }) => {
    return (
        <Modal open={Boolean(preventedRoute)} onClose={onClose}>
            {preventedRoute === '/add-listing' && (
                <ModalContainer sx={{ p: 2 }}>
                    <ModalContent>
                        <AddListingContent />
                    </ModalContent>

                    <ModalActions>
                        <SignupLink onClick={onClose} />
                    </ModalActions>
                </ModalContainer>
            )}
            {(preventedRoute === '/wardrobe' || preventedRoute === '/profile') && (
                <ModalContainer sx={{ p: 2 }}>
                    <ModalContent>
                        <ProfileContent />
                    </ModalContent>

                    <ModalActions>
                        <SignupLink onClick={onClose} />
                    </ModalActions>
                </ModalContainer>
            )}
        </Modal>
    );
};
