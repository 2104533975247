import { useTranslation } from 'react-i18next';
import { LinkBehavior } from '../..';
import { Button, ButtonPropsVariantOverrides, SxProps } from '@mui/material';
import { ButtonProps } from '@mui/base/Button';

export const SignupLink: React.FC<{
    fullWidth?: boolean;
    sx?: SxProps;
    variant?: 'contained' | 'outlined' | 'text';
    label?: React.ReactNode;
    onClick?: () => void;
}> = ({ fullWidth, sx, label, onClick, variant = 'contained' }) => {
    const { t } = useTranslation();

    return (
        <Button
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...sx }}
            fullWidth={fullWidth}
            onClick={onClick}
            variant={variant}
            LinkComponent={LinkBehavior}
            href="/login"
        >
            {label ?? t('signUp')}
        </Button>
    );
};
