import { styled } from '@mui/material';
import { motion } from 'framer-motion';

export const ButtonContainer = styled('div')(() => ({
    position: 'absolute',
    borderRadius: '50%',
    top: 0,
    right: 0,
    zIndex: 3,
    padding: '5px',
}));

export const CustomButton = styled(motion.button)(() => ({
    background: 'none',
    border: 'none',
}));
