import { Box, Skeleton, SxProps } from '@mui/material';
import React from 'react';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { fadeInOut } from '../../../animations/constants';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

const skeletonSx: SxProps = {
    animationDuration: '1.2s !important',
    animationDelay: '0.1s',
    '&:after': {
        animationDuration: '1.2s !important',
        animationDelay: '0.1s',
    },
};

const SkeletonItem: React.FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Skeleton animation="wave" variant="circular" width={50} height={50} sx={skeletonSx} />
            <Box sx={{ display: 'flex', ml: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton animation="wave" variant="text" height={20} width={200} sx={skeletonSx} />
                <Skeleton animation="wave" variant="text" height={20} width={200} sx={skeletonSx} />
            </Box>
        </Box>
    );
};

export const SearchResultsSkeleton: React.FC = () => {
    const isDesktop = useBreakpoint('sm');
    const itemsCount = isDesktop ? 4 : 10;
    const arr = Array.from(Array(itemsCount).keys());

    return (
        <AnimatedContainer
            style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px', paddingTop: '16px' }}
            variants={fadeInOut}
        >
            {arr.map((item) => (
                <SkeletonItem key={item} />
            ))}
        </AnimatedContainer>
    );
};
