import React from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { TabListItem, TabButton, TabLabel, Underline, TabContainer } from './TabList.components';

interface TabListProps {
    tabName: string;
    selectedTab: number;
    tabs: { label: string; icon?: JSX.Element }[];
    onClick: (index: number) => void;
}

export const TabList: ReactFCWithChildren<TabListProps> = ({ tabName, selectedTab, tabs, onClick }) => (
    <TabContainer>
        {tabs.map((item, index) => (
            <TabListItem layout key={item.label}>
                <TabButton fullWidth onClick={() => onClick(index)}>
                    <TabLabel selected={index === selectedTab}>
                        {item.icon ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '5px' }}>
                                {item.icon}
                                {item.label}
                            </div>
                        ) : (
                            <>{item.label}</>
                        )}
                    </TabLabel>
                </TabButton>
                {index === selectedTab ? <Underline layoutId={`${tabName}_underline`} /> : null}
            </TabListItem>
        ))}
    </TabContainer>
);
