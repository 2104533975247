import { useMotionValue, animate } from 'framer-motion';
import { useId, useEffect, useMemo } from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { IndicatorContainer, DotContainer, Dot, DotHighlight } from './StepperIndicators.components';
import { useStepperContext } from './Stepper';

const calcvalue = (index: number, imagesCount: number) => {
    const modulo = index % imagesCount;
    return modulo < 0 ? imagesCount + modulo : modulo;
};

interface IndicatorDotListProps {
    indices: number[];
    value: number;
}

const IndicatorDotList: ReactFCWithChildren<IndicatorDotListProps> = ({ indices, value }) => {
    const selectedIndicator = calcvalue(value, indices.length);
    const x = useMotionValue(0);
    const layoutId = useId();

    useEffect(() => {
        if (indices.length < 3) {
            return;
        }
        const newXValue = -Math.max(0, selectedIndicator - 3) * 10;
        animate(x, newXValue, { duration: 0.2 });
    }, [x, indices, selectedIndicator]);

    return (
        <IndicatorContainer>
            <div style={{ display: 'flex' }}>
                {indices.map((idx) => (
                    <DotContainer key={idx} tabIndex={-1} style={{ x }}>
                        <Dot>{idx === selectedIndicator && <DotHighlight layoutId={layoutId} />}</Dot>
                    </DotContainer>
                ))}
            </div>
        </IndicatorContainer>
    );
};

export const StepperIndicators: React.FC = () => {
    const { activeStep, stepsCount } = useStepperContext();
    const indices = useMemo(() => Array.from(Array(stepsCount)).map((_el, i) => i), [stepsCount]);

    return <IndicatorDotList indices={indices} value={activeStep} />;
};
