import { createListenerMiddleware, TypedStartListening, ListenerEffectAPI, addListener, TypedAddListener } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from './types';
import { resetFilters } from './listingsReducer';
import { UpdateFilters } from '../views/Listings/types';

type ResetFiltersEvent = {
    type: 'resetFilters';
    payload: null;
};

type UpdateFilterAndNavigateEvent = {
    type: 'replaceFilterAndNavigate';
    payload: {
        type: UpdateFilters['type'];
        payload: string | [number, number];
    };
};

type EnabledPushNotificationsEvent = {
    type: 'enabledPushNotifications';
    payload: null;
};

export type ListenerEvent = ResetFiltersEvent | UpdateFilterAndNavigateEvent | EnabledPushNotificationsEvent;

export const listenerMiddleware = createListenerMiddleware();

export const dispatchCustomEvent = (event: ListenerEvent['type'], payload?: Record<string, unknown>) => {
    const e = new CustomEvent(event, { detail: payload });
    document.dispatchEvent(e);
};

listenerMiddleware.startListening({
    actionCreator: resetFilters,
    effect: () => {
        dispatchCustomEvent('resetFilters');
    },
});

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>; // Parameters<typeof startAppListening>[0]['effect']

// don't export this, if you wanna listen for an action, export an effect and call it here
// const listen = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;
