import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import bookingReducer from './bookingReducer';
import listingsReducer from './listingsReducer';
import settingsReducer from './settingsReducer';
import userReducer from './userReducer';
import searchHistoryReducer from './searchHistoryReducer';
import appStateReducer from './appStateReducer';
import { listenerMiddleware } from './listeners';

const persistConfig = {
    key: 'robesroot',
    storage,
    blacklist: ['listings', 'app-state', 'appState'],
};

const appReducer = combineReducers({
    user: userReducer,
    listings: listingsReducer,
    settings: settingsReducer,
    booking: bookingReducer,
    searchHistory: searchHistoryReducer,
    appState: appStateReducer,
});

const rootReducer = persistReducer(persistConfig, appReducer);

const setupStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleWare) =>
            getDefaultMiddleWare({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            })
                .prepend(listenerMiddleware.middleware)
                .concat(),
    });

export const store = setupStore();
export const persistor = persistStore(store);

// use ./types.d.ts::AppStore instead of this
export type __RTKStore = ReturnType<typeof setupStore>;
