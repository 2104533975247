import { Box, styled } from '@mui/material';
import { motion } from 'framer-motion';

export const ProfileContainer = styled('div')(() => ({
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

export const ProfileImageContainer = styled('div')(({ theme }) => ({
    width: '100px',
    height: '100px',
    background: theme.palette.background.default,
    borderRadius: '50%',
    overflow: 'hidden',
}));

export const SettingsContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    padding: '30px 20px',
}));

export const ButtonContainer = styled(motion.div)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    gap: '10px',
}));
