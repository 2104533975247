import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormRenderer } from '../../components/FormRenderer/FormRenderer';
import {
    getDeliveryDetailsFormFields,
    getDeliveryMethodFormFields,
    getDeliveryTimeFormFields,
    renderDeliveryDetailsValue,
    renderDeliveryMethodValue,
    renderTimeValue,
} from './LogisticsSection.helpers';
import SwipeableFormDrawer from '../../components/SwipeableFormDrawer/SwipeableFormDrawer';
import { useDrawer } from '../../context/drawer';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import { ArrowForwardIos } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { Timeslot } from '../ViewListing/ListingFooter.helpers';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { useDeliveryConfigurationMethods, useOfficeConfigurationMethods } from '../../queries/useDeliveryTiming';
import { ReturnDetailsSection } from './ReturnDetailsSection';
import { deliveryTimeInterval } from '../../constants';
import { RobesAddressSection } from './RobesAddressSection';
import { DisplayReturnTime } from './DisplayReturnTime';
import { DisplayPickupTime } from './DisplayPickupTime';
import { DifferentDayAlertMaybe } from './DifferentDayAlertMaybe';

interface LogisticsSectionProps {
    listingId: string;
    booking: Booking | BookingDraft;
    woltEnabled: boolean;
    showroomEnabled: boolean;
    faceToFaceEnabled: boolean;
    timeslots: Timeslot[] | undefined;
}

export const LogisticsSection: React.FC<LogisticsSectionProps> = ({
    listingId,
    booking,
    timeslots,
    woltEnabled,
    showroomEnabled,
    faceToFaceEnabled,
}) => {
    const { watch } = useFormContext();

    const deliveryMethod = watch('deliveryMethod');
    const deliveryTime = watch('deliveryTime');

    const { t } = useTranslation();

    const { openDrawersState, openDrawer, closeDrawers } = useDrawer();

    const handleClickDrawerButton = (name: string) => {
        if (openDrawersState[name]) {
            closeDrawers(name);
        } else {
            openDrawer(name, true);
        }
    };

    const handleCloseDrawer = (name: string) => {
        closeDrawers(name);
    };

    const bookingDetails = { booking, woltEnabled, showroomEnabled, deliveryMethod, faceToFaceEnabled, timeslots };
    const params = { listingId, bookingStart: booking.range.bookingStart as string, bookingEnd: booking.range.bookingEnd as string };

    const officeConfigurationMethods = useOfficeConfigurationMethods();
    const deliveryConfigurationMethods = useDeliveryConfigurationMethods(params);
    const methods = { ...officeConfigurationMethods, ...deliveryConfigurationMethods };

    const deliveryMethodFormFields = getDeliveryMethodFormFields(t, 'deliveryMethod', bookingDetails, methods);
    const deliveryDetailsFormFields = getDeliveryDetailsFormFields(t, 'deliveryDetails');
    const deliveryTimeFormFields = getDeliveryTimeFormFields(t, bookingDetails, methods);

    const showReturnDetailsSection = deliveryMethod === 'wolt' && deliveryTime;

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px', py: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('delivery')}
            </Typography>

            <SwipeableFormDrawer
                id="deliveryMethod"
                label={t('selectDeliveryMethod')}
                buttonLabel={t('deliveryMethod')}
                open={openDrawersState.deliveryMethod || false}
                onClick={() => handleClickDrawerButton('deliveryMethod')}
                onClose={() => handleCloseDrawer('deliveryMethod')}
                renderValue={renderDeliveryMethodValue()}
                sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={
                    <StyledSvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M450.001-177.23v-285.54L200-607.539v278.615q0 3.078 1.539 5.77 1.538 2.693 4.615 4.616L450.001-177.23Zm59.998 0 243.847-141.308q3.077-1.923 4.615-4.616 1.539-2.692 1.539-5.77v-278.615L509.999-462.77v285.54Zm-66.153 65.459L176.155-265.847q-17.077-9.846-26.615-26.308-9.539-16.461-9.539-36.153v-303.384q0-19.692 9.539-36.153 9.538-16.462 26.615-26.308l267.691-154.076q17.077-9.846 36.154-9.846t36.154 9.846l267.691 154.076q17.077 9.846 26.615 26.308 9.539 16.461 9.539 36.153v303.384q0 19.692-9.539 36.153-9.538 16.462-26.615 26.308L516.154-111.771q-17.077 9.846-36.154 9.846t-36.154-9.846ZM634.23-603.999l92.771-53.231-240.847-139.308q-3.077-1.923-6.154-1.923t-6.154 1.923l-86.847 50L634.23-603.999ZM480-514.461l93.001-53.846L326-711.077l-93.001 53.847L480-514.461Z" />
                        </svg>
                    </StyledSvgIcon>
                }
            >
                <FormRenderer formFields={deliveryMethodFormFields} />
            </SwipeableFormDrawer>

            {deliveryMethod === 'wolt' && (
                <>
                    <Divider />
                    <SwipeableFormDrawer
                        id="deliveryDetails"
                        label={t('logisticsSectionDeliveryDetails')}
                        open={openDrawersState.deliveryDetails || false}
                        buttonLabel={t('logisticsSectionDeliveryDetails')}
                        onClick={() => handleClickDrawerButton('deliveryDetails')}
                        onClose={() => handleCloseDrawer('deliveryDetails')}
                        renderValue={renderDeliveryDetailsValue()}
                        sx={{
                            padding: '16px 16px calc(16px + env(safe-area-inset-bottom))',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                        }}
                        buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                        buttonPrefix={
                            <StyledSvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path d="M220.001-180.001v-320h-87.689L480-813.075l156.923 141.692v-97.846h92.306v180.46l98.459 88.768h-87.689v320H524.616v-224.615h-89.232v224.615H220.001ZM280-240h95.386v-224.614h209.228V-240H680v-312L480-732 280-552v312Zm95.386-224.614h209.228-209.228Zm28.46-94.771h152.308q0-30.076-22.847-49.422-22.846-19.347-53.307-19.347-30.461 0-53.307 19.312-22.847 19.312-22.847 49.457Z" />
                                </svg>
                            </StyledSvgIcon>
                        }
                    >
                        <FormRenderer formFields={deliveryDetailsFormFields} fieldName="deliveryDetails" />
                    </SwipeableFormDrawer>

                    <Divider />

                    <SwipeableFormDrawer
                        id="deliveryTime"
                        label={t('deliveryTime')}
                        open={openDrawersState.deliveryTime || false}
                        buttonLabel={t('deliveryTime')}
                        onClick={() => handleClickDrawerButton('deliveryTime')}
                        onClose={() => handleCloseDrawer('deliveryTime')}
                        renderValue={renderTimeValue(deliveryMethod, methods, booking, 'deliveryTime', deliveryTimeInterval)}
                        sx={{ padding: '16px 16px calc(16px + env(safe-area-inset-bottom))', display: 'flex', flexDirection: 'column', gap: '15px' }}
                        buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                        buttonPrefix={
                            <StyledSvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path d="m618.924-298.924 42.152-42.152-151.077-151.087V-680h-59.998v212.154l168.923 168.922ZM480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                                </svg>
                            </StyledSvgIcon>
                        }
                    >
                        <FormRenderer formFields={deliveryTimeFormFields} />
                    </SwipeableFormDrawer>

                    <DifferentDayAlertMaybe methods={methods} bookingDetails={bookingDetails} type="delivery" />
                </>
            )}

            {deliveryMethod === 'showroom' && (
                <>
                    <Divider />

                    <RobesAddressSection sectionLabel={t('showroomAddress')} />

                    <Divider />

                    <DisplayPickupTime t={t} methods={methods} bookingDetails={bookingDetails} />

                    <Divider />

                    <DisplayReturnTime
                        t={t}
                        methods={methods}
                        bookingDetails={bookingDetails}
                        icon={
                            <StyledSvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                    <path d="M372.308-267.692 160-480l212.308-212.308L400.615-664 236.616-500H760v-160h40v200H236.616l163.999 164-28.307 28.308Z" />
                                </svg>
                            </StyledSvgIcon>
                        }
                    />
                </>
            )}

            {showReturnDetailsSection && (
                <ReturnDetailsSection
                    booking={booking}
                    bookingDetails={bookingDetails}
                    openDrawersState={openDrawersState}
                    methods={methods}
                    onClickDrawerButton={handleClickDrawerButton}
                    onCloseDrawer={handleCloseDrawer}
                />
            )}

            {deliveryMethod === 'faceToFace' && (
                <Typography variant="caption">
                    <Trans i18nKey="faceToFaceDeliveryDescription" components={{ underline: <span style={{ textDecoration: 'underline' }} /> }} />
                </Typography>
            )}
        </Box>
    );
};
