import React from 'react';
import parse from 'html-react-parser';
import { HTMLContainer } from '../components/HTMLContainer/HTMLContainer';
import { ListingCarousel } from '../views/Home/Categories/Categories.components';
import { CarouselSettings, StrapiComponent } from './types';
import { Box, Grid } from '@mui/material';
import { ListingItem } from '../views/Listings/ListingItem/ListingItem';
import { Listing } from '../types/apiTypes';
import { assertIsDefined, capitalizeEachWord } from '../helpers/commonHelpers';
import { ImageLoader } from '../components/ImageLoader/ImageLoader';
import { DiscountDefinition } from './hooks/useDiscountDefinition';
import { ChipSection } from '../components/ChipSection/ChipSection';
import { TFunction } from 'i18next';
import { PromotionalContentSlider } from '../views/Home/PromotedContent/PromotionalContentSlider';
import { UserCarousel } from './components/UserCarousel';
import { ListingsCollection } from './components/ListingsCollection/ListingsCollection';
import { AccordionGroup } from '../components/AccordionGroup/AccordionGroup';
import { ChatWithSupportButton } from '../components/ChatWithUserButton/ChatWithSupportButton';
import { BrandCarousel } from './components/BrandCarousel';
import { CategoryCarousel } from './components/CategoryCarousel';

type StrapiComponentConfig = {
    imageSize?: number;
    isDesktop?: boolean;
    onListingClick?: (listing: Listing) => void;
    onToggleFavorite?: (listingId: string) => void;
    isFavoritedFn?: (listingId: string) => boolean;
    onChipClick?: (value: string, type: string) => void;
    translate?: TFunction;
};

const defaultConfig: StrapiComponentConfig = {
    imageSize: 135,
};

// Our strapi HTML editor (CKEditor) adds <p> tags around images, which is not what we want. Remove them.
const removeParagraphsAroundImages = (htmlString: string) => {
    // regex to match <p> tags with <img> tags inside them
    const imgInPTagRegex = /<p[^>]*>\s*<img[^>]*>\s*<\/p>/g;

    const modifiedHtml = htmlString.replace(imgInPTagRegex, (match) => {
        // Replace the matched <p> tag with an empty string but keep the <img> tag inside it
        return match.replace(/<p[^>]*>|<\/p>/g, '');
    });

    return modifiedHtml;
};

export const renderStrapiComponent = (component: StrapiComponent, config: StrapiComponentConfig = defaultConfig) => {
    const { isDesktop, imageSize, onListingClick, onToggleFavorite, isFavoritedFn, translate, onChipClick } = config;
    const draggable = !isDesktop;

    if (component.type === 'text') {
        const parsedContent = component.html ? parse(removeParagraphsAroundImages(component.html)) : null;
        return <HTMLContainer>{parsedContent}</HTMLContainer>;
    }

    if (component.type === 'carousel') {
        assertIsDefined(imageSize, 'imageSize should be provided for carousel');

        return (
            <ListingCarousel
                listings={component.listings}
                onClick={(index) => onListingClick?.(component.listings[index])}
                imageDimensions={imageSize}
                draggable={draggable}
            />
        );
    }
    if (component.type === 'list') {
        return (
            <Grid container>
                {component.listings.map((listing) => (
                    <Grid item xs={6} key={listing.id}>
                        <ListingItem
                            listing={listing}
                            compactMode
                            onClick={onListingClick}
                            toggleFavorite={onToggleFavorite}
                            isDesktop={!!isDesktop}
                            isFavorited={!!isFavoritedFn?.(listing.id)}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
    if (component.type === 'image') {
        const { image, blurhash, width, height, fit, position, alignment, maxWidth } = component;
        const style = { objectFit: fit, objectPosition: position, maxWidth };
        const containerStyle = { display: 'flex', justifyContent: alignment };

        return <ImageLoader url={image} hash={blurhash} containerStyle={containerStyle} style={{ ...style, height, width: `${width}%` }} />;
    }

    if (component.type === 'accordion-group') {
        const padding = `${component.paddingY || 0}px ${component.paddingX || 0}px`;
        return <AccordionGroup key={`accordion-group-${component.id}`} accordions={component.accordions} sx={{ padding }} />;
    }

    if (component.type === 'image-container') {
        const { id, images, maxPerRow } = component;
        const itemsPerRow = maxPerRow ? Math.min(images.length + 1, maxPerRow) : images.length;

        return (
            <div key={`image-container-${id}`} style={{ display: 'grid', gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`, gap: '5px' }}>
                {images.map((image) => {
                    const { fit, position, blurhash } = image;
                    const style = { width: '100%', height: '100%', objectFit: fit, objectPosition: position };

                    return (
                        <Box
                            key={image.id}
                            sx={{
                                aspectRatio: '1/1',
                                display: 'flex',
                                alignItems: 'center',
                                '& img': { ...style },
                            }}
                        >
                            <ImageLoader url={image.image} hash={blurhash} style={{ width: '100%', height: '100%' }} />
                        </Box>
                    );
                })}
            </div>
        );
    }

    if (component.type === 'embed') {
        const parsedContent = component.html ? parse(component.html) : null;
        return (
            <Box
                key={`embed-${component.id}`}
                sx={{
                    position: 'relative',
                    width: '100%',
                    paddingBottom: '56.25%',
                    '& iframe': { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '0' },
                }}
            >
                {parsedContent}
            </Box>
        );
    }

    if (component.type === 'brand-section') {
        const { brands, label } = component;
        const chips = brands.map((brand) => ({ value: brand.brand, label: brand.label || capitalizeEachWord(brand.brand) }));

        const handleChipClick = (val: string) => {
            onChipClick?.(val, 'brand');
        };

        return <ChipSection key={component.id} chips={chips} label={label} onClick={handleChipClick} />;
    }

    if (component.type === 'category-section') {
        assertIsDefined(translate, 'translate function should be provided for category section');

        const chips = component.categories.map((category) => ({ value: category.category, label: translate(category.category) }));
        const label = component.label;

        const handleChipClick = (val: string) => {
            onChipClick?.(val, 'category');
        };

        return <ChipSection key={component.id} chips={chips} label={label} onClick={handleChipClick} />;
    }

    if (component.type === 'spacer') {
        if (isDesktop && component.mobileOnly) return null;

        return <Box key={`spacer-${component.id}`} sx={{ width: '100%', height: component.height }} />;
    }

    if (component.type === 'user-section') {
        if (isDesktop) {
            return null;
        }

        return <UserCarousel component={component} />;
    }

    if (component.type === 'user-carousel') {
        return (
            <UserCarousel
                component={component}
                labelTypographyProps={{
                    fontFamily: 'Inter Tight',
                    fontWeight: '400',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    zIndex: 11,
                    fontSize: '1.15rem',
                    ...(isDesktop && { marginLeft: '10px', px: '20px' }),
                }}
                {...(isDesktop && { carouselStyles: { padding: '10px 20px' } })}
            />
        );
    }

    if (component.type === 'promotional-content-slider') {
        return <PromotionalContentSlider content={component.slides} />;
    }

    if (component.type === 'collection') {
        assertIsDefined(isDesktop, 'isDesktop should be provided for collection');

        return <ListingsCollection collection={component} isDesktop={isDesktop} />;
    }

    if (component.type === 'chat-support-button') {
        const padding = `${component.paddingY || 0}px ${component.paddingX || 0}px`;
        return <ChatWithSupportButton key={`chat-support-button-${component.id}`} sx={{ padding }} description={component.description} />;
    }

    if (component.type === 'brand-carousel') {
        return <BrandCarousel component={component} isDesktop={isDesktop} />;
    }

    if (component.type === 'category-carousel') {
        return <CategoryCarousel component={component} isDesktop={isDesktop} />;
    }

    return null;
};

export const getDiscountForCategory = (category: string, definition: DiscountDefinition) => {
    const match = definition.discountGroups.find((group) => group.categories.includes(category));

    if (match) {
        return { weekly: match.weekly, monthly: match.monthly };
    }

    return { weekly: definition.defaultWeekly, monthly: definition.defaultMonthly };
};

export const resolveSlideSettings = (isDesktop: boolean | undefined, carouselSettings: CarouselSettings) => {
    if (isDesktop && carouselSettings.slideSettingsDesktop) {
        return { ...carouselSettings.slideSettings, ...carouselSettings.slideSettingsDesktop };
    }

    return carouselSettings.slideSettings;
};
