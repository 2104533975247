import { Box, styled } from '@mui/material';

export const ListingFooterContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxWidth: 660,
    height: 'calc(80px + env(safe-area-inset-bottom, 0px))',
    padding: '10px 15px calc(env(safe-area-inset-bottom, 0px) + 10px)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.boxShadow['elevation-12'],
}));
