import { InAppBrowser, InAppBrowserEvent } from '@awesome-cordova-plugins/in-app-browser';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { NavigateFunction } from 'react-router-dom';

const CUSTOM_URL_SCHEME = 'robesapp://';

/**
 * Opens a browser window which on native platforms listens for custom url scheme calls and then closes the browser.
 *
 * Ideally we would use Capacitor's native Browser plugin, but that plugin does not have 'close' method on Android which makes it
 * unusable for e.g external identification services, as we need to be able to close the browser after the user is finished in the service.
 *
 * External flows that utilize callbackUrls should first redirect to our own API, where the api redirects back to the app using custom url
 * scheme (TODO: make it an env variable). The event listener added by this function will then close the browser.
 */
export async function openNativeBrowserWithCloseCallback(url: string, navigate: NavigateFunction, beforeClose?: () => Promise<void>): Promise<void> {
    const isNative = Capacitor.isNativePlatform();

    if (isNative) {
        const target = '_blank';
        const options =
            Capacitor.getPlatform() === 'ios'
                ? 'location=no,hidden=yes,beforeload=get,enableViewportScale=yes'
                : 'hideurlbar=yes,hidden=yes,beforeload=yes';

        const browser = InAppBrowser.create(url, target, options);

        const handleBrowserClose = async (event: InAppBrowserEvent) => {
            if (beforeClose) {
                // e.g refetch user data
                await beforeClose();
            }

            const slug = event.url.split(CUSTOM_URL_SCHEME).pop();
            if (slug) {
                const hasLeadingSlash = slug.charAt(0) === '/';
                const resolvedPath = hasLeadingSlash ? slug : `/${slug}`;

                navigate(resolvedPath);
            }

            browser.close();
        };

        // For some reason beforeload event is not fired on android for the CUSTOM_URL_SCHEME, so we need to use loadstart instead and close the browser.
        // Hide the browser while the event is being handled to avoid showing an error page.
        browser.on('loadstart').subscribe(async (event) => {
            if (event.url.includes(CUSTOM_URL_SCHEME)) {
                browser.insertCSS({ code: 'body { visibility: hidden; }' });
                await handleBrowserClose(event);
            }
        });

        // Intercept redirects to the apps custom url scheme and instead just close the IAB, and navigate to the URL slug.
        browser.on('beforeload').subscribe(async (event) => {
            if (!event.url.includes(CUSTOM_URL_SCHEME)) {
                browser._loadAfterBeforeload(event.url);
            } else {
                await handleBrowserClose(event);
            }
        });

        browser.on('loadstop').subscribe(() => {
            browser.show();
        });
    } else {
        await Browser.open({ url, windowName: '_self' });
    }
}
