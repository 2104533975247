import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';

export const ItemTitleText = styled(Typography)(() => ({
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'left',
    margin: '9px 0',
}));

export const ResponsiveContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
}));

export const FavoriteCountContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    '& button': {
        display: 'flex',
    },
}));

export const ItemDescriptionText = styled(Typography)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    marginTop: '16px',
}));

export const ImageContainer = styled(motion.div)(() => ({
    width: '100%',
    position: 'relative',
}));

export const ListingDataContainer = styled(motion.div)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 16px 120px',
    overflowY: 'auto',
    position: 'relative',
    background: theme.palette.background.paper,
}));

export const ProfileContainer = styled('div')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '100px 16px',
    backgroundColor: '#7e7e7e78',
    zIndex: 100,
}));
