import { Box, Chip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

interface ChipSectionProps {
    label: string;
    chips: { value: string; label: string }[];
    onClick?: (value: string) => void;
}

export const ChipSection: React.FC<ChipSectionProps> = ({ label, chips, onClick }) => {
    return (
        <Box component={motion.div} sx={{ mx: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ px: '10px', mb: 1 }}>
                {label}
            </Typography>

            {chips.map((chip) => {
                return <Chip key={chip.value} label={chip.label} sx={{ m: 1 }} onClick={() => onClick?.(chip.value)} />;
            })}
        </Box>
    );
};
