import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useSafeNavigate } from './useSafeNavigate';

export const useAppUrlListener = () => {
    const navigate = useSafeNavigate();

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            const fullUrl = new URL(event.url);

            const pathname = fullUrl.pathname;
            const searchQuery = fullUrl.search;

            if (!pathname) {
                return;
            }

            const fullPathWithQuery = `${pathname}${searchQuery}`;

            navigate(fullPathWithQuery);
        });
    }, []);
};
