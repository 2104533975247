import React, { CSSProperties } from 'react';
import { SvgIcon } from '@mui/material';
import { CustomButton } from './FavoriteButton.components';
import { grey, pink } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { ReactFCWithChildren } from '../../types/types';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';

interface FavoriteButtonProps {
    onClick: () => void;
    selected: boolean;
    size?: 'small' | 'medium' | 'large';
    style?: CSSProperties;
}

export const FavoriteButton: ReactFCWithChildren<FavoriteButtonProps> = ({ size, selected, onClick, style }) => {
    const token = useAppSelector(selectToken);

    if (!token) {
        return null;
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={style}>
            <CustomButton onClick={onClick} whileTap={{ scale: 1.3 }}>
                <SvgIcon fontSize={size || 'medium'} color={selected ? 'primary' : 'action'}>
                    <path
                        // eslint-disable-next-line max-len
                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                        stroke="white"
                        fill={selected ? pink[500] : grey[700]}
                        strokeWidth={1.5}
                    />
                </SvgIcon>
            </CustomButton>
        </motion.div>
    );
};
