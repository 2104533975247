import { styled } from '@mui/material';

export const CarouselContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    height: '60px',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    zIndex: 3,
    background: theme.palette.background.paper,
}));

export const TabContent = styled('div')(() => ({
    overflowY: 'auto',
}));
