import { Box, Typography, Divider, MenuItemProps, MenuItem } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import React from 'react';
import { DigitalClock } from '@mui/x-date-pickers';
import { noop } from '../../../helpers/commonHelpers';
import { FormFieldTime } from '../FormRenderer.types';
import { formatTime } from '../../../helpers/dateAndTimeHelpers';
import { addMinutes } from 'date-fns';

interface TimeFieldProps {
    field: FormFieldTime;
    control: Control<FieldValues, any>;
    closeDrawers: (drawerNames: string[]) => void;
}

const CustomDigitalClockItem = (props: MenuItemProps & { range?: number; maxTime?: Date; disableDisplayRangeOverMaxTime?: boolean }) => {
    if (!props.range) {
        return <MenuItem {...props}>{props.children}</MenuItem>;
    }

    const { maxTime, disableDisplayRangeOverMaxTime, ...rest } = props;

    const date = new Date();

    const hoursAndMinutes = props.children as string;
    const [hours, minutes] = hoursAndMinutes.split(':');

    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    if (maxTime && disableDisplayRangeOverMaxTime) {
        if (date.getHours() === maxTime.getHours() && date.getMinutes() === maxTime.getMinutes()) {
            return null;
        }
    }

    const formattedValue = `${formatTime(date)} - ${formatTime(addMinutes(date, props.range))}`;

    return <MenuItem {...rest}>{formattedValue}</MenuItem>;
};

export const FormTimeField: React.FC<TimeFieldProps> = ({ field, control, closeDrawers }) => {
    const handleSelectTime = () => {
        if (field.parentControl && field.closeDrawerOnSelect) {
            return closeDrawers([field.parentControl]);
        } else {
            return noop;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} key={field.controlName}>
            <Divider />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'auto' }} onTouchStart={(ev) => ev.stopPropagation()}>
                <Controller
                    name={field.controlName}
                    control={control}
                    rules={{ ...field.registerOptions }}
                    render={({ field: fieldRenderer }) => (
                        <DigitalClock
                            key={field.type}
                            value={fieldRenderer.value || null}
                            timeStep={field.range || 30}
                            onChange={(val) => {
                                fieldRenderer.onChange(val);
                                handleSelectTime();
                            }}
                            slotProps={{
                                digitalClockItem: {
                                    // @ts-ignore
                                    range: field.range,
                                    disableDisplayRangeOverMaxTime: field.disableDisplayRangeOverMaxTime,
                                    maxTime: field.maxTime,
                                },
                            }}
                            slots={{ digitalClockItem: CustomDigitalClockItem }}
                            minTime={field.minTime}
                            maxTime={field.maxTime}
                            skipDisabled
                        />
                    )}
                />
            </Box>
            <Divider />

            <Box sx={{ width: '100%', mt: 1 }}>
                {field.alert && <>{field.alert}</>}
                {field.description && <Typography variant="caption">{field.description}</Typography>}
            </Box>
        </Box>
    );
};
