import { Badge, Chip, useScrollTrigger, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { Carousel } from '../Carousel/Carousel';
import { CarouselContainer } from './ChipTabs.components';

interface ChipTabsProps {
    selectedTab: number;
    tabs: { label: string; content: JSX.Element; badgeCount?: number }[];
    onClickTab: (i: number) => void;
}

export const ChipTabs: ReactFCWithChildren<ChipTabsProps> = ({ tabs, selectedTab, onClickTab }) => {
    const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
    const theme = useTheme();
    const scrollTrigger = useScrollTrigger({ disableHysteresis: true, target: scrollTarget, threshold: 0 });

    const carouselItems = useMemo(
        () =>
            tabs.map((tab, index) => ({
                key: tab.label,
                content: (
                    <Badge badgeContent={tab.badgeCount} color="primary">
                        <Chip
                            size="medium"
                            label={tab.label}
                            sx={{ fontSize: '15px', px: '10px' }}
                            variant={selectedTab === index ? 'filled' : 'outlined'}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                onClickTab(index);
                            }}
                        />
                    </Badge>
                ),
            })),
        [selectedTab, tabs],
    );

    return (
        <>
            <CarouselContainer
                style={{
                    ...(scrollTrigger && { boxShadow: theme.palette.boxShadow.medium }),
                }}
            >
                <Carousel
                    items={carouselItems}
                    itemStyle={{ padding: '0 5px' }}
                    carouselStyles={{ padding: '20px 0', width: '100%', display: 'flex', justifyContent: 'center' }}
                />
            </CarouselContainer>

            <div
                key={selectedTab}
                style={{ paddingBottom: '56px' }}
                ref={(node) => {
                    if (node) {
                        setScrollTarget(node);
                    }
                }}
            >
                {tabs[selectedTab].content}
            </div>
        </>
    );
};
