import { ButtonBase, styled } from '@mui/material';

export const StyledLoginButton = styled(ButtonBase)(({ theme }) => ({
    appearance: 'none',
    height: '58px',
    width: '300px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.border.light,
    borderRadius: '5px',
    background: 'white',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    alignItems: 'center',
    '> *': {
        color: 'black',
        textAlign: 'center',
    },
}));

export const TextLoginButton = styled(ButtonBase)(({ theme }) => ({
    appearance: 'none',
    height: '58px',
    width: '300px',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    alignItems: 'center',
    '> *': {
        color: theme.palette.text.primary,
        opacity: 0.7,
        textAlign: 'center',
    },
}));
