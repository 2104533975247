import { useEffect, useId } from 'react';
import { replaceUrlParams } from '../helpers/urlHelpers';

export const useHashChange = (open: boolean, onClose?: () => void) => {
    const identifier = useId();

    useEffect(() => {
        const onHashChange = () => {
            if (!window.location.hash || window.location.hash !== `#${identifier}`) {
                onClose?.();
            }
        };
        window.addEventListener('hashchange', onHashChange);
        return () => window.removeEventListener('hashchange', onHashChange);
    }, [onClose, identifier]);

    useEffect(() => {
        if (open && (!window.location.hash || window.location.hash !== identifier)) {
            window.history.pushState(null, '', `#${identifier}`);
        }

        if (!open && window.location.hash === `#${identifier}`) {
            replaceUrlParams(window.location.search);
        }
    }, [open, identifier]);
};
