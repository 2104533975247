import { Box, Button, Divider, Typography } from '@mui/material';
import { useCurrentUser } from '../../user/hooks/useUser';
import { RedirectToHome } from '../../components/RedirectToHome/RedirectToHome';
import { SettingsContainer } from '../UserProfile/UserProfile.components';
import { useState } from 'react';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { logger } from '../../helpers/logger';

const exampleObj = {
    test: 'test',
    test2: 'test2',
    updateAvailability: 'updateAvailability',
};

export const DeveloperSettings: React.FC = () => {
    const [updateInformation, setUpdateInformation] = useState<AppUpdateInfo>();
    const [updateInformationError, setUpdateInformationError] = useState<string | null>(null);

    const { data } = useCurrentUser();

    if (!data) {
        return null;
    }

    if (!data?.profile.privateData.developer) {
        return <RedirectToHome />;
    }

    const handleSetUpdateInfo = async () => {
        try {
            const info = await AppUpdate.getAppUpdateInfo();
            setUpdateInformation(info);
            logger.info('AppUpdate info', info);
        } catch (error: any) {
            if (error instanceof Error) {
                setUpdateInformationError(error.message);
                logger.error('AppUpdate error', error);
            } else {
                setUpdateInformationError(JSON.stringify(error));
                logger.error('AppUpdate error', error);
            }
        }
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', py: 2 }}>
            <Box>
                <Button onClick={handleSetUpdateInfo} variant="contained">
                    Show AppUpdate information
                </Button>

                {updateInformation && (
                    <pre style={{ width: '100%', overflowX: 'auto', backgroundColor: '#f1f1f1' }}>
                        <code>{JSON.stringify(updateInformation, null, 4)}</code>
                    </pre>
                )}
                {updateInformationError && <Typography color="error">{updateInformationError}</Typography>}
                <Divider sx={{ my: 2 }} />
            </Box>
        </Box>
    );
};
