import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useNativePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        const setScreen = async () => {
            const { pathname, search } = location;
            const screenName = `${pathname}${search}`;
            await FirebaseAnalytics.logEvent({ name: 'screen_view', params: { screen_name: screenName } });
        };

        setScreen();
    }, [location]);
};
