import { Add, ArrowForwardIos, CreditCard } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { PaymentOptionsDrawer } from '../../components/PaymentOptionsDrawer/PaymentOptionsDrawer';
import { assertNever } from '../../helpers/commonHelpers';
import { PaymentMethod } from '../UserProfile/Payments/types';
import { useTranslation } from 'react-i18next';

const PaymentMethodDrawerButton: React.FC<{ paymentMethod: PaymentMethod; openDrawer: () => void }> = ({ paymentMethod, openDrawer }) => {
    if (!paymentMethod) {
        return (
            <Button onClick={openDrawer}>
                <Add sx={{ marginRight: '10px' }} /> Add new payment method
            </Button>
        );
    }

    const getCardText = () => {
        if (paymentMethod === 'card') {
            return 'Credit Card';
        }

        if (paymentMethod === 'google_pay') {
            return 'Google Pay';
        }

        if (paymentMethod === 'apple_pay') {
            return 'Apple Pay';
        }

        return assertNever(paymentMethod);
    };

    return (
        <Button onClick={openDrawer} fullWidth sx={{ textTransform: 'none' }} endIcon={<ArrowForwardIos color="action" />}>
            <div style={{ height: '30px', display: 'flex', gap: '15px', alignItems: 'center', width: '100%' }}>
                {paymentMethod === 'card' && <CreditCard />}
                {paymentMethod === 'google_pay' && <img src="/google-pay.png" alt="google pay logo" height="100%" />}
                {paymentMethod === 'apple_pay' && <img src="/apple-pay.svg" alt="apple pay logo" height="100%" />}

                <Typography variant="overline" sx={{ color: (theme) => theme.palette.text.primary }} fontSize="16px">
                    {getCardText()}
                </Typography>
            </div>
        </Button>
    );
};

interface PaymentOptionsSectionProps {
    selectedPaymentMethod: PaymentMethod;
    isLoading: boolean;
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    onSelectPaymentMethod: (...args: any[]) => void;
}

export const PaymentOptionsSection: React.FC<PaymentOptionsSectionProps> = ({
    open,
    isLoading,
    onClose,
    onOpen,
    onSelectPaymentMethod,
    selectedPaymentMethod,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '15px' }}>
                {t('payWith')}
            </Typography>

            <PaymentMethodDrawerButton paymentMethod={selectedPaymentMethod} openDrawer={onOpen} />

            <PaymentOptionsDrawer
                open={open}
                onClose={onClose}
                onClick={onSelectPaymentMethod}
                selectedItem={selectedPaymentMethod}
                isLoading={isLoading}
            />
        </>
    );
};
