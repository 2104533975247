import { TransactionHistory } from './apiTypes';
import { TransitionName } from './constants';
import { Accept, Decline, LineItem } from './types';

export const hasInitialisedTransaction = (transactionHistory: TransactionHistory): boolean => !!transactionHistory.data.length;

export const getDeclinePayload = (transactionId: string): Decline => ({
    transactionId,
    transition: TransitionName.DECLINE,
    params: {},
});

export const getAcceptPayload = (transactionId: string): Accept => ({
    transactionId,
    transition: TransitionName.ACCEPT,
    params: {},
});

export const filterCustomerLineItems = (lineItems: LineItem[]) => lineItems.filter((item) => item.includeFor.includes('customer'));
export const filterProviderLineItems = (lineItems: LineItem[]) => lineItems.filter((item) => item.includeFor.includes('provider'));
