import { Button, Snackbar, SnackbarCloseReason, Typography } from '@mui/material';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckNewVersionAvailable } from './useCheckNewVersionAvailable';

const AlertContent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                {t('newVersionAvailableTitle')}
            </Typography>
            <Typography variant="body2">{t('newVersionAvailableDescription')}</Typography>
        </div>
    );
};

export const NewVersionAlert: React.FC = () => {
    const [open, setOpen] = useState(true);

    const { t } = useTranslation();

    const handleOpenStore = async () => {
        await AppUpdate.openAppStore({ country: 'FI' });
    };

    return (
        <Snackbar
            open={open}
            message={<AlertContent />}
            sx={{
                top: 'calc(8px + env(safe-area-inset-top))',
                '.MuiSnackbarContent-root': { borderRadius: '15px' },
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transitionDuration={500}
            action={
                <>
                    <Button size="small" onClick={() => setOpen(false)}>
                        {t('dismiss')}
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={handleOpenStore}>
                        {t('updateApp')}
                    </Button>
                </>
            }
        />
    );
};

export const NewVersionAlertMaybe: React.FC = () => {
    const { data: newVersionAvailable } = useCheckNewVersionAvailable();

    if (!newVersionAvailable || typeof newVersionAvailable !== 'boolean') {
        return null;
    }

    return <NewVersionAlert />;
};
