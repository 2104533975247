import { useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { useIsFirstMount } from './useIsFirstMount';
import { Capacitor } from '@capacitor/core';

export const useVirtualKeyboardOpen = (): boolean => {
    const firstMount = useIsFirstMount();
    const [visible, setVisible] = useState(false);

    if (!Capacitor.isPluginAvailable('Keyboard')) {
        return visible;
    }

    if (firstMount) {
        Keyboard.addListener('keyboardWillShow', () => {
            setVisible(true);
        });
        Keyboard.addListener('keyboardWillHide', () => {
            setVisible(false);
        });
    }

    return visible;
};
