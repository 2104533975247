import React from 'react';
import Box from '@mui/material/Box';
import { useIdpLogin } from '../../hooks/auth/useIdpLogin';
import { GoogleLoginButton, AppleLoginButton, EmailLoginButton } from './components/LoginButtons';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { LoadingOverlay } from '@mantine/core';

export const ChooseLoginProvider: React.FC = () => {
    const { mutate: loginIDP, isLoading } = useIdpLogin(GoogleAuth);

    const platform = Capacitor.getPlatform();
    const showAppleLogin = platform === 'ios' || platform === 'web';

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '10px', padding: '56px' }}>
            <GoogleLoginButton onClick={() => loginIDP('google')} />

            {showAppleLogin && <AppleLoginButton onClick={() => loginIDP('apple')} />}

            <EmailLoginButton href="email" />

            <LoadingOverlay visible={isLoading} keepMounted loaderProps={{ color: '#EC630B' }} transitionDuration={250} />
        </Box>
    );
};
