import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { strapiApiClient } from '../services/sharetribe/apiClients';
import { Listing } from '../types/apiTypes';
import { HomePageConfiguration } from '../strapi/types';

export interface Category {
    id: number;
    description: string;
    title: string;
    hasMore: boolean;
    listings: Listing[];
}

export const useHomePage = (): UseQueryResult<HomePageConfiguration> => {
    const getHomePage = async () => {
        const { data } = await strapiApiClient.get<HomePageConfiguration>('/home-page');
        return data;
    };

    return useQuery(['home-page'], getHomePage, {
        staleTime: 2 * 60 * 1000,
    });
};
