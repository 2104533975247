import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { get } from 'lodash';
import React from 'react';
import { TFunction } from 'i18next';
import { FormFieldInput } from '../FormRenderer.types';

interface UnControlledFormTextFieldProps {
    t: TFunction;
    field: FormFieldInput;
    fieldName?: string;
    type: 'text' | 'number';
    isControlled: boolean;
    errors: FieldErrors<FieldValues>;
    multiline?: boolean;
    rows?: number;
    tempFormState?: Record<string, unknown>;
    control: Control<FieldValues, any>;
    registerOptions?: RegisterOptions;
    renderErrorMessage: (t: any, errors: FieldErrors<FieldValues>, controlName: string) => string | false | undefined;
}

interface ControlledFormTextFieldProps extends UnControlledFormTextFieldProps {
    register: UseFormRegister<FieldValues>;
    handleFieldChange: (fieldName: string, value: string) => void;
}

type FormTextFieldProps = UnControlledFormTextFieldProps | ControlledFormTextFieldProps;

export const FormTextField: React.FC<FormTextFieldProps> = (props) => {
    const { isControlled, type, field, fieldName, errors, renderErrorMessage, t, control, multiline, rows } = props;
    const resolvedControlName = fieldName ? `${fieldName}.${field.controlName}` : field.controlName;
    const resolvedErrors = (fieldName ? errors[fieldName] : errors) as FieldErrors<FieldValues>;
    const hasError = resolvedErrors && !!resolvedErrors[field.controlName];

    if (isControlled) {
        const { register, handleFieldChange } = props as ControlledFormTextFieldProps;
        const resolvedValue = (get(props.tempFormState, resolvedControlName) as string) || '';

        return (
            <TextField
                key={resolvedControlName}
                {...register(resolvedControlName, field.registerOptions)}
                value={resolvedValue}
                label={field.label}
                type={type}
                multiline={multiline}
                rows={rows}
                onChange={(e) => handleFieldChange(resolvedControlName, e.target.value)}
                error={hasError}
                helperText={hasError ? renderErrorMessage(t, resolvedErrors, field.controlName) : ''}
            />
        );
    } else {
        return (
            <Controller
                key={field.controlName}
                name={field.controlName}
                control={control}
                defaultValue=""
                rules={{ ...field.registerOptions }}
                render={({ field: fieldRenderer }) => {
                    return (
                        <TextField
                            {...fieldRenderer}
                            fullWidth
                            type={type}
                            label={field.label}
                            multiline={multiline}
                            rows={rows}
                            error={hasError}
                            helperText={hasError ? renderErrorMessage(t, resolvedErrors, field.controlName) : ''}
                        />
                    );
                }}
            />
        );
    }
};
