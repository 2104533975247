import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { emailValidation, phoneNumberValidation } from '../../helpers/regex';
import useLocationParams from '../../hooks/useLocationParams';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { LoadingButton } from '@mui/lab';
import { useSignUp } from '../../hooks/auth/useSignUp';
import { TermsOfServiceModal } from './TermsOfService';
import { DatePicker } from '@mui/x-date-pickers';
import MuiPhoneNumber from 'mui-phone-number';
import { isValid } from 'date-fns';
import { SectionLabel } from '../../components/SectionLabel/SectionLabel';
import { SignUpLayout } from './SignUp.components';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import { LanguageSelector } from '../UserProfile/routes/Language';

interface FormInput {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    dob: string;
    confirmPassword: string;
    phone: string;
}

const SignUpView = (): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { t } = useTranslation();
    const { idpId, idpToken, idpClientId, email, firstName, lastName } = useLocationParams();
    const isDesktop = useBreakpoint('sm');

    const passwordRef = useRef({});
    const {
        reset,
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormInput>();

    passwordRef.current = watch('password', '');
    const dateOfBirth = watch('dob');

    useEffect(() => {
        reset({ email, firstName, lastName });
    }, [email, firstName, lastName, reset]);

    const { mutate: signUp, isLoading } = useSignUp();

    const onSubmit: SubmitHandler<FormInput> = async (formData) => {
        const isIdpSignup = idpId && idpToken && idpClientId;
        const { phone, dob, ...rest } = formData;

        const validatedPhoneNumber = phoneNumberValidation.test(phone) ? phone : undefined;
        const formattedData = {
            ...rest,
            privateData: {
                phone: validatedPhoneNumber,
                dob,
            },
        };

        const signUpData = isIdpSignup ? { ...formattedData, idpId, idpToken, idpClientId } : formattedData;

        signUp(signUpData);
    };

    const isIdpSignup = Boolean(idpId && idpToken && idpClientId);

    return (
        <SignUpLayout elevation={isDesktop ? 8 : 0}>
            <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <SectionLabel text={t('preferredLanguage')} />

                    <Grid item xs={12}>
                        <LanguageSelector displayIcon variant="outlined" />
                    </Grid>

                    <SectionLabel text={t('email')} required />

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            disabled={isIdpSignup}
                            placeholder="you@example.com"
                            error={!!errors.email}
                            helperText={errors.email ? t('emailError') : undefined}
                            {...register('email', { required: true, pattern: emailValidation })}
                        />
                    </Grid>

                    <SectionLabel text={t('name')} required />

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            placeholder={t('firstname')}
                            error={!!errors.firstName}
                            helperText={errors.firstName ? t('firstnameError') : undefined}
                            {...register('firstName', { required: true, maxLength: 255 })}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            placeholder={t('lastname')}
                            error={!!errors.lastName}
                            helperText={errors.lastName ? t('lastnameError') : undefined}
                            {...register('lastName', { required: true, maxLength: 255 })}
                        />
                    </Grid>

                    <SectionLabel text={t('dob')} />

                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="dob"
                            rules={{
                                required: false,
                                validate: (val) => {
                                    if (!val) {
                                        return true;
                                    }
                                    return isValid(new Date(val));
                                },
                            }}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    // @ts-ignore
                                    value={dateOfBirth || null}
                                    disableFuture
                                    closeOnSelect
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!errors.dob,
                                            helperText: errors.dob ? t('dobError') : undefined,
                                        },
                                        actionBar: {
                                            actions: isDesktop ? [] : ['clear'],
                                        },
                                        field: { clearable: true, onClear: () => setValue('dob', '') },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <SectionLabel text={t('phoneNumber')} />

                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field }) => (
                                <MuiPhoneNumber
                                    {...field}
                                    onlyCountries={['fi']}
                                    defaultCountry="fi"
                                    regions="europe"
                                    variant="outlined"
                                    fullWidth
                                    autoComplete="off"
                                    inputProps={{ autoComplete: 'no', form: { autoComplete: 'off' } }}
                                    error={!!errors.phone}
                                    helperText={errors.phone ? t('phoneError') : undefined}
                                    countryCodeEditable={false}
                                    {...register('phone', {
                                        required: false,
                                        validate: (val) => {
                                            if (!val || val === '+358') {
                                                return true;
                                            }

                                            return phoneNumberValidation.test(val);
                                        },
                                    })}
                                    sx={{ '& svg': { height: '0.8em' } }}
                                    onChange={(val) => {
                                        if (typeof val === 'string') {
                                            setValue('phone', val);
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    {!isIdpSignup && (
                        <>
                            <SectionLabel text={t('password')} required />
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    placeholder={t('password')}
                                    type={showPassword ? 'search' : 'password'}
                                    autoComplete="new-password"
                                    error={!!errors.password}
                                    helperText={errors.password ? t('passwordError') : undefined}
                                    {...register('password', {
                                        required: true,
                                        minLength: 8,
                                        maxLength: 255,
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    placeholder={t('confirmPassword')}
                                    type={showConfirmPassword ? 'search' : 'password'}
                                    autoComplete="new-password"
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword ? t('confirmPasswordError') : undefined}
                                    {...register('confirmPassword', {
                                        required: true,
                                        validate: (value) => value === passwordRef.current,
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {showConfirmPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TermsOfServiceModal />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton type="submit" fullWidth sx={{ mt: 3 }} variant="contained" loading={isLoading}>
                            {t('signUp')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </SignUpLayout>
    );
};

export default SignUpView;
