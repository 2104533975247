import { Box, CircularProgress, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { INDICATOR_CONTAINER_SIZE_PX, INDICATOR_SIZE_PX, DISTANCE } from './PullToRefresh.constants';

export const PullToRefreshContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',
}));

export const IndicatorContainer = styled(motion.div)(({ theme }) => ({
    width: INDICATOR_CONTAINER_SIZE_PX,
    height: INDICATOR_CONTAINER_SIZE_PX,
    backgroundColor: theme.palette.spinner.background,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 10000,
    left: `calc(50% - ${INDICATOR_CONTAINER_SIZE_PX / 2}px)`,
    transform: 'translateX(-50%)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)',
}));

export const RefreshIndicator = styled(CircularProgress)(() => ({
    marginLeft: DISTANCE / 2,
    marginTop: DISTANCE / 2,
}));

export const RefreshSvg = styled(motion.svg)(() => ({
    zIndex: 10001,
    width: INDICATOR_SIZE_PX,
    height: INDICATOR_SIZE_PX,
    marginLeft: DISTANCE / 2,
    marginTop: DISTANCE / 2,
}));
