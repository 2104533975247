import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import { DisplayFormValueComponent } from './DisplayFormValue';
import { showroomDisplayNameFull } from '../../constants';

interface RobesAddressSectionProps {
    sectionLabel: string;
    showDescription?: boolean;
}

export const RobesAddressSection: React.FC<RobesAddressSectionProps> = ({ sectionLabel, showDescription = false }) => {
    const { t } = useTranslation();

    return (
        <DisplayFormValueComponent
            label={sectionLabel}
            prefix={
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M220.001-180.001v-320h-87.689L480-813.075l156.923 141.692v-97.846h92.306v180.46l98.459 88.768h-87.689v320H524.616v-224.615h-89.232v224.615H220.001ZM280-240h95.386v-224.614h209.228V-240H680v-312L480-732 280-552v312Zm95.386-224.614h209.228-209.228Zm28.46-94.771h152.308q0-30.076-22.847-49.422-22.846-19.347-53.307-19.347-30.461 0-53.307 19.312-22.847 19.312-22.847 49.457Z" />
                    </svg>
                </StyledSvgIcon>
            }
            value={
                <>
                    {showDescription && <Typography variant="body2">{t('robesShowroom')}</Typography>}
                    <Typography variant="body2">{showroomDisplayNameFull}</Typography>
                </>
            }
        />
    );
};
