import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, List, ListItem, TextField, Typography } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import { FormProvider, RegisterOptions, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { displayNameValidation } from '../../../helpers/regex';
import { User } from '../../../types/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { useUpdateProfile } from '../hooks/useUpdateProfile';
import { ButtonContainer, SettingsContainer } from '../UserProfile.components';
import { ShowMore } from '../../../components/ShowMore/ShowMore';
import { LinkBehavior } from '../../..';
import { formatLongDateAndYear } from '../../../helpers/dateAndTimeHelpers';

type UserData = Omit<User['profile'], 'publicData' | 'privateData'>;
type ViewModeItemProps = Pick<ProfileListItemProps, 'value' | 'label'>;
type EditModeItemProps = Omit<ProfileListItemProps, 'viewMode' | 'value'>;

interface ProfileListItemProps {
    viewMode: boolean;
    multiline: boolean;
    controlName: keyof UserData;
    options: RegisterOptions<UserData>;
    label: string;
    value: string;
    errorText: string;
}

const ViewModeItem: ReactFCWithChildren<ViewModeItemProps> = ({ value, label }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption" sx={{ fontWeight: '300', fontSize: 12 }}>
                {label}
            </Typography>

            <ShowMore value={value} />
        </Box>
    );
};

const formControlVariants: Variants = {
    initial: { opacity: 0, scale: 0.95 },
    in: {
        opacity: 1,
        scale: 1,
    },
};

const AnimatedFormControl: ReactFCWithChildren = ({ children }) => (
    <motion.div variants={formControlVariants} style={{ width: '100%' }}>
        {children}
    </motion.div>
);

const EditModeItem: ReactFCWithChildren<EditModeItemProps> = ({ label, errorText, controlName, options, multiline }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<UserData>();

    return (
        <TextField
            error={!!errors[controlName]}
            helperText={errors[controlName] ? errorText : ''}
            fullWidth
            autoComplete="off"
            variant="standard"
            label={label}
            multiline={multiline}
            inputProps={register(controlName, { ...options })}
        />
    );
};

const ProfileListItem: ReactFCWithChildren<ProfileListItemProps> = ({ viewMode, value, label, errorText, multiline, controlName, options }) =>
    viewMode ? (
        <AnimatedFormControl key={`view-mode${value}`}>
            <ViewModeItem label={label} value={value} />
        </AnimatedFormControl>
    ) : (
        <AnimatedFormControl key={`edit-mode${value}`}>
            <EditModeItem label={label} controlName={controlName} multiline={multiline} options={options} errorText={errorText} />
        </AnimatedFormControl>
    );

export const PersonalInformation: ReactFCWithChildren = () => {
    const { data: user } = useCurrentUser();
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState(true);
    const { mutate: updateProfile, isLoading } = useUpdateProfile(() => setViewMode(true));

    const methods = useForm<UserData>();

    const onSubmit = () => {
        const values = methods.getValues();
        updateProfile(values);
    };

    if (!user) {
        return null;
    }

    return (
        <SettingsContainer sx={{ flexDirection: 'column', justifyContent: 'space-between' }}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%', paddingBottom: '100px' }}>
                    <List sx={{ width: '100%' }}>
                        <ListItem>
                            <ViewModeItem label={t('email')} value={user.email} />
                        </ListItem>

                        {user.profile?.privateData?.dob && (
                            <ListItem>
                                <ViewModeItem label={t('dob')} value={formatLongDateAndYear(user.profile.privateData.dob)} />
                            </ListItem>
                        )}

                        <ListItem>
                            <ProfileListItem
                                viewMode={viewMode}
                                label={t('displayName')}
                                multiline={false}
                                value={user.profile.displayName}
                                errorText={t('displayNameError')}
                                controlName="displayName"
                                options={{
                                    value: user.profile.displayName,
                                    required: true,
                                    maxLength: 50,
                                    minLength: 5,
                                    pattern: displayNameValidation,
                                }}
                            />
                        </ListItem>

                        <ListItem>
                            <ProfileListItem
                                viewMode={viewMode}
                                label={t('bio')}
                                multiline
                                value={user.profile.bio || ''}
                                errorText={t('maxLengthError', { length: 5000 })}
                                controlName="bio"
                                options={{ value: user.profile.bio || '', maxLength: 5000 }}
                            />
                        </ListItem>
                    </List>

                    <ButtonContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {viewMode && (
                            <Button fullWidth variant="contained" onClick={() => setViewMode(false)}>
                                {t('edit')}
                            </Button>
                        )}

                        {!viewMode && (
                            <>
                                <LoadingButton fullWidth variant="contained" type="submit" loading={isLoading}>
                                    {t('save')}
                                </LoadingButton>
                                <Button fullWidth variant="outlined" onClick={() => setViewMode(true)}>
                                    {t('cancel')}
                                </Button>
                            </>
                        )}
                    </ButtonContainer>
                </form>
            </FormProvider>

            {viewMode && (
                <Box sx={{ p: 2, pb: 10, display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        {t('removalOfPersonalInformation')}
                    </Typography>

                    <Button LinkComponent={LinkBehavior} href="/profile/delete-account" fullWidth color="error" variant="text">
                        {t('deleteAccount')}
                    </Button>
                </Box>
            )}
        </SettingsContainer>
    );
};
