/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeEnum } from '../types/enums';
import { RootState } from './types';

export interface SettingsState {
    theme: ThemeEnum;
    compactMode: boolean;
    hideUI: boolean;
}

export const initialSettingsState: SettingsState = {
    theme: ThemeEnum.LIGHT,
    compactMode: true,
    hideUI: true,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialSettingsState,
    reducers: {
        setTheme: (state, action: PayloadAction<ThemeEnum>) => {
            state.theme = action.payload;
        },
        toggleCompactMode: (state) => {
            state.compactMode = !state.compactMode;
        },
        toggleHideUI: (state) => {
            state.hideUI = !state.hideUI;
        },
        setCompactMode: (state, action: PayloadAction<boolean>) => {
            state.compactMode = action.payload;
        },
        setHideUI: (state, action: PayloadAction<boolean>) => {
            state.hideUI = action.payload;
        },
    },
});

export const { setTheme, toggleCompactMode, toggleHideUI, setCompactMode, setHideUI } = settingsSlice.actions;
export const selectTheme = (state: RootState): ThemeEnum => state.settings.theme;
export const selectCompactMode = (state: RootState): boolean => state.settings.compactMode;
export const selectHideUI = (state: RootState): boolean => state.settings.hideUI;

export default settingsSlice.reducer;
