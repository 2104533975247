import { Browser } from '@capacitor/browser';
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StripeConnectFormProps, StripeConnectFormData } from './StripeConnectForm.types';
import { isValidCurrencyForCountryCode, supportedCountryCodes } from './StripeConnectForm.utils';
import { useCreateBankToken } from './useCreateBankToken';
import { useTranslation } from 'react-i18next';

export const StripeConnectForm: React.FC<StripeConnectFormProps> = ({ isLoading, connectStripeFn }) => {
    const [tokenState, setTokenState] = useState({ token: '', stripeError: '' });
    const { t } = useTranslation();
    const countryItems = [{ label: 'Finland', value: 'FI' }];
    const currencyItems = [{ label: 'EUR', value: 'EUR' }];

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { isDirty, isValid },
    } = useForm<StripeConnectFormData>({
        defaultValues: {
            countryCode: 'FI',
            currency: 'EUR',
        },
    });

    const countryCode = watch('countryCode');
    const currency = watch('currency');
    const iban = watch('iban');

    const bankTokenParams = {
        currency,
        countryCode,
        onSuccess: (token: string) => setTokenState({ token, stripeError: '' }),
        onError: (stripeError: string) => setTokenState({ token: '', stripeError }),
    };

    const { mutate: validateBankToken, isLoading: isValidating } = useCreateBankToken(bankTokenParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedValidateToken = useCallback(
        debounce(() => {
            validateBankToken(getValues());
        }, 1000),
        [],
    );

    useEffect(() => {
        debouncedValidateToken();
    }, [debouncedValidateToken, countryCode, currency, iban]);

    const submitHandler = (formData: StripeConnectFormData) => {
        const { token: bankAccountToken, stripeError } = tokenState;
        if (!bankAccountToken || stripeError) {
            throw new Error('Bank account token missing or stripe error present');
        }

        connectStripeFn({ ...formData, bankAccountToken });
    };

    const onClickStripeAgreement = async () => {
        await Browser.open({ url: 'https://stripe.com/en-fi/legal/connect-account' });
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        // @ts-ignore
                        inputRef={register('countryCode', {
                            validate: (value) => supportedCountryCodes.includes(value),
                            required: true,
                        })}
                        select
                        onChange={(e) => setValue('countryCode', e.target.value)}
                        label="Country of residence"
                        defaultValue="FI"
                    >
                        {countryItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        // @ts-ignore
                        inputRef={register('currency', {
                            validate: (value) => isValidCurrencyForCountryCode(countryCode, value),
                            required: true,
                        })}
                        select
                        onChange={(e) => setValue('currency', e.target.value)}
                        label="Currency"
                        defaultValue="EUR"
                    >
                        {currencyItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="IBAN"
                        fullWidth
                        error={!!tokenState.stripeError}
                        helperText={tokenState.stripeError || ' '}
                        {...register('iban', { required: true })}
                    />
                </Grid>
            </Grid>

            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                    By proceeding, you agree to the{' '}
                    <span
                        role="button"
                        tabIndex={0}
                        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                        onClick={onClickStripeAgreement}
                        onKeyDown={(evt) => {
                            if (evt.key === 'Enter') {
                                onClickStripeAgreement();
                            }
                        }}
                    >
                        Stripe Connected Account Agreement
                    </span>
                </Typography>
            </div>

            <LoadingButton
                fullWidth
                disabled={!isDirty || !isValid || !tokenState.token}
                type="submit"
                variant="contained"
                loading={isLoading || isValidating}
            >
                {t('proceed')}
            </LoadingButton>
        </form>
    );
};
