import React, { useCallback } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCompactMode, selectHideUI, selectTheme, setTheme, toggleCompactMode, toggleHideUI } from '../../../store/settingsReducer';
import { ThemeEnum } from '../../../types/enums';
import { SettingsContainer } from '../UserProfile.components';
import { LanguageSelector } from './Language';
import { User } from '../../../types/apiTypes';
import { useUpdateProfile } from '../hooks/useUpdateProfile';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { noop } from '../../../helpers/commonHelpers';

const buildVersion = process.env.REACT_APP_VERSION || '';

export const Settings: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentTheme = useAppSelector(selectTheme);
    const compactMode = useAppSelector(selectCompactMode);
    const uiHidden = useAppSelector(selectHideUI);

    const { data: user } = useCurrentUser();
    const { mutate: updateProfile } = useUpdateProfile(noop, false);

    const isMarketingEnabled = Boolean(user?.profile?.privateData?.settings?.notifications?.marketing);

    const toggleDarkMode = useCallback(() => {
        const newTheme = currentTheme === ThemeEnum.DARK ? ThemeEnum.LIGHT : ThemeEnum.DARK;
        dispatch(setTheme(newTheme));
    }, [dispatch, currentTheme]);

    const toggleCompactModeListings = () => dispatch(toggleCompactMode());

    const toggleHideUIListings = () => dispatch(toggleHideUI());

    const toggleMarketingNotifications = () => {
        const payload = {
            privateData: {
                settings: {
                    notifications: {
                        marketing: !isMarketingEnabled,
                    },
                },
            },
        };

        updateProfile(payload);
    };

    return (
        <SettingsContainer>
            <Typography sx={{ mt: 4, mb: 2, ml: 2, fontVariationSettings: "'wght' 500" }} variant="h6">
                {t('notifications')}
            </Typography>

            <List sx={{ width: '100%' }}>
                <ListItem>
                    <ListItemText id="settings-allow-marketing-switch" primary={t('allowMarketingPrimary')} />
                    <Switch
                        edge="end"
                        onChange={toggleMarketingNotifications}
                        checked={isMarketingEnabled}
                        inputProps={{
                            'aria-labelledby': 'settings-allow-marketing-switch',
                        }}
                    />
                </ListItem>
            </List>

            <Typography sx={{ mt: 4, mb: 2, ml: 2, fontVariationSettings: "'wght' 500" }} variant="h6">
                {t('preferences')}
            </Typography>

            <List sx={{ width: '100%' }}>
                <ListItem>
                    <ListItemText primary={t('language')} />
                    <LanguageSelector />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-dark-mode-switch" primary={t('darkMode')} />
                    <Switch
                        edge="end"
                        onChange={toggleDarkMode}
                        checked={currentTheme === ThemeEnum.DARK}
                        inputProps={{
                            'aria-labelledby': 'settings-dark-mode-switch',
                        }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-compact-mode-switch" primary={t('compactMode')} />
                    <Switch
                        edge="end"
                        onChange={toggleCompactModeListings}
                        checked={compactMode}
                        inputProps={{
                            'aria-labelledby': 'settings-compact-mode-switch',
                        }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-hide-ui-switch" primary={t('hideUiPrimary')} secondary={t('hideUiSecondary')} />
                    <Switch
                        edge="end"
                        onChange={toggleHideUIListings}
                        checked={uiHidden}
                        inputProps={{
                            'aria-labelledby': 'settings-hide-ui-switch',
                        }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-build-version" primary={t('buildVersion')} secondary={buildVersion} />
                </ListItem>
            </List>
        </SettingsContainer>
    );
};
