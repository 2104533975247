import React from 'react';
import { Avatar, Typography, Box, ButtonBase } from '@mui/material';
import { Bounce, toast } from 'react-toastify';

interface ToastContentProps {
    title: React.ReactNode;
    message: React.ReactNode;
    avatarSrc?: string;
}

const ToastContent: React.FC<ToastContentProps> = ({ title, message, avatarSrc }) => {
    return (
        <ButtonBase style={{ textAlign: 'initial' }}>
            {' '}
            {/* Making the whole area clickable */}
            <Box sx={{ maxWidth: '302px' }}>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <Avatar src={avatarSrc} sx={{ width: 30, height: 30 }} />
                    <Typography variant="body2" fontWeight="bold" style={{ marginLeft: '8px' }}>
                        {title}
                    </Typography>
                </Box>
                <Typography variant="body1" sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {message}
                </Typography>
            </Box>
        </ButtonBase>
    );
};

export const displayPushNotificationToast = (onClick: () => void, title: React.ReactNode, body: React.ReactNode, avatar: string) => {
    toast(<ToastContent title={title} message={body} avatarSrc={avatar} />, {
        transition: Bounce,
        autoClose: false,
        onClick,
        toastId: 'push-notification-toast',
    });
};
