import React from 'react';
import { ListingsCollection as StrapiListingsCollection } from '../../types';
import { useQueryClient } from '@tanstack/react-query';
import { analytics } from '../../../analytics/events/helpers';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { resolveSlideSettings } from '../../helpers';
import { Collection } from '../Collection/Collection';
import { getListingTitle } from '../../../helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
interface ListingsCollectionProps {
    isDesktop: boolean | undefined;
    collection: StrapiListingsCollection;
}

export const ListingsCollection: React.FC<ListingsCollectionProps> = ({ isDesktop, collection }) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const handleClick = (index: number) => {
        const listing = collection.listings[index];
        const queryKey = ['listing', { listingId: listing.id }];

        analytics.listingClick(listing.id, listing.title, 'home-page');

        const cachedData = queryClient.getQueryData(queryKey);
        if (!cachedData) {
            // Invalidating the manually updated cache immediately will fetch and append rest of the information
            queryClient.setQueryData(queryKey, () => listing);
            queryClient.invalidateQueries(queryKey);
        }
        navigate(`/listing/${listing.id}`);
    };

    const handleCollectionClick = () => {
        analytics.homePageAction('collection_click', { collection: collection.label });
        navigate(`/listings?selectedCategory=${collection.id}`);
    };

    const { listings, label } = collection;

    const data = listings.map((listing) => ({
        id: listing.id,
        image: listing.images[0].url,
        label: getListingTitle(listing, t),
        blurhash: listing.publicData.blurhash,
    }));

    const slideSettings = resolveSlideSettings(isDesktop, collection);

    return (
        <Collection
            title={label}
            data={data}
            slideSettings={slideSettings}
            hasMore={collection.hasMore}
            onClickItem={handleClick}
            onClickMore={handleCollectionClick}
        />
    );
};
