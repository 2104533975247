import React, { useContext, useEffect } from 'react';
import { Channel, PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';

import { notificationsApiClient } from '../services/sharetribe/apiClients';
import { logger } from '../helpers/logger';
import { useCurrentUser } from '../user/hooks/useUser';
import { useLocation } from 'react-router-dom';
import { assertNever, noop } from '../helpers/commonHelpers';
import { ApsNewMessageNotification, AndroidNewMessageNotification, PushNotification } from './types';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { useQueryClient } from '@tanstack/react-query';
import { useLatestValue } from '../hooks/useLatestValue';
import { displayPushNotificationToast } from './PushNotificationToast';
import NotifyListeners from '../plugins/NotifyListeners';
import { useChatWindow } from '../context/chat';
import { useSafeNavigate } from '../hooks/useSafeNavigate';
import { Message } from '../messages/apiTypes';
import { handleAddMessageToQueryCache } from '../messages/hooks/useSocketMessages';
import { useSocket } from '../context/socket';
import { useDispatchListener } from '../hooks/useDispatchListener';
import { dispatchCustomEvent } from '../store/listeners';
import { useAppActiveState } from '../hooks/useAppActiveState';

// Create our custom transition

// Android
const availableChannels: Channel[] = [
    { id: 'messages/user', name: 'Messages', description: 'Messages received from other users' },
    { id: 'transactions', name: 'Transactions', description: 'Information about ongoing transactions' },
    { id: 'reminders', name: 'Reminders', description: 'Reminders of various things, such as bookings ending.' },
    { id: 'misc', name: 'Miscellaneous', description: 'Other notifications' },
];

const PushNotificationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const { data: user } = useCurrentUser();
    const { connected } = useSocket();
    const location = useLocation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();
    const state = useChatWindow();

    const platform = Capacitor.getPlatform();
    const locationRef = useLatestValue(location);
    const chatStateRef = useLatestValue(state);
    const socketConnectedRef = useLatestValue(connected);
    const activeState = useAppActiveState();

    let notifyListener: PluginListenerHandle;

    const initPushNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();
        const onboardingComplete = user?.profile.privateData.onboardingState === 'complete';

        //  This is intended for users who, for example, have a new phone. Normally users are asked for PN permissions during onboarding.
        if (permStatus.receive === 'prompt' && onboardingComplete) {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            return;
        }

        await PushNotifications.register();

        if (platform === 'android') {
            const { channels: registeredChannels } = await PushNotifications.listChannels();

            for (const availableChannel of availableChannels) {
                const channelIdx = registeredChannels.findIndex((registeredChannel) => registeredChannel.id === availableChannel.id);
                if (channelIdx === -1) {
                    await PushNotifications.createChannel(availableChannel);
                }
            }
        }

        PushNotifications.addListener('registration', async (token) => {
            /**
             * - For iOS we must use the `FCM` plugin, because `PushNotifications` returns an APNS token (that is not usable by our backend)
             * - For Android we can use the token returned by PushNotifications.
             * @see https://github.com/capacitor-community/fcm/issues/99
             */
            const getFcmToken = async () => {
                if (platform === 'android') {
                    return token.value;
                } else {
                    const { token: fcmToken } = await FCM.getToken();
                    return fcmToken;
                }
            };

            const fcmToken = await getFcmToken();
            await notificationsApiClient.post('/store-token', { token: fcmToken, platform: Capacitor.getPlatform() });

            console.info('Registration token: ', fcmToken);
        });

        PushNotifications.addListener('registrationError', (err) => {
            console.error('Registration error: ', err.error);
        });

        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
            console.log(`Push received: ${JSON.stringify(notification)}`);

            if (notification.data.type !== 'message/new') {
                return;
            }

            const message = JSON.parse(notification.data.message) as Message;

            const { pathname } = locationRef.current;
            const { state } = chatStateRef.current;

            const chatAlreadyOpenForConversation = state && state.participant.id === message.senderId;
            const conversationId = message.conversationId;

            if (!socketConnectedRef.current) {
                handleAddMessageToQueryCache(queryClient, message, state, ['message-history', { conversationId }]);
            }

            if (!pathname.includes('messages') && !chatAlreadyOpenForConversation) {
                let title,
                    body,
                    avatar = notification.data.avatar,
                    senderId = message.senderId;

                if ('aps' in notification.data) {
                    title = notification.data.aps.alert.title;
                    body = notification.data.aps.alert.body;
                } else {
                    title = notification.data.title;
                    body = notification.data.body;
                }

                const handleClickToast = () => {
                    navigate(`/messages`, { state: { senderId } });
                };

                displayPushNotificationToast(handleClickToast, title, body, avatar);
            }
        });

        const handleActionPerformed = (actionPerformed: { actionId: string; notification: PushNotification }) => {
            const { actionId, notification } = actionPerformed;

            if (actionId !== 'tap') {
                logger.info('Unexpected actionId: ', actionId);
                return;
            }

            const handleMessageTapAction = async (notificationData: ApsNewMessageNotification['data'] | AndroidNewMessageNotification['data']) => {
                const message = JSON.parse(notificationData.message) as Message;
                const senderId = message.senderId;
                const { state } = chatStateRef.current;
                const conversationId = message.conversationId;

                handleAddMessageToQueryCache(queryClient, message, state, ['message-history', { conversationId }]);

                navigate(`/messages`, { state: { senderId } });
            };

            const handleBookingTapAction = async (url: string) => {
                await queryClient.invalidateQueries(['own-transactions']);

                // Close message drawer if it's open
                if (chatStateRef.current.state) {
                    chatStateRef.current.closeChat();
                }

                navigate(url);
            };

            const handleListingTapAction = async (url: string) => {
                await queryClient.invalidateQueries(['own-listings']);
                navigate(url);
            };

            const handleGenericTapAction = async (url: string) => {
                if (url) {
                    navigate(url);
                }
            };

            const handleNoficationTapAction = () => {
                switch (notification.data.type) {
                    case 'message/new':
                        return handleMessageTapAction(notification.data);
                    case 'listing/approved':
                        return handleListingTapAction('/wardrobe?tab=0');
                    case 'booking/created':
                        return handleBookingTapAction('/wardrobe?tab=1');
                    case 'booking/declined':
                        return handleBookingTapAction('/wardrobe?tab=2');
                    case 'booking/accepted':
                        return handleBookingTapAction('/wardrobe?tab=2');
                    case 'booking/cancelled-by-customer':
                        return handleBookingTapAction('/wardrobe?tab=1');
                    case 'booking/cancelled-by-provider':
                        return handleBookingTapAction('/wardrobe?tab=2');
                    case 'booking/started':
                    case 'booking/cancelled-by-operator':
                    case 'booking/completed':
                    case 'booking/renter-wolt-delivery-tomorrow':
                    case 'booking/renter-wolt-delivery-today':
                    case 'booking/renter-wolt-return-tomorrow':
                    case 'booking/renter-wolt-return-today':
                    case 'booking/lender-wolt-dropoff-tomorrow':
                    case 'booking/lender-wolt-dropoff-today':
                    case 'booking/lender-wolt-pickup-tomorrow':
                    case 'booking/lender-wolt-pickup-today':
                    case 'booking/renter-showroom-delivery-tomorrow':
                    case 'booking/renter-showroom-delivery-today':
                    case 'booking/renter-showroom-return-tomorrow':
                    case 'booking/renter-showroom-return-today':
                    case 'booking/lender-showroom-dropoff-tomorrow':
                    case 'booking/lender-showroom-dropoff-today':
                    case 'booking/lender-showroom-pickup-tomorrow':
                    case 'booking/lender-showroom-pickup-today':
                        return handleBookingTapAction(`/wardrobe?tab=${notification.data.isProvider === 'true' ? '1' : '2'}`);
                    default:
                        return handleGenericTapAction(notification.data.url);
                }
            };

            handleNoficationTapAction()
                .then(noop)
                .catch((err) => {
                    logger.error('Error handling notification tap action: ', err);
                });
        };

        if (platform === 'android') {
            // Handles action events that were fired using the `NotifyListeners` plugin instead of the `PushNotifications` plugin.
            // This is needed for custom grouping of notifications on Android that is not supported by the `PushNotifications` plugin.
            notifyListener = await NotifyListeners.addListener(
                'pushNotificationActionPerformed',
                (actionPerformed: { actionId: string; notification: PushNotification }) => {
                    logger.info(`NotifyListeners - Push action performed: ${JSON.stringify(actionPerformed)}`);
                    handleActionPerformed(actionPerformed);
                },
            );
        }

        // Handles action events that were fired using the `PushNotifications` plugin.
        PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed: { actionId: string; notification: PushNotification }) => {
            logger.info(`PushNotifications - Push action performed: ${JSON.stringify(actionPerformed)}`);
            handleActionPerformed(actionPerformed);
        });
    };

    useEffect(() => {
        // No support for push notifications for web users.
        // Do not register notifications before we have an userId to associate the registrationToken with.
        if (platform === 'web' || !user?.id) {
            return;
        }

        initPushNotifications();

        return () => {
            PushNotifications.removeAllListeners();

            if (platform === 'android' && notifyListener) {
                notifyListener.remove();
            }
        };
    }, [user, activeState]);

    useDispatchListener('enabledPushNotifications', initPushNotifications);

    return children;
};

export default PushNotificationProvider;
