import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SettingsContainer } from '../UserProfile.components';

export const ContactUs: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <SettingsContainer>
            <Box>
                <Typography variant="h5">{t('emailSectionTitle')}</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    <Trans i18nKey="emailSectionBody">
                        Write to us at our email
                        <a href="mailto: hello@robesrental.com" style={{ textDecoration: 'underline', color: theme.palette.primary.main }}>
                            hello@robesrental.com
                        </a>
                        and we will contact you soon.
                    </Trans>
                </Typography>
            </Box>
        </SettingsContainer>
    );
};
