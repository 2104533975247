import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton, styled, SwipeableDrawer as MuiSwipeableDrawer, Typography, useTheme } from '@mui/material';
import { ReactFCWithChildren } from '../../types/types';
import { useHashChange } from '../../hooks/useHashChange';
import { grey } from '@mui/material/colors';
import { Close } from '@mui/icons-material';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[400],
    borderRadius: 3,
}));

const CloseButton = styled(IconButton)(() => ({
    width: '30px',
    height: '30px',
    borderRadius: '50%',
}));

export const SwipeableDrawer: ReactFCWithChildren<{
    open: boolean;
    onClose: () => void;
    headerSuffix?: React.ReactNode;
    fillHeight?: boolean;
    headerText?: string;
    ModalStyle?: React.CSSProperties | undefined;
}> = ({ children, fillHeight = true, headerText, open, onClose, ModalStyle, headerSuffix }) => {
    useHashChange(open, onClose);

    const handleClose = () => {
        window.history.back();
        onClose();
    };

    const isSmBreakpoint = useBreakpoint('sm');
    const anchor = isSmBreakpoint ? 'right' : 'bottom';
    const theme = useTheme();

    const contentRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState('none');

    useEffect(() => {
        if (contentRef.current) {
            setMaxHeight(`${contentRef.current.scrollHeight}px`);
        }
    }, [children]);

    const modalStyle = useMemo(() => {
        const baseStyle = {
            backgroundColor: theme.palette.background.paper,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            ...(isSmBreakpoint && {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            }),
            backgroundImage: 'none',
        };

        return isSmBreakpoint
            ? {
                  ...baseStyle,
                  maxWidth: '500px',
                  width: '100%',
              }
            : {
                  ...baseStyle,
                  maxHeight: '90%',
                  ...(fillHeight && { height: '100%' }),
              };
    }, [isSmBreakpoint, fillHeight, theme]);

    return (
        <MuiSwipeableDrawer
            disableSwipeToOpen
            swipeAreaWidth={0}
            disableDiscovery
            keepMounted={false}
            anchor={anchor}
            open={open}
            onClose={(ev) => {
                // only the top-most drawer should close
                ev?.stopPropagation();
                handleClose();
            }}
            onOpen={() => null}
            ModalProps={{ keepMounted: false }}
            PaperProps={{
                style: { ...modalStyle, ...ModalStyle },
            }}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.palette.background.paper,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                }}
            >
                {!isSmBreakpoint && (
                    <div
                        style={{
                            height: '10px',
                            width: '100%',
                            display: 'flex',
                            marginTop: '5px',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Puller />
                    </div>
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
                    <div style={{ textAlign: 'start', marginLeft: '15px', flexBasis: '30px' }}>{headerSuffix}</div>
                    <Typography variant="body1" sx={{ textTransform: 'capitalize', fontWeight: 'bold', textAlign: 'center' }}>
                        {headerText}
                    </Typography>
                    <CloseButton onClick={handleClose} size="small" sx={{ marginRight: '15px' }}>
                        <Close />
                    </CloseButton>
                </div>
            </Box>
            <Box
                ref={contentRef}
                sx={{
                    overflowY: 'auto',
                    width: '100%',
                    height: '100%',
                    backgroundColor: theme.palette.background.paper,
                    transition: 'max-height 0.5s cubic-bezier(0.2, 0.2, 0.1, 1)',
                    maxHeight: fillHeight ? '100%' : maxHeight,
                }}
            >
                {children}
            </Box>
        </MuiSwipeableDrawer>
    );
};
