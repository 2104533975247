import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadMoreButton } from '../../views/Listings/LoadMoreButton/LoadMoreButton';
import { ChatInformation, useChat } from '../../messages/hooks/useChat';
import { Author, ExtendedListing } from '../../types/apiTypes';

import { ContentLoader } from '../ContentLoader/ContentLoader';
import { ChatMessages } from './ChatMessages';
import { ChatHeader, MessageContainer, StyledAvatar } from './ChatMessages.components';
import { ChatInputField } from './ChatInputField';
import { ReactFCWithChildren } from '../../types/types';

import { CloseButton } from '@mantine/core';
import { Capacitor } from '@capacitor/core';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useChatWindow } from '../../context/chat';

const HEADER_HEIGHT_PX = 44;

interface ChatWindowProps {
    userId: string;
    participant: Author;
    listing?: ExtendedListing | undefined | null;
    onClose: () => void;
    onClickUser?: (userId: string) => void;
}

const shouldShowListingSuggestion = (listing: ExtendedListing | undefined | null, chatInfo: ChatInformation) => {
    if (!listing) {
        return false;
    }
    const { topic, conversationId } = chatInfo;
    return !!conversationId && !!listing && !!(topic?.listingId !== listing?.id);
};

type QueryKey = ['message-history', { conversationId: string | undefined }];

export const ChatWindow: ReactFCWithChildren<ChatWindowProps> = ({ userId, participant, listing, onClickUser, onClose }) => {
    const [contentHeight, setContentHeight] = useState(HEADER_HEIGHT_PX);
    const [includeListingData, setIncludeListingData] = useState(true);

    const { addConversationIdMaybe } = useChatWindow();
    const { t } = useTranslation();
    const { chatInfo, loadingStatus, isFetchingNextPage, fetchNextPage, hasNextPage } = useChat(participant.id);

    const queryKey: QueryKey = ['message-history', { conversationId: chatInfo.conversationId }];
    const showListingSuggestion = shouldShowListingSuggestion(listing, chatInfo);

    const inputFieldRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const navigate = useSafeNavigate();

    const handleUserClick = () => {
        if (onClickUser) {
            onClickUser(participant.id);
        } else {
            navigate(`/user/${participant.id}`);
        }
    };

    useEffect(() => {
        if (!inputFieldRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setContentHeight((inputFieldRef.current?.clientHeight || 0) + HEADER_HEIGHT_PX);
        });
        resizeObserver.observe(inputFieldRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!chatInfo?.conversationId) return;

        addConversationIdMaybe(chatInfo.conversationId);
    }, [chatInfo?.conversationId]);

    // Workaround for iOS where the chat window is not scrolled to the bottom when receiving new messages
    useLayoutEffect(() => {
        if (Capacitor.getPlatform() !== 'ios') {
            return;
        }

        const container = containerRef.current;

        if (!container) {
            return;
        }

        if (container.scrollTop === 0) {
            // Force a layout computation by setting an arbitrary `scrollTop` value. For some reason, when new messages
            // are received on iOS, the `scrollTop` value is not updated properly.
            container.scrollTop = -50;

            // Set back to intended value
            container.scrollTop = 0;
        } else if (container.scrollTop > -200 && container.scrollTop < 0) {
            container.scrollTo({ behavior: 'smooth', top: 0 });
        }
    }, [chatInfo.messageHistory, contentHeight]);

    return (
        <>
            <ChatHeader sx={{ height: HEADER_HEIGHT_PX }}>
                <span style={{ display: 'flex' }} onClick={handleUserClick} role="button">
                    {participant.displayName}
                    <StyledAvatar src={participant.profileImage} />
                </span>

                <CloseButton onClick={onClose} />
            </ChatHeader>

            <ContentLoader status={loadingStatus} style={{ flexWrap: 'unset', justifyContent: 'unset' }}>
                <MessageContainer ref={containerRef} style={{ padding: `50px 10px ${contentHeight + 20}px` }}>
                    <ChatMessages messageHistory={chatInfo.messageHistory} userId={userId} queryKey={queryKey} />
                    {!!chatInfo.messageHistory && (
                        <LoadMoreButton
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            noItems={false}
                            hasNextPageText={t('loadNextPage')}
                            noMoreItemsText={t('beginningOfMessageHistory', { name: participant.displayName })}
                        />
                    )}
                </MessageContainer>
            </ContentLoader>

            <ChatInputField
                ref={inputFieldRef}
                userId={userId}
                chatInfo={chatInfo}
                listing={listing}
                includeListingData={includeListingData}
                showListingSuggestion={showListingSuggestion}
                onToggle={() => setIncludeListingData(!includeListingData)}
            />
        </>
    );
};
