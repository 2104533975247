import * as React from 'react';
import { SxProps, styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { analytics } from '../../analytics/events/helpers';

const Accordion = styled((props: AccordionProps) => <MuiAccordion elevation={2} {...props} />)(({ theme }) => ({}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

interface AccordionGroupProps {
    accordions: { id: number; title: string; content: string; click_event?: string }[];
    sx?: SxProps;
}

type Accordion = AccordionGroupProps['accordions'][0];

export const AccordionGroup: React.FC<AccordionGroupProps> = ({ accordions, sx }) => {
    const [expanded, setExpanded] = React.useState<number | false>(false);

    const handleChange = (accordion: Accordion) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? accordion.id : false);

        if (accordion.click_event) {
            analytics.genericEvent(accordion.click_event);
        }
    };

    return (
        <Box sx={sx}>
            {accordions.map((accordion) => (
                <Accordion key={accordion.id} expanded={expanded === accordion.id} onChange={handleChange(accordion)}>
                    <AccordionSummary aria-controls={`panel-${accordion.id + 1}-content`} id={`panel-${accordion.id + 1}-header`}>
                        <Typography variant="body2" fontWeight="bold">
                            {accordion.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">{accordion.content}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};
