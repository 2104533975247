import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography/Typography';
import { Box, InputBase } from '@mui/material';
import { motion } from 'framer-motion';

export const ErrorText = styled('p')(() => ({
    fontSize: '12px',
    color: '#a8323a', // TODO: Use Theme
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid transparent',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            boxShadow: theme.palette.boxShadow.input,
            background: theme.palette.background.paper,
        },
    },
}));

export const AutocompleteTextfield = styled(TextField)(({ theme }) => ({
    borderRadius: 4,
    border: '1px solid transparent',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
        borderRadius: 4,
        borderColor: theme.palette.primary.main,
        boxShadow: theme.palette.boxShadow.input,
    },
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-root': {
        marginTop: '23px',
        padding: '6px 26px 6px 12px',
        backgroundColor: theme.palette.background.paper,
        '&.Mui-focused': {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            boxShadow: theme.palette.boxShadow.input,
        },
    },
}));

export const ImagesScrollProvider = styled('div')(() => ({
    '::-webkit-scrollbar': { display: 'none' },
    overflowX: 'auto',
}));

export const ImagesScrollContainer = styled(Box)(() => ({
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
}));

export const ImageContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'cover' })<{ cover?: boolean }>(({ theme, cover }) => ({
    transition: 'all 0.2s ease',
    maxWidth: 115,
    maxHeight: 115,
    width: 'calc((100vw - 48px) / 2.5)',
    height: 'calc((100vw - 48px) / 2.5)',
    background: theme.palette.primary.main,
    borderRadius: '19px',
    overflow: 'hidden',
    marginRight: '16px',
    ...(cover && { border: `8px solid ${theme.palette.primary.main}` }),
}));

export const AddedImageContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const CoverText = styled(Typography)(() => ({
    margin: 0,
    marginRight: '16px',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    border: 'none',
    borderRadius: '16px',

    '& .MuiInputBase-root': {
        borderRadius: '8px',
        padding: '10px 26px 10px 12px',
        marginTop: '23px',
        backgroundColor: theme.palette.background.paper,
        '&.Mui-focused': {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            boxShadow: theme.palette.boxShadow.input,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
}));

export const ChipsContainer = styled('div')(({ theme }) => ({
    width: '100%',
    background: theme.palette.background.paper,
    padding: '4px',
    borderRadius: '8px',
}));

export const TabContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: '16px',
    marginBottom: '-2px',
}));

export const TabButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'selected' })<{ selected?: boolean }>(({ theme, selected }) => ({
    borderRadius: 0,
    fontWeight: 'normal',
    transition: 'none',
    ...(selected && {
        fontWeight: 'bold',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    }),
}));

export const ColorIndicator = styled('div')(({ theme }) => ({
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.border.light}`,
}));

export const AddListingHeader = styled(motion.div)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    padding: '4px',
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-top)',
    width: '100%',
    height: 'calc(64px + env(safe-area-inset-top))',
    background: theme.palette.background.paper,
    boxShadow: theme.palette.boxShadow.light,
    zIndex: 2,
}));

export const SectionContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
}));
