import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userApiClient } from '../../services/sharetribe/apiClients';
import { OnboardingState, User } from '../../types/apiTypes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useUpdateOnboardingState = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const updateFn = async (onboardingState: OnboardingState) => {
        if (!onboardingState) {
            return;
        }

        await userApiClient.put('/', { privateData: { onboardingState } });

        return onboardingState;
    };

    return useMutation(updateFn, {
        onSuccess: (onboardingState) => {
            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }

                userData.profile.privateData.onboardingState = onboardingState;
                return userData;
            });

            onSuccess?.();
        },
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
    });
};
