import { Toll, MarkChatUnread } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';
import { assertIsDefined, sleep } from '../../helpers/commonHelpers';
import { Permission } from '../../types/apiTypes';
import { AdminIssueCreditsModal } from './AdminIssueCreditsModal';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { AdminActionsState, AdminActionsTarget } from '../../context/admin';
import { useMarkConversationAsUnread } from '../../messages/hooks/useMarkAsUnread';
import { AdminAction } from './helpers';

type AdminActionsDrawerProps = {
    target: AdminActionsTarget | undefined;
    state: AdminActionsState | null;
    permissions: Permission[];
    actions: AdminAction[];
    open: boolean;
    onClose: () => void;
};

export const AdminActionsDrawer: React.FC<AdminActionsDrawerProps> = ({ state, open, onClose, actions }) => {
    const [adminActionState, setAdminActionState] = useState<Permission | null>(null);
    const { mutate: markConversationAsUnread } = useMarkConversationAsUnread({ onMutate: onClose });

    const handleClickAdminAction = async (permission: Permission) => {
        assertIsDefined(state, 'Admin action state should be defined');

        if (permission === 'mark_conversation_as_unread' && state.target === 'conversation') {
            markConversationAsUnread(state.conversation.conversationId);
        } else {
            setAdminActionState(permission);
        }
    };

    return (
        <>
            <SwipeableDrawer open={open} onClose={onClose} fillHeight={false} headerText="Admin Controls">
                <List sx={{ paddingBottom: `calc(30px + env(safe-area-inset-bottom))` }}>
                    {actions.map((item) => {
                        return (
                            <ListItemButton onClick={() => handleClickAdminAction(item.permission)} key={item.label} disabled={item.disabled}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText>{item.label}</ListItemText>
                            </ListItemButton>
                        );
                    })}
                </List>
            </SwipeableDrawer>

            {state?.target === 'user' && (
                <AdminIssueCreditsModal user={state.user} open={adminActionState === 'issue_credits'} onClose={() => setAdminActionState(null)} />
            )}
        </>
    );
};
