import React from 'react';
import { Typography, Container, styled, useTheme, Box, ButtonBase } from '@mui/material';
import AppStoreBadge from '../../assets/svgs/app-store-badge.svg';
import GooglePlayBadge from '../../assets/images/google-play-badge.png';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Image } from '@mantine/core';
import { RobesLogoFull } from '../RobesLogo/RobesLogoFull';
import { invariant } from '../../helpers/commonHelpers';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useCheckSatisfiesMinimumVersion } from './useCheckSatisfiesMinimumVersion';

const FullScreenContainer = styled(Container)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    zIndex: 14000,
}));

export const OutOfDateModal = () => {
    const { t } = useTranslation();

    const handleOpenStore = async () => {
        await AppUpdate.openAppStore({ country: 'FI' });
    };

    const platform = Capacitor.getPlatform();

    invariant(platform === 'ios' || platform === 'android', 'Platform is not ios or android');

    return (
        <FullScreenContainer maxWidth="sm">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column', gap: '15px' }}>
                <Box sx={{ mb: 4 }}>
                    <RobesLogoFull height={80} />
                </Box>
                <Typography variant="h5" gutterBottom>
                    {t('outdatedVersionTitle')}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ opacity: 0.8 }}>
                    {t('outdatedVersionDescription')}
                </Typography>

                <div>
                    {platform === 'ios' && (
                        <ButtonBase onClick={handleOpenStore}>
                            <Image src={AppStoreBadge} width={150} alt="Download on the App Store" />
                        </ButtonBase>
                    )}

                    {platform === 'android' && (
                        <ButtonBase onClick={handleOpenStore}>
                            <Image width={200} src={GooglePlayBadge} alt="Get it on Google Play" />
                        </ButtonBase>
                    )}
                </div>
            </Box>
        </FullScreenContainer>
    );
};

export const OutOfDateModalMaybe: React.FC = () => {
    const { data: satisfiesMinimumVersion } = useCheckSatisfiesMinimumVersion();

    if (satisfiesMinimumVersion || typeof satisfiesMinimumVersion !== 'boolean') {
        return null;
    }

    return <OutOfDateModal />;
};
