import { useRef, useEffect } from 'react';

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        if (ref.current !== value) {
            ref.current = value;
        }
    }, [value]);

    return ref.current;
}
