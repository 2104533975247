import { Skeleton } from '@mui/material';
import { motion } from 'framer-motion';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseInfiniteQueryResult, useQueryClient } from '@tanstack/react-query';

import { ConfirmDialog } from '../../components/ConfirmDialog/ConfirmDialog';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { ProfileListingItem } from '../../components/ProfileListingItem/ProfileListingItem';
import { useToggleFavorite } from '../../messages/hooks/useToggleFavorite';
import { Listing, ListingsResponse } from '../../types/apiTypes';
import { LoadMoreButton } from '../Listings/LoadMoreButton/LoadMoreButton';
import { analytics } from '../../analytics/events/helpers';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

interface ListingItemsGridProps {
    queryData: UseInfiniteQueryResult<ListingsResponse, unknown>;
    showFavoriteButton?: boolean;
    itemsPerRow: number;
    own?: boolean; // whether the listings are the user's own
    noMoreItemsText?: string;
    noItemsText?: string;
    gridStyle?: CSSProperties;
}

const ListingItemsSkeleton: React.FC<{ itemsPerRow: number }> = ({ itemsPerRow }) => {
    const renderGridItemSkeleton = (i: number) => (
        <div key={i} style={{ width: `calc(100% / ${itemsPerRow})`, aspectRatio: '1/1', padding: '1px' }}>
            <Skeleton sx={{ width: '100%', height: '100%', transform: 'none', animationDuration: '1.2s' }} />
        </div>
    );

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', maxHeight: '100%', padding: '0 2px' }}>
            {Array.from({ length: 4 * itemsPerRow }).map((_, i) => renderGridItemSkeleton(i))}
        </div>
    );
};

export const ListingItemsGrid: React.FC<ListingItemsGridProps> = ({
    queryData,
    gridStyle,
    noMoreItemsText,
    noItemsText,
    showFavoriteButton = false,
    itemsPerRow,
    own = false,
}) => {
    const [confirmRemovalId, setConfirmRemovalId] = useState('');
    const { status, data, fetchNextPage, hasNextPage, isFetchingNextPage } = queryData;
    const listings = data?.pages?.map((page) => page.data).flat() || [];
    const { mutate: toggleFavorite } = useToggleFavorite();
    const { t } = useTranslation();

    const queryClient = useQueryClient();
    const navigate = useSafeNavigate();

    const handleCardClick = useCallback(
        (listing: Listing) => {
            const queryKey = ['listing', { listingId: listing.id }];

            analytics.listingClick(listing.id, listing.title, 'profile-page');

            const cachedData = queryClient.getQueryData(queryKey);
            if (!cachedData) {
                // Invalidating the manually updated cache immediately will fetch and append rest of the information
                queryClient.setQueryData(queryKey, () => listing);
                queryClient.invalidateQueries(queryKey);
            }

            const path = own ? `/listing/own/${listing.id}` : `/listing/${listing.id}`;
            navigate(path);
        },
        [navigate, queryClient, own],
    );
    return (
        <ContentLoader
            status={status}
            style={{ ...gridStyle, height: 'unset', width: '100%' }}
            skeleton={<ListingItemsSkeleton itemsPerRow={itemsPerRow} />}
        >
            <motion.div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', maxHeight: '100%', padding: '0 2px' }}>
                {listings.map((listing) => (
                    <motion.div key={listing.id} layout style={{ width: `calc(100% / ${itemsPerRow})`, padding: '1px' }}>
                        <ProfileListingItem
                            listing={listing}
                            showFavoriteIcon={showFavoriteButton}
                            onToggleFavorite={setConfirmRemovalId}
                            onClick={handleCardClick}
                        />
                    </motion.div>
                ))}

                <motion.div layout style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <LoadMoreButton
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        noMoreItemsText={noMoreItemsText}
                        noItemsText={noItemsText}
                        noItems={listings.length === 0}
                    />
                </motion.div>
            </motion.div>

            <ConfirmDialog
                open={!!confirmRemovalId}
                title={t('removeFavorite')}
                message=""
                loading={false}
                onConfirm={() => {
                    toggleFavorite(confirmRemovalId);
                    setConfirmRemovalId('');
                }}
                onCancel={() => setConfirmRemovalId('')}
            />
        </ContentLoader>
    );
};
