import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Box from '@mui/material/Box/Box';
import Badge from '@mui/material/Badge/Badge';
import { TagTypeEnum } from '../../types/enums';
import { TagObject, useSearchTags } from './useSearchTags';
import { TextField, TextFieldProps } from '@mui/material';

type Props = {
    type: TagTypeEnum;
    selectedValues: string[];
    onValueSelected: (value: string[]) => void;
    limit?: number;
    includeNew?: boolean;
    textFieldVariant?: 'standard' | 'outlined';
};

export const TagSelector = ({
    type,
    selectedValues,
    onValueSelected,
    limit = 1,
    textFieldVariant = 'outlined',
    includeNew = true,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [suggestions, setSuggestions] = useState<TagObject[]>([]);
    const [disableInput, setDisableInput] = useState(false);
    const [query, setQuery] = useState('');
    const [values, setValues] = useState<TagObject[]>(selectedValues.map((value: string) => ({ type, tag: value })));
    const [inputValue, setInputValue] = useState('');
    const { data, status } = useSearchTags(type, query, includeNew);

    const debouncedSearch = useRef(
        debounce((newInput) => {
            setQuery(newInput);
        }, 300),
    ).current;

    useEffect(() => {
        setValues(selectedValues.map((v: string) => ({ type, tag: v })));
        setDisableInput(selectedValues.length >= limit);
    }, [limit, selectedValues, type]);

    useEffect(
        () => () => {
            debouncedSearch.cancel();
        },
        [debouncedSearch],
    );

    useEffect(() => {
        if (data) {
            setSuggestions(data);
        }
    }, [data]);

    const handleInputChange = (event: any) => {
        const newValue = event.target.value;
        debouncedSearch(newValue);
    };

    return (
        (<Autocomplete
            freeSolo
            multiple
            value={values}
            loading={status === 'loading'}
            id="size-small-standard-multi"
            size="medium"
            getOptionDisabled={() => disableInput}
            options={suggestions}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.tag;
            }}
            onChange={(event: any, newValue: (TagObject | string)[] | null) => {
                if (newValue) {
                    onValueSelected(
                        newValue?.map((tag) => {
                            if (typeof tag === 'string') {
                                return tag;
                            }
                            return tag.tag;
                        }),
                    );
                    setDisableInput(newValue.length >= limit);
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                // @ts-ignore
                (<TextField
                    {...params}
                    variant={textFieldVariant as TextFieldProps['variant']}
                    InputProps={{ ...params.InputProps }}
                    onChange={handleInputChange}
                    label={t(type)}
                />)
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <Box style={{ flex: 1 }}>{option.tag}</Box>
                    <Badge badgeContent="new" invisible={!option.new} sx={{ marginRight: 2 }} color="primary" />
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.tag === value.tag}
        />)
    );
};
