import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonProps, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFCWithChildren } from '../../types/types';
import { Modal } from '../Modal/Modal';

interface ConfirmDialogProps {
    title?: string;
    message?: React.ReactNode;
    open: boolean;
    color?: ButtonProps['color'];
    loading?: boolean;
    cancelText?: React.ReactNode;
    confirmText?: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmDialog: ReactFCWithChildren<ConfirmDialogProps> = ({
    title,
    message,
    open,
    loading,
    onConfirm,
    onCancel,
    color = 'primary',
    confirmText,
    cancelText,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} variant="auto" onClose={onCancel} modalStyle={{ maxWidth: '450px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '8px 8px 0 8px' }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onCancel}>{cancelText || t('cancel')}</Button>
                    <LoadingButton loading={loading} color={color} variant="contained" onClick={onConfirm}>
                        {confirmText || t('confirm')}
                    </LoadingButton>
                </DialogActions>
            </div>
        </Modal>
    );
};
