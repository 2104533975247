import { BrandCarousel as StrapiBrandCarousel } from '../types';
import { useTranslation } from 'react-i18next';
import { Collection } from './Collection/Collection';
import { dispatchCustomEvent } from '../../store/listeners';
import { resolveSlideSettings } from '../helpers';
import { analytics } from '../../analytics/events/helpers';

interface BrandCarouselProps {
    component: StrapiBrandCarousel;
    isDesktop?: boolean;
}

export const BrandCarousel: React.FC<BrandCarouselProps> = ({ component, isDesktop }) => {
    const { title, brands } = component;
    const { t } = useTranslation();

    const data = brands.map((brand) => ({
        id: brand.id,
        image: brand.image?.data.attributes.formats.medium.url || '',
        blurhash: brand.image?.data.attributes.blurhash,
        label: t(brand.label || brand.brand),
    }));

    const handleClickBrand = (idx: number) => {
        const value = brands[idx].brand;

        if (value) {
            analytics.homePageAction('brand_click', { brand: value });
            dispatchCustomEvent('replaceFilterAndNavigate', { type: 'brand', payload: [value] });
        }
    };

    const slideSettings = resolveSlideSettings(isDesktop, component);

    return <Collection title={title} data={data} slideSettings={slideSettings} onClickItem={handleClickBrand} />;
};
