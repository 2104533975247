import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTheme, selectCompactMode, selectHideUI, setTheme, setHideUI, setCompactMode, initialSettingsState } from '../store/settingsReducer';

export const useInitializeSettings = () => {
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);
    const compactMode = useSelector(selectCompactMode);
    const hideUI = useSelector(selectHideUI);

    useEffect(() => {
        if (theme === undefined) {
            dispatch(setTheme(initialSettingsState.theme));
        }
        if (compactMode === undefined) {
            dispatch(setCompactMode(initialSettingsState.compactMode));
        }
        if (hideUI === undefined) {
            dispatch(setHideUI(initialSettingsState.hideUI));
        }
    }, [dispatch, theme, compactMode, hideUI]);
};
