import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useGesture } from '@use-gesture/react';
import { FullScreenFooter, FullScreenModal, FullScreenTopbar } from './FullScreenImage.components';
import { Box, IconButton, Portal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useHashChange } from '../../hooks/useHashChange';

export const DefaultTopBarContent: React.FC<{ onClose: (evt: React.MouseEvent<HTMLButtonElement>) => void }> = ({ onClose }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', zIndex: 10000 }}>
            <IconButton onClick={onClose} sx={{ color: 'white' }}>
                <Close />
            </IconButton>
        </Box>
    );
};

interface FullScreenImageProps {
    src: string;
    style?: React.CSSProperties;
    alt?: string;
    topBarContent?: JSX.Element;
    footerContent?: JSX.Element;
}

interface FullScreenImageModalProps {
    src: string;
    alt?: string;
    topBarContent?: JSX.Element;
    footerContent?: JSX.Element;
    onClose: (evt: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const FullScreenImageModal: React.FC<FullScreenImageModalProps> = ({ src, alt, onClose, topBarContent, footerContent }) => {
    return (
        <Portal>
            <FullScreenModal
                onClick={onClose}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
            >
                <FullScreenTopbar>{topBarContent ? topBarContent : <DefaultTopBarContent onClose={onClose} />}</FullScreenTopbar>
                <ImageWithGestures src={src} alt={alt} style={{ maxWidth: '100%', maxHeight: '80%', objectFit: 'contain' }} />
                <FullScreenFooter>{footerContent}</FullScreenFooter>
            </FullScreenModal>
        </Portal>
    );
};

interface ImageWithGesturesProps {
    src: string;
    alt?: string;
    style?: React.CSSProperties;
    onScaleChange?: (scale: number) => void;
}

export const ImageWithGestures: React.FC<ImageWithGesturesProps> = ({ src, alt, style, onScaleChange }) => {
    const [target, setTarget] = useState<HTMLImageElement | null>(null);
    const [position, setPosition] = useState({ x: 0, y: 0, scale: 1 });

    useEffect(() => {
        onScaleChange?.(position.scale);
    }, [position.scale]);

    useGesture(
        {
            onDrag: ({ pinching, cancel, first, offset: [x, y], memo }) => {
                if (pinching || position.scale === 1) return cancel();

                if (first) {
                    const imageRect = target!.getBoundingClientRect();

                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;

                    const imageAspectRatio = imageRect.width / imageRect.height;

                    memo = {
                        imageWidth: imageRect.width / position.scale,
                        imageHeight: imageRect.height / position.scale,
                        viewportWidth,
                        viewportHeight,
                        isLandscapeImage: imageAspectRatio > 1,
                    };
                }

                const scaledWidth = memo.imageWidth * position.scale;
                const scaledHeight = memo.imageHeight * position.scale;

                // Calculate center offsets
                const offsetX = scaledWidth / 2;
                const offsetY = scaledHeight / 2;

                // Adjust drag limits based on center offset
                let maxX, maxY;

                if (memo.isLandscapeImage) {
                    maxX = Math.max(offsetX - memo.viewportWidth / 2, 0);
                    maxY = Math.max((memo.viewportHeight - scaledHeight) / (2 * position.scale), 0);
                } else {
                    maxX = Math.max(offsetX - memo.viewportWidth / 2, 0);
                    maxY = Math.max(offsetY - memo.viewportHeight / 2, 0);
                }

                // Constrain x and y
                const constrainedX = Math.min(Math.max(x, -maxX), maxX);
                const constrainedY = Math.min(Math.max(y, -maxY), maxY);

                setPosition({ ...position, x: constrainedX, y: constrainedY });

                return memo;
            },
            onPinch: ({ origin: [ox, oy], first, last, movement: [ms], offset: [s], memo }) => {
                if (first) {
                    const { width, height, x, y } = target!.getBoundingClientRect();
                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;

                    const imageAspectRatio = width / height;

                    const isLandscapeImage = imageAspectRatio > 1;

                    memo = {
                        position: { x: position.x, y: position.y },
                        target: { x: ox - (x + width / 2), y: oy - (y + height / 2) },
                        imageWidth: width / position.scale,
                        imageHeight: height / position.scale,
                        viewportWidth,
                        viewportHeight,
                        isLandscapeImage,
                    };
                }

                const scaledWidth = memo.imageWidth * position.scale;

                // Calculate center offset
                const offsetX = scaledWidth / 2;

                // Adjust pinch limits based on center offset
                let maxX, maxY;
                if (memo.isLandscapeImage) {
                    maxX = Math.max(offsetX - memo.viewportWidth / 2, 0);
                    maxY = Infinity;
                } else {
                    maxX = Math.max(offsetX - memo.viewportWidth / 2, 0);
                    maxY = Infinity;
                }

                const newScale = s;

                let x, y;

                const isZoomingIn = newScale > position.scale;

                if (isZoomingIn) {
                    // Zooming in - use existing logic
                    x = memo.position.x - (ms - 1) * memo.target.x;
                    y = memo.position.y - (ms - 1) * memo.target.y;
                } else {
                    // Recalculate x and y using interpolatePosition that tries to target the center of the image
                    x = interpolatePosition(position.x, 0, newScale, position.scale);
                    y = interpolatePosition(position.y, 0, newScale, position.scale);
                }

                const constrainedX = Math.min(Math.max(x, -maxX), maxX);
                const constrainedY = Math.min(Math.max(y, -maxY), maxY);

                if (last && newScale <= 1) {
                    setPosition({ scale: 1, x: 0, y: 0 });
                } else {
                    setPosition({ scale: newScale, x: constrainedX, y: constrainedY });
                }

                return memo;
            },
        },
        {
            target: target || undefined,
            drag: { from: () => [position.x, position.y] },
            pinch: { scaleBounds: { min: 1, max: 4 }, rubberband: true },
        },
    );

    const imageStyle = {
        transform: `translate3d(${position.x}px, ${position.y}px, 0) scale(${position.scale})`,
        touchAction: 'none',
        ...style,
    };

    return <img src={src} alt={alt} ref={setTarget} style={{ ...imageStyle, touchAction: 'none', ...style }} />;
};

const interpolatePosition = (currentPos: number, targetPos: number, newScale: number, currentScale: number, neutralScale: number = 1) => {
    const scaleRange = currentScale - neutralScale;
    let factor = 0;

    if (scaleRange !== 0) {
        factor = (currentScale - newScale) / scaleRange;
    }

    // Ensuring the factor is within the range [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the change in position
    const posChange = factor * (targetPos - currentPos);

    // Update the current position
    const newPos = currentPos + posChange;

    return Math.abs(newPos) * Math.sign(currentPos);
};

const FullScreenImage: React.FC<FullScreenImageProps> = ({ src, style, alt, topBarContent, footerContent }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = (evt: React.MouseEvent<HTMLElement>) => {
        setIsFullScreen(!isFullScreen);
    };

    useHashChange(isFullScreen, () => setIsFullScreen(false));

    return (
        <>
            <img
                style={{ height: 'inherit', width: 'inherit', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', ...style }}
                src={src}
                alt={alt}
                onClick={toggleFullScreen}
            />
            <AnimatePresence>
                {isFullScreen && (
                    <FullScreenImageModal
                        src={src}
                        alt={alt}
                        onClose={toggleFullScreen}
                        topBarContent={topBarContent}
                        footerContent={footerContent}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default FullScreenImage;
