import { styled } from '@mui/material';

const tabNavHeight = '50px';

export const TabNav = styled('nav')(() => ({
    borderRadius: '10px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const MainContent = styled('main')(() => ({
    width: '100%',
    height: `calc(100% - ${tabNavHeight})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: '1',
    userSelect: 'none',
}));
