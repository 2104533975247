import { Alert, AlertTitle, ButtonBase, SxProps, styled } from '@mui/material';
import React, { useState } from 'react';
import { ResponsiveDrawer } from '../ResponsiveDrawer/ResponsiveDrawer';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';

interface AlertDrawerProps {
    severity: 'error' | 'warning' | 'info' | 'success';
    summary: React.ReactNode;
    title: React.ReactNode;
    message: React.ReactNode;
    alertBtnAlertSx?: SxProps;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    width: '100%',
    borderRadius: '15px',
    boxShadow: theme.palette.boxShadow.lighter,
    '.MuiAlert-icon': {
        marginRight: '11px',
    },
}));

export const AlertDrawer: React.FC<AlertDrawerProps> = ({ severity, summary, title, message, alertBtnAlertSx }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <ButtonBase
                onClick={() => setOpen(true)}
                sx={{
                    width: '100%',
                }}
            >
                <StyledAlert severity={severity} sx={alertBtnAlertSx}>
                    {summary}
                </StyledAlert>
            </ButtonBase>

            <SwipeableDrawer open={open} onClose={() => setOpen(false)} fillHeight={false}>
                <Alert severity={severity} sx={{ borderRadius: '20px', margin: '0 20px 20px 20px' }}>
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            </SwipeableDrawer>
        </>
    );
};
