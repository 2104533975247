import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

interface MultipleSelectProps {
    label?: string;
    variant?: 'standard' | 'outlined';
    showLabel?: boolean;
    selectedValues: string[];
    options: { value: string; label: string }[];
    onChange: (value: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            marginLeft: '8px',
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        },
    },
};

export const MultipleSelect: React.FC<MultipleSelectProps> = ({ label, showLabel, options, variant = 'standard', selectedValues, onChange }) => {
    const { t } = useTranslation();
    const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
            target: { value },
        } = event;

        onChange(typeof value === 'string' ? value.split(',') : value);
    };

    const buttonLabel = selectedValues.length
        ? selectedValues
              .map((val) => {
                  const match = options.find((el) => el.value === val);
                  return match?.label;
              })
              .filter((el) => !!el)
              .join(', ')
        : `Select ${label}`;

    const resolvedLabel = showLabel ? label : '';

    return (
        <Box sx={{ p: 1 }}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id={`${resolvedLabel}-multiple-checkbox-label`}>{resolvedLabel}</InputLabel>
                <Select
                    labelId={`${resolvedLabel}-multiple-checkbox-label`}
                    label={resolvedLabel}
                    multiple
                    variant="outlined"
                    value={selectedValues}
                    placeholder="Test"
                    onChange={handleChange}
                    renderValue={() => buttonLabel}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
