import { Breakpoint, ThemeOptions } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Returns boolean depending on the current screen width: e.g with arguments 'sm' and 'up', it will return true
 * when screen width is above the 'sm' threshold
 */
export const useBreakpoint = (breakpoint: Breakpoint, direction: 'up' | 'down' = 'up'): boolean =>
    useMediaQuery(
        (theme: ThemeOptions) => {
            if (theme.breakpoints) {
                const func = theme.breakpoints[direction];
                return func ? func(breakpoint) : '';
            }
            return '';
        },
        { noSsr: true },
    );
