import React, { useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { takePhoto, pickPhoto, presentPhotoPrompt } from '../../helpers/camera';
import { assertNever } from '../../helpers/commonHelpers';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { AddButton, AddButtonIcon, Image } from '../AddImage/AddImagesButton.components';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { ProfilePictureEditor } from '../ProfilePictureEditor/ProfilePictureEditor';
import { Modal } from '../Modal/Modal';
import { useUploadAndUpdateProfileImage } from '../../user/hooks/useUpdateProfileImage';

const showActions = async () => {
    const result = await ActionSheet.showActions({
        title: 'Profile Picture',
        message: 'Select an option to perform',
        options: [
            {
                title: 'Take photo',
                icon: 'camera',
            },
            {
                title: 'Pick from gallery',
                icon: 'image',
            },
            {
                title: 'Cancel',
                style: ActionSheetButtonStyle.Cancel,
                icon: 'close',
            },
        ],
    });

    return result.index as 0 | 1 | 2;
};

export const AddProfilePicture: React.FC<{ imgUrl: string | undefined }> = ({ imgUrl }) => {
    const [open, setOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(imgUrl || '');
    const [uploading, setIsUploading] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleShowActions = async () => {
        const handleResult = async (result: 0 | 1 | 2) => {
            if (result === 0) {
                return takePhoto();
            }
            if (result === 1) {
                return pickPhoto();
            }
            if (result === 2) {
                return;
            }

            assertNever(result);
        };

        let photo;

        // Safari works in mysterious ways, workaround for the photo prompt not appearing.
        if (isSafari) {
            photo = await presentPhotoPrompt();
        } else {
            const result = await showActions();
            photo = await handleResult(result);
        }

        if (photo) {
            setImageUrl(photo);
            setOpen(true);
        }
    };

    useEffect(() => {
        if (isImageLoaded) {
            setIsUploading(false);
        }
    }, [isImageLoaded]);

    const handleSuccess = () => {
        setOpen(false);
    };

    const { mutate: uploadAndUpdateProfileImage, isLoading } = useUploadAndUpdateProfileImage(() => handleSuccess());

    const handleStartUpload = (blob: Blob) => {
        setIsUploading(true);
        setIsImageLoaded(false);
        uploadAndUpdateProfileImage(blob);
    };

    return (
        <>
            <AddButton
                onClick={handleShowActions}
                loading={uploading}
                loadingIndicator={<CircularProgress variant="indeterminate" color="inherit" size={16} />}
            >
                {imgUrl ? (
                    <Image
                        loading="lazy"
                        onLoad={() => setIsImageLoaded(true)}
                        src={imgUrl}
                        style={{ opacity: uploading ? 0.2 : 1, borderRadius: '50%' }}
                    />
                ) : (
                    <motion.div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        initial={{ opacity: 0, scale: 0, rotate: -45 }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            rotate: 270,
                        }}
                        transition={{ type: 'spring', stiffness: 200, damping: 30, bounce: 0 }}
                    >
                        {!uploading && <AddButtonIcon />}
                    </motion.div>
                )}
            </AddButton>

            <Modal open={open} onClose={() => setOpen(false)} animationDuration={0}>
                <ProfilePictureEditor imageUrl={imageUrl} loading={isLoading} showActions={handleShowActions} onCrop={handleStartUpload} />
            </Modal>
        </>
    );
};
