import styled from '@mui/material/styles/styled';
import React from 'react';
import { ComponentPropType } from '../../types/shared';

const StyledContentRoot = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '16px',
}));

const StyledContent = styled('div', { shouldForwardProp: (prop) => prop !== 'fillScreen' })<{
    fillScreen: boolean | undefined;
}>(({ fillScreen }) => ({
    width: '100%',
    height: fillScreen ? '100%' : 'unset',
}));

interface CenteredContainerProps extends ComponentPropType {
    fillScreen?: boolean;
}

const CenteredContainer = ({ children, fillScreen }: CenteredContainerProps): JSX.Element => (
    <StyledContentRoot>
        <StyledContent fillScreen={fillScreen}>{children}</StyledContent>
    </StyledContentRoot>
);

CenteredContainer.defaultProps = {
    fillScreen: false,
};

export default CenteredContainer;
