import { Backdrop, Box, CircularProgress, Divider, Portal, Skeleton } from '@mui/material';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { useScroll } from 'framer-motion';
import { cloneDeep, omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { headerHeightPX } from '../../constants';
import { DrawerProvider } from '../../context/drawer';
import { assertIsDefined, sleep } from '../../helpers/commonHelpers';
import {
    isSharetribeError,
    isTransactionInitiateBookingTimeNotAvailableError,
    isTransactionInitiateListingNotFoundError,
    isTransactionInitiateMissingStripeAccountError,
} from '../../helpers/error/helpers';
import { logger } from '../../helpers/logger';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useLineItems, usePrefetchLineItems } from '../../queries/useLineItems';
import { useListing } from '../../queries/useListing';
import {
    Booking,
    BookingDraft,
    resetBookingDraft,
    selectBooking,
    selectBookingRange,
    updateBookingDraft,
    updateBookingDraftAsync,
    updateOngoing,
    updateOngoingAsync,
} from '../../store/bookingReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useCurrentUser } from '../../user/hooks/useUser';
import {
    ERROR_APPLE_PAY,
    ERROR_CARD_PAYMENT,
    ERROR_GOOGLE_PAY,
    ERROR_PAYMENT_CANCELLED,
    usePaymentFlow,
} from '../UserProfile/Payments/usePaymentFlow';
import { PaymentMethod, UsePaymentParams } from '../UserProfile/Payments/types';
import { BookingBreakdownSection } from './BookingBreakdownSection';
import { CheckoutFooter } from './CheckoutFooter';
import { ListingDetailsSection } from './ListingDetailsSection';
import { LogisticsSection } from './LogisticsSection';
import { PaymentOptionsSection } from './PaymentOptionsSection';
import { User } from '../../types/apiTypes';
import { store } from '../../store/store';
import { useTimeslots } from '../../queries/useTimeslots';
import { useDeliveryConfigurationMethods } from '../../queries/useDeliveryTiming';
import { formatTime } from '../../helpers/dateAndTimeHelpers';
import { CreditsSection } from './CreditsSection';
import { useCreditsConfiguration } from '../UserProfile/hooks/useCreditsConfiguration';
import { useCreditsBalance } from '../UserProfile/hooks/useCreditsBalance';
import { isBooking, isBookingDraft } from '../UserProfile/Payments/helpers';
import { useCapacitorStripeEvents } from '../UserProfile/Payments/capacitorStripeEvents';

interface FormData {
    deliveryDetails?: {
        street: string;
        postalCode: string;
        city: string;
        phone: string;
        rememberForLater?: boolean;
    };
    deliveryTime?: Date;
    deliveryMethod: string;
    returnTime?: Date;
    returnMethod?: string;
    useCredits?: boolean;
}

const defaultFormValues: Partial<FormData> = {
    deliveryDetails: {
        street: '',
        postalCode: '',
        city: '',
        phone: '',
        rememberForLater: true,
    },
    useCredits: true,
};

const getDefaultFormValues = (user: User | undefined, listingId: string) => {
    if (!user) {
        return defaultFormValues;
    }

    let formValues: Partial<FormData> = cloneDeep(defaultFormValues);

    if (user.profile.privateData?.deliveryDetails) {
        formValues.deliveryDetails = {
            ...defaultFormValues.deliveryDetails,
            ...user.profile.privateData.deliveryDetails,
        };
    }

    const ongoingBooking = selectBooking(store.getState(), listingId) as Booking | BookingDraft;

    const setTime = (timeString: string) => {
        const d = new Date();
        const [hours, minutes] = timeString.split(':').map(Number);
        d.setHours(hours);
        d.setMinutes(minutes);
        return d;
    };

    if (ongoingBooking?.deliveryMethod) {
        formValues.deliveryMethod = ongoingBooking.deliveryMethod;

        if (ongoingBooking.deliveryTime) {
            formValues.deliveryTime = setTime(ongoingBooking.deliveryTime);
        }
    }

    if (ongoingBooking?.returnMethod) {
        formValues.returnMethod = ongoingBooking.returnMethod;

        if (ongoingBooking.returnTime) {
            formValues.returnTime = setTime(ongoingBooking.returnTime);
        }
    }

    return formValues;
};

export const CheckoutPage: React.FC = () => {
    const [lineItemsAndCreditsInit, setLineItemsAndCreditsInit] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [paymentDisabled, setPaymentDisabled] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const { id: listingId } = useParams() as { id: string };
    const { data: currentUser } = useCurrentUser();

    useCapacitorStripeEvents();

    const methods = useForm<FormData>({ defaultValues: getDefaultFormValues(currentUser, listingId) });
    const deliveryMethod = methods.watch('deliveryMethod');
    const useCredits = methods.watch('useCredits');

    /**
     * Handles form value initialisation for the following cases:
     * 1. when deliveryMethod is changed and the fields have "shouldUnregister" set to true (so that these fields are not considered in field validation upon submit)
     * 2. when the page was reloaded and during form initialisation the currentUser was not yet available
     */
    useEffect(() => {
        if (!currentUser) {
            return;
        }

        const ongoingBooking = selectBooking(store.getState(), listingId) as Booking | BookingDraft;

        if (deliveryMethod || ongoingBooking?.deliveryMethod) {
            const updatedFormValues = { ...methods.getValues(), ...getDefaultFormValues(currentUser, listingId) };
            methods.reset(updatedFormValues);
        }
    }, [currentUser, methods.reset, deliveryMethod]);

    const { data: listing, status } = useListing(listingId);
    const { bookingStart, bookingEnd } = useAppSelector((s) => selectBookingRange(s, listingId));
    const {
        data: lineItemData,
        status: lineItemStatus,
        isInitialLoading: isInitialLoadingLineItems,
    } = useLineItems({ listingId, bookingStart, bookingEnd, deliveryMethod, useCredits });

    const { data: creditsConfiguration, isInitialLoading: isInitialLoadingConfiguration } = useCreditsConfiguration();
    const { data: creditsBalance, isInitialLoading: isInitialLoadingCredits } = useCreditsBalance();

    useEffect(() => {
        if (!isInitialLoadingConfiguration && !isInitialLoadingCredits && !isInitialLoadingLineItems) {
            setLineItemsAndCreditsInit(true);
        }
    }, [isInitialLoadingConfiguration, isInitialLoadingCredits, isInitialLoadingLineItems]);

    const prefetchLineItemsWithoutCreditsFn = usePrefetchLineItems({ listingId, bookingStart, bookingEnd, deliveryMethod, useCredits: false });

    const { t } = useTranslation();

    const queryClient = useQueryClient();
    const booking = useAppSelector((s) => selectBooking(s, listingId) as Booking | BookingDraft);

    const navigate = useSafeNavigate();
    const dispatch = useAppDispatch();
    const paymentMethod = booking.paymentMethod;

    const isDesktop = useBreakpoint('sm');

    const isVerified = Boolean(currentUser?.profile.publicData.identityVerified);

    const handlePaymentSuccess = async () => {
        navigate(`/listing/${listingId}/complete`, { replace: true });

        // Avoid reset during the transition to the next page
        await sleep(1000);

        methods.reset();
    };

    const handleGoBack = () => {
        navigate(`/listing/${listingId}`, { replace: true });
    };

    const handleResetBooking = (currentBooking: Booking | BookingDraft) => {
        const newBooking = {
            listingId: currentBooking.listingId,
            range: currentBooking.range,
            paymentMethod: currentBooking.paymentMethod,
        };

        dispatch(resetBookingDraft(newBooking));
    };

    const handleBookingExpired = () => {
        setConfirmed(false);

        toast.error('Booking expired.');
        handleResetBooking(booking);
    };

    const handleBookingConflict = (error: any) => {
        logger.error(error);

        if (isSharetribeError(error)) {
            const listingQueryKey = ['listing', { listingId }];

            const getErrorMsg = (): string => {
                if (isTransactionInitiateBookingTimeNotAvailableError(error)) {
                    return t('bookingTimeNotAvailableError');
                }
                if (isTransactionInitiateListingNotFoundError(error)) {
                    return t('listingNotAvailableError');
                }
                if (isTransactionInitiateMissingStripeAccountError(error)) {
                    return t('stripeAccountSetupError');
                }

                logger.info('Add a translation for this error:', error);
                return t('unexpectedError');
            };

            toast.info(getErrorMsg());
            queryClient.invalidateQueries(listingQueryKey);

            if (isBooking(booking)) {
                handleResetBooking(booking);
            }
            setPaymentDisabled(true);
        } else {
            const knownErrors = [ERROR_GOOGLE_PAY, ERROR_APPLE_PAY, ERROR_CARD_PAYMENT, ERROR_PAYMENT_CANCELLED];
            const msg = typeof error.message === 'string' && knownErrors.includes(error.message) ? error.message : 'unexpectedError';
            toast.info<string>(t(msg));

            setConfirmed(false);
        }
    };

    const { mutate: startPayment, isLoading: paymentInProgress } = usePaymentFlow();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const closeDrawer = () => setDrawerOpen(false);
    const openDrawer = () => setDrawerOpen(true);

    const onSelectPaymentMethod = (value: PaymentMethod) => {
        if (isBooking(booking)) {
            dispatch(updateOngoing({ ...booking, paymentMethod: value }));
        } else {
            dispatch(updateBookingDraft({ ...booking, paymentMethod: value }));
        }

        closeDrawer();
    };

    const params = { listingId, bookingStart: bookingStart as string, bookingEnd: bookingEnd as string };
    const deliveryConfigurationMethods = useDeliveryConfigurationMethods(params);

    const onAccept = async (ev?: PaymentRequestPaymentMethodEvent) => {
        methods.handleSubmit(
            async (data) => {
                let formData;

                if (deliveryMethod === 'wolt') {
                    assertIsDefined(data.deliveryDetails, 'deliveryDetails must be defined when deliveryMethod is wolt');
                    assertIsDefined(data.deliveryTime, 'deliveryTime must be defined when deliveryMethod is wolt');
                    assertIsDefined(data.returnMethod, 'returnMethod must be defined when deliveryMethod is wolt');

                    formData = {
                        ...data,
                        deliveryTime: formatTime(data.deliveryTime),
                        returnTime: formatTime(data.returnTime),
                    };
                } else if (deliveryMethod === 'showroom') {
                    formData = {
                        ...omit(data, ['deliveryDetails', 'deliveryTime', 'returnTime']),
                        returnMethod: 'showroom',
                    };
                } else {
                    formData = omit(data, ['deliveryDetails', 'deliveryTime', 'returnTime', 'returnMethod']);
                }

                // Add the calculated pickup and return dates to the form data. DeliveryTimingMethods' `getNextReturnDateAndTime` and `getPickupDateWithinOpeningHours`
                // will use these dates when transaction is in progress, because when the transaction is in progress, timeslots have already been occupied by the current booking.
                if ((deliveryMethod === 'wolt' || deliveryMethod === 'showroom') && isBookingDraft(booking)) {
                    const dates = deliveryConfigurationMethods.deliveryMethods?.[deliveryMethod];

                    assertIsDefined(dates, 'dates must be defined when deliveryMethod is wolt or showroom');

                    formData = {
                        ...formData,
                        renterDeliveryDate: dates.renterDeliveryDate,
                        renterReturnDate: dates.renterReturnDate,
                    };
                }

                if (isBooking(booking)) {
                    await dispatch(updateOngoingAsync({ ...booking, ...formData }));
                } else {
                    await dispatch(updateBookingDraftAsync({ ...booking, ...formData }));
                }

                const updatedBooking = selectBooking(store.getState(), listingId) as Booking | BookingDraft;

                const resolvedBooking = formData ? { ...updatedBooking, ...formData } : updatedBooking;

                const params: UsePaymentParams = {
                    listingId,
                    booking: resolvedBooking,
                    fullCreditPayment: lineItemData?.fullCreditPayment,
                    onSuccess: handlePaymentSuccess,
                    onExpired: handleBookingExpired,
                    onConflict: handleBookingConflict,
                    ...(ev && { ev }),
                };

                setConfirmed(true);
                startPayment(params);
            },
            (err) => {
                setConfirmed(false);
            },
        )();
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({ container: containerRef, layoutEffect: true });
    const scrollInitRef = useRef<boolean>(false);

    scrollYProgress.on('change', (val) => {
        // Initial scroll measure always shows 100% progress incorrectly, skip it
        if (!scrollInitRef.current) {
            scrollInitRef.current = true;
        } else if (minimized && val >= 0.9) {
            setMinimized(false);
        }
    });

    const { data: timeslots } = useTimeslots(listingId);

    const resolveLineItems = () => {
        // Draft -> no saved lineItem data yet
        if (!booking.status) {
            return lineItemData?.lineItems || [];
        }

        return booking.transaction?.attributes.lineItems || [];
    };

    const resolvedLineItems = resolveLineItems();

    return (
        <DrawerProvider>
            <FormProvider {...methods}>
                <div style={{ height: '100%', width: '100%', overflowY: 'auto', paddingTop: isDesktop ? 20 : headerHeightPX }} ref={containerRef}>
                    <ContentLoader status={status} style={{ display: 'block', position: 'relative', padding: '0 20px' }}>
                        <form>
                            {listing && bookingStart && bookingEnd && (
                                <>
                                    {!isDesktop && <Divider sx={{ width: '100%', margin: '10px 0 20px' }} />}

                                    <ListingDetailsSection
                                        listingTitle={listing.title}
                                        listingCoverImage={listing.images[0].url}
                                        bookingStart={bookingStart}
                                        bookingEnd={bookingEnd}
                                    />

                                    <Divider sx={{ width: '100%', margin: '20px 0 20px' }} />

                                    <LogisticsSection
                                        listingId={listingId}
                                        booking={booking}
                                        timeslots={timeslots}
                                        woltEnabled={!!listing.publicData.deliveryOptions?.includes('wolt')}
                                        showroomEnabled={!!listing.publicData.deliveryOptions?.includes('showroom')}
                                        faceToFaceEnabled={!!listing.publicData.deliveryOptions?.includes('faceToFace')}
                                    />

                                    <Divider sx={{ width: '100%', margin: '10px 0 20px' }} />

                                    {isVerified && (
                                        <>
                                            <PaymentOptionsSection
                                                open={drawerOpen}
                                                onOpen={openDrawer}
                                                onClose={closeDrawer}
                                                onSelectPaymentMethod={onSelectPaymentMethod}
                                                selectedPaymentMethod={paymentMethod || 'card'}
                                                isLoading={false}
                                            />
                                            <Box sx={{ mt: 1 }} />

                                            {lineItemsAndCreditsInit ? (
                                                <CreditsSection
                                                    lineItems={resolvedLineItems}
                                                    lineItemStatus={lineItemStatus}
                                                    fullCreditPayment={lineItemData?.fullCreditPayment}
                                                    booking={booking}
                                                    creditsConfiguration={creditsConfiguration}
                                                    credits={creditsBalance}
                                                    prefetchLineItemsWithoutCredits={prefetchLineItemsWithoutCreditsFn}
                                                />
                                            ) : (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    <Skeleton variant="rounded" sx={{ height: 42, width: '100%' }} />
                                                    <Skeleton variant="rounded" sx={{ height: 42, width: '100%' }} />
                                                </Box>
                                            )}

                                            <Divider sx={{ width: '100%', margin: '20px 0 20px' }} />
                                        </>
                                    )}

                                    <BookingBreakdownSection lineItems={resolvedLineItems} initialLoadingLineItems={isInitialLoadingLineItems} />

                                    <Portal>
                                        <Backdrop open={paymentInProgress} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    </Portal>

                                    <div style={{ height: isDesktop ? '50px' : '160px' }} />
                                </>
                            )}

                            {isDesktop ? (
                                <CheckoutFooter
                                    onAccept={onAccept}
                                    confirmed={confirmed}
                                    onClickGoBack={handleGoBack}
                                    isDesktop={isDesktop}
                                    booking={booking}
                                    listing={listing}
                                    total={lineItemData?.total}
                                    minimized={minimized}
                                    paymentDisabled={paymentDisabled}
                                    startPayment={onAccept}
                                    setConfirmed={setConfirmed}
                                />
                            ) : (
                                <Box sx={{ position: 'fixed', bottom: 0, width: 'calc(100% - 40px)', maxWidth: '100%', zIndex: 100 }}>
                                    <CheckoutFooter
                                        onAccept={onAccept}
                                        confirmed={confirmed}
                                        onClickGoBack={handleGoBack}
                                        isDesktop={isDesktop}
                                        booking={booking}
                                        listing={listing}
                                        total={lineItemData?.total}
                                        minimized={minimized}
                                        paymentDisabled={paymentDisabled}
                                        startPayment={onAccept}
                                        setConfirmed={setConfirmed}
                                    />
                                </Box>
                            )}
                        </form>
                    </ContentLoader>
                </div>
            </FormProvider>
        </DrawerProvider>
    );
};
