import React, { useState } from 'react';
import { Badge, BottomNavigation, BottomNavigationAction, Box, ButtonBase, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddListingIcon, FooterContainer, HomeIcon, ProfileIcon, SearchIcon, WardrobeIcon } from './Footer.components';
import { Link, Link as RouterLink, useLocation } from 'react-router-dom';
import { getPathConfig } from '../../../routes/helpers';
import { useVirtualKeyboardOpen } from '../../../hooks/useVirtualKeyboardOpen';
import { useActionRequiredTxCount } from '../../../transactions/hooks/useActionRequiredTxCount';
import { NavigationPreventedModal } from './NavigationPreventedModal';
import { useAppSelector } from '../../../store/hooks';
import { selectToken } from '../../../store/userReducer';
import { selectIsScrolling } from '../../../store/listingsReducer';
import './Footer.css';
import { usePrevious } from '../../../hooks/usePrevious';

const protectedRoutes = ['/add-listing', '/wardrobe', '/profile'];

// Mainly to prevent filters from being reset when navigating to the active route
const preventNavigationIfAlreadyOnRouteRoutes = ['/listings'];

type ProtectedRoute = typeof protectedRoutes[number];

const FooterContent: React.FC<{ pathname: string; showPrivacyPolicyInFooter: boolean }> = ({ pathname, showPrivacyPolicyInFooter }) => {
    const [preventedRoute, setPreventedRoute] = useState<ProtectedRoute | null>(null);
    const { t } = useTranslation();
    const { data: count } = useActionRequiredTxCount();
    const userToken = useAppSelector(selectToken);
    const theme = useTheme();

    const handleRouteClick = (evt: React.MouseEvent<HTMLAnchorElement>, route: string) => {
        if (protectedRoutes.includes(route) && !userToken) {
            evt.preventDefault();
            setPreventedRoute(route);
        }

        if (preventNavigationIfAlreadyOnRouteRoutes.includes(route) && pathname.includes(route)) {
            evt.preventDefault();
        }
    };

    if (showPrivacyPolicyInFooter) {
        return (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', p: 2 }}>
                <ButtonBase href="/privacy-policy" sx={{ fontSize: '12px', color: theme.palette.primary.main, opacity: 0.7 }}>{` ${t(
                    'privacyPolicy',
                )}`}</ButtonBase>
            </Box>
        );
    }

    const navigationItems = [
        {
            label: t('home'),
            path: '/',
            icon: <HomeIcon />,
        },
        {
            label: t('browse'),
            path: '/listings',
            icon: <SearchIcon />,
        },
        {
            label: t('add'),
            path: '/add-listing',
            protected: true,
            icon: <AddListingIcon />,
        },
        {
            label: t('wardrobe'),
            path: '/wardrobe',
            protected: true,
            icon: (
                <Badge badgeContent={count || 0} color="primary" variant="dot">
                    <WardrobeIcon />
                </Badge>
            ),
        },
        {
            label: t('profile'),
            path: '/profile',
            protected: true,
            icon: <ProfileIcon />,
        },
    ];

    return (
        <BottomNavigation value={pathname} showLabels>
            {navigationItems.map(({ label, path, protected: isProtected, icon }) => (
                <BottomNavigationAction
                    key={path}
                    component={Link}
                    to={path}
                    value={path}
                    label={label}
                    icon={icon}
                    onClick={(evt) => handleRouteClick(evt, path)}
                    sx={{
                        '&.Mui-selected': {
                            '& svg': {
                                fill: theme.palette.primary.main,
                            },
                        },
                        '.MuiBottomNavigationAction-label': {
                            color: theme.palette.svgIcon.default,
                        },
                        '.MuiBottomNavigationAction-label.Mui-selected': {
                            color: theme.palette.primary.main,
                        },
                    }}
                />
            ))}

            <NavigationPreventedModal preventedRoute={preventedRoute} onClose={() => setPreventedRoute(null)} />
        </BottomNavigation>
    );
};

export const Footer: React.FC<{ isDesktop: boolean }> = ({ isDesktop }) => {
    const { pathname } = useLocation();
    const { hideFooter, showPrivacyPolicyInFooter } = getPathConfig(pathname);
    const previousPath = usePrevious(pathname);
    const keyboardOpen = useVirtualKeyboardOpen();
    const isScrolling = useAppSelector(selectIsScrolling);
    const hide = isScrolling || keyboardOpen || hideFooter;

    const enableTransition = previousPath?.includes('/listings') && pathname.includes('/listings');

    let footerClass = hide ? 'footer-hidden' : 'footer-visible';

    if (isDesktop && !showPrivacyPolicyInFooter) {
        return null;
    }

    return (
        <FooterContainer
            className={footerClass}
            showShadow={!showPrivacyPolicyInFooter}
            style={{ bottom: 0, transitionDuration: enableTransition ? '250ms' : '0ms', paddingBottom: 'env(safe-area-inset-bottom)' }}
        >
            <FooterContent pathname={pathname} showPrivacyPolicyInFooter={!!showPrivacyPolicyInFooter} />
        </FooterContainer>
    );
};
