import React from 'react';
import { motion, Variants } from 'framer-motion';
import { AppHeader } from './Header/Header';
import { useLocation } from 'react-router-dom';
import { getPathConfig, resolveConfigValue } from '../../../routes/helpers';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { resetFilters, selectFiltersChanged, selectIsScrolling } from '../../../store/listingsReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { FilterBar } from '../../FilterBar/Filterbar';
import { CustomToolbar } from './Topbar.components';
import './Topbar.css';
import { usePrevious } from '../../../hooks/usePrevious';
import { Box, ButtonBase, Skeleton, Typography } from '@mui/material';
import { useListings } from '../../../queries/useListings';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { filterResultsCountHeightPX } from '../../../constants';

const variants: Variants = {
    visible: {
        height: filterResultsCountHeightPX,
        opacity: 1,
        transition: {
            height: { type: 'spring', stiffness: 300, damping: 45 },
            opacity: { type: 'spring', stiffness: 300, damping: 45, delay: 0.3 },
        },
    },
    hidden: {
        height: 0,
        opacity: 0,
        transition: {
            height: { type: 'spring', stiffness: 300, damping: 45 },
            opacity: { type: 'spring', stiffness: 300, damping: 45, delay: 0.3 },
        },
    },
};

export const TopBar: React.FC = () => {
    const navigate = useSafeNavigate();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { hideTopbar, showFilterBar } = getPathConfig(pathname);
    const filtersChanged = useAppSelector(selectFiltersChanged);
    const previousPath = usePrevious(pathname);
    const isDesktop = useBreakpoint('sm');

    const isScrolling = useAppSelector(selectIsScrolling);
    const shouldHide = isScrolling || resolveConfigValue(hideTopbar, isDesktop);
    const enableTransition = previousPath?.includes('/listings') && pathname.includes('/listings');
    const { data: listingsData, isLoading } = useListings(search, 1, 'listing-filters');

    const totalItems = listingsData?.pages[0].meta.totalItems || 0;

    const topbarClass = shouldHide ? 'topbar-hidden' : 'topbar-visible';

    const handleClearFilters = () => {
        dispatch(resetFilters());

        const selectedCategory = new URLSearchParams(search).get('selectedCategory');
        const url = selectedCategory ? `/listings?selectedCategory=${selectedCategory}` : '/listings';

        navigate(url, { replace: true });
    };

    return (
        <CustomToolbar className={topbarClass} style={{ transitionDuration: enableTransition ? '250ms' : '0s' }}>
            <AppHeader navigate={navigate} />
            {!isDesktop && showFilterBar && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <motion.div
                        variants={variants}
                        animate={filtersChanged ? 'visible' : 'hidden'}
                        style={{ margin: '0px 15px 5px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        {filtersChanged && (
                            <>
                                <div>
                                    {isLoading ? (
                                        <Skeleton variant="text" height={16} width={50} />
                                    ) : (
                                        <>
                                            {totalItems > 0 && (
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                    <Typography variant="caption" sx={{ opacity: 0.8 }}>
                                                        {totalItems} {t('results')}
                                                    </Typography>
                                                </motion.div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <ButtonBase sx={{ opacity: 0.8 }} onClick={handleClearFilters}>
                                    <Close sx={{ fontSize: '16px' }} />
                                    <Typography variant="caption">{t('clear')}</Typography>
                                </ButtonBase>
                            </>
                        )}
                    </motion.div>
                    <FilterBar />
                </Box>
            )}
        </CustomToolbar>
    );
};
