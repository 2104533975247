import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { SwipeableDrawer } from '../../../../components/SwipeableDrawer/SwipeableDrawer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
    initialState,
    listingsStateReducer,
    replaceState,
    resetFilters,
    selectDrawerState,
    selectListingsState,
    setDrawerState,
    updateListingsState,
} from '../../../../store/listingsReducer';
import { AllFilters } from './AllFilters';
import { useListings } from '../../../../queries/useListings';
import { useSafeNavigate } from '../../../../hooks/useSafeNavigate';
import { useTranslation } from 'react-i18next';
import { queryString2State, state2QueryString } from '../../../../helpers/urlHelpers';
import { useFilterOptions } from './helpers';
import { DrawerState, ListingsState } from '../../types';
import { SingleSelectFilterOptions } from './SingleSelectFilterOptions';
import { MultiSelectFilterOptions } from './MultiSelectFilterOptions';
import { ButtonBase } from '@mui/material';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { PriceFilterOptions } from './PriceFilterOptions';
import { defaultPriceRange } from '../../constants';
import { useDispatchListener } from '../../../../hooks/useDispatchListener';
import useLocationParams from '../../../../hooks/useLocationParams';
import { useLocation } from 'react-router-dom';

const singleSelectFilters = ['category', 'sort', 'price'] as const;
const multiSelectFilters = ['size', 'color', 'brand', 'bodytype', 'delivery'] as const;

type SingleSelectFilter = typeof singleSelectFilters[number];
type MultiSelectFilter = typeof multiSelectFilters[number];

type FilterValue = string | [number, number];

const isStringValue = (value: FilterValue): value is string => {
    return typeof value === 'string';
};

const isNumberTuple = (value: FilterValue): value is [number, number] => {
    return Array.isArray(value) && value.length === 2 && value.every((item) => typeof item === 'number');
};

const isSingleSelectFilter = (state: DrawerState | null): state is SingleSelectFilter => {
    return singleSelectFilters.includes(state as SingleSelectFilter);
};

const isMultiSelectFilter = (state: DrawerState | null): state is MultiSelectFilter => {
    return multiSelectFilters.includes(state as MultiSelectFilter);
};

export const FilterDrawer: React.FC = () => {
    const [delayedDrawerState, setDelayedDrawerState] = useState<DrawerState | null>(null);
    const { selectedCategory } = useLocationParams();
    const location = useLocation();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const drawerState = useAppSelector(selectDrawerState);
    const navigate = useSafeNavigate();
    const listingsState = useAppSelector(selectListingsState);
    const priceRangeRef = useRef<{ resetPriceRange: () => void }>(null);

    const [listingsStateDraft, setListingsStateDraft] = useReducer(listingsStateReducer, listingsState);

    const queryParams = useMemo(() => `${state2QueryString(listingsStateDraft, selectedCategory)}`, [listingsStateDraft, selectedCategory]);
    const listingStateDraftRef = useRef(listingsStateDraft);

    useEffect(() => {
        if (location.pathname !== '/listings') {
            return;
        }

        if (location.search && listingStateDraftRef.current) {
            const state = queryString2State(location.search);
            const newState = { ...listingStateDraftRef.current, ...state };
            dispatch(replaceState(newState));
            setListingsStateDraft({ type: 'apply', payload: newState });
        }

        if (!location.search) {
            dispatch(resetFilters());
            setListingsStateDraft({ type: 'reset' });
        }
    }, [location.search]);

    const handleSingleSelectFilter = (filter: SingleSelectFilter, value: FilterValue) => {
        switch (filter) {
            case 'sort':
                if (isStringValue(value)) {
                    return { ...listingsStateDraft, sortBy: value };
                }

                break;
            case 'category':
                if (isStringValue(value)) {
                    if (!value) {
                        return { ...listingsStateDraft, filters: { ...listingsStateDraft.filters, category: [] } };
                    } else {
                        const currentFilters = listingsStateDraft.filters[filter];
                        const newFilters = currentFilters.includes(value) ? [] : [value];
                        return { ...listingsStateDraft, filters: { ...listingsStateDraft.filters, [filter]: newFilters } };
                    }
                }

                break;
            case 'price':
                if (isNumberTuple(value)) {
                    return { ...listingsStateDraft, filters: { ...listingsStateDraft.filters, price: value } };
                }

                break;
            default:
                return listingsStateDraft;
        }
    };

    const handleMultiSelectFilter = (filter: MultiSelectFilter, value: FilterValue) => {
        if (isStringValue(value)) {
            const currentFilters = listingsStateDraft.filters[filter];
            const newFilters = currentFilters.includes(value) ? currentFilters.filter((el) => el !== value) : [...currentFilters, value];
            return { ...listingsStateDraft, filters: { ...listingsStateDraft.filters, [filter]: newFilters } };
        }
        return listingsStateDraft;
    };

    const setListingsStateDraftAndNavigate = useCallback(
        (state: ListingsState, selectedCategory: string) => {
            setListingsStateDraft({ type: 'apply', payload: state });
            dispatch(replaceState(state));

            const params = state2QueryString(state, selectedCategory);

            // If the drawer is still open, we want to keep the hash in the url
            const url = drawerState !== 'closed' && window.location.hash ? `/listings${params}${window.location.hash}` : `/listings${params}`;

            navigate(url);
        },
        [drawerState],
    );

    const { data: listingsData, isLoading } = useListings(queryParams, 1, 'listing-filters');

    const totalItems = listingsData?.pages[0].meta.totalItems || 0;

    // Without delayedDrawerState, the drawer content is unmounted immediately and the exit animation of the SwipeableDrawer looks wonky
    const handleCloseDrawer = () => {
        if (drawerState === 'closed') {
            return;
        }

        setDelayedDrawerState(drawerState);
        dispatch(setDrawerState('closed'));

        setTimeout(() => {
            setDelayedDrawerState('closed');
        }, 1000);
    };

    const currentDrawerState = drawerState === 'closed' ? delayedDrawerState : drawerState;

    const applyListingDraft = useCallback(
        (closeDrawer = true) => {
            dispatch(updateListingsState(listingsStateDraft));

            const params = state2QueryString(listingsStateDraft, selectedCategory);
            const url = `/listings${params}`;

            if (closeDrawer) {
                handleCloseDrawer();
            }

            navigate(url, { replace: true });
        },
        [listingsStateDraft, selectedCategory],
    );

    const filterOptions = useFilterOptions(listingsStateDraft.filters);

    const updateFiltersAndNavigate = useCallback(
        (filter: MultiSelectFilter | SingleSelectFilter, value: FilterValue) => {
            let newState;
            if (isSingleSelectFilter(filter)) {
                newState = handleSingleSelectFilter(filter, value);
            } else if (isMultiSelectFilter(filter)) {
                newState = handleMultiSelectFilter(filter, value);
            }

            if (newState) {
                setListingsStateDraftAndNavigate(newState, selectedCategory);
            }
        },
        [listingsStateDraft, selectedCategory],
    );

    useDispatchListener('resetFilters', () => {
        setListingsStateDraft({ type: 'reset' });
    });

    useDispatchListener('replaceFilterAndNavigate', (event) => {
        const { type } = event.detail;

        if (type !== 'reset' && type !== 'keywords') {
            const newState = { ...listingsStateDraft, filters: { ...initialState.filters, [type]: event.detail.payload } };
            setListingsStateDraftAndNavigate(newState, selectedCategory);
        }
    });

    // Selects the filter and applies it immediately
    const onSelectSingleOption = (filter: SingleSelectFilter, value: string) => {
        updateFiltersAndNavigate(filter, value);
        handleCloseDrawer();
    };

    // Selects the filter but does not apply it yet
    const onSelectMultiOption = (filter: MultiSelectFilter, value: string) => {
        const currentFilters = listingsStateDraft.filters[filter];
        const newFilters = currentFilters.includes(value) ? currentFilters.filter((el) => el !== value) : [...currentFilters, value];

        setListingsStateDraft({ type: filter, payload: newFilters });
    };

    const onSelectPriceFilter = (value: number[]) => {
        setListingsStateDraft({ type: 'price', payload: value });
    };

    const handleApplyMultiSelectFilters = () => {
        if (totalItems === 0) {
            dispatch(resetFilters());
            setListingsStateDraft({ type: 'reset' });

            handleCloseDrawer();

            navigate('/listings', { replace: true });
        } else {
            applyListingDraft();
        }
    };

    const handleReset = () => {
        if (!currentDrawerState) {
            return;
        }

        if (currentDrawerState === 'allFilters') {
            setListingsStateDraft({ type: 'reset' });
            priceRangeRef.current?.resetPriceRange();
        }

        if (isMultiSelectFilter(currentDrawerState)) {
            setListingsStateDraft({ type: currentDrawerState, payload: [] });
        }

        if (isSingleSelectFilter(currentDrawerState)) {
            if (currentDrawerState === 'sort') {
                updateFiltersAndNavigate(currentDrawerState, 'createdAt');
            }

            if (currentDrawerState === 'category') {
                updateFiltersAndNavigate(currentDrawerState, '');
            }

            if (currentDrawerState === 'price') {
                updateFiltersAndNavigate(currentDrawerState, defaultPriceRange);
                priceRangeRef.current?.resetPriceRange();
            }

            handleCloseDrawer();
        }
    };

    const renderDrawerContent = () => {
        switch (currentDrawerState) {
            case 'allFilters':
                return (
                    <AllFilters
                        ref={priceRangeRef}
                        stateDraft={listingsStateDraft}
                        filterOptions={filterOptions}
                        totalItems={totalItems}
                        loading={isLoading}
                        onApply={applyListingDraft}
                        onUpdateStateDraft={setListingsStateDraft}
                    />
                );
            case 'price':
                return (
                    <PriceFilterOptions
                        ref={priceRangeRef}
                        selectedValue={listingsStateDraft.filters[currentDrawerState]}
                        onApplyFilters={handleApplyMultiSelectFilters}
                        totalItems={totalItems}
                        loading={isLoading}
                        onUpdate={(value) => onSelectPriceFilter(value)}
                    />
                );
            case 'sort':
            case 'category':
                const hasMultipleSelectedCategories = currentDrawerState === 'category' && listingsStateDraft.filters.category.length > 1;

                return (
                    <SingleSelectFilterOptions
                        options={filterOptions[currentDrawerState]}
                        selectedValue={currentDrawerState === 'sort' ? listingsStateDraft.sortBy : listingsStateDraft.filters.category[0]}
                        onSelectOption={(value) => onSelectSingleOption(currentDrawerState, value)}
                        {...(hasMultipleSelectedCategories && {
                            isSelectedFn(value) {
                                return listingsStateDraft.filters.category.includes(value);
                            },
                        })}
                    />
                );
            default:
                return isMultiSelectFilter(currentDrawerState) ? (
                    <MultiSelectFilterOptions
                        options={filterOptions[currentDrawerState]}
                        selectedValues={listingsStateDraft.filters[currentDrawerState]}
                        onApplyFilters={handleApplyMultiSelectFilters}
                        totalItems={totalItems}
                        loading={isLoading}
                        onSelectOption={(value) => onSelectMultiOption(currentDrawerState, value)}
                    />
                ) : null;
        }
    };

    const drawerHeaderText = t(currentDrawerState || '');
    const fillHeight = isMultiSelectFilter(currentDrawerState) && filterOptions[currentDrawerState].length >= 20;
    const isDesktop = useBreakpoint('sm');

    const ModalStyle = useMemo(() => {
        if (isDesktop) {
            return;
        }

        if (drawerState === 'allFilters') {
            return { maxHeight: '95%', height: '100%' };
        }

        return { maxHeight: '90%' };
    }, [isDesktop, drawerState]);

    const headerSuffix = location.pathname === '/listings' ? <ButtonBase onClick={handleReset}>{t('clear')}</ButtonBase> : null;

    return (
        <SwipeableDrawer
            open={drawerState !== 'closed'}
            onClose={handleCloseDrawer}
            fillHeight={fillHeight}
            headerText={drawerHeaderText}
            ModalStyle={ModalStyle}
            headerSuffix={headerSuffix}
        >
            {renderDrawerContent()}
        </SwipeableDrawer>
    );
};
