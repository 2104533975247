import { Help, VerifiedUser } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyledLinkColor } from '../../components/Styled/Styled.components';
import { ChatWithSupportButton } from '../../components/ChatWithUserButton/ChatWithSupportButton';
import { ExtendedListing } from '../../types/apiTypes';
import { IconSection } from '../../components/IconSection.tsx/IconSection';

interface HowRentingWorksSectionProps {
    listing?: ExtendedListing;
}

export const HowRentingWorksSection: React.FC<HowRentingWorksSectionProps> = ({ listing }) => {
    const { t } = useTranslation();

    return (
        <>
            <IconSection
                sx={{ py: 3 }}
                icon={<Help color="primary" />}
                title={
                    <Typography variant="body1" fontWeight="bold">
                        {t('howRentingWorksSectionTitle')}
                    </Typography>
                }
            >
                <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                    <Trans i18nKey="howRentingWorksSectionDescription" components={{ button: <StyledLinkColor to="/how-renting-works" /> }} />
                </Typography>
            </IconSection>

            <ChatWithSupportButton listingId={listing?.id} />
        </>
    );
};
