import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, IconButton, Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { IconTextField } from '../../../components/IconTextField/IconTextField';
import { ForgotPasswordSectionContainer, ForgotPasswordButton } from '../Login.components';
import { useTranslation } from 'react-i18next';

import { useLogin } from '../../../hooks/auth/useLogin';
import { ReactFCWithChildren } from '../../../types/types';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

const CustomDivider: ReactFCWithChildren<{ label: string }> = ({ label }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '10px',
            width: '100%',
        }}
    >
        <Divider style={{ width: '40%' }} />
        <Typography variant="subtitle1">{label}</Typography>
        <Divider style={{ width: '40%' }} />
    </Box>
);

export const EmailLoginView: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');

    const { mutate: login, isLoading } = useLogin();

    // TODO: use native form element and react-hook-form instead.
    const handleLogin = useCallback(async () => {
        login({ email: emailInput, password: passwordInput });
    }, [login, emailInput, passwordInput]);

    const handleForgotPasswordClick = () => {
        navigate('/reset-password/request');
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <Box
            component="form"
            sx={{ padding: '56px 20px' }}
            onSubmit={(ev: { preventDefault: () => void }) => {
                ev.preventDefault();
                handleLogin();
            }}
        >
            <div style={{ paddingBottom: '20px' }} />
            <IconTextField iconName="email" label="email" variant="outlined" TextFieldProps={{ autoComplete: 'username' }} onChange={setEmailInput} />

            <IconTextField
                iconName="key"
                label="password"
                variant="outlined"
                TextFieldProps={{ autoComplete: 'current-password' }}
                type={showPassword ? 'search' : 'password'}
                onChange={setPasswordInput}
                FieldInputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <ForgotPasswordSectionContainer>
                <ForgotPasswordButton onClick={handleForgotPasswordClick}>{t('forgotPassword')}</ForgotPasswordButton>
            </ForgotPasswordSectionContainer>

            <LoadingButton sx={{ marginTop: '20px' }} variant="contained" type="submit" fullWidth onClick={handleLogin} loading={isLoading}>
                {t('signIn')}
            </LoadingButton>

            <CustomDivider label={t('or')} />

            <LoadingButton fullWidth variant="contained" onClick={handleSignUpClick} sx={{ background: '#322f2f' }}>
                {t('createNewAccount')}
            </LoadingButton>
        </Box>
    );
};
