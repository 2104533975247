import React from 'react';
import { Alert, Box, FormControl, FormHelperText, List, ListItemButton, Typography } from '@mui/material';
import { useFormContext, Controller, ControllerRenderProps, RegisterOptions } from 'react-hook-form';
import { FormSelectItem, SelectItem } from '../FormRenderer.types';
import { ShowMore } from '../../ShowMore/ShowMore';

interface FormSelectProps {
    controlName: string;
    label: string;
    items: FormSelectItem[];
    registerOptions?: RegisterOptions;
    onSelect?: () => void;
    renderItem?: (item: SelectItem) => React.ReactNode;
}

export const FormSelect: React.FC<FormSelectProps> = ({ controlName, items, renderItem, onSelect, registerOptions }) => {
    const { control } = useFormContext();

    const renderOption = (item: SelectItem) => {
        if (renderItem) {
            return renderItem(item);
        }
        return <>{item.title}</>;
    };

    const handleSelect = (field: ControllerRenderProps, value: string) => {
        if (onSelect) {
            onSelect();
        }

        field.onChange(value);
    };

    return (
        <FormControl fullWidth>
            <Controller
                name={controlName}
                control={control}
                rules={{ ...registerOptions }}
                render={({ field }) => (
                    <List>
                        {items.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.type === 'item' ? (
                                    <>
                                        <ListItemButton
                                            disabled={item.disabled}
                                            selected={field.value === item.value}
                                            onClick={() => handleSelect(field, item.value)}
                                        >
                                            {renderOption(item)}
                                        </ListItemButton>

                                        {item.disabled && item.disabledReason && (
                                            <FormHelperText sx={{ px: 2, py: 1 }} error>
                                                {item.disabledReason}
                                            </FormHelperText>
                                        )}
                                    </>
                                ) : (
                                    <Box sx={{ width: '100%', px: 2, py: 1 }}>
                                        <Alert severity="info">
                                            {item.maxHeight ? (
                                                <ShowMore value={item.content} maxHeight={item.maxHeight} rows={item.rows} />
                                            ) : (
                                                <Typography variant="caption">{item.content}</Typography>
                                            )}
                                        </Alert>
                                    </Box>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            />
        </FormControl>
    );
};
