import { useEffect } from 'react';
import { Location } from 'react-router-dom';
import useLocationParams from '../../hooks/useLocationParams';
import { selectIsOnboarding, setIsOnboarding } from '../../store/appStateReducer';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { useOnboardingState } from '../../user/hooks/useOnboardingState';
import { useCurrentUser } from '../../user/hooks/useUser';
import { IdentityVerificationState } from '../../views/IdentityVerification/IdentityVerification';
import { UserOnboarding } from '../NewUserOnboarding/UserOnboarding';
import { Modal } from '../Modal/Modal';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { Capacitor } from '@capacitor/core';

export const OnboardingModalMaybe: React.FC<{ location: Location }> = ({ location }) => {
    const includedPaths = ['/article', '/add-listing', '/profile/', '/messages', '/settings', '/wardrobe'];

    const { data: user } = useCurrentUser();
    const { identification } = useLocationParams();

    const onboardingState = useOnboardingState();
    const isOnboarding = useAppSelector(selectIsOnboarding);

    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();

    const finishOnboarding = () => {
        dispatch(setIsOnboarding(false));

        if (location.pathname !== '/') {
            navigate('/');
        }
    };

    // Only show onboarding modal on certain paths
    const isOnIncludedPath = location.pathname === '/' || includedPaths.some((path) => location.pathname.includes(path));

    useEffect(() => {
        if (!user) {
            return;
        }

        const shouldStartOnboarding = !isOnboarding && onboardingState !== 'complete' && isOnIncludedPath;

        if (shouldStartOnboarding) {
            // Native platforms have dedicated full-screen onboarding flow
            if (Capacitor.isNativePlatform()) {
                navigate('/onboarding', { replace: true });

                return;
            }
            // Returned from Signicat authentication
            const startImmediately = onboardingState === 'verification' && identification && user.profile.publicData.identityVerified;

            if (startImmediately) {
                dispatch(setIsOnboarding(true));
            } else {
                setTimeout(() => {
                    if (!!window.requestIdleCallback) {
                        requestIdleCallback(
                            () => {
                                dispatch(setIsOnboarding(true));
                            },
                            { timeout: 5 * 1000 },
                        );
                    } else {
                        dispatch(setIsOnboarding(true));
                    }
                }, 1500);
            }
        }
    }, [onboardingState, location.pathname, user]);

    if (Capacitor.isNativePlatform()) {
        return null;
    }

    return (
        <>
            {user && isOnIncludedPath && (
                <Modal open={isOnboarding} hideCloseBtn variant="lg" modalStyle={{ width: '100%', margin: '16px', maxHeight: '800px' }}>
                    <UserOnboarding
                        onboardingState={onboardingState}
                        onEnd={finishOnboarding}
                        user={user}
                        identification={identification as IdentityVerificationState}
                    />
                </Modal>
            )}
        </>
    );
};
