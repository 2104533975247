import React, { useEffect, useRef } from 'react';
import { StyledButton } from './LoadMoreButton.components';
import { useIsElementInView } from '../../../hooks/useIsElementInView';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { SxProps } from '@mui/material';
import { ReactFCWithChildren } from '../../../types/types';

export interface LoadMoreButtonProps {
    fetchNextPage: () => Promise<unknown>;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    noItems: boolean;
    isFetchingText?: string;
    hasNextPageText?: string;
    noMoreItemsComponent?: React.ReactNode;
    noItemsText?: string;
    noMoreItemsText?: string;
    sx?: SxProps;
}

export const LoadMoreButton: ReactFCWithChildren<LoadMoreButtonProps> = ({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    noItems,
    isFetchingText,
    hasNextPageText,
    noItemsText,
    noMoreItemsText,
    noMoreItemsComponent,
    sx,
}) => {
    const { t } = useTranslation();
    const loadMoreButtonRef = useRef(null);

    useIsElementInView({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: !!hasNextPage,
    });

    const { ref, inView } = useInView({ skip: !hasNextPage });

    const getButtonText = () => {
        if (isFetchingNextPage) {
            return isFetchingText !== undefined ? isFetchingText : t('loading');
        }
        if (hasNextPage) {
            return hasNextPageText !== undefined ? hasNextPageText : t('loadNextPage');
        }
        if (noItems) {
            return noItemsText !== undefined ? noItemsText : t('noItems');
        }
        return noMoreItemsText || t('noMoreItems');
    };

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, fetchNextPage]);

    if (noMoreItemsComponent && !noItems && !hasNextPage) {
        return <>{noMoreItemsComponent}</>;
    }

    return (
        <StyledButton ref={ref} onClick={() => fetchNextPage()} loading={isFetchingNextPage} disabled={!hasNextPage || isFetchingNextPage} sx={sx}>
            {getButtonText()}
        </StyledButton>
    );
};
