import { Divider, ListItem, Skeleton } from '@mui/material';
import React from 'react';
import { StyledList } from '../../components/StyledList/StyledList.components';

const MESSAGES_AMOUNT = 5;

const MessageItem: React.FC = () => {
    return (
        <>
            <ListItem style={{ display: 'flex', padding: '8px 16px', height: '70px' }}>
                <Skeleton variant="circular" width={40} height={40} />
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                    <Skeleton variant="text" width={80}></Skeleton>
                    <Skeleton variant="text" width={200} height={20}></Skeleton>
                </div>
            </ListItem>
            <Divider variant="fullWidth" component="li" />
        </>
    );
};

export const MessagesSkeleton: React.FC = () => {
    return (
        <StyledList>
            {Array.from(Array(MESSAGES_AMOUNT)).map((_el, i) => (
                <MessageItem key={i} />
            ))}
        </StyledList>
    );
};
