import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { isEqual } from 'date-fns';
import { isEmpty } from 'lodash';
import { defaultPriceRange } from '../../views/Listings/constants';
import { ListingsState } from '../../views/Listings/types';
import { HomePageAction } from './types';

const genericEvent = async (eventName: string) => {
    await FirebaseAnalytics.logEvent({ name: eventName });
};

const homePageAction = async (action: HomePageAction, params: Record<string, unknown>) => {
    await FirebaseAnalytics.logEvent({ name: 'home_page_action', params: { action, ...params } });
};

const listingClick = async (id: string, title: string, source: 'home-page' | 'listings-page' | 'profile-page') => {
    await FirebaseAnalytics.logEvent({
        name: 'listing_click',
        params: {
            action: 'Clicked listing',
            listing_id: id,
            listing_title: title,
        },
    });
};

const filtersReset = async () => {
    await FirebaseAnalytics.logEvent({ name: 'filters_reset', params: { action: 'Filters reset' } });
};

const filtersChanged = async (actionType: keyof ListingsState['filters'], filters: ListingsState['filters']) => {
    const keys = Object.keys(filters) as (keyof typeof filters)[];
    const activeFilters = keys.reduce((acc, curr) => {
        if (!isEmpty(filters[curr])) {
            const filterValue = filters[curr] as typeof filters[typeof curr];
            if (typeof filterValue === 'string') {
                acc[`filter_${curr}`] = filterValue;
            } else if (curr === 'price') {
                // @ts-ignore
                if (isEqual(filterValue, defaultPriceRange)) {
                    return acc;
                }

                const [min, max] = filterValue;
                acc[`filter_${curr}_min`] = min;
                acc[`filter_${curr}_max`] = max;
            } else {
                acc[`filter_${curr}`] = filterValue.join(',');
            }
        }

        return acc;
    }, {} as Record<string, unknown>);

    await FirebaseAnalytics.logEvent({
        name: 'filters_changed',
        params: { action: 'Modified filters', ...activeFilters, type: actionType },
    });
};

const onboardingStepComplete = async (step: string) => {
    await FirebaseAnalytics.logEvent({ name: 'onboarding_step', params: { step } });
};

const postalCodeCheck = async (postalCode: string, available: boolean) => {
    await FirebaseAnalytics.logEvent({ name: 'wolt_availability_check', params: { postalCode } });
};

export const nativeAnalytics = {
    genericEvent,
    homePageAction,
    listingClick,
    filtersChanged,
    filtersReset,
    postalCodeCheck,
    onboardingStepComplete,
};
