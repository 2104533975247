import { makeQueryString } from '../helpers/urlHelpers';
import { useSafeNavigate } from './useSafeNavigate';

const useSafeNavigateWithParams = (): ((url: string, params: Record<string, string | string[] | null>) => void) => {
    const navigate = useSafeNavigate();

    return (url: string, params: Record<string, string | string[] | null>) => {
        const queryString = makeQueryString(params);
        const path = queryString ? `${url}?${queryString}` : url;
        navigate(path);
    };
};

export default useSafeNavigateWithParams;
