import React from 'react';
import { User } from '../../types/apiTypes';
import { ShowMore } from '../ShowMore/ShowMore';
import { ProfileBioContainer } from './ProfileSection.components';

export interface ProfileRowBioProps {
    show: boolean;
    userData: User;
}

export const ProfileRowBio: React.FC<ProfileRowBioProps> = ({ show, userData }) => (
    <>
        {show && userData.profile.bio && (
            <ProfileBioContainer>
                <ShowMore value={userData.profile.bio} />
            </ProfileBioContainer>
        )}
    </>
);
