import React, { CSSProperties, MutableRefObject, useRef } from 'react';
import { Box } from '@mui/material';
import { useScroll } from 'framer-motion';
import _ from 'lodash';
import { Listing, ExtendedListing, ListingPreview } from '../../types/apiTypes';
import { ReactFCWithChildren } from '../../types/types';
import { ListingImageSection } from './ListingImageSection';
import { ListingPublicDataSection } from './ListingPublicDataSection';
import { ScrollRestore } from '../../components/ScrollRestore/ScrollRestore';

interface ListingInfoProps {
    listing: Listing | ExtendedListing | ListingPreview;
    showProfile?: boolean;
    setShowProfile?: (val: boolean) => void;
    isOwnListing: boolean;
}

export const ListingDetails: ReactFCWithChildren<ListingInfoProps> = ({ showProfile, setShowProfile, listing, isOwnListing, children }) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const { scrollY } = useScroll({ container: scrollContainerRef });

    return (
        <div ref={scrollContainerRef} style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <ScrollRestore containerRef={scrollContainerRef as MutableRefObject<HTMLDivElement>} />

            <ListingImageSection listing={listing} imageHeight={400} scrollY={scrollY} />

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
                <ListingPublicDataSection showProfile={showProfile} setShowProfile={setShowProfile} listing={listing} isOwnListing={isOwnListing} />
                {children}
            </Box>
        </div>
    );
};
