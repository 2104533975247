import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';

interface CarouselProps {
    items: { key: string; content: JSX.Element }[];
    onClick?: (index: number) => void;
    carouselStyles?: React.CSSProperties;
    itemStyle?: React.CSSProperties;
    insetPx?: number;
}

/**
 * Carousel component for JSX.elements, supports gestures and dragging
 */
export const Carousel: ReactFCWithChildren<CarouselProps> = ({ items, onClick, carouselStyles, itemStyle, insetPx }): JSX.Element => {
    const [width, setWidth] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!carouselRef.current) {
            return;
        }

        const offset = carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
        setWidth(offset);
    }, []);

    return (
        <motion.div ref={carouselRef} style={{ cursor: 'grab', overflow: 'hidden', ...carouselStyles }}>
            <motion.div drag="x" dragConstraints={{ right: 0, left: -width }} style={{ display: 'flex' }} dragTransition={{ power: 0.4 }}>
                {items.map((item, i) => (
                    <motion.div
                        key={item.key}
                        onClick={() => (onClick ? onClick(i) : null)}
                        style={{ ...itemStyle, ...(insetPx && i === 0 && { marginLeft: insetPx }) }}
                    >
                        {item.content}
                    </motion.div>
                ))}
            </motion.div>
        </motion.div>
    );
};
