import React, { CSSProperties, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { ListingQueryResult } from '../../../queries/useListings';
import { ListingItem, ListingItemDesktop } from '../ListingItem/ListingItem';
import { LoadMoreButtonContainer, NoMoreItemsButton } from '../Listings.components';
import { useAppSelector } from '../../../store/hooks';
import { selectCompactMode } from '../../../store/settingsReducer';
import { Listing } from '../../../types/apiTypes';
import { useQueryClient } from '@tanstack/react-query';

import { LoadMoreButton } from '../LoadMoreButton/LoadMoreButton';
import { useToggleFavorite } from '../../../messages/hooks/useToggleFavorite';
import { ReactFCWithChildren } from '../../../types/types';
import { useFavorites } from '../../../queries/useFavorites';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useListingItemStyle } from '../ListingItem/useListingItemStyle';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ScrollRestore } from '../../../components/ScrollRestore/ScrollRestore';
import { analytics } from '../../../analytics/events/helpers';
import { Box } from '@mui/material';
import useLocationParams from '../../../hooks/useLocationParams';
import { PullToRefresh } from '../../../components/PullToRefresh/PullToRefresh';
import { Capacitor } from '@capacitor/core';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { filterBarHeightPX, filterResultsCountHeightPX, headerHeightPX } from '../../../constants';
import { useUpdateScrollStatus } from '../../../hooks/useScrollStatus';
import { compactModeCardHeight, defaultModeCardHeight } from '../constants';
import { usePrevious } from '../../../hooks/usePrevious';

const loadingButtonHeight = 120;

interface ListingCardsProps {
    listingData: ListingQueryResult | undefined;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    noItems: boolean;
    paddingStart: number;
    paddingEnd: number;
    onRefresh?: () => Promise<void>;
    fetchNextPage: () => Promise<unknown>;
}

const estimateSizeFn = (index: number, itemLength: number, rowHeight: number) => {
    if (index !== itemLength) {
        return rowHeight;
    }

    return loadingButtonHeight;
};

interface VirtualContainerProps {
    onRefresh: (() => Promise<void>) | undefined;
    containerStyle: CSSProperties;
    contentStyle: CSSProperties;
    children: React.ReactNode;
}

// Wrapping children in PullToRefresh on mobile browsers causes duplicate pull to refresh elements to be rendered (or our own implementation + browser native implementation)
const VirtualContainer = React.forwardRef<HTMLDivElement, VirtualContainerProps>(({ children, onRefresh, containerStyle, contentStyle }, ref) => {
    if (Capacitor.isNativePlatform()) {
        return (
            <PullToRefresh
                ref={ref}
                onRefresh={onRefresh}
                containerStyle={containerStyle}
                contentStyle={contentStyle}
                offsetY={filterBarHeightPX + filterResultsCountHeightPX + headerHeightPX}
            >
                {children}
            </PullToRefresh>
        );
    } else {
        return <div style={containerStyle}>{children}</div>;
    }
});

export const ListingCards: ReactFCWithChildren<ListingCardsProps> = ({
    listingData,
    hasNextPage,
    noItems,
    paddingStart,
    paddingEnd,
    fetchNextPage,
    isFetchingNextPage,
    onRefresh,
}) => {
    const navigate = useSafeNavigate();
    const compactMode = useAppSelector(selectCompactMode);
    const previousCompactMode = usePrevious(compactMode);
    const queryClient = useQueryClient();
    const favorites = useFavorites();
    const isDesktop = useBreakpoint('sm');
    const parentRef = useRef<HTMLDivElement>(null);
    const { selectedCategory } = useLocationParams();

    const { mutate: toggleFavorite } = useToggleFavorite();

    const handleClickListing = useCallback(
        (listing: Listing) => {
            const queryKey = ['listing', { listingId: listing.id }];
            analytics.listingClick(listing.id, listing.title, 'listings-page');

            const cachedData = queryClient.getQueryData(queryKey);
            if (!cachedData) {
                queryClient.setQueryData(queryKey, () => listing);
                queryClient.invalidateQueries(queryKey);
            }

            navigate(`/listing/${listing.id}`);
        },
        [navigate, queryClient],
    );

    const itemStyle = useListingItemStyle(compactMode);
    const allListings = listingData?.pages.map((page) => page.data).flat() || [];

    const itemsPerRow = useMemo(() => {
        if (isDesktop) {
            return 3;
        }

        return compactMode ? 2 : 1;
    }, [compactMode, isDesktop]);

    const virtualizer = useVirtualizer({
        count: allListings.length + 1,
        lanes: itemsPerRow,
        paddingEnd,
        paddingStart,
        overscan: 6,
        getScrollElement: () => parentRef.current,
        estimateSize: (index) => estimateSizeFn(index, allListings.length, (itemStyle.height || itemStyle.maxHeight) as number),
    });

    useEffect(() => {
        // No previous value, probably the user navigated back from the listing page -> do not scroll
        if (previousCompactMode === undefined) {
            return;
        }

        virtualizer.measure();
        const ratio = (defaultModeCardHeight / compactModeCardHeight) * 2;
        const offset = compactMode ? virtualizer.scrollOffset! / ratio : virtualizer.scrollOffset! * ratio;

        virtualizer.scrollToOffset(offset);
    }, [compactMode]);

    useUpdateScrollStatus(parentRef);

    const totalItemsOdd = allListings.length % itemsPerRow !== 0;
    // When we have an odd amount of items, the loading button will be offset to the next row. Take this into account when calculating the total size
    const totalSize = virtualizer.getTotalSize() + (totalItemsOdd ? loadingButtonHeight + paddingEnd : 0);

    return (
        <>
            <ScrollRestore containerRef={parentRef as MutableRefObject<HTMLDivElement>} />
            <div
                ref={parentRef}
                style={{
                    height: `100%`,
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <VirtualContainer
                    ref={parentRef}
                    onRefresh={onRefresh}
                    contentStyle={{ overflow: 'hidden' }}
                    containerStyle={{
                        height: `${totalSize}px`,
                        width: '100%',
                        minHeight: '100%',
                        position: 'relative',
                    }}
                >
                    {virtualizer.getVirtualItems().map((virtualItem) => {
                        const isLoaderRow = virtualItem.index > allListings.length - 1;
                        const listing = allListings[virtualItem.index];

                        const getRowOffset = () => {
                            if (!isLoaderRow || itemsPerRow === 1) {
                                return 0;
                            }

                            const unEvenOffset = virtualItem.index % itemsPerRow;
                            const offsetBy = unEvenOffset > 0 ? itemStyle.height || itemStyle.maxHeight : 0;
                            return offsetBy || 0;
                        };

                        const offset = getRowOffset();
                        const itemWidth = isLoaderRow ? '100%' : `${100 / itemsPerRow}%`;
                        const itemOffset = `${((virtualItem.index % itemsPerRow) * 100) / itemsPerRow}%`;

                        return (
                            <div
                                key={virtualItem.key}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: isLoaderRow ? 0 : itemOffset,
                                    width: itemWidth,
                                    height: `${virtualItem.size}px`,
                                    transform: `translateY(${virtualItem.start + offset}px)`,
                                }}
                            >
                                {isLoaderRow ? (
                                    <LoadMoreButtonContainer sx={{ ...(noItems && { height: '100%' }) }}>
                                        <LoadMoreButton
                                            fetchNextPage={fetchNextPage}
                                            hasNextPage={hasNextPage}
                                            isFetchingNextPage={isFetchingNextPage}
                                            noItems={noItems}
                                            noMoreItemsComponent={
                                                selectedCategory ? <NoMoreItemsButton sx={{ ...(isDesktop && { mt: 3, mb: 5 }) }} /> : null
                                            }
                                        />
                                    </LoadMoreButtonContainer>
                                ) : (
                                    <ListingItem
                                        key={listing.id}
                                        listing={listing}
                                        compactMode={compactMode}
                                        isDesktop={isDesktop}
                                        onClick={() => handleClickListing(listing)}
                                        isFavorited={favorites.includes(listing.id)}
                                        toggleFavorite={toggleFavorite}
                                    />
                                )}
                            </div>
                        );
                    })}
                </VirtualContainer>
            </div>
        </>
    );
};

type ListingsDesktopProps = Omit<ListingCardsProps, 'paddingStart' | 'paddingEnd'>;

export const ListingsDesktop: React.FC<ListingsDesktopProps> = ({ listingData, fetchNextPage, hasNextPage, isFetchingNextPage, noItems }) => {
    const allListings = listingData?.pages.map((page) => page.data).flat() || [];
    const favorites = useFavorites();
    const queryClient = useQueryClient();
    const navigate = useSafeNavigate();
    const { selectedCategory } = useLocationParams();

    const handleClickListing = useCallback(
        (listing: Listing) => {
            const queryKey = ['listing', { listingId: listing.id }];
            analytics.listingClick(listing.id, listing.title, 'listings-page');

            const cachedData = queryClient.getQueryData(queryKey);
            if (!cachedData) {
                queryClient.setQueryData(queryKey, () => listing);
                queryClient.invalidateQueries(queryKey);
            }

            navigate(`/listing/${listing.id}`);
        },
        [navigate, queryClient],
    );

    const { mutate: toggleFavorite } = useToggleFavorite();

    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', rowGap: '20px' }}>
                {allListings.map((listing) => (
                    <div style={{ width: `calc(100% / 4)`, aspectRatio: '1/1', padding: '16px' }} key={listing.id}>
                        <ListingItemDesktop
                            listing={listing}
                            onClick={() => handleClickListing(listing)}
                            isFavorited={favorites.includes(listing.id)}
                            toggleFavorite={toggleFavorite}
                        />
                    </div>
                ))}
            </Box>
            <LoadMoreButtonContainer sx={{ ...(noItems && { height: '100%' }) }}>
                <LoadMoreButton
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    noItems={noItems}
                    noMoreItemsComponent={selectedCategory ? <NoMoreItemsButton sx={{ mt: 3, mb: 5 }} /> : null}
                />
            </LoadMoreButtonContainer>
        </>
    );
};
