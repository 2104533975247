import React, { CSSProperties } from 'react';
import { Skeleton, styled } from '@mui/material';
import { ReactFCWithChildren } from '../../types/types';

const StyledCategorySkeleton = styled(Skeleton)(({ theme }) => ({
    background: theme.palette.background.default,
}));

interface CategorySkeletonProps {
    containerStyle: CSSProperties;
    mainCardStyle: CSSProperties;
    variant: 'promoted' | 'category';
}

export const CategorySkeleton: ReactFCWithChildren<CategorySkeletonProps> = ({ containerStyle, mainCardStyle, variant }) => (
    <div style={{ position: 'relative', ...containerStyle }}>
        {variant === 'promoted' ? (
            <>
                <StyledCategorySkeleton variant="rounded" style={{ width: '100%', height: '100%', ...mainCardStyle }} />
                <Skeleton
                    variant="rounded"
                    style={{
                        width: 'calc(100% - 20px)',
                        height: 'calc(100% - 20px)',
                        position: 'absolute',
                        margin: '10px 0 0 10px',
                        top: 0,
                        borderRadius: '10px',
                    }}
                />
            </>
        ) : (
            <>
                <Skeleton
                    animation="wave"
                    width={120}
                    height={10}
                    variant="text"
                    style={{ position: 'absolute', zIndex: 3, top: '10%', left: '3%' }}
                />
                <StyledCategorySkeleton variant="rounded" style={{ width: '100%', height: '100%', ...mainCardStyle }} />
                <Skeleton animation="wave" width={20} height={20} variant="circular" style={{ position: 'absolute', top: '10%', right: '5%' }} />
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        width: '100%',
                        zIndex: 3,
                        bottom: '10%',
                        alignContent: 'flex-start',
                        gap: '10px',
                        paddingLeft: '10px',
                    }}
                >
                    <Skeleton width={70} height={70} variant="rounded" style={{ borderRadius: '10px' }} />
                    <Skeleton width={70} height={70} variant="rounded" style={{ borderRadius: '10px' }} />
                    <Skeleton width={70} height={70} variant="rounded" style={{ borderRadius: '10px' }} />
                </div>
            </>
        )}
    </div>
);
