import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from '@mui/material';

interface ClickableTypographyProps extends TypographyProps {
    text: string;
}

const clientOrigin = process.env.REACT_APP_CLIENT_ORIGIN;

/**
 * Otherwise normal typography component, but renders http(s) links as clickable links
 */
export const ClickableTypography: React.FC<ClickableTypographyProps> = ({ text, ...typographyProps }) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const parts = text.split(urlRegex);

    return (
        <Typography {...typographyProps}>
            {parts.map((part, index) => {
                if (part.match(urlRegex)) {
                    if (clientOrigin && part.startsWith(clientOrigin)) {
                        const slug = part.replace(clientOrigin, '') || '/';

                        return (
                            <Link key={index} href={slug} sx={{ color: 'inherit', textDecorationColor: 'inherit' }}>
                                {part}
                            </Link>
                        );
                    }
                    // If the part is a URL, render it as a clickable link
                    return (
                        <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit ' }}>
                            {part}
                        </a>
                    );
                }

                // Otherwise, render regular text
                return <span key={index}>{part}</span>;
            })}
        </Typography>
    );
};

export default ClickableTypography;
