import { AxiosResponse } from 'axios';
import { isNil, omitBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { listingsApiClient } from '../../services/sharetribe/apiClients';
import { MutationResult, Image } from '../../types/apiTypes';
import { ListingData } from '../Listings/types';
import { UnifiedImage } from '../../components/ImageUploader/ImageUploader.types';
import { is } from 'date-fns/locale';
import { toast } from 'react-toastify';

interface AddListingParams {
    listingData: ListingData;
    images: UnifiedImage[];
    id?: string;
    errorMsg: string;
    uploadImagesFn: (images: UnifiedImage[]) => Promise<Image[]>;
}

const addListingFn = async (params: AddListingParams): Promise<any> => {
    const url = `/create/`;
    const { listingData, images, id } = params;

    let newImages: Image[] = [];

    const uploadedImages = images.filter((img) => img.uploaded).map((el) => ({ uuid: el.id, url: el.url }));
    const unUploadedImages = images.filter((img) => !img.uploaded);

    if (unUploadedImages.length) {
        try {
            newImages = await params.uploadImagesFn(unUploadedImages);
        } catch (err) {
            toast.error(params.errorMsg);
        }
    }

    const imageIds = [...uploadedImages, ...newImages].map((img) => img.uuid);

    if (!imageIds.length) {
        throw new Error('No images');
    }
    const { data } = await listingsApiClient.post<AxiosResponse>(url, { ...omitBy(listingData, isNil), images: imageIds, ...(id && { id }) });

    return data.data;
};

export const useAddListing = (onSuccess: () => void): MutationResult<any> => {
    const queryClient = useQueryClient();
    const queriesToInvalidate = ['listings', 'listing', 'listing-own', 'categories'];

    return useMutation(addListingFn, {
        onSuccess: () => {
            queriesToInvalidate.forEach((queryKey) => queryClient.invalidateQueries([queryKey]));
            onSuccess();
        },
    });
};
