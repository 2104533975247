import { Message, Tune } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';
import React from 'react';
import { useConversationsOverview } from '../../../../messages/hooks/useConversationsOverview';
import { selectFiltersChanged } from '../../../../store/listingsReducer';
import { selectToken } from '../../../../store/userReducer';
import { useTranslation } from 'react-i18next';
import { SignupLink } from '../../../SignUpLink/SignUpLink';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { MessageSvgIcon } from '../../../MessageIconButton/MessageSvgIcon';
import { StyledSvgIcon } from '../../../Styled/Styled.components';
import { useAppSelector } from '../../../../store/hooks';

interface TopbarSuffixProps {
    showFilterIcon: boolean;
    showMessageIcon: boolean;
    onClickFilter: () => void;
    onClickMessage: () => void;
}

const MessageIcon: React.FC = () => {
    const { data } = useConversationsOverview();
    const msgCount = data?.unreadMessages.length ?? undefined;

    return (
        <Badge badgeContent={msgCount} color="error">
            <MessageSvgIcon />
        </Badge>
    );
};
export const TopbarSuffix: React.FC<TopbarSuffixProps> = ({ showFilterIcon, showMessageIcon, onClickFilter, onClickMessage }) => {
    const { t } = useTranslation();
    const isDesktop = useBreakpoint('sm');
    const userToken = useAppSelector(selectToken);
    const filtersChanged = useAppSelector(selectFiltersChanged);

    if (!userToken && showMessageIcon) {
        // No space to show login CTA on mobile
        return isDesktop ? (
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <SignupLink label={t('login')} variant="text" />
                <SignupLink label={t('signUp')} />
            </Box>
        ) : (
            <SignupLink label={t('signUp')} sx={{ borderRadius: '20px', ml: 1 }} />
        );
    }

    return (
        <IconButton
            sx={{ flexShrink: 0, ...(showFilterIcon && filtersChanged && { backgroundColor: (t) => `${t.palette.primary.main} !important` }) }}
            onClick={() => {
                if (showFilterIcon) {
                    onClickFilter();
                }
                if (showMessageIcon) {
                    onClickMessage();
                }
            }}
        >
            {showFilterIcon ? (
                <StyledSvgIcon sx={{ '&:hover': { cursor: 'pointer' }, ...(filtersChanged && { color: 'common.white' }) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                        <path d="M476-164v-200h32v84h288v32H508v84h-32Zm-312-84v-32h200v32H164Zm144-132v-84H164v-32h144v-84h32v200h-32Zm144-84v-32h344v32H452Zm144-132v-200h32v84h168v32H628v84h-32Zm-432-84v-32h344v32H164Z" />
                    </svg>
                </StyledSvgIcon>
            ) : (
                <>{showMessageIcon && <MessageIcon />}</>
            )}
        </IconButton>
    );
};
