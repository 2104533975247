import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { SwipeableDrawer } from '../../components/SwipeableDrawer/SwipeableDrawer';
import { useTranslation } from 'react-i18next';

const bannedBrands = [
    'H&M',
    'Zara',
    'Weekday',
    'Monki',
    'ASOS',
    'Ellos',
    '& Other Stories',
    'Gina Tricot',
    'Lindex',
    'Massimo Dutti',
    'Cubus',
    'COS',
    'Vero Moda',
    'Only',
    'Bik Bok',
    'ARKET',
    'Pull & Bear',
    'Bershka',
    'VILA',
    'Stradivarius',
];

export const BannedBrandsDrawer: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <SwipeableDrawer open={open} onClose={onClose} headerText={t('bannedBrands')}>
            <Box sx={{ padding: 2 }}>
                <Typography variant="body1">{t('bannedBrandsBodyText')}</Typography>
            </Box>

            <List>
                {bannedBrands.map((el) => (
                    <ListItem key={el}>{el}</ListItem>
                ))}
            </List>
        </SwipeableDrawer>
    );
};
