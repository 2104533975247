import { StripePlugin, GooglePayEventsEnum, ApplePayEventsEnum, PaymentSheetEventsEnum } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import { PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import { merchantIdentifier } from '../../../constants';
import { measureDuration, sendToSentry } from '../../../helpers/commonHelpers';
import { Money } from '../../../transactions/types';
import { ERROR_GOOGLE_PAY, ERROR_APPLE_PAY, ERROR_CARD_PAYMENT } from './usePaymentFlow';
import { PaymentIntent } from './types';

export const googlePayFlow = async (stripe: StripePlugin, paymentIntent: PaymentIntent, productLabel: string, payinTotal: Money) => {
    const { paymentIntentClientSecret } = paymentIntent;
    const { amount, currency } = payinTotal;

    await stripe.createGooglePay({
        paymentIntentClientSecret,
        paymentSummaryItems: [
            {
                label: productLabel,
                amount,
            },
            {
                label: 'Robes Rental Oy',
                amount,
            },
        ],
        merchantIdentifier: 'robes.rental',
        countryCode: 'FI',
        currency,
    });

    const { paymentResult } = await stripe.presentGooglePay().catch(() => {
        throw new Error(ERROR_GOOGLE_PAY);
    });

    if (paymentResult !== GooglePayEventsEnum.Completed) {
        throw new Error(ERROR_GOOGLE_PAY);
    }

    return paymentIntent;
};

/**
 * Capacitor Stripe Apple Pay implementation does not work in web environments. Implement Apple Pay for web using
 * Stripe Payment Request button instead.
 */
export const applePayFlow = async (
    capacitorStripe: StripePlugin,
    stripe: Stripe | null,
    paymentIntent: PaymentIntent,
    productLabel: string,
    payinTotal: Money,
    ev?: PaymentRequestPaymentMethodEvent,
) => {
    const { paymentIntentClientSecret } = paymentIntent;
    const { amount, currency } = payinTotal;

    if (Capacitor.isNativePlatform()) {
        await capacitorStripe.createApplePay({
            paymentIntentClientSecret,
            paymentSummaryItems: [
                {
                    label: productLabel,
                    amount: amount / 100,
                },
                {
                    label: 'Robes Rental Oy',
                    // unsure why, but Apple Pay on native iOS accepts amount as a "full" unit of currency instead of cents.
                    amount: amount / 100,
                },
            ],
            merchantIdentifier,
            countryCode: 'FI',
            currency,
        });

        const { start, end } = measureDuration();

        const handleReportPaymentError = (err: Error, paymentResult: string) => {
            const durationMS = end();
            sendToSentry(err, { durationMS, paymentResult });
        };

        start();

        const { paymentResult } = await capacitorStripe.presentApplePay().catch((err) => {
            const error = err instanceof Error ? err : new Error('Unknown Payment Error');
            handleReportPaymentError(error, paymentResult);
            throw error;
        });

        if (paymentResult !== ApplePayEventsEnum.Completed) {
            const err = new Error(ERROR_APPLE_PAY);
            handleReportPaymentError(err, paymentResult);
            throw err;
        }

        return paymentIntent;
    }
    if (stripe && ev) {
        if (!stripe) {
            throw new Error('Stripe not loaded');
        }

        const { paymentIntent: paymentIntentResult, error: confirmError } = await stripe.confirmCardPayment(
            paymentIntentClientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false },
        );

        if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');

            return paymentIntent;
        }
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.

        if (paymentIntentResult.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret);
            if (error) {
                // The payment failed -- ask your customer for a new payment method.
                throw new Error('confirmCardPayment failed');
            } else {
                return paymentIntent;
            }
        } else {
            return paymentIntent;
        }
    } else {
        throw new Error('Payment failed - no applicable flow');
    }
};

export const cardPaymentFlow = async (stripe: StripePlugin, paymentIntent: PaymentIntent) => {
    const { customer, paymentIntentClientSecret, ephemeralKey } = paymentIntent;

    await stripe.createPaymentSheet({
        paymentIntentClientSecret,
        customerId: customer,
        customerEphemeralKeySecret: ephemeralKey,
        merchantDisplayName: 'Robes',
    });

    const { start, end } = measureDuration();

    const handleReportPaymentError = (err: Error, paymentResult: string) => {
        const durationMS = end();
        sendToSentry(err, { durationMS, paymentResult });
    };

    start();

    const { paymentResult } = await stripe.presentPaymentSheet();

    if (paymentResult !== PaymentSheetEventsEnum.Completed) {
        const err = new Error(ERROR_CARD_PAYMENT);
        handleReportPaymentError(err, paymentResult);
        throw err;
    }

    return paymentIntent;
};
