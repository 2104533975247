import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@mui/material/styles/styled';

export const Image = styled('img')(() => ({
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 200ms',
}));

export const AddButton = styled(LoadingButton)(() => ({
    minWidth: 'unset',
    height: '100%',
    width: '100%',
    padding: 0,
}));

export const AddButtonIcon = styled(AddIcon)(() => ({
    height: '100%',
    width: '100%',
    maxWidth: '4rem',
    paddingBottom: 0,
    color: 'white',
}));
