import { Variants } from 'framer-motion';
import { omit } from 'lodash';
import { ExtendedListing } from '../../types/apiTypes';
import { ListingFormData, UpdateListingAction } from './AddListing.types';
import { Nil } from '../../types/types';

export const initialListingData: ListingFormData = {
    images: [],
    brand: '',
    category: '',
    size: '',
    color: '',
    bodytype: '',
    price: {
        dailyPrice: '',
    },
};

export const listingStateReducer = (listing: ListingFormData, action: UpdateListingAction) => {
    if (action.type === 'reset') {
        return initialListingData;
    }

    return { ...listing, [action.type]: action.payload };
};

export const formatListingPrice = (val: number | undefined): string => {
    if (!val) {
        return '';
    }

    return `${val / 100}`;
};

export const getDefaultFormValues = (listingData: ExtendedListing) => ({
    defaultValues: {
        price: formatListingPrice(listingData.price.amount),
        description: listingData.description,
    },
});

export function getInitialData(isNew: boolean, listingData: ExtendedListing | Nil): ListingFormData {
    if (isNew || !listingData) {
        return initialListingData;
    }

    const images = listingData.images || [];

    const { pricing } = listingData.publicData;
    const price = {
        dailyPrice: formatListingPrice(listingData.price.amount),
        ...(pricing?.original && { originalPrice: formatListingPrice(pricing.original) }),
        ...(pricing?.weekly && { weeklyPrice: formatListingPrice(pricing.weekly) }),
        ...(pricing?.monthly && { monthlyPrice: formatListingPrice(pricing.monthly) }),
    };
    const storedData = omit(listingData?.publicData, ['favorites', 'blurhash', 'pricing']);

    return { ...storedData, images, price };
}

export const successVariants: Variants = {
    initial: {
        opacity: 0,
        scale: 1.3,
    },
    in: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.25,
        },
    },
    out: {
        opacity: 0,
    },
};
