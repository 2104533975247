import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Divider, SxProps, Box } from '@mui/material';
import React from 'react';
import { LineItem, Money } from '../../transactions/types';
import { LineItems } from '../LineItems/LineItems';

interface BreakdownAccordionProps {
    summary: React.ReactNode;
    lineItems: LineItem[];
    containerSX?: SxProps;
}

export const BreakdownAccordion: React.FC<BreakdownAccordionProps> = ({ summary, lineItems, containerSX }) => (
    <Box sx={{ width: '100%', ...containerSX }}>
        <Accordion
            elevation={0}
            sx={{
                '&:before': {
                    backgroundColor: 'transparent !important',
                },
                backgroundColor: 'transparent',
                margin: '0 !important',
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    minHeight: '0! important',
                    '&.Mui-expanded': {
                        margin: '0 !important',
                        minHeight: '0 !important',
                    },
                    '& > div': { margin: '0 !important' },
                    '&.MuiAccordionSummary-content': { margin: '0 !important', minHeight: '0 !important' },
                }}
            >
                <Typography variant="body2">{summary}</Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0 } }}>
                <Divider />
                <LineItems lineItems={lineItems} />
            </AccordionDetails>
        </Accordion>
    </Box>
);
