import React, { useCallback, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { VirtualizedPage } from '../ImageSlider/VirtualizedPage';
import { MainContent, TabNav } from './SwipeableTabs.components';
import { TabList } from '../TabList/TabList';
import { ReactFCWithChildren } from '../../types/types';

const calcCurrentPage = (index: number, tabsCount: number) => {
    const modulo = index % tabsCount;
    return modulo < 0 ? tabsCount + modulo : modulo;
};

interface SwipeableTabsProps {
    tabs: { label: string; content: JSX.Element }[];
    initialTab?: number;
    onChange?: (index: number) => void;
}

export const SwipeableTabs: ReactFCWithChildren<SwipeableTabsProps> = ({ tabs, onChange, initialTab }) => {
    const [page, setPage] = useState(initialTab || 0);
    const selectedTab = calcCurrentPage(page, tabs.length);

    const paginate = useCallback(
        (newDirection: number) => {
            setPage(page + newDirection);
            onChange?.(page + newDirection);
        },
        [page],
    );

    const handleTabClick = useCallback(
        (index: number) => {
            if (index === page) {
                return;
            }
            const direction = index > page ? 1 : -1;
            paginate(direction);
        },
        [page, paginate],
    );

    if (!tabs.length) {
        return <></>;
    }

    return (
        <>
            <TabNav>
                <TabList tabName="swipeable-tabs" tabs={tabs} selectedTab={selectedTab} onClick={handleTabClick} />
            </TabNav>

            <MainContent>
                <AnimatePresence initial={false}>
                    <VirtualizedPage currentPage={page} paginate={paginate} infiniteSwipe={false} pageTotal={tabs.length}>
                        {({ index }) => <>{tabs[calcCurrentPage(index, tabs.length)].content}</>}
                    </VirtualizedPage>
                </AnimatePresence>
            </MainContent>
        </>
    );
};
