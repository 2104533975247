import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { ExtendedListing } from '../../types/apiTypes';
import { Nil } from '../../types/types';
import { Money } from '../../transactions/types';
import { useTranslation } from 'react-i18next';
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { useTheme } from '@mui/material';

interface PaymentRequestButtonProps {
    booking: Booking | BookingDraft;
    total: Money | undefined;
    listing: ExtendedListing | Nil;
    startPayment: (ev?: PaymentRequestPaymentMethodEvent) => void;
}

export const PaymentRequestButton: React.FC<PaymentRequestButtonProps> = ({ booking, total, listing, startPayment }) => {
    const stripe = useStripe();
    const theme = useTheme();
    const { t } = useTranslation();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

    useEffect(() => {
        if (stripe && total && booking && listing) {
            const { amount, currency } = total;
            const pr = stripe.paymentRequest({
                country: 'FI',
                currency: currency.toLowerCase(),
                disableWallets: ['googlePay', 'link', 'browserCard'],
                total: {
                    label: `${listing.title} ${t('rental')}`,
                    amount,
                },
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });

            pr.on('paymentmethod', async (ev) => {
                startPayment(ev);
            });
        }
    }, [stripe, total, booking, listing]);

    if (paymentRequest) {
        return (
            <PaymentRequestButtonElement
                options={{ paymentRequest, style: { paymentRequestButton: { theme: theme.palette.mode === 'dark' ? 'light' : 'dark' } } }}
            />
        );
    }

    return null;
};
