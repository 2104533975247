import { VerifiedUser } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyledLinkColor } from '../../components/Styled/Styled.components';
import { IconSection } from '../../components/IconSection.tsx/IconSection';

export const RenterProtectionSection: React.FC = () => {
    const { t } = useTranslation();

    return (
        <IconSection
            icon={<VerifiedUser color="primary" sx={{ mt: '1px' }} />}
            title={
                <Typography variant="body1" fontWeight="bold">
                    {t('renterProtectionSectionTitle')}
                </Typography>
            }
        >
            <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                <Trans i18nKey="renterProtectionSectionDescription" components={{ button: <StyledLinkColor to="/renter-protection" /> }} />
            </Typography>
        </IconSection>
    );
};
