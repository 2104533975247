import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useArticle } from './useArticle';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { renderStrapiComponent } from '../../strapi/helpers';
import { analytics } from '../../analytics/events/helpers';
import { Listing } from '../../types/apiTypes';
import { useQueryClient } from '@tanstack/react-query';
import { useFavorites } from '../../queries/useFavorites';
import { useToggleFavorite } from '../../messages/hooks/useToggleFavorite';
import { ArticleSkeleton } from './Article.Skeleton';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeIn } from '../../animations/constants';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { Box, Card, Typography } from '@mui/material';
import { SignupLink } from '../../components/SignUpLink/SignUpLink';
import { useTranslation } from 'react-i18next';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export const ArticleView: React.FC = () => {
    const { t } = useTranslation();
    const { id: articleId } = useParams() as { id: string };
    const { data: article, refetch, status } = useArticle(articleId);
    const { mutate: toggleFavorite } = useToggleFavorite();

    const favorites = useFavorites();
    const isDesktop = useBreakpoint('sm');
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();
    const userToken = useAppSelector(selectToken);

    const handleClickListing = useCallback(
        (listing: Listing) => {
            const queryKey = ['listing', { listingId: listing.id }];
            analytics.listingClick(listing.id, listing.title, 'listings-page');

            const cachedData = queryClient.getQueryData(queryKey);
            if (!cachedData) {
                queryClient.setQueryData(queryKey, () => listing);
                queryClient.invalidateQueries(queryKey);
            }

            navigate(`/listing/${listing.id}`);
        },
        [navigate, queryClient],
    );

    const isFavorited = useCallback((listingId: string) => favorites.includes(listingId), [favorites]);

    return (
        <ContentLoader status={status} skeleton={<ArticleSkeleton />} style={{ paddingBottom: '56px' }}>
            <AnimatedContainer variants={fadeIn} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {article?.components.map((component) => (
                    <React.Fragment key={`${component.type}-${component.id}`}>
                        {renderStrapiComponent(component, {
                            imageSize: 135,
                            isDesktop,
                            onListingClick: handleClickListing,
                            isFavoritedFn: isFavorited,
                            onToggleFavorite: toggleFavorite,
                        })}
                    </React.Fragment>
                ))}
            </AnimatedContainer>
            {!userToken && (
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Card sx={{ maxWidth: '350px', p: 2, borderRadius: '15px' }} elevation={8}>
                        <Typography variant="body2" sx={{ textAlign: 'center', margin: '10px 32px 32px' }}>
                            {t('signUpCTADescription')}
                        </Typography>
                        <SignupLink fullWidth />
                    </Card>
                </Box>
            )}
        </ContentLoader>
    );
};
