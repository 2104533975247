import { Box, Button, ButtonGroup, InputAdornment, TextField, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, ControllerProps, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DAYS_IN_MONTH,
    DAYS_IN_WEEK,
    calculateSuggestedPrices,
    parseFloatString,
    validateLessThanOrEqual,
    validatePriceFn,
} from './PricingModelForm.helpers';
import { FormData, SuggestedPrices } from './PricingModelForm.types';
import { DiscountDefinition } from '../../strapi/hooks/useDiscountDefinition';
import { LoadingOverlay } from '@mantine/core';
import { PricingSummary } from './PricingSummary';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeUp, grow } from '../../animations/constants';

const PriceField: React.FC<{ label: React.ReactNode; controlName: string; rules?: ControllerProps['rules']; required?: boolean }> = ({
    label,
    controlName,
    rules,
    required = false,
}) => {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={controlName}
            control={control}
            rules={rules}
            render={({ field: { ref, onBlur, value }, fieldState }) => (
                <TextField
                    value={value || ''}
                    required={required}
                    inputRef={ref}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error ? fieldState.error.message : null}
                    onChange={(evt) => setValue(controlName, evt.target.value, { shouldValidate: true })}
                    onBlur={onBlur}
                    fullWidth
                    label={label}
                    variant="standard"
                    InputProps={{
                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />
            )}
        />
    );
};

interface PricingModelFormProps {
    onApply: (data: FormData) => void;
    category: string;
    loading?: boolean;
    defaultValues?: FormData;
    discountDefinition: DiscountDefinition | undefined;
}

export const PricingModelForm: React.FC<PricingModelFormProps> = ({ onApply, category, defaultValues, loading = false, discountDefinition }) => {
    const [showSummary, setShowSummary] = useState(!!defaultValues?.originalPrice);
    const [pricingSummary, setPricingSummary] = useState<SuggestedPrices | null>(null);
    const [editable, setEditable] = useState(false);
    const [userEditedOriginalPrice, setUserEditedOriginalPrice] = useState(false);

    const methods = useForm<FormData>({ defaultValues, reValidateMode: 'onBlur' });
    const {
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        getValues,
        watch,
    } = methods;

    const { t } = useTranslation();

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const originalPrice = watch('originalPrice');
    const dailyPrice = watch('dailyPrice');
    const weeklyPrice = watch('weeklyPrice');
    const monthlyPrice = watch('monthlyPrice');

    const suggestedPrices = useMemo(
        () => calculateSuggestedPrices(originalPrice, category, discountDefinition),
        [originalPrice, category, discountDefinition],
    );

    const handleSubmitWithoutPropagation = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!editable && !showSummary) {
            setShowSummary(true);
        } else {
            handleSubmit(onApply)(e);
        }
    };

    useEffect(() => {
        const subscription = watch((_value, { name }) => {
            if (name === 'originalPrice') {
                setUserEditedOriginalPrice(true);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const getPricingSummary = () => {
            const [dailyPrice, weeklyPrice, monthlyPrice] = getValues(['dailyPrice', 'weeklyPrice', 'monthlyPrice']);

            const dailyPriceNum = parseFloatString(dailyPrice);

            if (!suggestedPrices) {
                return null;
            }

            let pricingSummary = cloneDeep(suggestedPrices);

            const calcSummary = (compareVal: number, val: string | undefined) => {
                if (!val) {
                    return;
                }

                const valNum = parseFloatString(val);

                return { percentage: Math.round((1 - valNum / compareVal) * 100), value: valNum * 100 };
            };

            const undiscountedWeekly = dailyPriceNum * DAYS_IN_WEEK;
            const undiscountedMonthly = dailyPriceNum * DAYS_IN_MONTH;

            if (dailyPrice) {
                pricingSummary.daily = { value: dailyPriceNum * 100 };
            }

            if (weeklyPrice) {
                const weeklySummary = calcSummary(undiscountedWeekly, weeklyPrice);
                if (weeklySummary) {
                    pricingSummary.weekly = weeklySummary;
                }
            } else {
                pricingSummary.weekly = { value: undiscountedWeekly * 100, percentage: 0 };
            }

            if (monthlyPrice) {
                const monthlySummary = calcSummary(undiscountedMonthly, monthlyPrice);
                if (monthlySummary) {
                    pricingSummary.monthly = monthlySummary;
                }
            } else {
                pricingSummary.monthly = { value: undiscountedMonthly * 100, percentage: 0 };
            }

            return pricingSummary;
        };

        const summary = getPricingSummary();

        setPricingSummary(summary);
    }, [suggestedPrices, originalPrice, dailyPrice, weeklyPrice, monthlyPrice, editable]);

    useEffect(() => {
        if (!suggestedPrices || !userEditedOriginalPrice) {
            setEditable(false);
            return;
        }

        const dailyPrice = `${suggestedPrices.daily.value / 100}`;
        const weeklyPrice = `${suggestedPrices.weekly.value / 100}`;
        const monthlyPrice = `${suggestedPrices.monthly.value / 100}`;

        setValue('dailyPrice', dailyPrice);
        setValue('weeklyPrice', weeklyPrice);
        setValue('monthlyPrice', monthlyPrice);
    }, [originalPrice]);

    const toggleEditable = () => {
        setEditable(!editable);
    };

    return (
        <FormProvider {...methods}>
            <form noValidate onSubmit={handleSubmitWithoutPropagation}>
                <Box sx={{ pb: 12, px: 2, position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="h5">{t('priceSectionTitle')}</Typography>
                    <Typography variant="body2">{t('priceSectionSubtitle')}</Typography>

                    <PriceField
                        required
                        controlName="originalPrice"
                        label={t('originalPrice')}
                        rules={{ required: true, validate: validatePriceFn }}
                    />

                    <AnimatePresence>
                        {editable && (
                            <AnimatedContainer variants={grow} key="edit-mode" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant="h6" sx={{ my: 1 }}>
                                    {t('dailyPrice')}
                                </Typography>

                                <PriceField
                                    required
                                    controlName="dailyPrice"
                                    label={t('dailyPrice')}
                                    rules={{
                                        required: true,
                                        validate: {
                                            value: (val) => validatePriceFn(val),
                                            lessThan: (val) => validateLessThanOrEqual(val, originalPrice, t('shouldBeLessThanOriginal')),
                                        },
                                    }}
                                />

                                <Typography variant="h6" sx={{ my: 1 }}>
                                    {t('weeklyAndMonthlyPriceSectionTitle')}
                                </Typography>

                                <PriceField
                                    controlName="weeklyPrice"
                                    label={t('weeklyPrice')}
                                    rules={{
                                        validate: {
                                            value: (val) => (val ? validatePriceFn(val) : true),
                                            lessThan: (val) => {
                                                if (!val) {
                                                    return true;
                                                }

                                                const dailyPriceNum = parseFloatString(dailyPrice);
                                                const undiscountedWeekly = isNaN(dailyPriceNum) ? 0 : dailyPriceNum * DAYS_IN_WEEK;
                                                const errorMsg = `${t('shouldBeLessThan', { value: `${undiscountedWeekly} €` })}`;

                                                return validateLessThanOrEqual(val, undiscountedWeekly, errorMsg);
                                            },
                                        },
                                    }}
                                />

                                <PriceField
                                    controlName="monthlyPrice"
                                    label={t('monthlyPrice')}
                                    rules={{
                                        validate: {
                                            value: (val) => (val ? validatePriceFn(val) : true),
                                            lessThan: (val) => {
                                                if (!val) {
                                                    return true;
                                                }

                                                const dailyPriceNum = parseFloatString(dailyPrice);
                                                const undiscountedMonthly = isNaN(dailyPriceNum) ? 0 : dailyPriceNum * DAYS_IN_MONTH;
                                                const errorMsg = `${t('shouldBeLessThan', { value: `${undiscountedMonthly} €` })}`;

                                                return validateLessThanOrEqual(val, undiscountedMonthly, errorMsg);
                                            },
                                        },
                                    }}
                                />
                            </AnimatedContainer>
                        )}
                    </AnimatePresence>

                    {showSummary && pricingSummary && (
                        <AnimatedContainer variants={fadeUp} layout>
                            <PricingSummary prices={pricingSummary} toggleEdit={toggleEditable} editable={editable} />
                        </AnimatedContainer>
                    )}
                </Box>

                <motion.div style={{ position: 'absolute', width: '100%', padding: '16px', bottom: 'env(safe-area-inset-bottom)' }}>
                    <ButtonGroup fullWidth sx={{ mt: 4 }}>
                        {editable && (
                            <>
                                <Button variant="outlined" onClick={() => reset()} sx={{ background: (theme) => theme.palette.background.paper }}>
                                    {t('reset')}
                                </Button>
                                <Button fullWidth variant="contained" onClick={toggleEditable} type="submit" disabled={!isEmpty(errors)}>
                                    {t('continue')}
                                </Button>
                            </>
                        )}

                        {!editable && showSummary && (
                            <>
                                <Button variant="outlined" onClick={() => reset()} sx={{ background: (theme) => theme.palette.background.paper }}>
                                    {t('reset')}
                                </Button>
                                <Button type="submit" variant="contained" disabled={!isEmpty(errors)}>
                                    {t('submit')}
                                </Button>
                            </>
                        )}
                        {!editable && !showSummary && (
                            <Button variant="contained" onClick={() => setShowSummary(true)}>
                                {t('continue')}
                            </Button>
                        )}
                    </ButtonGroup>
                </motion.div>
            </form>

            <LoadingOverlay visible={loading} loaderProps={{ color: '#EC630B' }} />
        </FormProvider>
    );
};
