import { sizing } from '@mui/system';

/**
 * Shirts, jeans etc
 */
export const sizingOptionsOne = [
    'oneSize',
    '32 - XS',
    '34 - S',
    '36 - M',
    '38 - M',
    '40 - L',
    '42 - L',
    '44 - XL',
    '46 - XL',
    '48 - XXL',
    '50 - XXL',
    '52 - 3XL',
    '54 - 4XL',
];

/**
 * Shoes, bags, accessories etc
 */
export const sizingOptionsTwo = ['oneSize', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49'];

export const colorOptions = [
    {
        value: 'black',
        colorCode: '#000',
        label: 'black',
    },
    {
        value: 'white',
        colorCode: '#fff',
        label: 'white',
    },
    {
        value: 'metallic',
        colorCode: '#aaa9ad',
        label: 'metallic',
    },
    {
        value: 'grey',
        colorCode: '#808080',
        label: 'grey',
    },
    {
        value: 'beige',
        colorCode: '#F5F5DC',
        label: 'beige',
    },
    {
        value: 'yellow',
        colorCode: '#FFFF00',
        label: 'yellow',
    },
    {
        value: 'gold',
        colorCode: '#FFD700',
        label: 'gold',
    },
    {
        value: 'orange',
        colorCode: '#FFA500',
        label: 'orange',
    },
    {
        value: 'brown',
        colorCode: '#964B00',
        label: 'brown',
    },
    {
        value: 'burgundy',
        colorCode: '#800020',
        label: 'burgundy',
    },
    {
        value: 'red',
        colorCode: '#CD001A',
        label: 'red',
    },
    {
        value: 'pink',
        colorCode: '#FFC0CB',
        label: 'pink',
    },
    {
        value: 'violet',
        colorCode: '#7F00FF',
        label: 'violet',
    },
    {
        value: 'blue',
        colorCode: '#0C71E0',
        label: 'blue',
    },
    {
        value: 'turquoise',
        colorCode: '#30D5C8',
        label: 'turquoise',
    },
    {
        value: 'green',
        colorCode: '#008000',
        label: 'green',
    },
    {
        value: 'multiple',
        src: '/multiple_colors.jpg',
        label: 'multiple',
    },
];

export const deliveryOptions = [
    { value: 'wolt', label: 'woltDelivery' },
    { value: 'showroom', label: 'showroomDelivery' },
    { value: 'faceToFace', label: 'faceToFaceDelivery' },
];

export const categories = [
    'dresses',
    'bags',
    'shoes',
    'tops',
    'jewellery',
    'coatsAndJackets',
    'accessories',
    'knitwear',
    'suits',
    'trousers',
    'skirts',
    'homewear',
    'shirtsAndTshirts',
    'shorts',
    'jumpsuits',
    'maternityWear',
] as const;

export type Category = typeof categories[number];

export const getSizingOptionsForCategory = (category: Category) => {
    const sizingOptionTwoCategories: Category[] = ['shoes', 'bags', 'accessories'];
    if (sizingOptionTwoCategories.includes(category)) {
        return sizingOptionsTwo;
    }

    return sizingOptionsOne;
};

export const getSizingOptionsForSelectedCategories = (selectedCategories: Category[]) => {
    const sizingOptionTwoCategories: Category[] = ['shoes', 'bags', 'accessories'];
    const includeOptionOne = selectedCategories.some((category) => !sizingOptionTwoCategories.includes(category));
    const includeOptionTwo = selectedCategories.some((category) => sizingOptionTwoCategories.includes(category));

    if (selectedCategories.length === 0 || (includeOptionOne && includeOptionTwo)) {
        // Remove duplicate 'oneSize' option
        return [...sizingOptionsOne, ...sizingOptionsTwo.filter((option) => option !== 'oneSize')];
    }

    if (includeOptionOne) {
        return sizingOptionsOne;
    }

    if (includeOptionTwo) {
        return sizingOptionsTwo;
    }

    return [];
};

export type Bodytype = 'male' | 'female' | 'unisex';

export const getBodytypeOptions = (): Bodytype[] => ['male', 'female', 'unisex'];
