import React from 'react';
import { useLocation } from 'react-router-dom';
import { StripeAccountAlertToast } from '../../StripeAccountAlert/StripeAccountAlertToast';
import { NewVersionAlertMaybe } from '../../VersionCheck/NewVersionAlertMaybe';
import { OutOfDateModalMaybe } from '../../VersionCheck/OutOfDateModalMaybe';

/**
 * Alerts and modals that show important information to the user
 */
export const Alerts: React.FC = () => {
    const location = useLocation();

    return (
        <>
            <StripeAccountAlertToast location={location} />
            <NewVersionAlertMaybe />
            <OutOfDateModalMaybe />
        </>
    );
};
