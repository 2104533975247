import React, { CSSProperties } from 'react';
import { Box, useTheme } from '@mui/material';
import RobesLogoBlack from '../../assets/images/logo-black.png';
import RobesLogoWhite from '../../assets/images/logo-white.png';
import { ReactFCWithChildren } from '../../types/types';

interface RobesLogoProps {
    containerStyle?: CSSProperties;
    modeOverride?: 'light' | 'dark';
    height: number;
}

export const RobesLogoFull: ReactFCWithChildren<RobesLogoProps> = ({ containerStyle, height, modeOverride }) => {
    const theme = useTheme();
    const colorMode = modeOverride ? modeOverride : theme.palette.mode;

    return (
        <Box sx={{ display: 'flex', ...containerStyle }}>
            <img src={colorMode === 'light' ? RobesLogoBlack : RobesLogoWhite} height={height} alt="robes-logo" />
        </Box>
    );
};
