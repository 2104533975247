import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ReactFCWithChildren } from '../types/types';
import { useCurrentUserId } from '../user/hooks/useUser';
import { Conversation, Permission, User } from '../types/apiTypes';
import { useLocation } from 'react-router-dom';
import { usePermissions } from '../user/hooks/usePermissions';
import { AdminActionsDrawer } from '../components/Admin/AdminActionsDrawer';
import { mapPermissionsToActions } from '../components/Admin/helpers';
import { assertIsDefined } from '../helpers/commonHelpers';

export type AdminActionsTarget = 'user' | 'conversation';

interface AdminActionsStateBase {
    target: AdminActionsTarget;
    currentUserId: string;
    pathname: string;
}

interface AdminActionsStateUser extends AdminActionsStateBase {
    target: 'user';
    user: User;
}

interface AdminActionsStateConversation extends AdminActionsStateBase {
    target: 'conversation';
    conversation: Conversation;
}

export type AdminActionsState = AdminActionsStateUser | AdminActionsStateConversation;
export type OpenUserAdminActionProps = Omit<AdminActionsStateUser, 'pathname' | 'currentUserId'>;
export type OpenConversationAdminActionProps = Omit<AdminActionsStateConversation, 'pathname' | 'currentUserId'>;
export type OpenAdminActionProps = OpenUserAdminActionProps | OpenConversationAdminActionProps;

const permissionsEligibleForActionsByTarget: Record<AdminActionsTarget, { permissions: Permission[] }> = {
    user: {
        permissions: ['issue_credits'],
    },
    conversation: {
        permissions: ['mark_conversation_as_unread'],
    },
};

export const hasActionPermissionsForTarget = (permissions: Permission[], target: AdminActionsTarget | undefined) => {
    if (!target) {
        return false;
    }

    return permissions.some((permission) => permissionsEligibleForActionsByTarget[target].permissions.includes(permission));
};

const AdminActionsContext = createContext<{
    openAdminControls: (props: OpenAdminActionProps) => void;
    closeAdminControls: () => void;
    state: AdminActionsState | null;
    hasAdminPermissions: boolean;
}>({
    openAdminControls: () => null,
    closeAdminControls: () => null,
    state: null,
    hasAdminPermissions: false,
});

export const AdminActionsProvider: ReactFCWithChildren = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [state, setState] = useState<AdminActionsState | null>(null);

    const { data: permissions } = usePermissions();
    const currentUserId = useCurrentUserId();
    const location = useLocation();

    const hasAdminPermissions = hasActionPermissionsForTarget(permissions || [], state?.target);

    const openAdminControls = useCallback(
        (props: OpenAdminActionProps) => {
            const isUserProps = (props: OpenAdminActionProps): props is OpenUserAdminActionProps => {
                return props.target === 'user';
            };
            const isConversationProps = (props: OpenAdminActionProps): props is OpenConversationAdminActionProps => {
                return props.target === 'conversation';
            };

            assertIsDefined(currentUserId, 'Current user id is undefined');

            if (isUserProps(props)) {
                setState({ ...props, pathname: location.pathname, currentUserId });
            }

            if (isConversationProps(props)) {
                setState({ ...props, pathname: location.pathname, currentUserId });
            }

            setDrawerOpen(true);
        },
        [currentUserId, location.pathname],
    );

    const handleCloseDrawer = () => {
        if (!drawerOpen) {
            return;
        }

        setDrawerOpen(false);

        setTimeout(() => {
            setState(null);
        }, 300);
    };

    const closeAdminControls = useCallback(() => {
        handleCloseDrawer();
    }, []);

    useEffect(() => {
        if (state && location.pathname !== state?.pathname) {
            closeAdminControls();
        }
    }, [location.pathname]);

    const adminActions = permissions && state ? mapPermissionsToActions(permissions, state) : [];

    return (
        <AdminActionsContext.Provider value={{ openAdminControls, closeAdminControls, state, hasAdminPermissions }}>
            <>{children}</>
            {permissions && currentUserId && (
                <AdminActionsDrawer
                    permissions={permissions}
                    state={state}
                    open={drawerOpen}
                    onClose={handleCloseDrawer}
                    target={state?.target}
                    actions={adminActions}
                />
            )}
        </AdminActionsContext.Provider>
    );
};

export const useAdminControlsDrawer = () => useContext(AdminActionsContext);
