/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './types';

interface SearchHistoryState {
    recentSearches: string[];
}

const initialState: SearchHistoryState = {
    recentSearches: [],
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        addToSearchHistory: (state, action: PayloadAction<string>) => {
            const searchTerm = action.payload;

            if (searchTerm.trim().length === 0) {
                return;
            }

            const searchHistoryCopy = state.recentSearches.slice();
            const existingIdx = searchHistoryCopy.findIndex((entry) => entry === searchTerm);

            if (existingIdx > -1) {
                searchHistoryCopy.splice(existingIdx, 1);
            }

            searchHistoryCopy.unshift(searchTerm);

            if (state.recentSearches.length > 50) {
                searchHistoryCopy.pop();
            }

            state.recentSearches = searchHistoryCopy;
        },
    },
});

export const { addToSearchHistory } = searchSlice.actions;

export const selectSearchHistory = (state: RootState): string[] => state.searchHistory.recentSearches;
export const selectLast10HistoryEntries = (state: RootState): string[] => state.searchHistory.recentSearches.slice(0, 10);

export default searchSlice.reducer;
