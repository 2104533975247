import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';

interface DragContainerProps {
    enabled?: boolean;
    carouselStyles?: React.CSSProperties;
    containerStyles?: React.CSSProperties;
    gap?: number;
}

export const DragContainer: ReactFCWithChildren<DragContainerProps> = ({ children, carouselStyles, containerStyles, gap = 5 }) => {
    const [width, setWidth] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const count = React.Children.count(children);

    useEffect(() => {
        if (!carouselRef.current) {
            return;
        }

        const offset = carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
        setWidth(offset + 10);
    }, [count]);

    return (
        <motion.div ref={carouselRef} style={{ cursor: 'grab', overflow: 'hidden', ...carouselStyles }}>
            <motion.div
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                style={{ display: 'inline-flex', alignItems: 'center', gap, ...containerStyles }}
            >
                {children}
            </motion.div>
        </motion.div>
    );
};
