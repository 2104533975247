import React, { useCallback, useRef } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import Divider from '@mui/material/Divider';
import { useConversations } from '../../messages/hooks/useConversations';
import { Author, Conversation } from '../../types/apiTypes';
import { formatFlexibleDateTime } from '../../helpers/dateAndTimeHelpers';
import { ListItemButton, useTheme } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { StyledList } from '../../components/StyledList/StyledList.components';
import { LoadMoreButton } from '../Listings/LoadMoreButton/LoadMoreButton';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ReactFCWithChildren } from '../../types/types';
import { useCurrentUserId } from '../../user/hooks/useUser';
import { useLocation } from 'react-router-dom';
import { ChatParticipant, useChatWindow } from '../../context/chat';
import { MessagesSkeleton } from './Messages.skeleton';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { useQueryClient } from '@tanstack/react-query';
import { fetchConversation } from '../../messages/hooks/useChat';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { assertIsDefined, sleep } from '../../helpers/commonHelpers';
import { useLongPress } from '../../hooks/useLongPress';
import { useAdminControlsDrawer } from '../../context/admin';
import { useHasPermission } from '../../user/hooks/usePermissions';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

interface ConversationItemProps {
    conversation: Conversation;
    userId: string | undefined;
    canMarkAsUnread: boolean;
    openChat: (participant: Author) => void;
    openAdminControls?: () => void;
}

const ConversationItem: ReactFCWithChildren<ConversationItemProps> = ({ conversation, openChat, userId, canMarkAsUnread, openAdminControls }) => {
    const { conversationId, lastMessage, participant } = conversation;
    const lastMessageUnreadByUser = userId && userId !== lastMessage?.senderId && !lastMessage?.readAt;
    const theme = useTheme();
    const rippleRef = useRef<TouchRippleActions>(null);

    const handleOpenAdminControls = () => {
        Haptics.impact({ style: ImpactStyle.Light });
        assertIsDefined(openAdminControls, 'openAdminControls should be provided if longPress is used');
        openAdminControls();

        if (rippleRef.current) {
            rippleRef.current.stop();
        }
    };

    const onClick = () => {
        openChat(participant);
    };

    const longPressHandlers = useLongPress(handleOpenAdminControls, { threshold: 300, enabled: canMarkAsUnread });

    return (
        <ListItem disablePadding alignItems="flex-start" key={conversationId} sx={{ position: 'relative' }}>
            <ListItemButton {...longPressHandlers} onClick={onClick} sx={{ width: '100%' }} touchRippleRef={rippleRef}>
                <ListItemAvatar>
                    <Avatar alt="profile image" src={participant.profileImage} />
                </ListItemAvatar>
                <ListItemText
                    primary={participant.displayName}
                    secondary={lastMessage?.content || ''}
                    primaryTypographyProps={{
                        variant: 'subtitle2',
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            ...(lastMessageUnreadByUser && {
                                fontWeight: 'bolder',
                            }),
                        },
                    }}
                    secondaryTypographyProps={{
                        variant: 'caption',
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            ...(lastMessageUnreadByUser && {
                                fontWeight: 'bold',
                                color: theme.palette.text.primary,
                            }),
                        },
                    }}
                />
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '10px',
                        ...(lastMessageUnreadByUser && {
                            fontWeight: 'bolder',
                        }),
                    }}
                >
                    {formatFlexibleDateTime(lastMessage?.createdAt)}
                </Typography>
            </ListItemButton>
        </ListItem>
    );
};

export const Messages: React.FC = () => {
    const { t } = useTranslation();
    const currentUserId = useCurrentUserId();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();
    const location = useLocation();

    const canMarkAsUnread = useHasPermission('mark_conversation_as_unread');

    const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useConversations();

    const isSmBreakpoint = useBreakpoint('sm');

    const { openChat, closeChat } = useChatWindow();
    const { openAdminControls } = useAdminControlsDrawer();

    const handleClickUser = useCallback((userId: string) => {
        closeChat();
        navigate(`/user/${userId}`);
    }, []);

    const handleOpenChat = useCallback((participant: ChatParticipant) => {
        const chatProps = {
            participant,
            onClickUser: handleClickUser,
        };

        openChat(chatProps);
    }, []);

    const handleOpenAdminControls = useCallback(
        (conversation: Conversation) => {
            openAdminControls({ target: 'conversation', conversation });
        },
        [data],
    );

    useEffectOnce(() => {
        if (!location.state?.senderId) return;

        const openChatOnMount = async () => {
            const senderId = location.state.senderId as string;

            const conversation = await fetchConversation(senderId);

            if (!conversation) {
                return;
            }

            queryClient.setQueryData(['conversation', { participantId: senderId }], conversation);

            const participantData = conversation?.participants.find((participant) => participant.id === senderId) || null;

            if (participantData) {
                await sleep(300);

                handleOpenChat(participantData);
            }
        };

        openChatOnMount();
    });

    return (
        <ContentLoader status={status} skeleton={<MessagesSkeleton />}>
            {!!data?.pages?.length && (
                <StyledList
                    subheader={
                        isSmBreakpoint && (
                            <ListSubheader sx={{ padding: '20px' }}>
                                <Typography variant="h5">Conversations</Typography>
                            </ListSubheader>
                        )
                    }
                >
                    {data.pages.map((page: { data: Conversation[] }) =>
                        page.data.map((conversation: Conversation) => (
                            <motion.div layout key={conversation.conversationId}>
                                <ConversationItem
                                    conversation={conversation}
                                    openChat={handleOpenChat}
                                    userId={currentUserId}
                                    canMarkAsUnread={canMarkAsUnread}
                                    openAdminControls={() => handleOpenAdminControls(conversation)}
                                />
                                <Divider variant="fullWidth" component="li" />
                            </motion.div>
                        )),
                    )}
                    <LoadMoreButton
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        noItems={false}
                        noMoreItemsText={t('noMoreConversations')}
                        sx={{ width: '100%', marginBottom: '56px' }}
                    />
                </StyledList>
            )}
        </ContentLoader>
    );
};
