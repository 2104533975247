import { Typography, styled, Alert, Switch, ButtonBase, Box, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeIn } from '../../animations/constants';
import { CreditsBalance, useCreditsBalance } from '../UserProfile/hooks/useCreditsBalance';
import { CreditsConfiguration } from '../UserProfile/hooks/useCreditsConfiguration';
import { formatMoney, formatMoneyShort, sleep } from '../../helpers/commonHelpers';
import { LineItem } from '../../transactions/types';
import { calculateTotalFromLineItemsExcludeCredits, getCreditsFromLineItems } from '../../helpers/currency';
import { useTranslation } from 'react-i18next';
import { PromotionalCodeDrawer } from '../../components/PromotionalCodeDrawer/PromotionalCodeDrawer';
import { AddedCredits, useUpdateCreditsBalance, useRedeemPromotionalCode } from '../UserProfile/hooks/useRedeemPromotionalCode';
import { Controller, useFormContext } from 'react-hook-form';
import { ArrowForwardIos, Redeem, Toll } from '@mui/icons-material';
import { QueryStatus } from '@tanstack/react-query';
import { Booking, BookingDraft } from '../../store/bookingReducer';

const ComponentContainer = styled(Box)(() => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    padding: '6px 0px 6px 8px',
    height: 'auto',
    gap: '15px',
}));

interface UseCreditsToggleMaybeProps {
    configuration: CreditsConfiguration;
    lineItems: LineItem[];
    hasMoreCreditsThanCanBeUsed: boolean;
    creditPaymentDisabled: boolean;
    displayCredits: string;
    bookingIsOngoing: boolean;
}

const UseCreditsToggleMaybe: React.FC<UseCreditsToggleMaybeProps> = ({
    displayCredits,
    lineItems,
    hasMoreCreditsThanCanBeUsed,
    creditPaymentDisabled,
    configuration,
    bookingIsOngoing,
}) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const [lastCreditsLineItem, setLastCreditsLineItem] = useState<LineItem | null>(null);

    useEffect(() => {
        if (creditPaymentDisabled) {
            const paidWithCredits = bookingIsOngoing && Boolean(getCreditsFromLineItems(lineItems));
            setValue('useCredits', paidWithCredits);
        }
    }, [creditPaymentDisabled, setValue]);

    useEffect(() => {
        const creditsLineItem = getCreditsFromLineItems(lineItems);
        if (creditsLineItem) {
            setLastCreditsLineItem(creditsLineItem);
        }
    }, [lineItems]);

    const getFormattedMaxCredits = () => {
        const creditsLineItem = lastCreditsLineItem;
        if (!creditsLineItem) {
            return null;
        }

        return formatMoney(Math.abs(creditsLineItem?.unitPrice.amount), configuration.currency);
    };

    const containerSx = creditPaymentDisabled ? { opacity: 0.5 } : {};

    return (
        <>
            <ComponentContainer sx={{ mt: 2, ...containerSx }}>
                <Toll sx={{ opacity: 0.8 }} />

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body2" fontWeight="bold">
                        {t('useRobesCredits')}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: '0.85rem', opacity: 0.6 }}>
                        {t('creditsAvailable', { credits: displayCredits })}
                    </Typography>
                </Box>

                <Controller
                    name="useCredits"
                    control={control}
                    render={({ field: fieldRenderer }) => (
                        <Switch {...fieldRenderer} disabled={creditPaymentDisabled} checked={fieldRenderer.value} />
                    )}
                />
            </ComponentContainer>

            {!bookingIsOngoing && (
                <>
                    <Collapse in={creditPaymentDisabled}>
                        <Alert severity="info">
                            {t('creditsUsageDisabledCaption', { price: formatMoney(configuration.minimumPurchase, configuration.currency) })}
                        </Alert>
                    </Collapse>

                    <Collapse in={hasMoreCreditsThanCanBeUsed}>
                        <Alert severity="info">{t('creditsExceedMaxUsage', { max: getFormattedMaxCredits() })}</Alert>
                    </Collapse>
                </>
            )}
        </>
    );
};

interface RedeemCodeDrawerButtonProps {
    onClick: () => void;
}

const RedeemCodeDrawerButton: React.FC<RedeemCodeDrawerButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <ComponentContainer sx={{ mt: 2, width: '100%' }} component={ButtonBase} onClick={onClick}>
            <Redeem sx={{ opacity: 0.8 }} />

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" fontWeight="bold">
                    {t('robesPromoCode')}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: '0.85rem', opacity: 0.6 }}>
                    {t('redeemPromoCode')}
                </Typography>
            </Box>

            <ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />
        </ComponentContainer>
    );
};

interface CreditsSectionProps {
    lineItems: LineItem[] | undefined;
    lineItemStatus: QueryStatus;
    booking: Booking | BookingDraft;
    fullCreditPayment: boolean | undefined;
    creditsConfiguration: CreditsConfiguration | undefined;
    credits: CreditsBalance | undefined;
    // Fetching the line-items without credits only after clicking the toggle is visually jarring -> prefetch
    prefetchLineItemsWithoutCredits: () => Promise<void>;
}

export const CreditsSection: React.FC<CreditsSectionProps> = ({
    lineItems = [],
    lineItemStatus,
    fullCreditPayment,
    booking,
    creditsConfiguration,
    credits,
    prefetchLineItemsWithoutCredits,
}) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [addedCredits, setAddedCredits] = useState<AddedCredits | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const updateCreditsBalance = useUpdateCreditsBalance();

    const handleSubmitSuccess = async (data: AddedCredits) => {
        setSubmitError(null);
        setAddedCredits(data);

        await updateCreditsBalance(data);
    };

    const handleSubmitError = (err: string) => {
        setSubmitError(err);
    };

    const handleCloseDrawer = async () => {
        setDrawerOpen(false);
        await sleep(1000);
        setAddedCredits(null);
    };

    const { mutate: redeemCode, isLoading: isRegisteringCode } = useRedeemPromotionalCode(handleSubmitSuccess, handleSubmitError);

    const availableCredits = Math.max((credits?.balance || 0) - (credits?.reserved || 0), 0);

    useEffect(() => {
        if (availableCredits) {
            prefetchLineItemsWithoutCredits();
        }
    }, [availableCredits]);

    if (!creditsConfiguration || !credits || !lineItems.length) {
        return null;
    }

    const hasMoreCreditsThanCanBeUsed = () => {
        // User is authorized to cover the full payment with credits
        if (fullCreditPayment) {
            return false;
        }

        const creditsLineItem = getCreditsFromLineItems(lineItems);

        if (!creditsLineItem) {
            return false;
        }

        return Math.abs(creditsLineItem.unitPrice.amount) < availableCredits;
    };

    const submitHandler = (formData: { code: string }) => {
        redeemCode(formData.code);
    };

    const displayCredits = formatMoneyShort(availableCredits, credits.currency);
    const totalWithoutCredits = calculateTotalFromLineItemsExcludeCredits(lineItems);
    const bookingIsOngoing = !!booking.status;
    const creditPaymentDisabled =
        (lineItemStatus !== 'loading' && totalWithoutCredits.amount < creditsConfiguration.minimumPurchase) || bookingIsOngoing;

    return (
        <AnimatedContainer variants={fadeIn}>
            {!!availableCredits && (
                <UseCreditsToggleMaybe
                    lineItems={lineItems}
                    configuration={creditsConfiguration}
                    displayCredits={displayCredits}
                    hasMoreCreditsThanCanBeUsed={hasMoreCreditsThanCanBeUsed()}
                    creditPaymentDisabled={creditPaymentDisabled}
                    bookingIsOngoing={bookingIsOngoing}
                />
            )}

            <RedeemCodeDrawerButton onClick={() => setDrawerOpen(true)} />

            <PromotionalCodeDrawer
                isLoading={isRegisteringCode}
                open={drawerOpen}
                addedCredits={addedCredits}
                onClose={handleCloseDrawer}
                configuration={creditsConfiguration}
                submitError={submitError}
                onSubmit={submitHandler}
                hideHeaderOnSuccess
                showSuccessAnimation
            />
        </AnimatedContainer>
    );
};
