import { differenceInMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { removeFromOngoing, selectOngoingBookings } from '../store/bookingReducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const cache = new Map();

/**
 * Removes expired bookings from the cache when they expire. Generally we don't have many of these, but sometimes we do I guess?
 */
export const useExpireBookings = () => {
    const dispatch = useAppDispatch();
    const bookings = useAppSelector(selectOngoingBookings);

    useEffect(() => {
        if (!bookings.length) {
            return;
        }

        bookings.forEach((booking) => {
            const { listingId, range, expiresAt } = booking;
            const cacheKey = `${listingId}${range.bookingStart}${range.bookingEnd}`;

            if (!cache.has(cacheKey) && expiresAt) {
                cache.set(cacheKey, 'scheduled');
                const d1 = new Date();
                const d2 = new Date(expiresAt);
                const msUntilExpire = differenceInMilliseconds(d2, d1);

                const handleRemove = () => {
                    dispatch(removeFromOngoing({ listingId }));
                    cache.delete(cacheKey);
                };

                if (msUntilExpire > 0) {
                    // Booking will expire in msUntilExpire milliseconds, set a delayed removal
                    setTimeout(handleRemove, msUntilExpire);
                } else {
                    // If already expired, remove right away
                    handleRemove();
                }
            }
        });
    }, [bookings, dispatch]);
};
