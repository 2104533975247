import React from 'react';
import { Skeleton } from '@mui/material';
import { CategorySkeleton } from '../../animations/components/CategorySkeleton';
import { useBreakpoint } from '../../hooks/useBreakpoint';

export const HomeViewSkeleton: React.FC = () => {
    const isDesktop = useBreakpoint('sm');

    return (
        <>
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 16px)', height: isDesktop ? '380px' : '335px', margin: '0 8px' }}
                mainCardStyle={{ borderRadius: '10px' }}
                variant="promoted"
            />
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 16px)', height: '135px', margin: '0 8px', marginTop: '15px' }}
                mainCardStyle={{ borderRadius: '15px' }}
                variant="category"
            />
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 16px)%', height: '135px', margin: '0 8px', marginTop: '15px' }}
                mainCardStyle={{ borderRadius: '15px' }}
                variant="category"
            />
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 16px)%', height: '135px', margin: '0 8px', marginTop: '15px' }}
                mainCardStyle={{ borderRadius: '15px' }}
                variant="category"
            />
        </>
    );
};
