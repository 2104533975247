import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { transactionsApiClient } from '../../services/sharetribe/apiClients';
import { TransactionHistory, Transaction, OwnTransaction } from '../apiTypes';

/**
 * Queries the last transaction for a specific listing.
 * Sharetribe returns the transaction history items in descending order sorted by createdAt
 */
export const useTransaction = (transactionId: string | undefined): UseQueryResult<OwnTransaction> => {
    const getTransaction = async () => {
        if (!transactionId) {
            return null;
        }
        const url = `/${transactionId}`;
        const { data } = await transactionsApiClient.get<OwnTransaction>(url);

        return data;
    };

    return useQuery(['transaction', { transactionId }], getTransaction, {
        staleTime: 2 * 60 * 1000,
    });
};
