import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { makeQueryString } from '../helpers/urlHelpers';
import { listingsApiClient } from '../services/sharetribe/apiClients';
import { Timeslot } from '../views/ViewListing/ListingFooter.helpers';
import { maxTimeslotRangeDays } from '../constants';
import { calculateOfficeClosedDays, useOfficeConfiguration } from './useDeliveryTiming';

// Lender picks the item up from the office the day after the rental ends.
export const LENDER_PICKUP_BUFFER_DAYS = 1;

export const useTimeslots = (listingId: string) => {
    const queryKey = ['timeslots', { listingId }];
    const { data: officeConfiguration } = useOfficeConfiguration();

    const queryTimeslots = async () => {
        const today = startOfDay(new Date());
        const startDate = today.toISOString();
        const endDate = addDays(today, maxTimeslotRangeDays).toISOString();
        const queryStr = makeQueryString({ startDate, endDate });
        const url = `/timeslots/${listingId}?${queryStr}`;
        const { data } = await listingsApiClient.get<Timeslot[]>(url);

        return data;
    };

    return useQuery(queryKey, queryTimeslots, { enabled: !!officeConfiguration });
};
