import { Box, Typography } from '@mui/material';
import { useOfficeConfigurationMethods } from '../../queries/useDeliveryTiming';
import { Trans, useTranslation } from 'react-i18next';
import { ContentLoader } from '../ContentLoader/ContentLoader';

export const ShowroomDetails: React.FC = () => {
    const { t } = useTranslation();
    const { status, getAvailabilityHoursString } = useOfficeConfigurationMethods();

    return (
        <ContentLoader status={status}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {t('showroomDetails')}
                </Typography>

                <Typography variant="body1">
                    <Trans i18nKey="showroomLocation" components={{ b: <b /> }} />
                </Typography>

                <Typography variant="body1">
                    <Trans i18nKey="showroomOpeningHours" components={{ b: <b /> }} values={{ openingHours: getAvailabilityHoursString() }} />
                </Typography>
            </Box>
        </ContentLoader>
    );
};
