import React, { useMemo } from 'react';
import { List, ListItem, Typography, ListItemSecondaryAction, ListItemText, Box } from '@mui/material';
import { formatPrice } from '../../helpers/commonHelpers';
import { LineItem, Money } from '../../transactions/types';
import { useTranslation } from 'react-i18next';
import { calculateTotalFromLineItems } from '../../helpers/currency';

const ReversalStrikeThrough: React.FC = () => {
    return <Box sx={{ position: 'absolute', top: '50%', left: 0, borderBottom: '1px solid #aeacac', width: '100%' }} />;
};

interface LineItemsProps {
    lineItems: LineItem[];
}

export const LineItems: React.FC<LineItemsProps> = ({ lineItems }) => {
    const { t } = useTranslation();

    const total = calculateTotalFromLineItems(lineItems);

    const getQuantityNum = (quantity: LineItem['quantity']) => {
        if (typeof quantity === 'number') {
            return quantity;
        }

        return parseInt(quantity.value, 10);
    };

    const lineItemHasReversal = (lineItem: LineItem) => !!lineItems.find((item) => item.code === lineItem.code && item.reversal);

    return (
        <List sx={{ width: '100%' }}>
            {lineItems
                .filter((item) => !item.reversal)
                .map((lineItem) => (
                    <React.Fragment key={`${lineItem.code}-reversal=${lineItem.reversal}`}>
                        {lineItem.code === 'line-item/day' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">
                                    {formatPrice({
                                        amount: lineItem.unitPrice.amount,
                                        currency: total.currency,
                                    })}
                                    &nbsp; x {getQuantityNum(lineItem.quantity)} {lineItem.quantity === 1 ? t('day') : t('days')}
                                </Typography>
                                <ListItemSecondaryAction>
                                    {formatPrice({
                                        amount: lineItem.unitPrice.amount * getQuantityNum(lineItem.quantity),
                                        currency: total.currency,
                                    })}
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                        {lineItem.code === 'line-item/wolt-delivery' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">{t('woltDeliveryFee')}</Typography>
                                <ListItemSecondaryAction>{formatPrice(lineItem.unitPrice as Money)}</ListItemSecondaryAction>
                            </ListItem>
                        )}
                        {lineItem.code === 'line-item/provider-commission' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">{t('serviceFee')}</Typography>
                                <ListItemSecondaryAction>{formatPrice(lineItem.lineTotal as Money)}</ListItemSecondaryAction>
                            </ListItem>
                        )}

                        {lineItem.code === 'line-item/weekly-discount' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">{t('weeklyDiscount')}</Typography>
                                <ListItemSecondaryAction>{formatPrice(lineItem.lineTotal as Money)}</ListItemSecondaryAction>
                            </ListItem>
                        )}
                        {lineItem.code === 'line-item/monthly-discount' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">{t('monthlyDiscount')}</Typography>
                                <ListItemSecondaryAction>{formatPrice(lineItem.lineTotal as Money)}</ListItemSecondaryAction>
                            </ListItem>
                        )}
                        {lineItem.code === 'line-item/robes-credits' && (
                            <ListItem disableGutters>
                                {lineItemHasReversal(lineItem) && <ReversalStrikeThrough />}
                                <Typography variant="body1">{t('robesCredits')}</Typography>
                                <ListItemSecondaryAction>{formatPrice(lineItem.unitPrice as Money)}</ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </React.Fragment>
                ))}

            <ListItem disableGutters>
                <ListItemText>
                    <b>
                        {t('total')} ({total.currency})
                    </b>
                </ListItemText>
                <ListItemSecondaryAction>
                    <b>{formatPrice(total)} </b>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};
