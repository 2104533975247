import { Box, IconButton, styled } from '@mui/material';

export const StepContent = styled(Box)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    '& .MuiTypography-root': { fontFamily: 'Inter Tight' },
}));

export const StepContentContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    textAlign: 'center',
}));

export const StepBackButton = styled(IconButton)(() => ({
    position: 'absolute',
    opacity: 0.7,
    top: '5px',
    left: '5px',
}));
