import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Modal } from '../../components/Modal/Modal';
import { useDeleteAccount } from '../../hooks/auth/useDeleteAccount';
import { IconTextField } from '../../components/IconTextField/IconTextField';
import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material';
import { useRequestResetPassword } from '../../hooks/auth/useRequestResetPassword';
import { useCurrentUser } from '../../user/hooks/useUser';
import { assertIsDefined, sleep } from '../../helpers/commonHelpers';
import { Preferences } from '@capacitor/preferences';
import { motion } from 'framer-motion';
import { addHours } from 'date-fns';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInOut, fadeUp } from '../../animations/constants';
import { useLocation } from 'react-router-dom';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import useLocationParams from '../../hooks/useLocationParams';

export const AccountDelete: React.FC = () => {
    const [passwordInput, setPasswordInput] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [requestedPasswordReset, setRequestedPasswordReset] = useState(false);

    const { data: user } = useCurrentUser();

    const { returned } = useLocationParams();
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    const { mutate: requestPasswordReset, isLoading: isRequestingPasswordReset } = useRequestResetPassword(() => setRequestedPasswordReset(true));
    const { mutate: deleteAccount, isLoading: isDeletingAccount } = useDeleteAccount(() => setModalOpen(false));

    useEffect(() => {
        if (returned) {
            navigate('/profile/delete-account', { replace: true });
            setModalOpen(true);
        }
    }, [returned]);

    const handleClickDelete = () => {
        deleteAccount(passwordInput);
    };

    const handleRequestPasswordReset = async () => {
        assertIsDefined(user, 'User is not defined');

        const payload = {
            value: true,
            expiresAt: addHours(new Date(), 1).getTime(),
        };

        await Preferences.set({ key: 'accountDeletionStatus', value: JSON.stringify(payload) });

        requestPasswordReset(user.email);
    };

    const handleClose = () => {
        setModalOpen(false);
        setShowPassword(false);
        setRequestedPasswordReset(false);
        setPasswordInput('');
    };

    return (
        <Box sx={{ p: 2, gap: 2, paddingBottom: '100px' }}>
            <Typography variant="body1">{t('requestDeletionBodyTitle')}</Typography>

            <ul style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Trans i18nKey="requestDeletionBody" components={{ listItem: <li /> }} />
            </ul>

            <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                <Trans i18nKey="permanentAction" components={{ strong: <b /> }} />
            </Typography>

            <Button variant="contained" fullWidth color="error" onClick={() => setModalOpen(true)}>
                {t('deleteAccount')}
            </Button>

            <Modal open={modalOpen} variant="auto" onClose={handleClose} modalStyle={{ maxWidth: '450px' }}>
                <motion.div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '8px 8px 0 8px', position: 'relative' }}>
                    <DialogTitle>{t('deleteAccount')}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" sx={{ my: 1 }}>
                            {t('enterCurrentPassword')}
                        </Typography>
                        <IconTextField
                            iconName="key"
                            label="currentPassword"
                            variant="outlined"
                            type={showPassword ? 'search' : 'password'}
                            onChange={setPasswordInput}
                            FieldInputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {requestedPasswordReset ? (
                            <AnimatedContainer key="requested-password-reset" variants={fadeUp}>
                                <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline', p: 0, mb: 2 }}>
                                    {t('resendNewPasswordLink')}
                                </Button>
                                <Typography variant="body2">{t('newPasswordLinkSent')}</Typography>
                            </AnimatedContainer>
                        ) : (
                            <AnimatedContainer key="request-password-reset" variants={fadeInOut}>
                                <Accordion elevation={0} sx={{ '&:before': { display: 'none' } }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{
                                            p: 0,
                                            alignItems: 'flex-start',
                                            '.MuiAccordionSummary-expandIconWrapper': { margin: '12px 0' },
                                            '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': { margin: '20px 0' },
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {t('iHaveNoRobesPassword')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '8px 0 16px' }}>
                                        <Typography variant="body2">{t('iHaveNoRobesPasswordDescription')}</Typography>
                                        <LoadingButton
                                            variant="text"
                                            loading={isRequestingPasswordReset}
                                            sx={{ textTransform: 'none', textDecoration: 'underline', p: 0, mt: 2 }}
                                            onClick={handleRequestPasswordReset}
                                        >
                                            {t('createNewPassword')}
                                        </LoadingButton>
                                    </AccordionDetails>
                                </Accordion>
                            </AnimatedContainer>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>{t('cancel')}</Button>
                        <LoadingButton
                            onClick={handleClickDelete}
                            loading={isDeletingAccount}
                            color="error"
                            disabled={isDeletingAccount || passwordInput?.length < 8}
                            variant="contained"
                        >
                            {t('delete')}
                        </LoadingButton>
                    </DialogActions>
                </motion.div>
            </Modal>
        </Box>
    );
};
