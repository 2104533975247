import { Box, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store/hooks';
import { selectSortBy } from '../../../../store/listingsReducer';
import { sortByOptions } from '../../constants';

interface SortProps {
    value: string;
    onSetSort: (value: string) => void;
}

export const Sort: React.FC<SortProps> = ({ value, onSetSort }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
            <Typography variant="h6">{t('sortBy')}</Typography>

            <Select
                variant="standard"
                disableUnderline
                id="sortby-select"
                value={value}
                onChange={(event) => onSetSort(event.target.value)}
                sx={{ fontSize: 14, fontWeight: '300', zIndex: (theme) => theme.zIndex.drawer + 3 }}
            >
                {sortByOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {t(item.label)}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};
