import React from 'react';
import { Typography, Box } from '@mui/material';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { SuccessSVG } from '../../components/SuccessSvg/SuccessSvg';
import { useTranslation } from 'react-i18next';

import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';

export const IdentityVerificationSuccess: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <StaggerList custom={{ stagger: 0.8 }} style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                <StaggerListElement
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <SuccessSVG variant="xss" />
                </StaggerListElement>

                <StaggerListElement style={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ width: '100%', textAlign: 'center' }}>
                        {t('verificationSuccess')}
                    </Typography>
                </StaggerListElement>
            </StaggerList>
        </Box>
    );
};
