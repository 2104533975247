import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useEffect, useState } from 'react';

export const useAppActiveState = () => {
    const [isActive, setIsActive] = useState<boolean | null>(null);

    useEffect(() => {
        let appStateListener: PluginListenerHandle;

        const addListenerFn = async () => {
            appStateListener = await App.addListener('appStateChange', ({ isActive }) => {
                setIsActive(isActive);
            });
        };

        addListenerFn();

        return () => {
            appStateListener?.remove();
        };
    }, []);

    return isActive;
};
