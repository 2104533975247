import { DependencyList, useMemo } from 'react';
import { noop } from '../helpers/commonHelpers';
import { Listener, useEvent, UseEventOptions } from './useEvent';

export type KeyPredicate = (event: KeyboardEvent) => boolean;
export type KeyFilter = string | KeyPredicate;
export type Handler = (event: KeyboardEvent) => void;
export interface UseKeyOptions<T extends Listener> {
    event?: 'keydown' | 'keypress' | 'keyup';
    target?: T | null;
    options?: UseEventOptions<T>;
}
const createKeyPredicate = (keyFilter: KeyFilter): KeyPredicate =>
    typeof keyFilter === 'function' ? keyFilter : (event: KeyboardEvent) => event.key === keyFilter;

export function useKey<T extends Listener>(key: KeyFilter, fn: Handler = noop, opts: UseKeyOptions<T> = {}, deps: DependencyList = [key]): void {
    const { event = 'keydown', target, options } = opts;
    const evtHandler = useMemo(() => {
        const predicate: KeyPredicate = createKeyPredicate(key);
        const handler: Handler = (evt) => {
            if (predicate(evt)) {
                return fn(evt);
            }
            return noop;
        };
        return handler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    // @ts-ignore
    useEvent(event, evtHandler, target, options);
}
