import React, { useEffect, useRef, useState } from 'react';
import { Button, Fab } from '@mui/material';
import { Crop as CropIcon, Preview } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sendToSentry } from '../../helpers/commonHelpers';
import { ImageCropper } from '../ImageCropper/ImageCropper';
import { StepContentContainer, StepContent } from '../NewUserOnboarding/UserOnboarding.components';

interface ProfilePictureEditor {
    imageUrl: string;
    loading: boolean;
    onCrop: (blob: Blob) => void;
    showActions: () => Promise<void>;
}

export const ProfilePictureEditor: React.FC<ProfilePictureEditor> = ({ imageUrl, loading, onCrop, showActions }) => {
    const [preview, setPreview] = useState(false);

    const cropperRef = useRef<{ getCroppedImageBlob: () => Promise<Blob | null | undefined> }>(null);

    const { t } = useTranslation();

    const handleConfirm = () => {
        const update = async () => {
            // Fetch the cropped image from canvas
            const handleImageError = (message: string) => {
                const toastMsg = t('imageDownloadError');
                toast.error(toastMsg);

                sendToSentry(new Error(message), { type: 'client', message });
            };

            if (!cropperRef.current) {
                handleImageError('No canvas element found');

                return;
            }

            cropperRef.current
                .getCroppedImageBlob()
                .then((blob) => {
                    if (!blob) {
                        handleImageError('No image generated');
                    } else {
                        onCrop(blob);
                    }
                })
                .catch((err: string) => {
                    handleImageError(`Error generating image from canvas: ${err}`);
                });
        };

        update();
    };

    useEffect(() => {
        if (imageUrl) {
            setPreview(false);
        }
    }, [imageUrl]);

    return (
        <StepContent sx={{ px: 2 }}>
            <StepContentContainer style={{ position: 'relative' }}>
                <>
                    <ImageCropper ref={cropperRef} src={imageUrl} preview={preview} maxHeight={300} />

                    <motion.div layout style={{ position: 'absolute', bottom: '50px' }}>
                        {preview ? (
                            <Fab variant="extended" onClick={() => setPreview(!preview)} size="small">
                                <motion.div layout="position" style={{ display: 'flex', alignItems: 'center' }}>
                                    <CropIcon sx={{ mr: 1 }} />
                                    <>{t('crop')}</>
                                </motion.div>
                            </Fab>
                        ) : (
                            <Fab variant="extended" onClick={() => setPreview(!preview)} size="small">
                                <motion.div layout="position" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Preview sx={{ mr: 1 }} />
                                    <>{t('preview')}</>
                                </motion.div>
                            </Fab>
                        )}
                    </motion.div>
                </>
            </StepContentContainer>

            <>
                <LoadingButton variant="contained" onClick={handleConfirm} loading={loading}>
                    {t('confirm')}
                </LoadingButton>
                <Button variant="text" onClick={showActions}>
                    {t('changePicture')}
                </Button>
            </>
        </StepContent>
    );
};
