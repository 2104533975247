import { Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { useTranslation } from 'react-i18next';

const sliderWidth = 60;

interface DragSliderProps {
    containerWidth: number;
    onAccept: () => void;
    setAccepted: (accepted: boolean) => void;
    accepted: boolean;
}

const DragSlider: React.FC<DragSliderProps> = ({ containerWidth, accepted, onAccept, setAccepted }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const x = useMotionValue(0);

    useEffect(() => {
        if (!accepted && x.get() > 0) {
            animate(x, 0);
        }
    }, [accepted, x]);

    const xInputPath = [containerWidth * 0.8, containerWidth];
    const xInputColor = [containerWidth * 0.4, containerWidth];
    const lightMode = theme.palette.mode === 'light';
    const backgroundColor = lightMode ? 'rgb(255, 255, 255)' : grey[600];
    const strokeColor = lightMode ? 'rgb(255, 255, 255)' : grey[800];
    const tickPath = useTransform(x, xInputPath, [0, 1]);
    const color = useTransform(x, xInputColor, [backgroundColor, 'rgb(3, 209, 0)']);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ width: '100%', height: '100%' }}>
            <motion.div
                style={{
                    height: '40px',
                    width: '100%',
                    position: 'absolute',
                    top: 'calc(50% - 40px / 2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'none',
                    background: 'transparent',
                }}
                animate={{ opacity: [0.3, 1, 0.3] }}
                transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}
            >
                <Typography variant="body1">{t('swipeToProceed')}</Typography>
            </motion.div>
            <motion.div
                style={{
                    x,
                    background: color,
                    borderRadius: '50%',
                    width: sliderWidth,
                    boxShadow: '0 5px 10px rgb(0 0 0 / 15%)',
                    height: sliderWidth,
                    position: 'absolute',
                    top: `calc(50% - ${sliderWidth}px / 2)`,
                    left: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(accepted && { pointerEvents: 'none' }),
                }}
                drag="x"
                dragElastic={0.1}
                draggable={!accepted}
                dragSnapToOrigin={!accepted}
                onDrag={(_event, info) => {
                    const constraintX = containerWidth;
                    const { x: offsetX } = info.offset;

                    const isAccepted = offsetX > constraintX;

                    setAccepted(isAccepted);
                }}
                onDragEnd={() => {
                    if (accepted) {
                        onAccept();
                    }
                }}
                dragConstraints={{ left: 0, right: containerWidth }}
                dragTransition={{ power: 0.1 }}
            >
                <svg style={{ width: '80%', height: '80%' }} viewBox="0 0 50 50">
                    <motion.path
                        fill="none"
                        strokeWidth="2"
                        stroke={strokeColor}
                        d="M14,26 L 22,33 L 35,16"
                        strokeDasharray="0 1"
                        style={{ pathLength: tickPath }}
                    />
                </svg>
            </motion.div>
        </motion.div>
    );
};

interface DragAcceptProps {
    accepted: boolean;
    onAccept: () => void;
    setAccepted: (val: boolean) => void;
}

/**
 * Drag component that calls the provided callback when the slider is fully dragged to the end of its container
 */
export const DragAccept: ReactFCWithChildren<DragAcceptProps> = ({ onAccept, accepted, setAccepted }) => {
    const theme = useTheme();
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        new ResizeObserver(() => {
            const target = document.getElementById('drag-container');
            if (target) {
                setContainerWidth(target.clientWidth - sliderWidth);
            }
        }).observe(document.body);
    }, []);

    return (
        <motion.div style={{ width: '100%', height: '60px' }}>
            <motion.div
                id="drag-container"
                key={containerWidth}
                ref={(el) => {
                    if (el) {
                        setContainerWidth(el.clientWidth - sliderWidth);
                    }
                }}
                style={{
                    position: 'relative',
                    background: theme.palette.mode === 'light' ? grey[100] : grey[800],
                    width: '100%',
                    height: '100%',
                    borderRadius: '50px',
                }}
            >
                {containerWidth && <DragSlider containerWidth={containerWidth} onAccept={onAccept} setAccepted={setAccepted} accepted={accepted} />}
            </motion.div>
        </motion.div>
    );
};
