import { matchPath } from 'react-router-dom';
import { RouteConfiguration } from './types';
import { pathConfig } from './pathConfig';

const defaultConfig = {
    label: '',
    animationOrder: 100,
};

export const getPathConfig = (pattern: string | undefined): RouteConfiguration => {
    if (!pattern) {
        return defaultConfig;
    }
    const match = pathConfig.find((config) => matchPath(config.path, pattern));

    return match?.configuration || defaultConfig;
};

export const resolveConfigValue = (val: boolean | { mobile: boolean; desktop: boolean } | undefined, isDesktop: boolean) => {
    if (!val) {
        return null;
    }

    if (typeof val === 'boolean') {
        return val;
    }

    return isDesktop ? val.desktop : val.mobile;
};

export const isTopbarHidden = (isDesktop: boolean, topbarSetting: undefined | true | { mobile: boolean; desktop: boolean }) => {
    if (!topbarSetting) {
        return undefined;
    }

    if (typeof topbarSetting === 'object') {
        return isDesktop ? topbarSetting.desktop : topbarSetting.mobile;
    }

    return topbarSetting;
};
