import { Link, Typography } from '@mui/material';
import AppleLogo from '../../../assets/svgs/Apple.svg';
import GoogleLogo from '../../../assets/svgs/Google.svg';
import { EmailOutlined } from '@mui/icons-material';
import { StyledLoginButton, TextLoginButton } from './LoginButtons.components';
import { GoogleLoginButtonProps, AppleLoginButtonProps, LoginButtonProps, EmailLoginButtonProps, SkipLoginButtonProps } from './LoginButtons.types';
import { useTranslation } from 'react-i18next';

export const LoginButton: React.FC<LoginButtonProps> = ({ logo, label, onClick, href }) => (
    <StyledLoginButton onClick={onClick} {...(href && { LinkComponent: Link, href })}>
        {logo}
        <Typography variant="body2">{label}</Typography>
    </StyledLoginButton>
);

export const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return <LoginButton onClick={onClick} logo={<img src={GoogleLogo} height={56} style={{ padding: '18px' }} />} label={t('continueWithGoogle')} />;
};

export const AppleLoginButton: React.FC<AppleLoginButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return <LoginButton onClick={onClick} logo={<img src={AppleLogo} height={56} />} label={t('continueWithApple')} />;
};

export const EmailLoginButton: React.FC<EmailLoginButtonProps> = ({ href }) => {
    const { t } = useTranslation();
    return (
        <LoginButton
            href={href}
            logo={<EmailOutlined sx={{ height: '56px', fontSize: '20px', marginLeft: '18px' }} />}
            label={t('continueWithEmail')}
        />
    );
};

export const SkipLoginButton: React.FC<SkipLoginButtonProps> = ({ href }) => {
    const { t } = useTranslation();

    return (
        <TextLoginButton
            // @ts-ignore
            href={href}
            LinkComponent={Link}
        >
            <Typography sx={{ fontSize: '0.75rem' }}>{t('continueWithoutSignUp')}</Typography>
        </TextLoginButton>
    );
};
