import axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import { store } from '../../store/store';
import { Capacitor } from '@capacitor/core';
import { getQueryClientInstance } from '../../queryClient';
import { User } from '../../types/apiTypes';

const createInstance = (url: string, serviceUrl = '/api/', withCredentials = false) => {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}${serviceUrl}${url}`,
        headers: {
            Accept: 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY || '',
            'accept-version': process.env.REACT_APP_VERSION || '',
            'x-platform': Capacitor.getPlatform(),
        },
        timeout: 10000,
        ...(withCredentials && { withCredentials: true }),
    });
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const { token } = store.getState().user;
        const user = getQueryClientInstance().getQueryData<User>(['current-user']);
        const language = i18next.language || 'en';

        if (config && config.headers) {
            if (token) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = token;
            }
            if (user) {
                config.headers['x-user-id'] = user.id;
                config.headers['x-user-email'] = user.email;
            }
            // eslint-disable-next-line no-param-reassign
            config.headers['app-language'] = language;
        }

        return config;
    });

    return instance;
};

export const userApiClient = createInstance('user');
export const authApiClient = createInstance('auth');
export const imageApiClient = createInstance('image');
export const listingsApiClient = createInstance('listings');
export const transactionsApiClient = createInstance('transactions');
export const conversationApiClient = createInstance('conversations');
export const messageApiClient = createInstance('messages');
export const tagsApiClient = createInstance('tags');
export const notificationsApiClient = createInstance('notifications');
export const identifyApiClient = createInstance('identification');
export const strapiApiClient = createInstance('strapi');
export const deliveryApiClient = createInstance('delivery');
export const searchApiClient = createInstance('search');
export const stripeApiClient = createInstance('stripe');
export const passwordResetApiClient = createInstance('password-reset');
export const minimumVersionApiClient = createInstance('minimum-version');
export const creditsApiClient = createInstance('credits');
export const referralsApiClient = createInstance('referrals');
