import { TransitionName } from '../../../transactions/constants';

export const transactionFilters = [
    TransitionName.COMPLETE,
    TransitionName.CONFIRM_PAYMENT,
    TransitionName.ACCEPT,
    TransitionName.DECLINE,
    TransitionName.CANCEL,
    TransitionName.CANCEL_NO_REFUND,
    TransitionName.CUSTOMER_CANCEL,
    TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT,
    TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT,
    TransitionName.OPERATOR_DECLINE,
    TransitionName.OPERATOR_DECLINE_NO_REFUND,
];
export const transactionFilterMenuItems = transactionFilters.map((el) => ({ value: el, label: el }));
