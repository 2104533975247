import { Tabs } from '@mui/material';
import Card from '@mui/material/Card';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const ProfileSectionContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '16px',
    textAlign: 'left',
}));

export const ProfileNameText = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 'bold',
}));

export const ProfileDescriptionText = styled(Typography)(() => ({
    marginTop: '8px',
    fontSize: '14px',
    maxHeight: '100px',
    overflowY: 'auto',
}));

export const EmptyListing = styled(Card)(({ theme }) => ({
    width: '100%',
    height: 'calc((100vw - 64px) / 3)',
    borderRadius: '5px',
    backgroundColor: theme.palette.wardrobe.emptyListing,
}));

export const ProfileViewContainer = styled('div')(({ theme }) => ({
    overflowY: 'auto',
    position: 'relative',
    height: '100%',
    width: '100%',
    paddingTop: '16px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
}));

export const ProfileViewCard = styled('div')(() => ({
    overflow: 'visible',
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    zIndex: 99,
    width: '100%',
    background: theme.palette.background.paper,
}));

export const OverlayContainer = styled('div')(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    top: '0',
    pointerEvents: 'none',
    background: '#3838389e',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));
