import { rem } from '@mantine/core';
import { styled } from '@mui/material';

export const CardSummary = styled('div')(() => ({
    height: '110px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

export const CardSummaryDetails = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    gap: '4px',
    marginLeft: '15px',
}));

export const CardAvatarContainer = styled('div')(() => ({
    width: rem(110),
    height: rem(110),
}));

export const CardTitle = styled('div')(() => ({
    marginBottom: '12px',
}));

export const CardPriceTotal = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 0 0',
}));

export const NextStep = styled('div')(() => ({}));
