import { useTranslation } from 'react-i18next';
import { Collection } from './Collection/Collection';
import { CategoryCarousel as StrapiCategoryCarousel } from '../types';
import { dispatchCustomEvent } from '../../store/listeners';
import { resolveSlideSettings } from '../helpers';
import { isDesktop } from 'react-device-detect';
import { analytics } from '../../analytics/events/helpers';

interface CategoryCarouselProps {
    component: StrapiCategoryCarousel;
    isDesktop?: boolean;
}

export const CategoryCarousel: React.FC<CategoryCarouselProps> = ({ component }) => {
    const { title, categories } = component;
    const { t } = useTranslation();

    const data = categories.map((category) => ({
        id: category.id,
        image: category.image?.data.attributes.formats.medium.url || '',
        blurhash: category.image?.data.attributes.blurhash,
        label: t(category.category),
    }));

    const handleClickCategory = (idx: number) => {
        const value = categories[idx].category;

        if (value) {
            analytics.homePageAction('category_click', { category: value });
            dispatchCustomEvent('replaceFilterAndNavigate', { type: 'category', payload: [value] });
        }
    };

    const slideSettings = resolveSlideSettings(isDesktop, component);

    return <Collection title={title} data={data} slideSettings={slideSettings} onClickItem={handleClickCategory} />;
};
