import Drawer from '@mui/material/Drawer';
import React, { useCallback, useMemo } from 'react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useHashChange } from '../../hooks/useHashChange';
import { ReactFCWithChildren } from '../../types/types';

export interface DrawerProps {
    open: boolean;
    onClose: (...args: any[]) => void;
    fillHeight?: boolean;
    identifier?: string;
}

export const ResponsiveDrawer: ReactFCWithChildren<DrawerProps> = ({ open, onClose, children, fillHeight }) => {
    const isSmBreakpoint = useBreakpoint('sm');
    const drawerAnchor = isSmBreakpoint ? 'right' : 'bottom';

    /**
     * The drawer is closed on back button presses on both mobile and desktop
     */
    useHashChange(open, onClose);

    const handleClose = useCallback(() => {
        window.history.back();
        onClose();
    }, [onClose]);

    const modalStyle = useMemo(() => {
        const baseStyle = {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            backgroundImage: 'none',
            overflowY: 'unset' as const,
        };

        return isSmBreakpoint
            ? {
                  ...baseStyle,
                  maxWidth: '500px',
                  width: '100%',
              }
            : {
                  ...baseStyle,
                  maxHeight: '90%',
                  ...(fillHeight && { height: '100%' }),
              };
    }, [isSmBreakpoint, fillHeight]);

    return (
        <Drawer
            keepMounted
            anchor={drawerAnchor}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: modalStyle,
            }}
        >
            {children}
        </Drawer>
    );
};
