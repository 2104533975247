import { Box, Card } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const SignUpLayout = styled(Card)(({ theme }) => ({
    padding: '40px 20px',
    [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 40px)',
        margin: '20px',
    },
}));

export const ErrorText = styled('p')(() => ({
    fontSize: '12px',
    color: '#a8323a', // TODO: Use Theme
}));

export const TosSpan = styled('span')(() => ({
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
        cursor: 'pointer',
    },
}));
