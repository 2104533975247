import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

export const StyledForgotPasswordContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: '0 20px',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const PasswordResetErrorText = styled('p')(() => ({
    height: '20px',
    display: 'flex',
    color: 'red',
    justifyContent: 'center',
    alignItems: 'center',
}));
