import React from 'react';
import { User } from '../../types/apiTypes';
import { ProfileContainer, ProfileImageContainer } from './UserProfile.components';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactFCWithChildren } from '../../types/types';
import { AnimatedOutlet } from '../../animations/components/AnimatedOutlet';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Outlet } from 'react-router-dom';
import { AddProfilePicture } from '../../components/AddProfilePicture/AddProfilePicture';

interface UserProfileProps {
    user: User;
}

export const UserInfo: ReactFCWithChildren<UserProfileProps> = ({ user }) => {
    const { t } = useTranslation();

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <Typography variant="h6" sx={{ textTransform: 'capitalize', padding: '10px', textAlign: 'center' }}>
                {user.profile.displayName} - {t('profile')}
            </Typography>

            <ProfileImageContainer>
                <AddProfilePicture imgUrl={user.profileImage} />
            </ProfileImageContainer>
        </Container>
    );
};

export const UserProfilePage: React.FC = () => {
    const isDesktop = useBreakpoint('sm');

    return <ProfileContainer>{isDesktop ? <Outlet /> : <AnimatedOutlet />}</ProfileContainer>;
};
