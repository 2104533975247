import React from 'react';
import { creditsApiClient } from '../../../services/sharetribe/apiClients';
import { formatMoney } from '../../../helpers/commonHelpers';
import { useCreditsBalance } from '../hooks/useCreditsBalance';
import { Box, Skeleton, Typography } from '@mui/material';
import { Center } from '@mantine/core';
import { defaultCurrency } from '../../../constants';

export const CreditsBalance: React.FC = () => {
    const { data: balance, isLoading } = useCreditsBalance();

    const getFormattedBalance = () => {
        if (!balance) {
            return formatMoney(0, defaultCurrency);
        }

        const totalAvailable = balance.balance - balance.reserved;
        return formatMoney(totalAvailable, balance.currency);
    };

    return (
        <Center>
            {isLoading ? (
                <Skeleton variant="text" height={15} width={50} />
            ) : (
                <Typography variant="body2" fontWeight="bold">
                    {getFormattedBalance()}
                </Typography>
            )}
        </Center>
    );
};
