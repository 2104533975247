import React, { useCallback } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { ImageSlider } from '../../../components/ImageSlider/ImageSlider';
import { ReactFCWithChildren } from '../../../types/types';
import { ImageSliderContainer, StyledTypography } from '../Home.components';
import { PromotionalContentContainer } from './PromotionalContentSlider.components';
import { Card, CardMedia } from '@mui/material';

import { selectIsOnboarding } from '../../../store/appStateReducer';
import { useAppSelector } from '../../../store/hooks';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ContentOverlay } from './ContentOverlay';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { PromotionalContent } from '../../../strapi/types';

interface PromotionalContentProps {
    content: PromotionalContent[] | undefined;
}

export const PromotionalContentSlider: ReactFCWithChildren<PromotionalContentProps> = ({ content }) => {
    const navigate = useSafeNavigate();
    const isOnboarding = useAppSelector(selectIsOnboarding);
    const isDesktop = useBreakpoint('sm');

    const renderOverlay = useCallback(
        (index: number, selectedPage: number) => {
            if (!content || index !== selectedPage) {
                return null;
            }

            const { title, subtitle, articleId, textColor, showLogo } = content[index];

            return (
                <ContentOverlay key={title} title={title} subtitle={subtitle} hasArticle={!!articleId} textColor={textColor} showLogo={showLogo} />
            );
        },
        [content],
    );

    if (!content?.length) {
        return null;
    }

    const { blurhash } = content[0];
    const imageUrls = content
        .sort((a, b) => a.rank - b.rank)
        .map((component, index) => ({ url: component.imageUrl, uuid: `promotion-image-${index}` }));

    const handleClickPromotionalContent = (idx: number) => {
        const { articleId } = content[idx];
        if (!articleId) {
            return;
        }

        navigate(`/article/${articleId}`);
    };

    if (isDesktop) {
        return (
            <Card elevation={4} sx={{ margin: '30px 0 20px', borderRadius: '10px', width: '100%' }}>
                <CardMedia sx={{ height: '380px', width: '100%' }}>
                    <ImageSliderContainer>
                        <ImageSlider
                            hash={blurhash}
                            images={imageUrls}
                            renderOverlay={renderOverlay}
                            onClick={handleClickPromotionalContent}
                            height={380}
                            borderRadius={0}
                            enableAutoplay={!isOnboarding}
                        />
                    </ImageSliderContainer>
                </CardMedia>
            </Card>
        );
    }

    return (
        <PromotionalContentContainer>
            {content && (
                <ImageSliderContainer>
                    <ImageSlider
                        hash={blurhash}
                        images={imageUrls}
                        renderOverlay={renderOverlay}
                        onClick={handleClickPromotionalContent}
                        height={335}
                        borderRadius={0}
                        enableAutoplay={!isOnboarding}
                    />
                </ImageSliderContainer>
            )}
        </PromotionalContentContainer>
    );
};
