import React from 'react';
import { HTMLMotionProps, motion, MotionProps } from 'framer-motion';
import { ReactFCWithChildren } from '../../types/types';
import { containerVariantsStagger, itemVariants } from '../constants';

interface Stagger {
    stagger?: number;
    delay?: number;
}

interface StaggerListProps extends MotionProps {
    custom?: Stagger;
}

export const StaggerList: ReactFCWithChildren<StaggerListProps> = ({ children, custom = { stagger: 0.3, delay: 0.3 }, style }) => (
    <motion.ul
        variants={containerVariantsStagger}
        custom={custom}
        initial="hidden"
        animate="show"
        style={{ marginTop: 0, paddingInlineStart: 0, listStyle: 'none', ...style }}
    >
        {children}
    </motion.ul>
);

export const StaggerListElement: ReactFCWithChildren<MotionProps & HTMLMotionProps<'li'>> = ({ children, ...rest }) => (
    <motion.li variants={itemVariants} {...rest}>
        {children}
    </motion.li>
);
