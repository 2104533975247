import { Box, SxProps } from '@mui/material';
import React from 'react';

interface StepProps {
    children: React.ReactNode;
    sx?: SxProps;
}

export const Step = React.forwardRef<HTMLDivElement, StepProps>(({ children, sx }, ref) => (
    <Box ref={ref} sx={{ width: '100%', height: '100%', ...sx }}>
        {children}
    </Box>
));
