import { useState, useEffect, RefObject, DependencyList } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setIsScrolling } from '../store/listingsReducer';
import { useLocation } from 'react-router-dom';
import { selectCompactMode, selectHideUI } from '../store/settingsReducer';
import { Capacitor } from '@capacitor/core';
import { filterBarHeightPX, filterResultsCountHeightPX, headerHeightPX } from '../constants';

const enabledRoutes = ['/listings'];

interface UseScrollStatusOptions {
    threshold?: number;
    enabled?: boolean;
    dependencies?: DependencyList; // used to reset lastScrollTop. Should only contain properties that affect the layout of the page.
    minimumScroll?: number;
}

const defaultMinimumScroll = headerHeightPX + filterBarHeightPX + filterResultsCountHeightPX;

export const useScrollStatus = (ref: RefObject<HTMLElement> | null, options: UseScrollStatusOptions = {}) => {
    const { threshold = 0, enabled, minimumScroll = defaultMinimumScroll, dependencies = [] } = options;
    const [hasScrolled, setHasScrolled] = useState(false);
    let lastScrollTop = 0;
    let isBouncing = false;
    let bounceTimeout: ReturnType<typeof setTimeout>;

    useEffect(() => {
        const element = ref?.current;
        if (!element || !enabled) return;

        // Reset lastScrollTop when dependencies change.
        lastScrollTop = 0;

        const handleScroll = () => {
            const currentScrollTop = element.scrollTop;

            if (isBouncing || currentScrollTop < minimumScroll) {
                return;
            }

            if (Capacitor.getPlatform() === 'ios') {
                // iOS has a bounce effect when scrolling past the bottom of the page.
                const maxScrollTop = element.scrollHeight - element.clientHeight;

                // It's difficult to determine when the bounce has ended in infinite scrolling containers because the maxScrollTop
                // can change during the bounce. Thus for the time being we will disable the scroll status updating for a set duration.
                if (currentScrollTop > maxScrollTop) {
                    isBouncing = true;
                    clearTimeout(bounceTimeout);
                    bounceTimeout = setTimeout(() => {
                        isBouncing = false;
                    }, 1000);

                    return;
                }
            }

            const isScrollingDown = currentScrollTop > lastScrollTop;
            const meetsThreshold = currentScrollTop - lastScrollTop > threshold;

            if (lastScrollTop && isScrollingDown && meetsThreshold) {
                setHasScrolled(true);
            } else if (currentScrollTop < lastScrollTop) {
                setHasScrolled(false);
            }
            lastScrollTop = currentScrollTop;
        };

        element.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            element.removeEventListener('scroll', handleScroll);
            clearTimeout(bounceTimeout);
        };
    }, [ref, threshold, enabled, dependencies]);

    return hasScrolled;
};

export const useUpdateScrollStatus = (ref: RefObject<HTMLElement> | null, options: UseScrollStatusOptions = {}) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const settingEnabled = useAppSelector(selectHideUI);
    const compactMode = useAppSelector(selectCompactMode);
    const enabled = settingEnabled && enabledRoutes.includes(location.pathname);
    const status = useScrollStatus(ref, { ...options, enabled, dependencies: [compactMode] });

    useEffect(() => {
        if (enabled) {
            dispatch(setIsScrolling(status));
        }

        return () => {
            dispatch(setIsScrolling(false));
        };
    }, [status, dispatch, enabled]);
};
