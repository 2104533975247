import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { StyledForgotPasswordContainer } from './PasswordReset.components';
import useLocationParams from '../../hooks/useLocationParams';
import { useResetPassword } from '../../hooks/auth/useResetPassword';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOutScale } from '../../animations/constants';
import { SuccessSVG } from '../../components/SuccessSvg/SuccessSvg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

interface FormInput {
    newPassword: string;
    confirmPassword: string;
}

export const PasswordResetView: React.FC = () => {
    const { t } = useTranslation();
    const { t: passwordResetToken, e: email } = useLocationParams();
    const navigate = useSafeNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { mutate: resetPassword, isLoading } = useResetPassword(() => setIsSubmitted(true));

    const {
        getValues,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>();

    const handlePasswordRequestSubmit = async (formData: FormInput) => {
        resetPassword({ email, passwordResetToken, newPassword: formData.newPassword });
    };

    const handleContinue = () => {
        navigate('/login');
    };

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <StyledForgotPasswordContainer>
                <motion.div layout>
                    <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />
                </motion.div>

                <form onSubmit={handleSubmit(handlePasswordRequestSubmit)}>
                    <AnimatePresence mode="popLayout">
                        {isSubmitted ? (
                            <AnimatedContainer key="submitted" variants={fadeInOutScale} custom={0.2} style={{ textAlign: 'center' }}>
                                <SuccessSVG variant="xs" />
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    {t('passwordResetSuccess')}
                                </Typography>
                                <Button sx={{ mt: 2 }} variant="text" onClick={handleContinue}>
                                    {t('continue')}
                                </Button>
                            </AnimatedContainer>
                        ) : (
                            <AnimatedContainer key="enter-password" variants={fadeInOutScale} layout>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('newPassword')}
                                            type={showPassword ? 'search' : 'password'}
                                            fullWidth
                                            error={!!errors.newPassword}
                                            helperText={errors.newPassword ? t('passwordError') : undefined}
                                            {...register('newPassword', {
                                                required: true,
                                                minLength: 8,
                                                maxLength: 255,
                                            })}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('confirmNewPassword')}
                                            type={showConfirmPassword ? 'search' : 'password'}
                                            error={!!errors.confirmPassword}
                                            helperText={errors.confirmPassword ? t('confirmPasswordError') : undefined}
                                            fullWidth
                                            {...register('confirmPassword', {
                                                validate: (c) => c === getValues()['newPassword'],
                                            })}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        >
                                                            {showConfirmPassword ? (
                                                                <Visibility fontSize="small" />
                                                            ) : (
                                                                <VisibilityOff fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton type="submit" fullWidth sx={{ mt: 3 }} variant="contained" loading={isLoading}>
                                            {t('submit')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </AnimatedContainer>
                        )}
                    </AnimatePresence>
                </form>
            </StyledForgotPasswordContainer>
        </Box>
    );
};
