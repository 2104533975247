import { Carousel, CarouselStylesNames, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { Modal, ModalBaseStylesNames, Styles, rem } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useHashChange } from '../../hooks/useHashChange';
import { ImageWithGestures } from '../FullScreenImage/FullScreenImage';

const HEADER_HEIGHT = 42;

const modalStyles: Styles<ModalBaseStylesNames> = {
    body: {
        height: `calc(100% - ${HEADER_HEIGHT * 2}px) `,
        padding: 0,
    },
    header: {
        padding: '0px 10px',
        height: `${HEADER_HEIGHT}px`,
        background: 'transparent',
    },
    close: {
        '&:hover': {
            background: '#2c2b2b',
        },
    },
    overlay: {
        zIndex: 1399,
    },
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inner: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1400,
        alignItems: 'center !important',
    },
    content: {
        backgroundColor: 'transparent',
        height: '100% !important',
    },
};

const carouselStyles: Styles<CarouselStylesNames> = {
    root: {
        height: '100%',
    },
    indicators: { bottom: '-1rem', alignItems: 'center' },

    indicator: {
        width: rem(4),
        height: rem(4),
        zIndex: 10,
        transition: 'all 250ms ease',

        '&[data-active]': {
            width: rem(6),
            height: rem(6),
        },
    },
};

interface ImageOverlayProps {
    opened: boolean;
    images: string[];
    initialSlide?: number;
    onClose: () => void;
}

const getCarouselConfig = (desktop: boolean, hasMultipleImages: boolean) => {
    if (desktop) {
        return {
            loop: false,
            draggable: false,
            withIndicators: hasMultipleImages,
            slideSize: '50%',
            align: 'center' as const,
            withControls: hasMultipleImages,
        };
    }

    return {
        loop: true,
        draggable: hasMultipleImages,
        withIndicators: hasMultipleImages,
        align: 'center' as const,
        withControls: false,
    };
};

export const ImageOverlay: React.FC<ImageOverlayProps> = ({ opened, images, onClose, initialSlide = 0 }) => {
    const [dragDisabled, setDragDisabled] = useState(false);
    const [imageScale, setImageScale] = useState(1);
    const [embla, setEmbla] = useState<Embla | null>(null);

    const isDesktop = useBreakpoint('sm');
    const hasMultipleImages = images.length > 1;

    const shouldAnimate = initialSlide === 0 && !isDesktop;
    const TRANSITION_DURATION = shouldAnimate ? 300 : 0;

    useAnimationOffsetEffect(embla, TRANSITION_DURATION);

    const handleClose = () => {
        window.history.back();
        onClose();
    };

    // If image is zoomed in, disable dragging
    useEffect(() => {
        if (embla) {
            const shouldDisableDrag = imageScale > 1 && !dragDisabled;
            const shouldEnableDrag = imageScale === 1 && dragDisabled;

            if (shouldDisableDrag) {
                embla.reInit({ draggable: false });
                setDragDisabled(true);
            } else if (shouldEnableDrag) {
                embla.reInit({ draggable: true });
                setDragDisabled(false);
            }
        }
    }, [embla, imageScale, dragDisabled]);

    useHashChange(opened, onClose);

    return (
        <Modal
            opened={opened}
            fullScreen
            onClose={handleClose}
            transitionProps={{ duration: TRANSITION_DURATION }}
            padding={0}
            withCloseButton={false}
            zIndex={10000}
            overlayProps={{ bg: 'black' }}
            style={{ display: 'flex', height: '100%', position: 'relative' }}
            styles={modalStyles}
        >
            <div style={{ height: HEADER_HEIGHT, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Modal.CloseButton tabIndex={-1} size={'md'} sx={{ margin: '50px 15px', zIndex: 10 }} />
            </div>
            <Carousel
                getEmblaApi={setEmbla}
                height="100%"
                slideGap={5}
                initialSlide={initialSlide}
                sx={{ flex: 1 }}
                styles={carouselStyles}
                {...getCarouselConfig(isDesktop, hasMultipleImages)}
            >
                {images.map((url) => (
                    <Carousel.Slide
                        key={url}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(evt) => {
                            if ((evt.target as HTMLElement).tagName !== 'IMG') {
                                onClose();
                            }
                        }}
                    >
                        <ImageWithGestures
                            src={url}
                            onScaleChange={setImageScale}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '90%',
                            }}
                        />
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Modal>
    );
};
