import React, { useState, ChangeEvent, useContext } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Send from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSendMessage } from '../../messages/hooks/useSendMessage';
import { useSocket } from '../../context/socket';
import { Message } from '../../messages/apiTypes';
import { Checkbox, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ExtendedListing } from '../../types/apiTypes';
import { assertIsDefined } from '../../helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { ChatInformation } from '../../messages/hooks/useChat';

interface ChatInputFieldProps {
    userId: string;
    chatInfo: ChatInformation;
    includeListingData: boolean;
    showListingSuggestion: boolean;
    listing: ExtendedListing | null | undefined;
    onToggle: () => void;
}

export const ChatInputField = React.forwardRef<HTMLDivElement, ChatInputFieldProps>(
    ({ userId, chatInfo, listing, includeListingData, showListingSuggestion, onToggle }, ref) => {
        const { conversationId, participantId, disabled } = chatInfo;
        const [content, setContent] = useState('');
        const { emit } = useSocket();
        const { t } = useTranslation();

        const emitMessage = (message: Message): void => emit('message', JSON.stringify(message));

        const { mutate: sendMessage, isLoading: sendingMessage } = useSendMessage(emitMessage, conversationId);

        const handleSendMessage = (ev?: { preventDefault: () => void }) => {
            ev?.preventDefault();
            if (content && !sendingMessage && conversationId) {
                const getPayload = () => {
                    const payload = {
                        participantId,
                        conversationId,
                        senderId: userId,
                        content,
                        createdAt: new Date().toISOString(),
                    };

                    if (!includeListingData || !showListingSuggestion) {
                        return payload;
                    }

                    assertIsDefined(listing, 'Trying to include listing in the message, but listing is not defined');

                    const listingData = { id: listing.id, imageUrl: listing.images[0].url, title: listing.title };
                    return { ...payload, listingData };
                };

                sendMessage(getPayload());
                setContent('');
            }
        };
        const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setContent(event.currentTarget.value);

        return (
            <Box
                component="form"
                onSubmit={handleSendMessage}
                ref={ref}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    padding: '10px',
                    paddingBottom: 'calc(env(safe-area-inset-bottom, 0px) + 10px)',
                    width: '100%',
                    borderTop: '1px solid #bab3b324',
                    background: (t) => t.palette.background.paper,
                }}
            >
                <motion.div layout style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                    <TextField
                        sx={{ ml: 1, flex: 1 }}
                        disabled={disabled}
                        placeholder={disabled ? t('chatDisabledPlaceholder') : t('chatPlaceholder')}
                        multiline
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        maxRows={4}
                        inputProps={{ 'aria-label': 'Type something' }}
                        onChange={handleInputChange}
                        value={content}
                    />

                    <LoadingButton disabled={disabled} color="primary" sx={{ p: '10px' }} aria-label="Send message" onClick={handleSendMessage}>
                        <Send />
                    </LoadingButton>
                </motion.div>

                {showListingSuggestion && (
                    <motion.div
                        layout
                        style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                        initial={{ height: 40, opacity: 1 }}
                        animate={{ height: 40, opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ type: 'spring', stiffness: 80, bounce: 0, damping: 15 }}
                    >
                        <Checkbox checked={includeListingData} onClick={onToggle} />
                        <Typography variant="body2">{t('includeListingInfo')}</Typography>
                    </motion.div>
                )}
            </Box>
        );
    },
);
