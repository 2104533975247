import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectFilters, updateFilters } from '../../../store/listingsReducer';
import { filtersToQueryString } from '../../../helpers/urlHelpers';

const SearchBar = styled(Box)(({ theme }) => ({
    position: 'relative',
    margin: '14px 0px',
    padding: '7px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '20px',
    opacity: 0.8,
    border: `1px solid ${theme.palette.border.light}`,
}));

export const MobileSearchBar: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState(new URLSearchParams(window.location.search).get('keywords') || '');
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const location = useLocation();
    const filters = useAppSelector(selectFilters);

    const { t } = useTranslation();

    const openSearch = () => {
        if (searchTerm) {
            const queryString = filtersToQueryString({ ...filters, keywords: searchTerm });
            navigate(`/search?${queryString}`);
        } else {
            navigate('/search');
        }
    };

    const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        dispatch(updateFilters({ type: 'keywords', payload: '' }));
        setSearchTerm('');
        navigate('/listings', { replace: true });
    };

    useEffect(() => {
        const search = new URLSearchParams(location.search).get('keywords') || '';

        if (search) {
            setSearchTerm(search);
        }

        if (!search && searchTerm) {
            setSearchTerm('');
        }
    }, [location]);

    return (
        <SearchBar onClick={openSearch}>
            <Search />
            <Typography variant="body1" sx={{ opacity: 0.8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {searchTerm ? searchTerm : t('searchRobes')}
            </Typography>

            {searchTerm && (
                <IconButton size="small" sx={{ p: 0, position: 'absolute', right: '10px' }} onClick={handleClear}>
                    <Clear fontSize="small" />
                </IconButton>
            )}
        </SearchBar>
    );
};
