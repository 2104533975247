import { RefObject, useEffect } from 'react';

interface Options {
    target: RefObject<HTMLElement>;
    onIntersect: () => Promise<unknown>;
    enabled: boolean;
}

export const useIsElementInView = (options: Options): void => {
    const { target, onIntersect, enabled } = options;
    useEffect(() => {
        if (!enabled) {
            return () => null;
        }

        const observer = new IntersectionObserver((entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()), {
            rootMargin: '0px',
            threshold: 0,
        });

        const el = target && target.current;

        if (!el) {
            return () => null;
        }

        observer.observe(el);

        return () => {
            observer.unobserve(el);
        };
    }, [enabled, onIntersect, target]);
};
