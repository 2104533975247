import { Box, styled } from '@mui/material';
import { pageMaxWidth } from '../Listings/constants';
import { motion } from 'framer-motion';

export const MobileFooterContainer = styled(motion.div)(({ theme }) => ({
    height: '100px',
    width: '100%',
    maxWidth: pageMaxWidth,
}));

export const DesktopFooterContainer = styled('div')(({ theme }) => ({
    height: '80px',
    bottom: 0,
    width: '100%',
}));

export const ApplePayBtnContainer = styled(Box)(() => ({
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '16px 0',
    width: '100%',
    margin: 'auto',
}));
