import React, { useCallback, useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { emailValidation } from '../../helpers/regex';
import { useRequestResetPassword } from '../../hooks/auth/useRequestResetPassword';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInOut, fadeInScale } from '../../animations/constants';
import { Button, Link } from '@mui/material';
import { StyledForgotPasswordContainer } from './PasswordReset.components';

interface FormInput {
    email: string;
}

export const PasswordResetRequestView = (): JSX.Element => {
    const { t } = useTranslation();

    const [isSubmitted, setSubmitted] = useState(false);
    const handleSetSubmitted = () => setSubmitted(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>();

    const { mutate: submitPasswordResetRequest, isLoading } = useRequestResetPassword(handleSetSubmitted);

    const handlePasswordRequestSubmit: SubmitHandler<FormInput> = async (formData) => {
        submitPasswordResetRequest(formData.email);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <StyledForgotPasswordContainer>
                <motion.div layout>
                    <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />
                </motion.div>

                <AnimatePresence mode="popLayout">
                    {!isSubmitted && (
                        <AnimatedContainer key="submit-request" variants={fadeInOut}>
                            <form onSubmit={handleSubmit(handlePasswordRequestSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} padding={1}>
                                        <Typography variant="body2">
                                            <Trans i18nKey="enterEmailPrompt" components={{ strong: <b /> }} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={!!errors.email}
                                            helperText={errors.email ? t('emailError') : undefined}
                                            label={t('email')}
                                            fullWidth
                                            {...register('email', { required: true, pattern: emailValidation })}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton variant="contained" type="submit" fullWidth loading={isLoading}>
                                            {t('submit')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </AnimatedContainer>
                    )}

                    {isSubmitted && (
                        <AnimatedContainer variants={fadeInOut} custom={0.2}>
                            <Grid container spacing={2} key="submitted" rowGap={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{t('passwordResetRequestSent')}</Typography>
                                </Grid>
                                <Button fullWidth href="/login/email" variant="text">
                                    {t('back')}
                                </Button>
                            </Grid>
                        </AnimatedContainer>
                    )}
                </AnimatePresence>
            </StyledForgotPasswordContainer>
        </Box>
    );
};
