import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useTranslation } from 'react-i18next';
import image from './404image.svg';
import { Image } from '@mantine/core';

export const NotFoundErrorPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    const handleNavigateBack = () => {
        navigate(-1);
    };

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '100px' }}>
            <Box sx={{ my: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Image src={image} sx={{ maxWidth: '350px' }} />
                </Box>
                <Typography variant="h6" gutterBottom>
                    {t('oopsPageNotFound')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {t('pageNotAvailable')}
                </Typography>
                <Button variant="contained" color="primary" onClick={handleNavigateBack} sx={{ my: 3, px: 4 }}>
                    {t('back')}
                </Button>
            </Box>
        </Container>
    );
};
