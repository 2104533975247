import { useQuery, useQueryClient } from '@tanstack/react-query';
import { transactionsApiClient } from '../services/sharetribe/apiClients';
import { LineItem, Money } from '../transactions/types';

export interface LineItemData {
    lineItems: LineItem[];
    total: Money;
}

interface UseLineItemsParams {
    listingId: string;
    bookingStart: string | undefined;
    bookingEnd: string | undefined;
    deliveryMethod?: string;
    useCredits?: boolean;
}

const getLineItemsData = async ({ listingId, bookingStart, bookingEnd, deliveryMethod, useCredits }: UseLineItemsParams) => {
    if (!bookingStart || !bookingEnd) {
        return null;
    }

    if (bookingStart === bookingEnd) {
        return null;
    }

    const params = new URLSearchParams({
        bookingStart,
        bookingEnd,
        type: 'customer',
        useCredits: useCredits ? 'true' : 'false',
        ...(deliveryMethod && { deliveryMethod }),
    });

    const url = `/line-items/${listingId}?${params.toString()}`;
    const { data } = await transactionsApiClient.get<string, { data: { lineItems: LineItem[]; total: Money; fullCreditPayment: boolean } }>(url);

    return data;
};

export const useLineItems = (params: UseLineItemsParams) => {
    const getLineItems = () => getLineItemsData(params);

    return useQuery(['line-items', params], getLineItems);
};

export const usePrefetchLineItems = (params: UseLineItemsParams) => {
    const queryClient = useQueryClient();

    const getLineItems = () => getLineItemsData(params);
    const prefetchFn = async () => await queryClient.prefetchQuery({ queryKey: ['line-items', params], queryFn: getLineItems });

    return prefetchFn;
};
