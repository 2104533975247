/* eslint-disable max-len */
import React from 'react';
import { Box } from '@mui/material';
import parse from 'html-react-parser';

const policy = `
<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/><title>Updated privacy policy 4.11.2023 - (for mobile app launch)</title><style>
/* cspell:disable-file */
/* webkit printing magic: print all background colors */
html {
	-webkit-print-color-adjust: exact;
}
* {
	box-sizing: border-box;
	-webkit-print-color-adjust: exact;
}

a,
a.visited {
	color: inherit;
	text-decoration: underline;
}

.pdf-relative-link-path {
	font-size: 80%;
	color: #444;
}

h1,
h2,
h3 {
	letter-spacing: -0.01em;
	line-height: 1.2;
	font-weight: 600;
	margin-bottom: 0;
}

.page-title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0.75em;
}

h1 {
	font-size: 1.875rem;
	margin-top: 1.875rem;
}

h2 {
	font-size: 1.5rem;
	margin-top: 1.5rem;
}

h3 {
	font-size: 1.25rem;
	margin-top: 1.25rem;
}

.source {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 1.5em;
	word-break: break-all;
}

.callout {
	border-radius: 3px;
	padding: 1rem;
}

figure {
	margin: 1.25em 0;
	page-break-inside: avoid;
}

figcaption {
	opacity: 0.5;
	font-size: 85%;
	margin-top: 0.5em;
}

mark {
	background-color: transparent;
}

.indented {
	padding-left: 1.5em;
}

hr {
	background: transparent;
	display: block;
	width: 100%;
	height: 1px;
	visibility: visible;
	border: none;
	border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
	max-width: 100%;
}

@media only print {
	img {
		max-height: 100vh;
		object-fit: contain;
	}
}

@page {
	margin: 1in;
}

.collection-content {
	font-size: 0.875rem;
}

.column-list {
	display: flex;
	justify-content: space-between;
}

.column {
	padding: 0 1em;
}

.column:first-child {
	padding-left: 0;
}

.column:last-child {
	padding-right: 0;
}

.table_of_contents-item {
	display: block;
	font-size: 0.875rem;
	line-height: 1.3;
	padding: 0.125rem;
}

.table_of_contents-indent-1 {
	margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
	margin-left: 3rem;
}

.table_of_contents-indent-3 {
	margin-left: 4.5rem;
}

.table_of_contents-link {
	text-decoration: none;
	opacity: 0.7;
	border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
	border: 1px solid rgba(55, 53, 47, 0.09);
	border-collapse: collapse;
}

table {
	border-left: none;
	border-right: none;
}

th,
td {
	font-weight: normal;
	padding: 0.25em 0.5em;
	line-height: 1.5;
	min-height: 1.5em;
	text-align: left;
}

th {
	color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
	margin: 0;
	margin-block-start: 0.6em;
	margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
	margin-block-start: 0.6em;
}

ul > li {
	list-style: disc;
}

ul.to-do-list {
	padding-inline-start: 0;
}

ul.to-do-list > li {
	list-style: none;
}

.to-do-children-checked {
	text-decoration: line-through;
	opacity: 0.375;
}

ul.toggle > li {
	list-style: none;
}

ul {
	padding-inline-start: 1.7em;
}

ul > li {
	padding-left: 0.1em;
}

ol {
	padding-inline-start: 1.6em;
}

ol > li {
	padding-left: 0.2em;
}

.mono ol {
	padding-inline-start: 2em;
}

.mono ol > li {
	text-indent: -0.4em;
}

.toggle {
	padding-inline-start: 0em;
	list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
	padding-left: 1.7em;
}

.toggle > li > details > summary {
	margin-left: -1.1em;
}

.selected-value {
	display: inline-block;
	padding: 0 0.5em;
	background: rgba(206, 205, 202, 0.5);
	border-radius: 3px;
	margin-right: 0.5em;
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	white-space: nowrap;
}

.collection-title {
	display: inline-block;
	margin-right: 1em;
}

.page-description {
    margin-bottom: 2em;
}

.simple-table {
	margin-top: 1em;
	font-size: 0.875rem;
	empty-cells: show;
}
.simple-table td {
	height: 29px;
	min-width: 220px;
}

.simple-table th {
	height: 29px;
	min-width: 220px;
}

.simple-table-header-color {
	background: rgb(247, 246, 243);
	color: black;
}
.simple-table-header {
	font-weight: 500;
}

time {
	opacity: 0.5;
}

.icon {
	display: inline-block;
	max-width: 1.2em;
	max-height: 1.2em;
	text-decoration: none;
	vertical-align: text-bottom;
	margin-right: 0.5em;
}

img.icon {
	border-radius: 3px;
}

.user-icon {
	width: 1.5em;
	height: 1.5em;
	border-radius: 100%;
	margin-right: 0.5rem;
}

.user-icon-inner {
	font-size: 0.8em;
}

.text-icon {
	border: 1px solid #000;
	text-align: center;
}

.page-cover-image {
	display: block;
	object-fit: cover;
	width: 100%;
	max-height: 30vh;
}

.page-header-icon {
	font-size: 3rem;
	margin-bottom: 1rem;
}

.page-header-icon-with-cover {
	margin-top: -0.72em;
	margin-left: 0.07em;
}

.page-header-icon img {
	border-radius: 3px;
}

.link-to-page {
	margin: 1em 0;
	padding: 0;
	border: none;
	font-weight: 500;
}

p > .user {
	opacity: 0.5;
}

td > .user,
td > time {
	white-space: nowrap;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin-right: 0.6em;
	vertical-align: middle;
}

p {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.image {
	border: none;
	margin: 1.5em 0;
	padding: 0;
	border-radius: 0;
	text-align: center;
}

.code,
code {
	background: rgba(135, 131, 120, 0.15);
	border-radius: 3px;
	padding: 0.2em 0.4em;
	border-radius: 3px;
	font-size: 85%;
	tab-size: 2;
}

code {
	color: #eb5757;
}

.code {
	padding: 1.5em 1em;
}

.code-wrap {
	white-space: pre-wrap;
	word-break: break-all;
}

.code > code {
	background: none;
	padding: 0;
	font-size: 100%;
	color: inherit;
}

blockquote {
	font-size: 1.25em;
	margin: 1em 0;
	padding-left: 1em;
	border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
	text-decoration: none;
	max-height: 8em;
	padding: 0;
	display: flex;
	width: 100%;
	align-items: stretch;
}

.bookmark-title {
	font-size: 0.85em;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 1.75em;
	white-space: nowrap;
}

.bookmark-text {
	display: flex;
	flex-direction: column;
}

.bookmark-info {
	flex: 4 1 180px;
	padding: 12px 14px 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.bookmark-image {
	width: 33%;
	flex: 1 1 180px;
	display: block;
	position: relative;
	object-fit: cover;
	border-radius: 1px;
}

.bookmark-description {
	color: rgba(55, 53, 47, 0.6);
	font-size: 0.75em;
	overflow: hidden;
	max-height: 4.5em;
	word-break: break-word;
}

.bookmark-href {
	font-size: 0.75em;
	margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.highlight-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background {
	background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
	background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
	background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
	background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
	background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
	background: rgba(253, 235, 236, 1);
}
.block-color-default {
	color: inherit;
	fill: inherit;
}
.block-color-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.block-color-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background {
	background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
	background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
	background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
	background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
	background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
	background: rgba(253, 235, 236, 1);
}
.select-value-color-interactiveBlue { background-color: rgba(35, 131, 226, .07); }
.select-value-color-pink { background-color: rgba(245, 224, 233, 1); }
.select-value-color-purple { background-color: rgba(232, 222, 238, 1); }
.select-value-color-green { background-color: rgba(219, 237, 219, 1); }
.select-value-color-gray { background-color: rgba(227, 226, 224, 1); }
.select-value-color-translucentGray { background-color: rgba(255, 255, 255, 0.0375); }
.select-value-color-orange { background-color: rgba(250, 222, 201, 1); }
.select-value-color-brown { background-color: rgba(238, 224, 218, 1); }
.select-value-color-red { background-color: rgba(255, 226, 221, 1); }
.select-value-color-yellow { background-color: rgba(253, 236, 200, 1); }
.select-value-color-blue { background-color: rgba(211, 229, 239, 1); }
.select-value-color-pageGlass { background-color: undefined; }
.select-value-color-washGlass { background-color: undefined; }

.checkbox {
	display: inline-flex;
	vertical-align: text-bottom;
	width: 16;
	height: 16;
	background-size: 16px;
	margin-left: 2px;
	margin-right: 5px;
}

.checkbox-on {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}
	
</style></head>
<body>
    <article id="217d0fbd-1960-4210-ae30-59fcf52ebc3a" class="page sans">
        <div class="page-body">
            <p id="1ca66705-808e-42f2-903f-9eedc2c8a642" class="">
            </p>
            <p id="953caf2a-62a3-4a5a-a0c5-af13989a227e" class="">
            </p>
            <p id="1a0756ca-0267-414c-9bd4-bb567425f8c5" class=""><strong>Robes Rental - Privacy Policy</strong></p>
            <p id="cd0d1b13-e89d-4ae3-b30c-a48ecbd5104e" class="">This Privacy Policy (&quot;Policy&quot;) outlines the
                procedures employed by Robes Rental Oy (&quot;Robes Rental&quot; or &quot;Robes&quot;) for the
                processing and acquisition of personal data, the potential uses and storage of such data, and delineates
                your rights concerning the personal data you may have provided to Robes Rental. We are committed to
                protecting your personal information and rights in relation to that information. </p>
            <p id="7f013a8d-caa7-4f90-81f9-b6edafd46536" class="">Personal information or personal data means any
                information about an identified individual or information from which that individual can be identified.
                It does not include anonymous information, from which a specific individual cannot be identified from.
                Personal information is primarily collected for the purpose of managing the customer relationship.</p>
            <p id="773a9c96-a07e-4ee8-ab67-298e7bfa1976" class="">By using this website and/or sharing your information
                with us, you are entrusting us with your data and consenting to the terms and conditions outlined in
                this Policy. We hold your right to privacy in the highest regard and approach the processing of your
                personal information with utmost seriousness. Within this Policy, our objective is to present a clear
                and understandable account of what information we may collect, how we gather it, how we may utilize that
                information, and how it is securely stored in our records. If there are any terms within this Policy
                that you disagree with, we kindly suggest discontinuing the use of our website and/or services.</p>
            <p id="8338382e-ad3b-45bc-b380-1a5f5bfc2742" class="">This Policy was last updated: November 2023.</p>
            <p id="822bd4f9-e649-42ea-98be-5b386bd3e844" class="">
            </p>
            <p id="ca0b1a76-a6d4-47a0-a9a4-3978701a6928" class=""><strong>Our details</strong></p>
            <p id="3ae9dac0-3bcf-41f4-a2e4-7146896b767f" class="">Company name: Robes Rental Oy</p>
            <p id="613cd547-2e6f-43a3-aae5-23f79587bfd1" class="">Address: Kirjatyöntekijänkatu 6 B 26, 00170 Helsinki,
                Finland</p>
            <p id="4e8e2352-67bd-4b4e-be49-ec1303bf2a03" class="">Privacy enquiries: <a
                    href="mailto:hello@robesrental.com">support@robesrental.com</a></p>
            <p id="021fc2b9-6b92-46c7-b9f7-dcc3d43757e4" class="">Name of Data Protection Officer : Anton Holmberg</p>
            <p id="4dd8b869-e762-4001-8f28-a987c06c18ec" class="">
            </p>
            <p id="ed0732b9-825e-45fb-8051-29f712e09def" class=""><strong>Information we may collect about you</strong>
            </p>
            <p id="8aad22f0-7164-40eb-b7c7-2f3af974d055" class="">We handle several categories of data that can be
                grouped as follows:</p>
            <ol type="1" id="8a20dd72-c101-4c72-a91b-652ceaf8a24d" class="numbered-list" start="1">
                <li>Contact Details - This includes personal information such as your name, email address, phone number
                    and physical address.</li>
            </ol>
            <ol type="1" id="e9316dd9-8473-4cb3-8fb9-45bc1bb7d977" class="numbered-list" start="2">
                <li>Financial Data - This pertains to billing information such as your billing address, credit card
                    number and bank account details.</li>
            </ol>
            <ol type="1" id="d753330e-6be9-446e-8134-08b60c8d5b00" class="numbered-list" start="3">
                <li>Identifiers and Legal Documents - This category covers personal identification documents such as
                    your ID card, national insurance number or business identity code, proof of residence and national
                    identification number.</li>
            </ol>
            <ol type="1" id="2410a3d4-b3ef-4a6e-b822-e166a1d3e94a" class="numbered-list" start="4">
                <li>Activity and Behavioural - This pertains to your activities and behaviour online using the service,
                    such as completed and disrupted payments.</li>
            </ol>
            <ol type="1" id="9c1a26fc-f00d-4deb-b67f-105f28ee09aa" class="numbered-list" start="5">
                <li>Personal Characteristics - This encompasses details like your nationality, gender, date of birth,
                    occupation, academic background and/or qualifications, height and clothing size.</li>
            </ol>
            <ol type="1" id="16298910-3ed2-49ab-b9e4-3f31d77be899" class="numbered-list" start="6">
                <li>Location Data - This involves information such as your city of residence.</li>
            </ol>
            <ol type="1" id="6fedf3de-79ba-40b7-8125-7c9abb7e7271" class="numbered-list" start="7">
                <li>Communications Data - This covers data related to your online and offline communications, including
                    instant messaging and social media posts.</li>
            </ol>
            <ol type="1" id="00a9f9be-f6fe-41e4-8387-5f09892a85c5" class="numbered-list" start="8">
                <li>Images and Recordings - This pertains to visual and audio recordings, such as photos and videos.
                </li>
            </ol>
            <ol type="1" id="8e2de637-3f38-4545-ac61-9dd2ca14b34b" class="numbered-list" start="9">
                <li>Views and Opinions - This includes your feedback, testimonials and favourite items.</li>
            </ol>
            <ol type="1" id="e28b148e-48ac-402c-ac64-cd78c2b4f63d" class="numbered-list" start="10">
                <li>Technical Identifiers - This covers data related to technical identifiers, such as device module
                    information, IP addresses, MAC addresses, usernames, and passwords.</li>
            </ol>
            <ol type="1" id="03226326-7710-4212-b7b6-44bdcf757932" class="numbered-list" start="11">
                <li>Aggregated Data - This pertains to statistical or demographic data that doesn&#x27;t directly or
                    indirectly identify you. Aggregated data could be derived from your personal data, but it&#x27;s not
                    considered personal data in law.</li>
            </ol>
            <p id="0b8ecaa5-f318-4cd1-bd5a-3456c96f7296" class="">
            </p>
            <p id="d28452c3-7d90-4bc2-8c6a-8bf4e39410a4" class=""><strong>How we may use your data </strong></p>
            <p id="61a57bbc-6ef6-4f71-9632-644cd033d9c6" class="">We leverage your data as our user for various
                business-driven purposes. To fulfill our contractual obligations, we employ your contact details and
                technical identifiers for user authentication.</p>
            <p id="16c78250-94a4-4717-9390-eb9054b656e3" class="">Furthermore, we utilize your images and technical
                identifiers for the purpose of conducting backups.</p>
            <div style="overflow-x: auto;">
                <table id="5765b25c-883e-44d5-9903-c8996b9f7c98" class="simple-table">
                    <tbody>
                        <tr id="03b38ae2-b3d3-498c-9833-a4f44fadee29">
                            <td id="qNT@" class=""><strong>Purpose</strong></td>
                            <td id="eTOu" class=""><strong>Type of personal data</strong></td>
                            <td id="wtw]" class=""><strong>Legal grounds</strong></td>
                        </tr>
                        <tr id="adda33ce-babb-4a1e-acdc-f2497a27d1c6">
                            <td id="qNT@" class="">Authenticating Users</td>
                            <td id="eTOu" class="">Contact details, technical identifiers</td>
                            <td id="wtw]" class="">Contractual obligations</td>
                        </tr>
                        <tr id="01697c5d-6e85-4aac-a2b8-4a68ed155128">
                            <td id="qNT@" class="">Backups</td>
                            <td id="eTOu" class="">Images and recordings, technical identifiers</td>
                            <td id="wtw]" class="">Legitimate interest</td>
                        </tr>
                        <tr id="856d91ef-a4e9-40b9-a34d-4b7fb505d85c">
                            <td id="qNT@" class="">Business intelligence and analytics</td>
                            <td id="eTOu" class="">Activity and behavioural, communication data, contact details, images
                                and recordings, personal characteristics, technical identifiers, views and opinions</td>
                            <td id="wtw]" class="">Legitimate interest</td>
                        </tr>
                        <tr id="49e99a78-2d82-4c06-a784-07c8cc4fd086">
                            <td id="qNT@" class="">Infrastructure/Integrations or File Storage</td>
                            <td id="eTOu" class="">Activity and Behavioural, Communications Data, Contact Details,
                                Images and Recordings, Personal Characteristics, Technical Identifiers, Views and
                                Opinions</td>
                            <td id="wtw]" class="">Legitimate Interest</td>
                        </tr>
                        <tr id="48edc2c6-efd5-4b2c-900d-bdd43719829a">
                            <td id="qNT@" class="">Onboarding &amp; Product Demos</td>
                            <td id="eTOu" class="">Activity and Behavioural, Contact Details</td>
                            <td id="wtw]" class="">Legitimate Interest</td>
                        </tr>
                        <tr id="44fb31ad-cb78-416e-9d2f-d4239de0de43">
                            <td id="qNT@" class="">Publish App to App Store</td>
                            <td id="eTOu" class="">Activity and Behavioural, Contact Details, Personal Characteristics,
                                Technical Identifiers, Views and Opinions</td>
                            <td id="wtw]" class="">Contractual Obligations</td>
                        </tr>
                        <tr id="4acde54f-8405-4924-b406-a5376ccb9e00">
                            <td id="qNT@" class="">Publish App to Google Play Store</td>
                            <td id="eTOu" class="">Activity and Behavioural, Technical Identifiers</td>
                            <td id="wtw]" class="">Contractual Obligations</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p id="83c4b169-2e90-4b6e-8bc9-bd2417613486" class=""><strong>How your personal data is collected</strong>
            </p>
            <p id="dfcca703-8521-42db-8a87-52918c7e40fa" class="">There are several ways in which you may share personal
                information with us, including:</p>
            <ul id="1e43e543-1c01-405e-9d04-9c41801e6615" class="bulleted-list">
                <li style="list-style-type:disc">Applying for our products and services</li>
            </ul>
            <ul id="81785e18-e98a-4328-94fb-498d8fb16cdc" class="bulleted-list">
                <li style="list-style-type:disc">Creating an account with us</li>
            </ul>
            <ul id="a60cad47-fda5-4ce7-9737-54bdbd5814a2" class="bulleted-list">
                <li style="list-style-type:disc">Subscribing to our publications</li>
            </ul>
            <ul id="67739032-8eba-4dcb-965a-613d80ae298b" class="bulleted-list">
                <li style="list-style-type:disc">Requesting marketing material from us</li>
            </ul>
            <ul id="34814a4e-fa24-416a-97fb-43d395773a53" class="bulleted-list">
                <li style="list-style-type:disc">Subscribing to our emailing list or publications</li>
            </ul>
            <ul id="5e1e4f8b-7def-4f49-b105-f89a096b9e09" class="bulleted-list">
                <li style="list-style-type:disc">Requesting marketing material from us</li>
            </ul>
            <ul id="8db3795f-13fe-4ef1-8ebe-1490dad7524d" class="bulleted-list">
                <li style="list-style-type:disc">Providing feedback or contacting us</li>
            </ul>
            <p id="469b84ab-4b2f-4467-bc72-92f83a2fcc65" class="">Furthermore, we automatically collect Technical,
                Profile and Usage Data about your equipment, browsing actions, and patterns when you use our website and
                services.</p>
            <p id="b20e2822-96ba-4c7d-8531-e72d75d8bc59" class="">
            </p>
            <p id="3938c299-a710-4490-acc5-c0a4074ae692" class=""><strong>Data Security</strong></p>
            <p id="b4ac9c8e-ba74-4ee1-8abe-dbb063bedd3e" class="">We are deeply committed to the security of your
                personal data and have implemented robust measures to safeguard against unauthorized access, disclosure,
                alteration, or loss of your personal information. Access to your personal data is restricted to
                individuals with a bona fide business need and who are bound by strict confidentiality obligations.
                Additionally, these individuals will process your personal data in accordance with our instructions and
                other specified requirements.</p>
            <p id="f1e417e7-efca-402f-97fe-0516f046b917" class="">In the event of a suspected breach of your personal
                data, we have established protocols to address such situations. If required by law, we will promptly
                notify you and the relevant regulatory authorities.</p>
            <p id="2fdb37d2-8513-4874-82fc-430143045993" class="">If you have created a password to access specific
                sections of our applications, the responsibility for maintaining its security and confidentiality lies
                with you. We kindly request that you refrain from sharing your password with anyone.</p>
            <p id="c2a71e4d-be68-45a1-a94c-f4337ccdb7fb" class="">
            </p>
            <p id="0e76da4a-83c7-46ec-b7ad-202f18621d99" class=""><strong>Data Retention</strong></p>
            <p id="3171f699-3637-4918-b67b-6c86ef3d548e" class="">We will retain your personal information only for the
                duration necessary to fulfill the objectives for which it was initially collected. This encompasses
                compliance with any legal, regulatory, tax, accounting, or reporting obligations. In specific
                situations, such as the presence of a complaint or the anticipation of potential legal proceedings
                involving you, we may extend the retention period for your personal information.</p>
            <p id="9a664959-b0e4-4fe6-bcde-b0830ef9c205" class="">In determining the appropriate retention period for
                personal data, we consider factors such as the volume, nature, and sensitivity of the personal data, the
                potential risks associated with unauthorized use or disclosure, the purposes for which we process the
                data, the feasibility of achieving those purposes through alternative means, as well as the legal,
                regulatory, tax, accounting, or other mandates that are applicable.</p>
            <p id="ef0cf30e-aad0-4541-814c-5954d895a7c3" class="">
            </p>
            <p id="de99ae21-bd7d-40a5-ae16-e934066b352b" class=""><strong>Your Legal Rights</strong></p>
            <p id="afbcbbff-48c3-4c9a-9f5c-becba89fa547" class="">You possess certain legal rights in relation to your
                personal information, which include:</p>
            <ol type="1" id="fe725ea2-5936-4d84-9739-b7d64e919ea5" class="numbered-list" start="1">
                <li>The right to request access to your personal data, also known as a &quot;data subject access
                    request.&quot; This allows you to receive a copy of the personal data we hold about you and to
                    verify that we are processing it lawfully.</li>
            </ol>
            <ol type="1" id="b1ddac31-c10e-4e33-87b2-698848f890e0" class="numbered-list" start="2">
                <li>The right to request correction of the personal data we hold about you. This enables you to have any
                    incomplete or inaccurate data we hold about you corrected, although we may need to verify the
                    accuracy of the new data you provide.</li>
            </ol>
            <ol type="1" id="aa2afb41-0643-4246-ae80-6aa6893b0926" class="numbered-list" start="3">
                <li>The right to request erasure of your personal data. This enables you to ask us to delete or remove
                    personal data where there is no good reason for us to continue to process it. You also have the
                    right to ask us to delete or remove your personal data where you have successfully exercised your
                    right to object to processing, where we may have processed your information unlawfully or where we
                    are required to erase your personal data to comply with local law. Please note that we may not
                    always be able to comply with your request for erasure for specific legal reasons.</li>
            </ol>
            <ol type="1" id="959bf2ca-f878-4258-9e3a-c07a9f3c6052" class="numbered-list" start="4">
                <li>The right to object to the processing of your personal data where we are relying on a legitimate
                    interest (or those of a third party) and there is something about your particular situation that
                    makes you want to object to processing on this ground as you feel it impacts on your fundamental
                    rights and freedoms. You also have the right to object where we are processing your personal data
                    for direct marketing purposes.</li>
            </ol>
            <ol type="1" id="0bf14e6c-207c-4d42-ae53-6e84c3068225" class="numbered-list" start="5">
                <li>The right to request restriction of processing of your personal data. This enables you to ask us to
                    suspend the processing of your personal data in certain scenarios, including if you want us to
                    establish the data&#x27;s accuracy, where our use of the data is unlawful but you do not want us to
                    erase it, where you need us to hold the data even if we no longer require it as you need it to
                    establish, exercise, or defend legal claims, or where you have objected to our use of your data, but
                    we need to verify whether we have overriding legitimate grounds to use it.</li>
            </ol>
            <ol type="1" id="20008ac8-799c-453a-95be-d8b2f7b323e3" class="numbered-list" start="6">
                <li>The right to request the transfer of your personal data to you or to a third party. We will provide
                    your personal data in a structured, commonly used, machine-readable format. However, this right only
                    applies to automated information which you initially provided consent for us to use or where we used
                    the information to perform a contract with you.</li>
            </ol>
            <ol type="1" id="d7e73c9e-c6c8-4ea3-9d1c-10a926516a85" class="numbered-list" start="7">
                <li>The right to withdraw consent at any time where we are relying on consent to process your personal
                    data. However, this will not affect the lawfulness of any processing carried out before you withdraw
                    your consent.</li>
            </ol>
            <ol type="1" id="3cd728f6-2d10-4aee-a2cf-25fa5a227abb" class="numbered-list" start="8">
                <li>The right to make a complaint at any time to the relevant regulator.</li>
            </ol>
            <p id="63039474-a08f-405a-b774-05dce2c72b81" class="">There is no charge for accessing your personal data or
                for exercising any of your other rights. Nevertheless, in cases where your request is deemed unfounded,
                repetitive, or excessive, we reserve the right to impose a reasonable fee or potentially decline your
                request.</p>
            <p id="67bfc72a-5b20-4b77-ad9d-385b54d6f3ec" class="">In order to confirm your identity and safeguard your
                personal data from unauthorized access, we may request specific information from you. Additionally, we
                might reach out to you for further details related to your request to expedite our response.</p>
            <p id="5563a1bc-7ea1-4c3f-a338-f8bf497ca63e" class="">Our objective is to address all valid requests within
                one month. However, if your request is particularly intricate or if you&#x27;ve submitted multiple
                requests, it may take longer than a month to provide a response. Should this be the case, we will inform
                you and keep you updated on our progress.</p>
            <p id="67991d09-796c-4bf7-9da4-e4cd129f7463" class="">
            </p>
            <p id="49c71a1a-a39f-4eee-a046-fdd516e94b0a" class=""><strong>Third-party links</strong></p>
            <p id="bfdeefa2-b9a9-4160-926a-05136dd0a6ce" class="">This website may contain links to external websites,
                plug-ins, or applications offered by third parties. When you click on these links or activate such
                connections, third parties may gather or distribute information about you. We lack control over these
                third-party websites and cannot be held responsible for their privacy policies. Hence, we strongly
                recommend that you review the privacy policy of every website you visit before divulging any personal
                data.</p>
            <p id="ab0b9abe-64b0-4f0f-99c9-f35c3eed52e1" class="">
            </p>
        </div>
    </article><span class="sans" style="font-size:14px;padding-top:2em"></span>
</body>
</html>`;

export const PrivacyPolicy: React.FC = () => <Box sx={{ px: 2, width: '100%' }}>{parse(policy)}</Box>;
