import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const apiPropagationTarget = process.env.API_URL || '';
const webAppVersion = process.env.REACT_APP_VERSION || '';

export const initializeSentry = async (sentryDsn: string) => {
    let appInfo: AppInfo | null;
    let distribution: string | undefined;

    const platform = Capacitor.getPlatform();
    const isNativePlatform = Capacitor.isNativePlatform();

    if (isNativePlatform) {
        appInfo = await App.getInfo();
        distribution = appInfo.build;
    }

    const getReleaseVersion = () => {
        if (appInfo) {
            // IMPORTANT: This notation must match the one used in build-scripts for native apps (build-android-prod.sh, build-ios-prod.sh) when uploading
            return `robes-${platform}-${appInfo.version}`;
        }
        return `robes-web-${webAppVersion}`;
    };

    Sentry.init({
        dsn: sentryDsn,
        tracePropagationTargets: ['localhost', apiPropagationTarget],
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
            new ExtraErrorDataIntegration(),
        ],

        tracesSampleRate: 1.0,
        release: getReleaseVersion(),
        dist: distribution,
    });
};
