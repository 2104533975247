import { useTranslation, Trans } from 'react-i18next';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInScaleZoom } from '../../animations/constants';
import { AlertDrawer } from '../../components/AlertDrawer/AlertDrawer';
import { assertNever, invariant } from '../../helpers/commonHelpers';
import { formatDayMonthAndDate } from '../../helpers/dateAndTimeHelpers';
import { Methods } from '../../queries/useDeliveryTiming';
import { BookingDetails } from './LogisticsSection.helpers';

export const DifferentDayAlertMaybe: React.FC<{
    methods: Methods;
    bookingDetails: BookingDetails;
    type: 'delivery' | 'pickup' | 'return';
}> = ({ methods, bookingDetails, type }) => {
    const { booking, deliveryMethod } = bookingDetails;
    const { t } = useTranslation();
    const { getPickupDateWithinOpeningHours, getNextReturnDateAndTime, getAvailabilityHoursDatesString, getDeliveryInformation } = methods;

    invariant(deliveryMethod === 'wolt' || deliveryMethod === 'showroom', 'deliveryMethod should be either wolt or showroom');

    let compareStartDate: Date | string | null = null;

    const getDeliveryInformationFn = () => {
        if (type === 'pickup' || type === 'delivery') {
            compareStartDate = getPickupDateWithinOpeningHours(deliveryMethod, booking);
            const compareEndDate = booking.range.bookingStart;

            return getDeliveryInformation(compareStartDate, compareEndDate, deliveryMethod);
        }

        if (type === 'return') {
            compareStartDate = getNextReturnDateAndTime(deliveryMethod, booking);
            const compareEndDate = booking.range.bookingEnd;

            return getDeliveryInformation(compareStartDate, compareEndDate, deliveryMethod);
        }

        assertNever(type);
    };

    const deliveryInformation = getDeliveryInformationFn();

    if (!deliveryInformation) {
        return null;
    }

    const { isDeliveryDateDifferent, daysDiff } = deliveryInformation;

    const dayString = daysDiff === 1 ? t('day') : t('days');
    const daysDiffString = `${daysDiff} ${dayString}`;

    // If showing alert for return delivery, show office opening hours instead of delivery hours
    const openingHoursDatesString = type === 'return' ? getAvailabilityHoursDatesString() : getAvailabilityHoursDatesString(deliveryMethod);

    if (isDeliveryDateDifferent) {
        return (
            <AnimatedContainer variants={fadeInScaleZoom} transition={{ delay: 0.3, type: 'spring', stiffness: 110, damping: 15 }}>
                <AlertDrawer
                    severity="info"
                    alertBtnAlertSx={{ padding: '6px 8px' }}
                    summary={<Trans i18nKey={`${type}OnDateSummary`} values={{ daysDiffString }} components={{ b: <b /> }} />}
                    title={t(`${type}DateNote`)}
                    message={
                        <Trans
                            i18nKey={`${type}OnDate`}
                            components={{ b: <b />, i: <i />, br: <br /> }}
                            values={{
                                date: formatDayMonthAndDate(compareStartDate),
                                daysDiffString,
                                openingHourDates: openingHoursDatesString,
                            }}
                        />
                    }
                />
            </AnimatedContainer>
        );
    }

    return null;
};
