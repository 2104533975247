/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerState, ListingsState, ListingsStateAction, UpdateFilters } from '../views/Listings/types';
import { defaultPriceRange } from '../views/Listings/constants';
import { isEqual, omit } from 'lodash';
import { queryString2State } from '../helpers/urlHelpers';
import { RootState } from './types';
import { analytics } from '../analytics/events/helpers';

export type Filters = ListingsState['filters'];

export const initialState: ListingsState = {
    sortBy: 'createdAt',
    drawerState: 'closed',
    queryParams: '',
    scrolling: false,
    filters: {
        keywords: '',
        price: defaultPriceRange,
        bodytype: [],
        color: [],
        size: [],
        brand: [],
        category: [],
        material: [],
        collection: [],
        delivery: [],
    },
};

export const listingsStateReducer = (state: ListingsState, action: ListingsStateAction): ListingsState => {
    if (action.type === 'apply') {
        return action.payload;
    }

    if (action.type === 'reset') {
        analytics.filtersReset();
        return initialState;
    }

    if (action.type === 'drawer') {
        return { ...state, drawerState: action.payload };
    }

    if (action.type === 'sort') {
        return { ...state, sortBy: action.payload };
    }

    const allowedFilterKeys = Object.keys(state.filters) as (keyof ListingsState['filters'])[];

    if (allowedFilterKeys.includes(action.type)) {
        const filters = { ...state.filters, [action.type]: action.payload };

        return { ...state, filters };
    }

    return state;
};

export const listingsSlice = createSlice({
    name: 'listings',
    initialState,
    reducers: {
        setInitialState: (state, action: PayloadAction<string>) => {
            const { filters, sortBy } = queryString2State(action.payload);
            state.filters = filters;
            state.sortBy = sortBy;
        },
        replaceState: (_state, action: PayloadAction<ListingsState>) => {
            return action.payload;
        },
        setDrawerState: (state, action: PayloadAction<DrawerState>) => {
            state.drawerState = action.payload;
        },
        updateListingsState: (state, action: PayloadAction<ListingsState>) => {
            state.filters = action.payload.filters;
            state.sortBy = action.payload.sortBy;
            state.drawerState = 'closed';
        },
        updateFilters: (state, action: PayloadAction<UpdateFilters>) => {
            state.filters = listingsStateReducer(state, action.payload).filters;
        },
        resetFilters: (state) => {
            state.filters = initialState.filters;
            state.sortBy = initialState.sortBy;
        },
        resetFilter: (state, action: PayloadAction<keyof Filters>) => {
            const resetFilterValue = <K extends keyof Filters>(key: K): void => {
                state.filters[key] = initialState.filters[key];
            };

            resetFilterValue(action.payload);
        },
        setIsScrolling: (state, action: PayloadAction<boolean>) => {
            state.scrolling = action.payload;
        },
    },
});

export const { setIsScrolling, setInitialState, replaceState, setDrawerState, resetFilters, resetFilter, updateListingsState, updateFilters } =
    listingsSlice.actions;

export const selectListingsState = (state: RootState): ListingsState => state.listings;
export const selectDrawerState = (state: RootState): DrawerState => state.listings.drawerState;
export const selectSortBy = (state: RootState): string => state.listings.sortBy;
export const selectSortByChanged = (state: RootState): boolean => state.listings.sortBy !== 'createdAt';
export const selectIsScrolling = (state: RootState): boolean => state.listings.scrolling;

export const selectFilters = (state: RootState): ListingsState['filters'] => state.listings.filters;
export const selectFiltersChanged = (state: RootState): boolean =>
    !isEqual(omit(state.listings.filters, 'keywords'), omit(initialState.filters, 'keywords')) || state.listings.sortBy !== 'createdAt';

export const selectChangedStateKeys = (state: RootState): string[] => {
    const filters = omit(selectFilters(state), 'keywords');
    const initialFilters = omit(initialState.filters, 'keywords');
    const filterNames = Object.keys(filters) as (keyof Omit<ListingsState['filters'], 'keywords'>)[];
    const changedFilters = filterNames.filter((filter) => !isEqual(filters[filter], initialFilters[filter]));
    const sortByChanged = selectSortByChanged(state);

    let changedStateKeys: string[] = [];

    if (sortByChanged) {
        changedStateKeys = [...changedStateKeys, 'sort'];
    }

    if (changedFilters.length > 0) {
        changedStateKeys = [...changedStateKeys, ...changedFilters];
    }

    return changedStateKeys;
};

export const selectPriceFilter = (state: RootState): number[] => state.listings.filters.price;

export default listingsSlice.reducer;
