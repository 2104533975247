/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInMinutes } from 'date-fns';
import { assertIsDefined, invariant } from '../helpers/commonHelpers';
import { Transaction } from '../transactions/apiTypes';
import { PaymentMethod } from '../views/UserProfile/Payments/types';
import { RootState } from './types';
import { isEqual } from 'lodash';
import { Change } from '../types/types';

const emptyDraft = {
    range: {
        bookingStart: undefined,
        bookingEnd: undefined,
    },
};

export type BookingStatus = 'payment-pending' | 'awaits-confirmation' | 'payment-confirmed' | null | undefined;

export interface BookingDraft {
    range: {
        bookingStart: string | undefined;
        bookingEnd: string | undefined;
    };
    listingId?: string;
    customer?: string;
    paymentMethod?: PaymentMethod;
    transaction?: Transaction['data'];
    returnMethod?: string;
    returnTime?: string;
    pickupTime?: string;
    deliveryMethod?: string;
    deliveryTime?: string;
    deliveryDetails?: {
        street: string;
        postalCode: string;
        city: string;
        phone: string;
        rememberForLater?: boolean;
    };
    expiresAt?: string;
    productLabel?: string;
    status?: BookingStatus;
    paymentIntentClientSecret?: string;
}

export type BookingDraftWithRequiredProperties = Omit<BookingDraft, 'range' | 'productLabel' | 'paymentMethod' | 'deliveryMethod'> & {
    range: { bookingStart: string; bookingEnd: string };
    productLabel: string;
    paymentMethod: PaymentMethod;
    deliveryMethod: string;
};

type BookingChanges = {
    [K in keyof Booking]?: Change<Booking[K]>;
};

export type DeliveryDetails = {
    street: string;
    postalCode: string;
    city: string;
    phone: string;
    rememberForLater?: boolean;
};

// TODO: Better name for this type (BookingWithInitiatedTransaction or something)
export type Booking = {
    range: {
        bookingStart: string;
        bookingEnd: string;
    };
    listingId: string;
    customer: string;
    productLabel: string;
    paymentMethod: PaymentMethod;
    transaction: Transaction['data'];
    expiresAt: string;
    status: BookingStatus;
    paymentIntentClientSecret: string;
    renterDeliveryDate?: string;
    renterReturnDate?: string;
    returnMethod?: string;
    returnTime?: string;
    deliveryMethod: string;
    deliveryTime?: string;
    deliveryDetails?: DeliveryDetails;
    changes?: BookingChanges;
};

export type BookingWithCreditsPayment = {
    range: {
        bookingStart: string;
        bookingEnd: string;
    };
    listingId: string;
    customer: string;
    productLabel: string;
    paymentMethod: 'robes-credits';
    transaction: Transaction['data'];
    renterDeliveryDate?: string;
    renterReturnDate?: string;
    returnMethod?: string;
    returnTime?: string;
    deliveryMethod: string;
    deliveryTime?: string;
    deliveryDetails?: DeliveryDetails;
};

interface PartialBooking extends Partial<Booking> {
    [key: string]: any;
}

export const updateOngoingAsync = createAsyncThunk('booking/updateOngoingAsync', async (bookingData: Partial<Booking>, { dispatch }) => {
    dispatch(updateOngoing(bookingData));
});

export const updateBookingDraftAsync = createAsyncThunk('booking/updateBookingDraftAsync', async (draftData: Partial<BookingDraft>, { dispatch }) => {
    dispatch(updateBookingDraft(draftData));
});

// Track changes to the booking if they occurred after the transaction was initiated, because some changes (e.g delivery method)
// warrant declining the previous transaction and starting a new one
export const getChanges = (oldObj: PartialBooking, newObj: PartialBooking) => {
    const changes: Record<string, { from: any; to: any }> = {};

    for (const key in newObj) {
        if (!isEqual(oldObj[key], newObj[key])) {
            changes[key] = {
                from: oldObj[key],
                to: newObj[key],
            };
        }
    }

    return changes;
};

const initialState: { draft: BookingDraft; ongoing: Booking[] } = {
    draft: emptyDraft,
    ongoing: [],
};

const bookinglice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        createBookingDraft: (
            state,
            action: PayloadAction<BookingDraft['range'] & { listingId: string; paymentMethod: PaymentMethod; productLabel: string }>,
        ) => {
            const { listingId, bookingStart, bookingEnd, paymentMethod, productLabel } = action.payload;
            state.draft = { listingId, paymentMethod, range: { bookingStart, bookingEnd }, productLabel };
        },
        updateBookingDraft: (state, action: PayloadAction<Partial<BookingDraft>>) => {
            state.draft = { ...state.draft, ...action.payload };
        },
        resetBookingDraft: (state, action: PayloadAction<Partial<Booking | BookingDraft> | null>) => {
            if (!action.payload) {
                state.draft = initialState.draft;
            }
            state.draft = { ...initialState.draft, ...action.payload };
        },
        addToOngoing: (state, action: PayloadAction<Partial<BookingDraft>>) => {
            const booking = action.payload;

            if (state.ongoing.findIndex((item) => item.listingId === booking.listingId) === -1) {
                state.ongoing.push(booking as Booking);
                state.draft = emptyDraft;
            } else {
                throw new Error('Trying to add a booking to ongoing, but a booking for this listingId already exists');
            }
        },
        removeFromOngoing: (state, action: PayloadAction<Partial<Booking>>) => {
            assertIsDefined(action.payload.listingId, 'trying to delete ongoing booking with no listingId');
            state.ongoing = state.ongoing.filter((booking) => booking.listingId !== action.payload.listingId);
        },
        updateOngoing: (state, action: PayloadAction<Partial<Booking>>) => {
            const currIdx = state.ongoing.findIndex((booking) => booking.listingId === action.payload.listingId);
            invariant(currIdx > -1, `trying to update ongoing booking, but none found for listing ${action.payload.listingId}`);

            const curr = state.ongoing[currIdx];

            const changes = getChanges(curr, action.payload);

            state.ongoing[currIdx] = { ...curr, ...action.payload, changes };
        },
    },
});

export const { updateBookingDraft, createBookingDraft, resetBookingDraft, addToOngoing, updateOngoing, removeFromOngoing } = bookinglice.actions;

export const selectOngoingBookings = (state: RootState) => state.booking.ongoing;

export const selectBooking = (state: RootState, listingId: string) => {
    const { booking } = state;
    const ongoingBooking = booking.ongoing.find((item) => item.listingId === listingId);

    if (ongoingBooking) {
        return ongoingBooking;
    }
    if (booking.draft.listingId === listingId) {
        return booking.draft;
    }

    return null;
};

export const selectBookingRange = (state: RootState, listingId: string) => {
    const booking = selectBooking(state, listingId);
    if (!booking || !booking.range) {
        return { bookingStart: undefined, bookingEnd: undefined };
    }

    return booking.range;
};

export const selectOngoingBooking = (state: RootState, listingId: string) => {
    const match = state.booking.ongoing.find((booking) => booking.listingId === listingId && !!booking.status);
    if (match) {
        const d1 = new Date(match.expiresAt);
        const d2 = new Date();
        const isExpired = Math.abs(differenceInMinutes(d1, d2)) > 14;
        return !isExpired ? match : null;
    }

    return null;
};

export const selectHasCompletedPayment = (state: RootState, listingId: string) => {
    const booking = selectBooking(state, listingId);

    if (!booking) {
        return false;
    }

    return booking.listingId === listingId && booking.status === 'payment-confirmed';
};

export default bookinglice.reducer;
