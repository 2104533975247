import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authApiClient, passwordResetApiClient } from '../../services/sharetribe/apiClients';

export const useResetPassword = (onSuccess: () => void) => {
    const { t } = useTranslation();

    const sendResetPasswordFn = async (args: { email: string; passwordResetToken: string; newPassword: string }) => {
        const { email, passwordResetToken, newPassword } = args;
        const url = '/reset';
        const { data } = await passwordResetApiClient.post(url, { email, passwordResetToken, newPassword });

        return data;
    };

    return useMutation(sendResetPasswordFn, {
        onSuccess,
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
    });
};
