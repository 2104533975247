import styled from '@mui/material/styles/styled';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledContent = styled(motion.div)(() => ({
    flex: '1 0 auto',
    position: 'relative',
}));

export const StyledToastContainer = styled(ToastContainer)<{ backgroundColor: string; textColor: string }>(({ backgroundColor, textColor }) => ({
    '.Toastify__toast': {
        backgroundColor,
        color: textColor,
        margin: '35px 35px 60px',
        borderRadius: '10px',
    },
}));
