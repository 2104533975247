import React, { useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { useSearchTags } from './useSearchTags';
import { TagTypeEnum } from '../../types/enums';
import { Badge, Box, Chip, Icon, Typography } from '@mui/material';
import { DrawerMultiSelect } from '../../components/DrawerSelect/DrawerMultiSelect';
import { DrawerSelect } from '../../components/DrawerSelect/DrawerSelect';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { TagSelector } from './TagSelector';
import { useTranslation } from 'react-i18next';

interface TagSingleSelectDrawerProps {
    selectedValue: string;
    label: string;
    onSelect: (value: string) => void;
}

export const TagSingleSelectDrawer: React.FC<TagSingleSelectDrawerProps> = ({ label, selectedValue, onSelect }) => {
    const [inputValue, setInputValue] = useState('');

    const { t } = useTranslation();
    const debouncedSearch = useDebounce(inputValue, 300);
    const isDesktop = useBreakpoint('sm');
    const { data } = useSearchTags(TagTypeEnum.BRAND, debouncedSearch);

    const opts = (data || []).map((tag) => ({
        value: tag.tag,
        label: tag.tag,
        ...(tag.new && {
            icon: <Chip variant="filled" color="primary" label={t('new')} />,
        }),
    }));

    return (
        <>
            {isDesktop ? (
                <TagSelector
                    type={TagTypeEnum.BRAND}
                    selectedValues={selectedValue ? [selectedValue] : []}
                    textFieldVariant="outlined"
                    onValueSelected={(val) => {
                        onSelect(val[0]);
                    }}
                />
            ) : (
                <DrawerSelect
                    onSelect={onSelect}
                    inputValue={inputValue}
                    onInputChange={setInputValue}
                    renderSelectedValue={() => (
                        <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                            {selectedValue}
                        </Typography>
                    )}
                    label={label}
                    selectedValue={selectedValue}
                    options={opts}
                />
            )}
        </>
    );
};

interface TagMultiSelectDrawerProps {
    selectedValues: string[];
    totalItems: number;
    loading: boolean;
    label: string;
    onSelect: (value: string[]) => void;
}

export const TagMultiSelectDrawer: React.FC<TagMultiSelectDrawerProps> = ({ label, selectedValues, totalItems, loading, onSelect }) => {
    const [inputValue, setInputValue] = useState('');
    const debouncedSearch = useDebounce(inputValue, 300);
    const isDesktop = useBreakpoint('sm');
    const { t } = useTranslation();

    const { data, isLoading } = useSearchTags(TagTypeEnum.BRAND, debouncedSearch, false);

    const opts = (data || []).map((tag) => ({
        value: tag.tag,
        label: tag.tag,
    }));

    const getButtonLabel = () => {
        if (!selectedValues.length) {
            return null;
        }

        return selectedValues.join(', ');
    };

    const buttonLabel = getButtonLabel();

    return (
        <>
            {isDesktop ? (
                <Box sx={{ p: 1 }}>
                    <TagSelector
                        type={TagTypeEnum.BRAND}
                        limit={10}
                        selectedValues={selectedValues}
                        textFieldVariant="outlined"
                        includeNew={false}
                        onValueSelected={(val) => {
                            onSelect(val);
                        }}
                    />
                </Box>
            ) : (
                <DrawerMultiSelect
                    onChange={onSelect}
                    iconPlacement="suffix"
                    onInputChange={setInputValue}
                    totalItems={totalItems}
                    label={label}
                    matchValues={false}
                    loading={loading}
                    selectedValues={selectedValues}
                    options={opts}
                />
            )}
        </>
    );
};
