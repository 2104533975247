import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrands } from '../../../../components/FilterBar/useBrands';
import { capitalizeString } from '../../../../helpers/commonHelpers';
import {
    getSizingOptionsForSelectedCategories,
    Category,
    getBodytypeOptions,
    categories,
    colorOptions,
    deliveryOptions,
} from '../../../AddListing/constants';
import { sortByOptions } from '../../constants';
import { ListingsState } from '../../types';
import { ColorIndicator } from '../../../AddListing/AddListing.components';

export const useFilterOptions = (filters: ListingsState['filters']) => {
    const { t } = useTranslation();

    const { data: brands } = useBrands();

    const sizingOptions = useMemo(() => {
        const opts = getSizingOptionsForSelectedCategories(filters.category as Category[]);
        return opts.map((el) => ({ value: el, label: t(el) }));
    }, [filters.category, t]);

    const bodyTypeOpts = getBodytypeOptions().map((opt) => ({
        value: opt,
        label: t(opt),
    }));

    const colorOpts = colorOptions.map((el) => ({
        value: el.value,
        label: capitalizeString(t(el.value)),
        icon: <ColorIndicator style={{ backgroundColor: el.colorCode }} />,
    }));

    const deliveryOpts = deliveryOptions.map((el) => ({ value: el.value, label: t(el.label) }));

    const categoryOpts = categories.map((el) => ({ value: el, label: t(el) }));
    const sortByOpts = sortByOptions.map((el) => ({ value: el.value, label: t(el.label) }));
    const brandOptions = brands || [];

    return {
        category: categoryOpts,
        size: sizingOptions,
        color: colorOpts,
        brand: brandOptions,
        sort: sortByOpts,
        bodytype: bodyTypeOpts,
        delivery: deliveryOpts,
    };
};

export type FilterOptions = ReturnType<typeof useFilterOptions>;
