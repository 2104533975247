import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { userService } from '../../services/sharetribe/user/service';
import { User } from '../../types/apiTypes';
import { imageService } from '../../services/sharetribe/image/service';
import { MAXIMUM_FILE_SIZE } from '../../views/Listings/constants';
import { invariant } from '../../helpers/commonHelpers';

export const useUpdateProfileImage = (): UseMutationResult<User | undefined, unknown, string, unknown> => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const updateFn = async (imageId: string) => {
        const { response } = await userService.update({
            profileImageId: imageId,
        });

        const userData = response?.data;
        if (userData) {
            queryClient.setQueryData(['current-user'], () => response.data);
        }

        return userData;
    };

    return useMutation(updateFn, {
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
        onSuccess: (data) => {
            if (data) {
                queryClient.setQueryData(['current-user'], () => data);
            }
        },
    });
};

export const useUploadAndUpdateProfileImage = (
    onSuccess?: () => void,
    onProgress?: (progress: number) => void,
): UseMutationResult<User | undefined, unknown, Blob, unknown> => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const uploadFn = async (blob: Blob) => {
        const file = new File([blob], 'newImageFile', { type: 'image/jpeg' });

        invariant(file.size < MAXIMUM_FILE_SIZE, 'imageSizeError');

        const { response } = await imageService.upload(file, onProgress);

        const imageId = response?.data.data.data.id.uuid;

        return imageId;
    };

    const updateFn = async (imageId: string) => {
        const { response } = await userService.update({
            profileImageId: imageId,
        });

        const userData = response?.data;
        if (userData) {
            queryClient.setQueryData(['current-user'], () => response.data);
        }

        return userData;
    };

    const composedFunc = async (blob: Blob) => {
        const uploadedImageId = await uploadFn(blob);
        const updatedUser = await updateFn(uploadedImageId);

        return updatedUser;
    };

    return useMutation(composedFunc, {
        onError: (err: Error) => {
            if (err.message === 'imageSizeError') {
                const msg = t('imageSizeError');
                toast.error(msg);
            } else {
                console.log(err);
                const msg = t('unexpectedError');
                toast.error(msg);
            }
        },
        onSuccess: (data) => {
            if (data) {
                queryClient.setQueryData(['current-user'], () => data);
            }

            if (onSuccess) {
                onSuccess();
            }
        },
    });
};
