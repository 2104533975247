import { useQuery } from '@tanstack/react-query';
import { creditsApiClient } from '../../../services/sharetribe/apiClients';

export type CreditsBalance = {
    balance: number;
    reserved: number;
    currency: string;
};

export const useCreditsBalance = () => {
    const getCreditsFn = async () => {
        const { data } = await creditsApiClient.get<CreditsBalance>('balance');
        return data;
    };

    return useQuery(['credits-balance'], getCreditsFn);
};
