import { Typography, Box } from '@mui/material';

export const DisplayFormValueComponent: React.FC<{
    value: React.ReactNode;
    label: React.ReactNode;
    prefix: React.ReactNode;
    alertComponent?: () => React.ReactNode;
}> = ({ value, label, prefix, alertComponent }) => {
    return (
        <>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center', px: '6px', height: 'auto', gap: '10px' }}>
                {prefix}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" fontWeight="bold">
                        {label}
                    </Typography>
                    <Typography variant="body2">{value}</Typography>
                </Box>
            </Box>

            {alertComponent && alertComponent()}
        </>
    );
};
