/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './types';

interface AppState {
    isOnboarding: boolean;
}

const initialState: AppState = {
    isOnboarding: false,
};

export const searchSlice = createSlice({
    name: 'app-state',
    initialState,
    reducers: {
        setIsOnboarding: (state, action: PayloadAction<boolean>) => {
            state.isOnboarding = action.payload;
        },
    },
});

export const { setIsOnboarding } = searchSlice.actions;

export const selectIsOnboarding = (state: RootState): boolean => state.appState.isOnboarding;

export default searchSlice.reducer;
