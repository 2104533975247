import { Box, Chip, Typography, styled } from '@mui/material';
import React from 'react';

interface FilterBarButtonProps {
    selected?: boolean;
    item: {
        label?: string;
        value: string;
        startIcon?: React.ReactNode;
        endIcon?: React.ReactNode;
    };
    onClick: (value: string) => void;
}

const StyledButton = styled(Chip, { shouldForwardProp: (prop) => prop !== 'selected' })<{
    selected: boolean | undefined;
}>(({ theme, selected }) => ({
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.border.lighter}`,
    padding: '0px 5px',
    height: '30px',
    boxSizing: 'border-box',
    alignItems: 'center',
    ...(selected && {
        border: `none`,
        color: theme.palette.common.white,
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& svg': {
            fill: theme.palette.common.white,
        },
    }),
}));

export const FilterBarButton: React.FC<FilterBarButtonProps> = ({ item, onClick, selected }) => {
    return (
        <StyledButton
            label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {item.label}
                    </Typography>

                    {item.endIcon}
                </Box>
            }
            variant="outlined"
            key={item.value}
            onClick={() => onClick(item.value)}
            selected={selected}
            {...(item.startIcon && { icon: item.startIcon as React.ReactElement })}
        />
    );
};
