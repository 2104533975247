import { SetupIntent } from '@stripe/stripe-js';
import { transactionsApiClient, stripeApiClient } from '../../../services/sharetribe/apiClients';
import { BookingDraft } from '../../../store/bookingReducer';
import { TransitionName } from '../../../transactions/constants';
import { TransactionInitiatedResponse } from './types';
import { Transaction } from '../../../transactions/apiTypes';

export const initiateTransaction = async (listingId: string, booking: BookingDraft) => {
    const url = '/initiate';

    const { data } = await transactionsApiClient.post<TransactionInitiatedResponse>(url, { listingId, booking }, { timeout: 20 * 1000 });

    return data;
};

/**
 * If transaction flow was canceled at some point, we need another ephemeral key to continue the flow.
 */
export const getEphemeralKey = async (customerId: string) => {
    const url = `/ephemeral-key?customer=${customerId}`;
    const { data } = await transactionsApiClient.get<string>(url);

    return data;
};

export const confirmPayment = async (transactionId: string): Promise<Transaction> => {
    const url = `/transition`;

    const { data } = await transactionsApiClient
        .post<Transaction>(url, {
            transactionId,
            transition: TransitionName.CONFIRM_PAYMENT,
            params: {},
        })
        .catch((err) => {
            throw new Error(err.response?.data);
        });

    return data;
};

export const declineTransaction = async (transactionId: string): Promise<Transaction> => {
    const url = `/transition`;

    const { data } = await transactionsApiClient
        .post<Transaction>(url, {
            transactionId,
            transition: TransitionName.CUSTOMER_DECLINE_BEFORE_CONFIRM_PAYMENT,
            params: {},
        })
        .catch((err) => {
            throw new Error(err.response?.data);
        });

    return data;
};

export const createSetupIntent = async (): Promise<SetupIntent> => {
    const url = '/intent/setup';
    const { data } = await stripeApiClient.get<string, { data: SetupIntent }>(url);

    return data;
};
