import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { Card } from '@mui/material';

export const ForgotPasswordButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontSize: '12px',
    opacity: 0.7,
}));

export const LoginLayoutContent = styled(Card)(() => ({
    width: '100%',
    borderRadius: '10px',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const LoginLayoutContainer = styled('div')(() => ({
    width: '100%',
    height: `100%`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
}));

export const ForgotPasswordSectionContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const NameInputContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '32px 0',
}));

export const LinkSpan = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

export const LoginErrorText = styled('p')(() => ({
    height: '20px',
    display: 'flex',
    color: 'red',
    justifyContent: 'center',
    alignItems: 'center',
}));
