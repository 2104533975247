import React, { useEffect } from 'react';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export const RedirectToHome: React.FC = () => {
    const navigate = useSafeNavigate();
    useEffect(() => {
        navigate('/', { replace: true });
    }, [navigate]);

    return null;
};
