export const defaultPageParams = {
    from: 0,
    to: 50,
};

export const routesWithoutBackButton: string[] = ['/'];
export const headerHeightPX = 60;
export const filterBarHeightPX = 50;
export const filterResultsCountHeightPX = 20;
export const footerHeightPX = 56;

export const maxPriceFilter = 40;
export const messagesPerPage = 20;
export const listingsPerPage = 24;

export const merchantIdentifier = 'merchant.robes.rental';

export const minimumRentalLength = 3;

export const maxTimeslotRangeDays = 90;

export const returnTimeInterval = 60;
export const pickupTimeInterval = 60;
export const deliveryTimeInterval = 30;

export const woltDeliveryAcceptedPostalCodes = [
    '00100',
    '00120',
    '00130',
    '00140',
    '00150',
    '00160',
    '00170',
    '00180',
    '00200',
    '00210',
    '00220',
    '00230',
    '00240',
    '00250',
    '00250',
    '00260',
    '00270',
    '00280',
    '00290',
    '00300',
    '00310',
    '00320',
    '00330',
    '00340',
    '00350',
    '00370',
    '00380',
    '00400',
    '00440',
    '00500',
    '00510',
    '00520',
    '00530',
    '00540',
    '00550',
    '00560',
    '00570',
    '00580',
    '00590',
    '00600',
    '00610',
    '00620',
    '00630',
    '00640',
    '00650',
    '00810',
    '00840',
    '00850',
    '00870',
    '02100',
    '02110',
    '02120',
    '02130',
    '02140',
    '02150',
    '02160',
    '02600',
];

export const defaultCurrency = 'EUR';

export const showroomDisplayNameFull = 'Fabianinkatu 4, 00130 Helsinki';
export const showroomAddressStreet = 'Fabianinkatu 4';
export const showroomAddressPostalCode = '00130';
export const showroomAddressCity = 'Helsinki';
