import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, SxProps } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

interface BasicMenuProps {
    menuItems: string[];
    menuButtonLabel?: React.ReactNode;
    menuButtonSxProps?: SxProps;
    onClickItem: (i: number) => void;
}

export const BasicMenu: React.FC<BasicMenuProps> = ({ menuItems, menuButtonSxProps, menuButtonLabel, onClickItem }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {menuButtonLabel ? (
                <Button
                    sx={{ ...menuButtonSxProps }}
                    aria-label="more"
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    {menuButtonLabel}
                </Button>
            ) : (
                <IconButton
                    sx={{ ...menuButtonSxProps }}
                    aria-label="more"
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVert />
                </IconButton>
            )}

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menuItems.map((item, i) => (
                    <MenuItem
                        key={item}
                        onClick={() => {
                            onClickItem(i);
                            handleClose();
                        }}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
