import React from 'react';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomBackButton } from '../../animations/components/CustomBackButton';
import { HeaderText } from '../../components/RootContainer/Topbar/Header/Header.components';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { AddListingHeader } from './AddListing.components';

export const ListingHeader: React.FC<{ setIsPreviewing: (val: boolean) => void; isNew: boolean }> = ({ setIsPreviewing, isNew }) => {
    const { t } = useTranslation();
    const isDesktop = useBreakpoint('sm');
    const headerText = isNew ? t('addListing') : t('editListing');

    return (
        <>
            {isDesktop ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setIsPreviewing(true)}>{t('preview')}</Button>
                    </Box>
                </>
            ) : (
                <AddListingHeader initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomBackButton isBackButtonVisible />
                            <HeaderText variant="h6">{headerText}</HeaderText>
                        </div>
                        <Button onClick={() => setIsPreviewing(true)}>{t('preview')}</Button>
                    </div>
                </AddListingHeader>
            )}
        </>
    );
};
