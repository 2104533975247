import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { SuccessSVG } from '../../components/SuccessSvg/SuccessSvg';
import { selectBooking } from '../../store/bookingReducer';
import { useAppSelector } from '../../store/hooks';
import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { itemVariantsB } from '../../animations/constants';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export const CheckoutCompleteView: React.FC = () => {
    const navigate = useSafeNavigate();
    const { id: listingId } = useParams() as { id: string };
    const { t } = useTranslation();
    const booking = useAppSelector((s) => selectBooking(s, listingId));

    useEffect(() => {
        if (!booking) {
            navigate(`/listing/${listingId}`);
        }
    }, [booking, listingId, navigate]);

    return (
        <>
            {booking && (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <StaggerList style={{ display: 'flex', gap: '32px', flexDirection: 'column' }} custom={{ stagger: 0.4, delay: 0.1 }}>
                        <StaggerListElement
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '40px',
                            }}
                        >
                            <RobesLogoFull height={70} />
                            <SuccessSVG variant="xs" />
                        </StaggerListElement>

                        <StaggerListElement style={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                                {t('checkoutSuccessTitle')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {t('checkoutSuccessBody')}
                            </Typography>
                        </StaggerListElement>

                        <StaggerListElement
                            style={{
                                width: '100%',
                                flex: '0.4 1 0',
                                display: 'flex',
                            }}
                            variants={itemVariantsB}
                            transition={{ delay: 1.3 }}
                        >
                            <Box
                                sx={{
                                    py: 3,
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                    gap: '10px',
                                }}
                            >
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <Button fullWidth variant="contained" onClick={() => navigate('/wardrobe?tab=2')}>
                                        {t('goToWardrobe')}
                                    </Button>
                                    <Button fullWidth variant="outlined" onClick={() => navigate('/listings')}>
                                        {t('exploreMore')}
                                    </Button>
                                </div>
                            </Box>
                        </StaggerListElement>
                    </StaggerList>
                </Box>
            )}
        </>
    );
};
