import { useNavigate, useLocation, To, NavigateOptions, NavigateFunction } from 'react-router-dom';
import { useCallback } from 'react';

/**
 * Exactly the same as useNavigate except:
 *
 * 1. It doesn't add duplicate entries to the stack
 * 2. It navigates to the home page if there are no entries in the history and user is trying to navigate back using delta -1
 */
export const useSafeNavigate = (): NavigateFunction => {
    const navigate = useNavigate();
    const location = useLocation();

    const safeNavigate = useCallback(
        (to: To | number, options?: NavigateOptions) => {
            if (typeof to === 'number' && to === -1) {
                // https://github.com/remix-run/history/blob/main/docs/api-reference.md#location
                if (location.key === 'default') {
                    navigate('/', options);
                } else {
                    navigate(to);
                }
            }

            // Prevent adding duplicate entries to the stack
            if (typeof to === 'string' || (typeof to === 'object' && 'pathname' in to)) {
                const targetLocation = typeof to === 'string' ? { pathname: to } : to;
                const currentFullPath = `${location.pathname}${location.search}${location.hash}`;
                const targetFullPath = `${targetLocation.pathname || ''}${targetLocation.search || ''}${targetLocation.hash || ''}`;

                if (targetFullPath === currentFullPath) {
                    return;
                }

                navigate(to, options);
            }
        },
        [navigate, location],
    );

    return safeNavigate;
};
