import { useQuery } from '@tanstack/react-query';
import { minimumVersionApiClient } from '../../services/sharetribe/apiClients';
import { Capacitor } from '@capacitor/core';
import { invariant } from '../../helpers/commonHelpers';
import semver from 'semver';

type MinimumVersions = {
    ios: string;
    android: string;
};

const clientVersion = process.env.REACT_APP_VERSION || '';

export const satisfiesMinimumVersion = async () => {
    const platform = Capacitor.getPlatform();
    invariant(platform === 'ios' || platform === 'android', 'Version check is only available on iOS and Android');

    const { data: minimumVersions } = await minimumVersionApiClient.get<MinimumVersions>('/');
    const satisfiesMinimumVersion = semver.gte(clientVersion, minimumVersions[platform]);

    return satisfiesMinimumVersion;
};

export const useCheckSatisfiesMinimumVersion = () => {
    const enabled = Capacitor.isNativePlatform();

    return useQuery(['versionCheck'], satisfiesMinimumVersion, { staleTime: 1000 * 60 * 15, enabled });
};
