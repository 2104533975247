import React, { useState } from 'react';
import parse from 'html-react-parser';
import { ButtonBase, Typography, styled } from '@mui/material';
import { Trans } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { TosSpan } from './SignUp.components';
import { template } from './TosTemplate';

export const ToSTemplate: React.FC = () => {
    const html = parse(template);
    return <>{html}</>;
};

const StyledDialogContent = styled('div')(({ theme }) => ({
    overflowY: 'auto',
    color: theme.palette.text.primary,
    padding: '20px',
}));

export const TermsOfService: React.FC = () => (
    <StyledDialogContent>
        <ToSTemplate />
    </StyledDialogContent>
);

export const TermsOfServiceModal: React.FC = () => {
    const [tosOpen, setTosOpen] = useState(false);

    return (
        <>
            <ButtonBase onClick={() => setTosOpen(true)} sx={{ width: '100%' }}>
                <Typography variant="caption">
                    <Trans i18nKey="tosAgreementText">
                        By signing up, you agree to our <TosSpan>Terms of Service</TosSpan>
                    </Trans>
                </Typography>
            </ButtonBase>

            <Modal open={tosOpen} onClose={() => setTosOpen(false)} variant="lg" modalStyle={{ overflowY: 'auto' }}>
                <TermsOfService />
            </Modal>
        </>
    );
};
