import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@mui/material/styles/styled';

export const FilterContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
}));

export const FilterComponent = styled('div')(() => ({
    padding: '15px 20px',
}));

export const FilterHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    width: '100%',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1230,
}));

export const HeaderContent = styled('div')(() => ({
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
}));

export const StyledFooter = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1230,
    paddingBottom: 'env(safe-area-inset-bottom)',
}));

export const FooterContent = styled('div')(() => ({
    display: 'flex',
    padding: '15px',
    justifyContent: 'center',
}));

export const ApplyFiltersButton = styled(LoadingButton)(() => ({
    width: '100%',
    padding: '5px 40px',
    borderRadius: '10px',
}));
