import React from 'react';
import { useEffect } from 'react';
import { ApplePayEventsEnum, GooglePayEventsEnum, PaymentSheetEventsEnum } from '@capacitor-community/stripe';
import { useCapacitorStripe } from '@capacitor-community/stripe/dist/esm/react/provider';
import { PluginListenerHandle } from '@capacitor/core';
import { isDev, sendToSentry } from '../../../helpers/commonHelpers';
import { logger } from '../../../helpers/logger';

type StripeEventEnum = ApplePayEventsEnum | GooglePayEventsEnum | PaymentSheetEventsEnum;

const reportToSentryEvents = [
    ApplePayEventsEnum.Failed,
    ApplePayEventsEnum.FailedToLoad,
    GooglePayEventsEnum.FailedToLoad,
    PaymentSheetEventsEnum.Failed,
    PaymentSheetEventsEnum.FailedToLoad,
];

export const useCapacitorStripeEvents = () => {
    const { stripe, isGooglePayAvailable, isApplePayAvailable } = useCapacitorStripe();

    useEffect(() => {
        if (!stripe) return;

        let listeners: PluginListenerHandle[] = [];

        const addListeners = async () => {
            const eventEnums: StripeEventEnum[] = [...Object.values(PaymentSheetEventsEnum)];

            if (isGooglePayAvailable) {
                eventEnums.push(...Object.values(GooglePayEventsEnum));
            }

            if (isApplePayAvailable) {
                eventEnums.push(...Object.values(ApplePayEventsEnum));
            }

            const listenerPromises = eventEnums.map(async (event) => {
                const addListener = stripe.addListener as any;
                const listener = await addListener(event, (info: any) => {
                    if (isDev) {
                        logger.info(`Stripe event: ${event}`, info);
                    }
                    if (reportToSentryEvents.includes(event)) {
                        const error = new Error(`Stripe payment error: ${event}`);
                        sendToSentry(error, { info });
                    }
                });
                return listener;
            });

            listeners = await Promise.all(listenerPromises);
        };

        addListeners();

        return () => {
            listeners.forEach((listener) => listener.remove());
        };
    }, [stripe, isApplePayAvailable, isGooglePayAvailable]);
};
