import { PluginListenerHandle, registerPlugin } from '@capacitor/core';
import { ActionPerformed } from '@capacitor/push-notifications';

interface NotifyListenersPlugin {
    addListener(
        eventName: 'pushNotificationActionPerformed',
        listenerFunc: (notification: ActionPerformed) => void,
    ): Promise<PluginListenerHandle> & PluginListenerHandle;
}

const NotifyListeners = registerPlugin<NotifyListenersPlugin>('NotifyListeners');

export default NotifyListeners;
