import { useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { StripeError } from '../../../types/apiTypes';
import { StripeConnectFormData } from './StripeConnectForm.types';
import { mapInputsToStripeAccountKeys, requiredInputs } from './StripeConnectForm.utils';

interface UseCreateBankTokenParams {
    countryCode: string;
    currency: string;
    onError: (error: any) => void;
    onSuccess: (token: string) => void;
}

export const useCreateBankToken = (params: UseCreateBankTokenParams) => {
    const { onSuccess, onError, countryCode, currency } = params;
    const stripe = useStripe();
    const { t } = useTranslation();

    return useMutation(
        async (form: StripeConnectFormData) => {
            if (!stripe || !countryCode || !currency) {
                return '';
            }

            const accountData = {
                country: countryCode,
                currency,
            };

            const required = requiredInputs(countryCode);
            const stripeAccountKeys = mapInputsToStripeAccountKeys(countryCode, form);
            const hasRequiredInputs = required.every((input) => !!form[input]);

            if (!hasRequiredInputs) {
                return '';
            }
            // @ts-ignore currently stripeAccountKeys doesn't 100% match the 'bank_account' overload
            const data = await stripe.createToken('bank_account', { ...accountData, ...stripeAccountKeys });

            if (data.error) {
                throw new Error(t('stripeTokenError'));
            }

            return data.token.id;
        },
        {
            onError: (err: AxiosError<StripeError>) => {
                const getMsg = () => {
                    if (typeof err.message === 'string') {
                        return err.message;
                    }
                    console.log(err);
                    return err.response?.data.error.statusText ?? 'Unknown error';
                };

                const msg = getMsg();
                onError(msg);
            },
            onSuccess: (token: string) => {
                onSuccess(token);
            },
        },
    );
};
