import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { IdentityVerification, IdentityVerificationState } from './IdentityVerification';
import { StepContent } from '../../components/NewUserOnboarding/UserOnboarding.components';
import { useStartRedirectFlow } from '../../hooks/auth/signicat/useStartRedirectFlow';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';

interface IdentityVerificationModalProps {
    redirectUrl: string;
    type?: IdentityVerificationState;
}

export const IdentityVerificationModal: React.FC<IdentityVerificationModalProps> = ({ redirectUrl, type = 'initial' }) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const redirectParams = {
        path: redirectUrl,
    };

    const { mutate: startRedirectFlow, isLoading } = useStartRedirectFlow(redirectParams);

    return (
        <>
            <Button variant="contained" sx={{ m: '15px', width: 'calc(100% - 30px)' }} onClick={() => setOpen(true)}>
                <motion.div animate={{ opacity: [0.3, 1, 0.3] }} transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}>
                    {t('verificationRequired')}
                </motion.div>
            </Button>

            <Modal open={open} onClose={() => setOpen(false)} variant="lg">
                <StepContent sx={{ pb: 2, overflowY: 'auto' }}>
                    <IdentityVerification type={type} />

                    <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                        {t('verifyIdentityShort')}
                    </LoadingButton>
                </StepContent>
            </Modal>
        </>
    );
};
