import { Sx } from '@mantine/core';
import { Box, SxProps } from '@mui/material';

interface IconSectionProps {
    icon: React.ReactNode;
    title: React.ReactNode;
    children: React.ReactNode;
    sx?: SxProps;
}

export const IconSection: React.FC<IconSectionProps> = ({ icon, title, children, sx }) => {
    return (
        <Box sx={{ py: 2, px: 1, display: 'flex', gap: '15px', alignItems: 'start', ...sx }}>
            {icon}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {title}
                {children}
            </Box>
        </Box>
    );
};
