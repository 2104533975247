import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './ImageCropper.helpers';
import { Variants, motion } from 'framer-motion';
import { sendToSentry } from '../../helpers/commonHelpers';
import { useDelayedEffect } from '../../hooks/useDelayedEffect';
import { isSafari } from 'react-device-detect';

const canvasVariants: Variants = {
    initial: (params) => ({ visibility: 'hidden', height: params.height, width: params.width }),
    in: {
        visibility: 'visible',
        width: 220,
        height: 220,
    },
};

interface ImageCropperProps {
    src: string;
    preview?: boolean;
    maxHeight?: number | string;
}

export const ImageCropper = forwardRef<{ getCroppedImageBlob: () => Promise<Blob | null | undefined> }, ImageCropperProps>(
    ({ src, preview, maxHeight }, ref) => {
        const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
        const [crop, setCrop] = useState<Crop>();

        const previewCanvasRef = useRef<HTMLCanvasElement>(null);
        const imgRef = useRef<HTMLImageElement>(null);

        const handleSetCrop = (crop: Crop) => {
            setCrop(crop);
        };

        useImperativeHandle(
            ref,
            () => ({
                async getCroppedImageBlob() {
                    const blob: Blob | PromiseLike<Blob> | null = await new Promise((resolve, reject) => {
                        if (previewCanvasRef.current) {
                            try {
                                previewCanvasRef.current.toBlob(
                                    (blob) => {
                                        const handleError = () => {
                                            const { width, height } = previewCanvasRef.current as HTMLCanvasElement;
                                            let str = 'No blob returned from toBlob';
                                            str += `Canvas size is ${width} x ${height}.`;
                                            str += `Crop size is ${crop?.width} x ${crop?.height}.`;
                                            str += `PixelCrop size is ${completedCrop?.width} x ${completedCrop?.height}`;
                                            reject(str);
                                        };

                                        if (blob) {
                                            resolve(blob);
                                        } else {
                                            // Fallback to dataURL if creating blob fails
                                            const dataUrl = previewCanvasRef.current?.toDataURL();
                                            if (dataUrl) {
                                                fetch(dataUrl)
                                                    .then((response) => response.blob())
                                                    .then((blob) => {
                                                        resolve(blob);
                                                    })
                                                    .catch(handleError);
                                            } else {
                                                handleError();
                                            }
                                        }
                                    },
                                    'image/jpeg',
                                    0.8,
                                );
                            } catch (err) {
                                sendToSentry(err);
                                reject('Could not convert canvas to Blob');
                            }
                        } else {
                            const err = new Error('No canvas reference found.');
                            sendToSentry(err);

                            reject('No canvas reference found.');
                        }
                    });
                    return blob;
                },
            }),
            [],
        );

        useDelayedEffect(() => {
            if (imgRef.current && previewCanvasRef.current && completedCrop) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        }, [completedCrop]);

        const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
            const { naturalWidth: width, naturalHeight: height, height: containerHeight, width: containerWidth } = e.currentTarget;

            const crop = centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 100,
                    },
                    1,
                    width,
                    height,
                ),
                width,
                height,
            );

            const pixelCrop = convertToPixelCrop(crop, containerWidth, containerHeight);

            handleSetCrop(crop);
            setCompletedCrop(pixelCrop);
        };

        return (
            <>
                {!!completedCrop && (
                    <>
                        {isSafari ? (
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    position: 'absolute',
                                    width: 220,
                                    height: 220,
                                    objectFit: 'contain',
                                    boxShadow: '0 5px 10px rgb(0 0 0 / 15%)',
                                    visibility: preview ? 'visible' : 'hidden',
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            <motion.canvas
                                variants={canvasVariants}
                                ref={previewCanvasRef}
                                initial="initial"
                                animate={preview ? 'in' : 'initial'}
                                exit="out"
                                custom={{ width: completedCrop?.width, height: completedCrop?.height }}
                                style={{
                                    position: 'absolute',
                                    maxHeight,
                                    objectFit: 'contain',
                                    boxShadow: '0 5px 10px rgb(0 0 0 / 15%)',
                                    visibility: preview ? 'visible' : 'hidden',
                                    borderRadius: '50%',
                                }}
                            />
                        )}
                    </>
                )}

                <ReactCrop
                    crop={crop}
                    onComplete={(c) => {
                        setCompletedCrop(c);
                    }}
                    onChange={(_crop, percentCrop) => {
                        handleSetCrop(percentCrop);
                    }}
                    aspect={1}
                    circularCrop
                    keepSelection
                    style={{ maxHeight, position: 'relative', visibility: preview ? 'hidden' : 'visible' }}
                >
                    <img ref={imgRef} src={src} onLoad={onImageLoad} />
                </ReactCrop>
            </>
        );
    },
);
