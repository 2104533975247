import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeUp } from '../../animations/constants';
import { Methods } from '../../queries/useDeliveryTiming';
import { BookingDetails, getReturnMethodFormFields, renderReturnMethodValue } from './LogisticsSection.helpers';
import { useFormContext } from 'react-hook-form';
import SwipeableFormDrawer from '../../components/SwipeableFormDrawer/SwipeableFormDrawer';
import { ArrowForwardIos } from '@mui/icons-material';
import { FormRenderer } from '../../components/FormRenderer/FormRenderer';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import { OpenDrawersState } from '../../context/drawer';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { RobesAddressSection } from './RobesAddressSection';
import { DisplayReturnTime } from './DisplayReturnTime';

interface ReturnDetailsSectionProps {
    booking: Booking | BookingDraft;
    bookingDetails: BookingDetails;
    methods: Methods;
    openDrawersState: OpenDrawersState;
    onClickDrawerButton: (drawerName: string) => void;
    onCloseDrawer: (drawerName: string) => void;
}

export const ReturnDetailsSection: React.FC<ReturnDetailsSectionProps> = ({
    bookingDetails,
    methods,
    openDrawersState,
    onClickDrawerButton,
    onCloseDrawer,
}) => {
    const { watch } = useFormContext();
    const { t } = useTranslation();

    const returnMethod = watch('returnMethod');

    const returnMethodFormFields = getReturnMethodFormFields(t, bookingDetails, methods);

    return (
        <AnimatedContainer variants={fadeUp} transition={{ delay: 0.1 }} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('returnSectionTitle')}
            </Typography>

            <SwipeableFormDrawer
                id="returnMethod"
                label={t('selectReturnMethod')}
                buttonLabel={t('returnMethod')}
                open={openDrawersState.returnMethod || false}
                onClick={() => onClickDrawerButton('returnMethod')}
                onClose={() => onCloseDrawer('returnMethod')}
                renderValue={renderReturnMethodValue()}
                sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={
                    <StyledSvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M372.308-267.692 160-480l212.308-212.308L400.615-664 236.616-500H760v-160h40v200H236.616l163.999 164-28.307 28.308Z" />
                        </svg>
                    </StyledSvgIcon>
                }
            >
                <FormRenderer formFields={returnMethodFormFields} />
            </SwipeableFormDrawer>

            {returnMethod && (
                <AnimatedContainer variants={fadeUp} transition={{ delay: 0.1 }} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Divider />

                    <RobesAddressSection sectionLabel={t('returnAddress')} showDescription />

                    <Divider />

                    <DisplayReturnTime t={t} bookingDetails={bookingDetails} methods={methods} />
                </AnimatedContainer>
            )}
        </AnimatedContainer>
    );
};
