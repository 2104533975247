import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userApiClient } from '../../services/sharetribe/apiClients';
import { User } from '../../types/apiTypes';
import { useLogout } from './useLogout';
import { useAppDispatch } from '../../store/hooks';
import { resetToken } from '../../store/userReducer';
import { useSafeNavigate } from '../useSafeNavigate';
import { clearStoredPaths } from '../../components/ScrollRestore/ScrollRestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../helpers/commonHelpers';
import { Preferences } from '@capacitor/preferences';

const deleteAccountFn = async (currentPassword?: string) => {
    const { data } = await userApiClient.delete('/', { data: { currentPassword } });

    return data;
};

export const useDeleteAccount = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const resetUser = async () => {
        dispatch(resetToken());
        queryClient.clear();
        clearStoredPaths();

        await Preferences.remove({ key: 'accountDeletionStatus' });
    };

    return useMutation(deleteAccountFn, {
        onSuccess: async () => {
            onSuccess?.();

            const msg = t('deleteAccountSuccess');
            toast.info(msg);

            await sleep(1000);

            resetUser();
            navigate('/login', { state: { loggedOut: true } });
        },
        onError: () => {
            const msg = t('deleteAccountFailed');
            toast.error(msg);
        },
    });
};
