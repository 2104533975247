import React from 'react';
import { Listing } from '../../../types/apiTypes';
import { Box, ButtonBase, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { ImageSlider } from '../../../components/ImageSlider/ImageSlider';
import { FavoriteButton } from '../../../components/FavoriteButton/FavoriteButton';
import { capitalizeEachWord, formatPriceStyled, getListingTitle } from '../../../helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { multiplyMoney } from '../../../helpers/currency';
import { minimumRentalLength } from '../../../constants';

interface ListingItemProps {
    listing: Listing;
    compactMode: boolean;
    isDesktop: boolean;
    isFavorited: boolean;
    onClick?: (listing: Listing) => void;
    toggleFavorite?: (listingId: string) => void;
}

export const ListingItem: React.FC<ListingItemProps> = ({ listing, compactMode, isDesktop, isFavorited, onClick, toggleFavorite }) => {
    const { t } = useTranslation();
    const imageHeight = compactMode || isDesktop ? 200 : 400;

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.tagName !== 'svg') {
            if (target.tagName === 'BUTTON') {
                event.stopPropagation();
            } else {
                onClick?.(listing);
            }
        }
    };

    const { category, size } = listing.publicData;
    const priceText = `${formatPriceStyled(listing.price)} / ${t('dayAbbreviated')}`;
    const bodyText = [category, size]
        .filter(Boolean)
        .map((el) => t(el))
        .join(', ');

    return (
        <Box sx={{ padding: compactMode && !isDesktop ? '0px 15px' : '0px 45px' }}>
            <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                <ButtonBase
                    component="div"
                    sx={{ width: '100%', height: '100%', borderRadius: '10px', '&:hover': { cursor: 'pointer' } }}
                    onClick={handleClick}
                >
                    <ImageSlider
                        key={String(compactMode)}
                        hash={listing.publicData.blurhash}
                        height={imageHeight}
                        images={listing.images}
                        borderRadius={10}
                        loadEager
                    />
                </ButtonBase>

                <div style={{ position: 'absolute', top: '5px', right: '0px' }}>
                    <FavoriteButton selected={isFavorited} onClick={() => toggleFavorite?.(listing.id)} />
                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'column', margin: '3px 0' }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontVariationSettings: "'wght' 500",
                        fontWeight: '400',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {capitalizeEachWord(listing.publicData.brand)}
                </Typography>

                <Typography variant="subtitle2" sx={{ opacity: 0.7, fontWeight: '400' }}>
                    {bodyText}
                </Typography>

                <Typography variant="subtitle2" sx={{ fontVariationSettings: "'wght' 500", fontWeight: '400' }}>
                    {priceText}
                </Typography>
            </Box>
        </Box>
    );
};

const DESKTOP_HEIGHT = 300;

type ListingItemDesktopProps = Omit<ListingItemProps, 'compactMode' | 'isDesktop'>;

export const ListingItemDesktop: React.FC<ListingItemDesktopProps> = ({ listing, isFavorited, onClick, toggleFavorite }) => {
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.tagName !== 'svg') {
            if (target.tagName === 'BUTTON') {
                event.stopPropagation();
            } else {
                onClick?.(listing);
            }
        }
    };

    const title = getListingTitle(listing, t);

    return (
        <Card
            sx={{
                height: DESKTOP_HEIGHT,
                position: 'relative',
                borderRadius: '10px',
                boxShadow: (t) => t.palette.boxShadow['elevation-3'],
                ':hover': {
                    boxShadow: (t) => t.palette.boxShadow['elevation-12'],
                },
            }}
        >
            <CardActionArea onClick={handleClick}>
                <ImageSlider hash={listing.publicData.blurhash} height={DESKTOP_HEIGHT - 80} images={listing.images} borderRadius={0} />
            </CardActionArea>
            <CardContent sx={{ p: 1 }}>
                <div style={{ position: 'absolute', top: '5px', right: '0px' }}>
                    <FavoriteButton selected={isFavorited} onClick={() => toggleFavorite?.(listing.id)} />
                </div>
                <Typography
                    variant="overline"
                    sx={{
                        width: '100%',
                        display: 'block',
                        textTransform: 'capitalize',
                        textOverflow: 'ellipsis',
                        overflow: ' hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {title}
                </Typography>

                <Typography variant="body2">
                    <strong>{formatPriceStyled(listing.price)}</strong> {`/ ${t('dayAbbreviated')}`}
                </Typography>
            </CardContent>
        </Card>
    );
};
