import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSafeNavigate } from './useSafeNavigate';
import { usePrevious } from './usePrevious';

/**
 * Custom hook to manage navigation with hash for opening and closing components like drawers.
 *
 * @param {boolean} isOpen - The open state of the component.
 * @param {string} identifier - Unique identifier for the component, used in the URL hash.
 * @param {Function} onClose - Callback function to be called when the component is closed via URL hash change.
 */
export const useHashNavigation = (isOpen: boolean, identifier: string, onClose?: () => void) => {
    const navigate = useSafeNavigate();
    const location = useLocation();
    const hash = `#${identifier}`;
    // Track whether the navigation was initiated programmatically.
    const programmaticNavigation = useRef(false);
    const previousHash = usePrevious(location.hash);

    useEffect(() => {
        const currentHash = location.hash;
        const desiredHashPresent = currentHash === hash;

        const handleAddHash = () => {
            programmaticNavigation.current = true; // Flag navigation as programmatic.
            navigate(`${location.pathname}${location.search}${hash}`);
        };

        const handleRemoveHash = () => {
            programmaticNavigation.current = true; // Flag navigation as programmatic.
            navigate(-1);
        };

        if (previousHash === hash) {
            if (!isOpen && desiredHashPresent) {
                handleRemoveHash();
            }

            return;
        }

        if (isOpen && !desiredHashPresent) {
            handleAddHash();
        } else if (!isOpen && desiredHashPresent) {
            handleRemoveHash();
        }
    }, [isOpen, navigate, location, hash]);

    useEffect(() => {
        const handleHashChange = () => {
            // Only act on hash changes if they were not initiated programmatically.
            if (!programmaticNavigation.current && location.hash !== hash && isOpen) {
                onClose?.();
            }
            // Reset the flag after reacting to a hash change.
            programmaticNavigation.current = false;
        };

        // Listen for hash changes.
        window.addEventListener('hashchange', handleHashChange);
        // Initial check in case the component mounts after the hash is already set.
        handleHashChange();

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [isOpen, location, hash, onClose]);

    // Ensure programmaticNavigation flag is reset if component unmounts immediately after navigating.
    useEffect(() => {
        return () => {
            programmaticNavigation.current = false;
        };
    }, []);
};
