import React from 'react';
import { SelectList } from '../../../../components/SelectList/SelectList';
import { DrawerConfig } from '../../../../components/FilterBar/Filterbar.types';
import { ListElement } from '../../../../components/SelectList/types';

interface SingleSelectFilterProps {
    options: ListElement[];
    selectedValue: string;
    onSelectOption: (option: any) => void;
    isSelectedFn?: (value: string) => boolean;
}

export const SingleSelectFilterOptions: React.FC<SingleSelectFilterProps> = ({ options, selectedValue, onSelectOption, isSelectedFn }) => {
    const hideSearch = options.length < 20;

    return (
        <SelectList
            hideSearch={hideSearch}
            options={options}
            selectedValue={selectedValue}
            onSelectOption={onSelectOption}
            multiSelect={false}
            selectedFn={isSelectedFn}
            sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
        />
    );
};
