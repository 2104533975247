import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { transactionsApiClient } from '../../services/sharetribe/apiClients';
import { TransactionHistory } from '../apiTypes';
import { getNextPageParam } from '../../helpers/commonHelpers';
import { ResponseMeta } from '../../types/apiTypes';
import { makeQueryString } from '../../helpers/urlHelpers';

/**
 * Queries the last transaction for a specific listing. Sharetribe returns the transaction history items in descending order (sorted by createdAt)
 *
 * @param type 'sale' returns transactions where user is the lender, 'order' returns transactions where user is the renter.
 * omitting type returns both
 */
export const useOwnTransactions = (
    type?: 'sale' | 'order',
    startingPage = 1,
    perPage = 10,
    transitions?: string[],
): UseInfiniteQueryResult<TransactionHistory> => {
    const getTransactions = async ({ pageParam = startingPage }) => {
        const params = { type, page: pageParam.toString(), perPage: perPage.toString() };
        const filterTransitions = transitions ? `&transitions=${transitions.join(',')}` : '';
        const url = `/own?${makeQueryString(params)}${filterTransitions}`;

        const { data } = await transactionsApiClient.get<{
            data: TransactionHistory;
            meta: ResponseMeta;
        }>(url);

        return {
            ...data,
            nextPage: pageParam + 1,
        };
    };

    return useInfiniteQuery(['own-transactions', type, transitions], getTransactions, {
        staleTime: 2 * 60 * 1000,
        getNextPageParam,
    });
};
