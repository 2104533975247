import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useEffect, useState } from 'react';

export const useInit3rdPartyLogins = () => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const init3rdPartyLogins = async () => {
            const getGoogleClientId = () => {
                const platformClientIds = {
                    ios: process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID,
                    web: process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID,
                    android: process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID,
                };
                const platform = Capacitor.getPlatform() as 'ios' | 'android' | 'web';
                return platformClientIds[platform];
            };

            GoogleAuth.initialize({
                clientId: getGoogleClientId(),
                scopes: ['profile', 'email'],
            });

            setInitialized(true);
        };
        if (!initialized) {
            init3rdPartyLogins();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
