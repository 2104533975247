import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../components/ConfirmDialog/ConfirmDialog';
import { ReactFCWithChildren } from '../types/types';

interface ModalProps {
    title: string;
    message?: React.ReactNode;
    cancelText?: React.ReactNode;
    confirmText?: React.ReactNode;
    loading?: boolean;
    onConfirm?: () => void;
    onClose?: () => void;
}

type Confirm = (options: Partial<ModalProps>) => Promise<unknown>;
type Close = () => void;
type UpdateOptions = (options: Partial<ModalProps>) => void;
type ConfirmationContext = { confirm: Confirm; close: Close; updateOptions: UpdateOptions };

const ConfirmationContext = createContext<ConfirmationContext>({ confirm: () => Promise.resolve(), close: () => null, updateOptions: () => null });

export const ConfirmProvider: ReactFCWithChildren = ({ children }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<Partial<ModalProps> | undefined>();
    const [resolveReject, setResolveReject] = useState<((value?: unknown) => void)[]>([]);
    const [resolve, reject] = resolveReject;

    const defaultModalProps = useMemo(
        () => ({
            title: t('confirmDialogTitle'),
            bodyText: null,
            loading: false,
        }),
        [t],
    );

    const confirm = useCallback(
        (props: Partial<ModalProps>) =>
            new Promise((res, rej) => {
                setOptions({ ...defaultModalProps, ...props });
                setResolveReject([res, rej]);
            }),
        [defaultModalProps],
    );

    const handleClose = useCallback(() => {
        if (reject) {
            reject();
            setResolveReject([]);
        }
    }, [reject]);

    const handleConfirm = useCallback(() => {
        if (resolve) {
            resolve();
            handleClose();
        }
    }, [resolve, handleClose]);

    const updateOptions = useCallback(
        (opts: Partial<ModalProps>) => {
            setOptions({ ...options, ...opts });
        },
        [options, setOptions],
    );

    return (
        <>
            <ConfirmationContext.Provider value={{ confirm, close: handleClose, updateOptions }}>{children}</ConfirmationContext.Provider>
            <ConfirmDialog open={resolveReject.length === 2} onCancel={handleClose} onConfirm={handleConfirm} {...options} />
        </>
    );
};

export const useConfirm = () => {
    const confirm = useContext(ConfirmationContext);
    return confirm;
};
