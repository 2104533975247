import React, { useEffect, useRef, useState } from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Container } from './ImageSlider.components';
import { ReactFCWithChildren } from '../../types/types';
import { Image } from '../../types/apiTypes';
import { ImageLoader } from '../ImageLoader/ImageLoader';
import { Carousel, CarouselStylesNames } from '@mantine/carousel';
import { ImageOverlay } from './ImageOverlay';
import { Styles, getStylesRef, rem } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { SxProps } from '@mui/system';
import { resolveImageUrl } from '../../helpers/commonHelpers';

const getCarouselConfig = (desktop: boolean, imagesCount: number, multiple: boolean) => {
    const hasMultipleImages = imagesCount > 1;
    const divisor = hasMultipleImages ? Math.min(3, imagesCount) : 1;
    const slideSize = `${100 / divisor}%`;

    if (desktop) {
        const baseDesktopConfig = {
            draggable: false,
            withIndicators: hasMultipleImages,
            withControls: hasMultipleImages,
        };

        return multiple ? { ...baseDesktopConfig, slideSize, gap: 'md', align: 'start' as const } : baseDesktopConfig;
    }

    return {
        draggable: hasMultipleImages,
        withIndicators: hasMultipleImages,
        align: 'start' as const,
        withControls: false,
    };
};

const carouselStyles: Styles<CarouselStylesNames> = {
    controls: {
        ref: getStylesRef('controls'),
        transition: 'opacity 150ms ease',
        opacity: 0,
    },

    root: {
        '&:hover': {
            [`& .${getStylesRef('controls')}`]: {
                opacity: 1,
            },
        },
    },
    control: {
        '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
        },
    },
    indicators: { gap: rem(5), bottom: rem(20), alignItems: 'center' },
    indicator: {
        width: rem(4),
        height: rem(4),
        zIndex: 10,
        transition: 'all 250ms ease',

        '&[data-active]': {
            width: rem(6),
            height: rem(6),
        },
    },
};

interface ImageSliderProps {
    hash?: string;
    images: Image[];
    borderRadius?: number;
    loadEager?: boolean;
    height?: string | number;
    allowFullScreen?: boolean;
    multiple?: boolean; // Show multiple images at once on
    enableAutoplay?: boolean;
    autoplayDelay?: number;
    containerProps?: SxProps;
    onClick?: (index: number) => void;
    renderOverlay?: (idx: number, selectedPage: number) => React.ReactNode;
}

export const ImageSlider: ReactFCWithChildren<ImageSliderProps> = React.memo(
    ({
        hash,
        images,
        borderRadius = 10,
        multiple = false,
        height,
        containerProps,
        renderOverlay,
        onClick,
        enableAutoplay,
        loadEager = false,
        allowFullScreen = false,
        autoplayDelay = 4000,
    }) => {
        const [autoplaying, setAutoplaying] = useState(enableAutoplay);
        const [selected, setSelected] = useState(0);
        const [fullScreenPage, setFullScreenPage] = useState<number>();

        const handleClick = (idx: number) => {
            if (allowFullScreen) {
                setFullScreenPage(idx);
            } else if (onClick) {
                onClick(idx);
            }
        };

        const autoplay = useRef(Autoplay({ delay: autoplayDelay, playOnInit: autoplaying }));

        const isDesktop = useBreakpoint('sm');

        useEffect(() => {
            if (!enableAutoplay && autoplaying) {
                autoplay.current.stop();
                setAutoplaying(false);
            }
            if (enableAutoplay && !autoplaying) {
                autoplay.current.play();
                setAutoplaying(true);
            }
        }, [enableAutoplay]);

        return (
            <Container sx={containerProps}>
                <Carousel
                    height={height}
                    onSlideChange={setSelected}
                    containScroll="trimSnaps"
                    plugins={[autoplay.current]}
                    onMouseEnter={autoplay.current.stop}
                    onMouseLeave={autoplay.current.reset}
                    styles={carouselStyles}
                    {...getCarouselConfig(isDesktop, images.length, multiple)}
                    loop
                >
                    {images.map((img, i) => (
                        <Carousel.Slide key={img.url} onClick={() => handleClick(i)} style={{ height: '100%' }}>
                            <ImageLoader
                                hash={hash}
                                url={resolveImageUrl(img)}
                                borderRadius={borderRadius}
                                loadEager={loadEager}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            {renderOverlay?.(i, selected)}
                        </Carousel.Slide>
                    ))}
                </Carousel>

                <ImageOverlay
                    images={images.map(resolveImageUrl)}
                    opened={fullScreenPage !== undefined}
                    initialSlide={fullScreenPage}
                    onClose={() => setFullScreenPage(undefined)}
                />
            </Container>
        );
    },
);
