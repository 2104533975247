import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { IdentityVerification, IdentityVerificationState } from './IdentityVerification';
import { StepContent, StepContentContainer } from '../../components/NewUserOnboarding/UserOnboarding.components';
import { useStartRedirectFlow } from '../../hooks/auth/signicat/useStartRedirectFlow';
import { LoadingButton } from '@mui/lab';

interface IdentityVerificationResultModalProps {
    open: boolean;
    onClose: () => void;
    redirectUrl: string;
    type?: IdentityVerificationState;
}

export const IdentityVerificationResultModal: React.FC<IdentityVerificationResultModalProps> = ({ open, onClose, redirectUrl, type = 'initial' }) => {
    const { t } = useTranslation();

    const redirectParams = {
        path: redirectUrl,
    };

    const { mutate: startRedirectFlow, isLoading } = useStartRedirectFlow(redirectParams);

    return (
        <Modal open={open} onClose={onClose}>
            <StepContent sx={{ p: 2 }}>
                <StepContentContainer>
                    <IdentityVerification type={type} />
                </StepContentContainer>

                {(type === 'abort' || type === 'error') && (
                    <>
                        <LoadingButton variant="contained" onClick={() => startRedirectFlow()}>
                            {t('tryAgain')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {type === 'initial' && (
                    <>
                        <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                            {t('verifyIdentityShort')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {type === 'success' && (
                    <>
                        <LoadingButton variant="contained" onClick={onClose}>
                            {t('continue')}
                        </LoadingButton>
                    </>
                )}
            </StepContent>
        </Modal>
    );
};
