import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';

import { createRoutesFromDefinition } from './routes/routes';

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const getRouter = () => sentryCreateBrowserRouter(createRoutesFromDefinition());

const App = (): JSX.Element => {
    const [router] = useState(getRouter());

    return <RouterProvider router={router} />;
};

export default App;
