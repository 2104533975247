import { useQuery } from '@tanstack/react-query';
import { strapiApiClient } from '../services/sharetribe/apiClients';
import { addDays, addMinutes, differenceInDays, endOfDay, getDay, isSameDay, isWithinInterval, parseISO, roundToNearestMinutes, set } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { NullableDateLike, formatDayMonthAndDate, formatHoursAndMinutes, parseDateStringUTC } from '../helpers/dateAndTimeHelpers';
import { Booking, BookingDraft } from '../store/bookingReducer';
import { assertIsDefined, invariant } from '../helpers/commonHelpers';
import { isBooking } from '../views/UserProfile/Payments/helpers';

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const daysOfWeek: DayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

type OpeningHourDefinition = {
    startDate: DayOfWeek;
    endDate: DayOfWeek;
    openingHour: string;
    closingHour: string;
};

type DeliveryMethodConfiguration = {
    availabilityHours: OpeningHourDefinition[];
    leadTimeDays: number;
    startBufferDays: number;
    endBufferDays: number;
    minimumPrice?: number;
    requestBufferMinutes?: number;
};

export type OfficeConfiguration = {
    openingHours: OpeningHourDefinition[];
    woltConfiguration: DeliveryMethodConfiguration;
    faceToFaceConfiguration: DeliveryMethodConfiguration;
    showroomConfiguration: DeliveryMethodConfiguration;
};

type DeliveryMethodDates = {
    renterDeliveryDate: string;
    renterReturnDate: string;
    bufferedBookingStart: string;
    bufferedBookingEnd: string;
    enabled: boolean;
};

type DeliveryMethods = {
    wolt: DeliveryMethodDates;
    showroom: DeliveryMethodDates;
    faceToFace: DeliveryMethodDates;
};

export type DeliveryConfigurationParams = {
    bookingStart: string;
    bookingEnd: string;
    listingId: string;
};

export const fetchOfficeConfiguration = async () => {
    const { data } = await strapiApiClient.get<OfficeConfiguration>('/office-configuration');
    return data;
};

export const useOfficeConfiguration = () => {
    return useQuery(['office-configuration'], fetchOfficeConfiguration);
};

export const useDeliveryMethodsForListing = (params?: DeliveryConfigurationParams) => {
    const { bookingStart, bookingEnd, listingId } = params || {};
    const enabled = Boolean(bookingStart && bookingEnd && listingId);

    const getDeliveryMethods = async () => {
        const queryString = new URLSearchParams(params);
        const url = `/delivery-methods?${queryString}`;
        const { data } = await strapiApiClient.get<DeliveryMethods>(url);

        return data;
    };

    return useQuery(['delivery-methods', { params }], getDeliveryMethods, { enabled });
};

export const findCurrentOrNextOpeningHour = (openingHours: OpeningHourDefinition[], date: Date): OpeningHourDefinition | undefined => {
    const day = date.getDay();
    const currentDayIndex = daysOfWeek.indexOf(daysOfWeek[day]);

    const currentOpeningHour = openingHours.find((openingHour) => {
        const startDayIndex = daysOfWeek.indexOf(openingHour.startDate);
        const endDayIndex = daysOfWeek.indexOf(openingHour.endDate);

        if (startDayIndex <= endDayIndex) {
            // Normal case where the start day is before the end day
            return currentDayIndex >= startDayIndex && currentDayIndex <= endDayIndex;
        } else {
            // Case where the range wraps around to the next week
            return currentDayIndex >= startDayIndex || currentDayIndex <= endDayIndex;
        }
    });

    if (currentOpeningHour) {
        return currentOpeningHour;
    }

    // If no current opening hour is found, find the next one
    const sortedOpeningHours = [...openingHours].sort((a, b) => daysOfWeek.indexOf(a.startDate) - daysOfWeek.indexOf(b.startDate));

    return sortedOpeningHours.find((openingHour) => daysOfWeek.indexOf(openingHour.startDate) > currentDayIndex) || sortedOpeningHours[0];
};

export const findCurrentOrPreviousOpeningHour = (openingHours: OpeningHourDefinition[], date: Date): OpeningHourDefinition | undefined => {
    const day = date.getDay();
    const currentDayIndex = daysOfWeek.indexOf(daysOfWeek[day]);

    const currentOpeningHour = openingHours.find((openingHour) => {
        const startDayIndex = daysOfWeek.indexOf(openingHour.startDate);
        const endDayIndex = daysOfWeek.indexOf(openingHour.endDate);

        if (startDayIndex <= endDayIndex) {
            // Normal case where the start day is before the end day
            return currentDayIndex >= startDayIndex && currentDayIndex <= endDayIndex;
        } else {
            // Case where the range wraps around to the next week
            return currentDayIndex >= startDayIndex || currentDayIndex <= endDayIndex;
        }
    });

    if (currentOpeningHour) {
        return currentOpeningHour;
    }

    // If no current opening hour is found, find the previous one
    const sortedOpeningHours = [...openingHours].sort((a, b) => daysOfWeek.indexOf(a.startDate) - daysOfWeek.indexOf(b.startDate));

    return (
        sortedOpeningHours.reverse().find((openingHour) => daysOfWeek.indexOf(openingHour.endDate) < currentDayIndex) ||
        sortedOpeningHours[sortedOpeningHours.length - 1]
    );
};

const getOfficeOpenDays = (openingHours: OpeningHourDefinition[]) => {
    const openDays = new Set<number>();

    openingHours.forEach((openingHour) => {
        const startDayIndex = daysOfWeek.indexOf(openingHour.startDate);
        const endDayIndex = daysOfWeek.indexOf(openingHour.endDate);

        if (startDayIndex <= endDayIndex) {
            for (let i = startDayIndex; i <= endDayIndex; i++) {
                openDays.add(i);
            }
        } else {
            for (let i = startDayIndex; i <= 6; i++) {
                openDays.add(i);
            }
            for (let i = 0; i <= endDayIndex; i++) {
                openDays.add(i);
            }
        }
    });

    return openDays;
};

// How many days of week is the office open per week
export const calculateOfficeOpenDays = (openingHours: OpeningHourDefinition[]) => {
    let openDays = 0;

    openingHours.forEach((hours) => {
        const startDayIndex = daysOfWeek.indexOf(hours.startDate);
        const endDayIndex = daysOfWeek.indexOf(hours.endDate);

        // If the start day is after the end day, it means the period wraps around the end of the week
        if (startDayIndex > endDayIndex) {
            openDays += daysOfWeek.length - startDayIndex + (endDayIndex + 1);
        } else {
            openDays += endDayIndex - startDayIndex + 1;
        }
    });

    return openDays;
};

// How many days of week is the office closed per week
export const calculateOfficeClosedDays = (openingHours: OpeningHourDefinition[]) => {
    const closedDays = daysOfWeek.length - calculateOfficeOpenDays(openingHours);
    invariant(closedDays >= 0, 'Closed days should not be negative');
    return closedDays;
};

// Hour definition is a string in the format "HH:MM:SS"
const parseHourDefinition = (openingHour: string) => {
    const [hours, minutes, seconds] = openingHour.split(':').map(Number);
    return { hours, minutes, seconds };
};

const getOpeningHoursForDeliveryMethod = (officeConfiguration: OfficeConfiguration, deliveryMethod?: string) => {
    switch (deliveryMethod) {
        case 'wolt':
            return officeConfiguration.woltConfiguration.availabilityHours;
        case 'showroom':
            return officeConfiguration.showroomConfiguration.availabilityHours;
        case 'faceToFace':
            return officeConfiguration.faceToFaceConfiguration.availabilityHours;
        default:
            return officeConfiguration.openingHours;
    }
};

/**
 * Returns methods useful for getting information about the office opening hours and configuration.
 */
export const useOfficeConfigurationMethods = () => {
    const { status, data: officeConfiguration } = useOfficeConfiguration();
    const { t } = useTranslation();

    const getCurrentOrNextOpeningHourString = useCallback(
        (d: NullableDateLike, deliveryMethod?: string): string | null => {
            if (!officeConfiguration || !d) return null;

            const date = typeof d === 'string' ? parseISO(d) : d;

            const allOpeningHoursForDeliveryMethod = getOpeningHoursForDeliveryMethod(officeConfiguration, deliveryMethod);
            const openingHours = findCurrentOrNextOpeningHour(allOpeningHoursForDeliveryMethod, date);

            if (!openingHours) return null;

            return `${formatDayMonthAndDate(date)} ${formatHoursAndMinutes(openingHours.openingHour)} - ${formatHoursAndMinutes(
                openingHours.closingHour,
            )}`;
        },
        [officeConfiguration],
    );

    const getCurrentOrPreviousOpeningHourString = useCallback(
        (d: NullableDateLike, deliveryMethod?: string): string | null => {
            if (!officeConfiguration || !d) return null;

            const date = typeof d === 'string' ? parseISO(d) : d;

            const allOpeningHoursForDeliveryMethod = getOpeningHoursForDeliveryMethod(officeConfiguration, deliveryMethod);
            const openingHours = findCurrentOrPreviousOpeningHour(allOpeningHoursForDeliveryMethod, date);

            if (!openingHours) return null;

            return `${formatDayMonthAndDate(date)} ${formatHoursAndMinutes(openingHours.openingHour)} - ${formatHoursAndMinutes(
                openingHours.closingHour,
            )}`;
        },
        [officeConfiguration],
    );

    const getDeliveryInformation = useCallback(
        (d: NullableDateLike, bookingDate: string | undefined, deliveryMethod: 'wolt' | 'showroom') => {
            if (!officeConfiguration || !d || !bookingDate) return null;

            const date = typeof d === 'string' ? new Date(d) : d;

            const configuration = deliveryMethod === 'wolt' ? officeConfiguration.woltConfiguration : officeConfiguration.showroomConfiguration;

            const nextOpeningHour = findCurrentOrNextOpeningHour(configuration.availabilityHours, date);

            if (!nextOpeningHour) return null;

            const now = new Date();
            const requestBufferMinutes = configuration.requestBufferMinutes;

            let min = set(date, { ...parseHourDefinition(nextOpeningHour.openingHour) });
            const max = set(date, { ...parseHourDefinition(nextOpeningHour.closingHour) });

            // Do not allow choosing delivery times that are less than now + requestBufferMinutes
            if (requestBufferMinutes && isWithinInterval(now, { start: min, end: max })) {
                const temp = addMinutes(now, requestBufferMinutes);
                min = roundToNearestMinutes(temp, { nearestTo: 30, roundingMethod: 'ceil' });
            }

            const localDateUTC = date.toISOString();

            // Booking date is in UTC, so we need to convert it to local time
            const bookingDateObj = endOfDay(parseISO(bookingDate));
            const localDateObj = endOfDay(parseISO(localDateUTC));

            const isDeliveryDateDifferent = !isSameDay(bookingDateObj, localDateObj);

            const daysDiff = isDeliveryDateDifferent ? Math.abs(differenceInDays(localDateObj, bookingDateObj)) : 0;

            return { min, max, isDeliveryDateDifferent, daysDiff };
        },
        [officeConfiguration],
    );

    // Returns a string of the availability hours for the office (if forConfig is not provided) or for a specific delivery method in format "Mon-Fri"
    const getAvailabilityHoursDatesString = useCallback(
        (forConfig?: string) => {
            if (!officeConfiguration) return '';

            let openingHours = officeConfiguration.openingHours;

            if (forConfig === 'wolt') {
                openingHours = officeConfiguration.woltConfiguration.availabilityHours;
            }

            if (forConfig === 'showroom') {
                openingHours = officeConfiguration.showroomConfiguration.availabilityHours;
            }

            if (forConfig === 'faceToFace') {
                openingHours = officeConfiguration.faceToFaceConfiguration.availabilityHours;
            }

            return openingHours
                .map((openingHour) => {
                    if (openingHour.startDate === openingHour.endDate) {
                        return `${t(openingHour.startDate + 'Short')}`;
                    }
                    return `${t(openingHour.startDate + 'Short')}-${t(openingHour.endDate + 'Short')}`;
                })
                .join(', ');
        },
        [t, officeConfiguration],
    );

    // Returns a string of the availability hours for the office (if forConfig is not provided) or for a specific delivery method in format "Mon-Tue 10:00-18:00, Wed 12:00-20:00"
    const getAvailabilityHoursString = useCallback(
        (forConfig?: string) => {
            if (!officeConfiguration) return '';

            let openingHours = officeConfiguration.openingHours;

            if (forConfig === 'wolt') {
                openingHours = officeConfiguration.woltConfiguration.availabilityHours;
            }

            if (forConfig === 'showroom') {
                openingHours = officeConfiguration.showroomConfiguration.availabilityHours;
            }

            if (forConfig === 'faceToFace') {
                openingHours = officeConfiguration.faceToFaceConfiguration.availabilityHours;
            }

            return openingHours
                .map((openingHour) => {
                    if (openingHour.startDate === openingHour.endDate) {
                        return `${t(openingHour.startDate + 'Short')} ${formatHoursAndMinutes(openingHour.openingHour)}-${formatHoursAndMinutes(
                            openingHour.closingHour,
                        )}`;
                    }
                    return `${t(openingHour.startDate + 'Short')}-${t(openingHour.endDate + 'Short')} ${formatHoursAndMinutes(
                        openingHour.openingHour,
                    )}-${formatHoursAndMinutes(openingHour.closingHour)}`;
                })
                .join(', ');
        },
        [t, officeConfiguration],
    );

    const getMinimumPriceForDeliveryMethod = useCallback(
        (deliveryMethod: string) => {
            if (!officeConfiguration) return 0;

            const { woltConfiguration, showroomConfiguration, faceToFaceConfiguration } = officeConfiguration;

            switch (deliveryMethod) {
                case 'wolt':
                    return woltConfiguration.minimumPrice || 0;
                case 'showroom':
                    return showroomConfiguration.minimumPrice || 0;
                case 'faceToFace':
                    return faceToFaceConfiguration.minimumPrice || 0;
                default:
                    return 0;
            }
        },
        [officeConfiguration],
    );

    const getNextDateWithinOpeningHours = useCallback(
        (d: NullableDateLike) => {
            if (!officeConfiguration || !d) return null;

            const date = typeof d === 'string' ? parseISO(d) : d;

            const currentDayOfWeek = getDay(date);
            const openingDaysOfWeek = getOfficeOpenDays(officeConfiguration.openingHours);

            // If the current day of week is within the opening days, return the date
            if (openingDaysOfWeek.has(currentDayOfWeek)) {
                return date;
            }

            // If the current day of week is not within the opening days, find the next opening day
            let nextDate = addDays(date, 1);

            while (!openingDaysOfWeek.has(getDay(nextDate))) {
                nextDate = addDays(nextDate, 1);
            }

            return nextDate;
        },
        [officeConfiguration],
    );

    return {
        status,
        getAvailabilityHoursString,
        getAvailabilityHoursDatesString,
        getCurrentOrNextOpeningHourString,
        getCurrentOrPreviousOpeningHourString,
        getDeliveryInformation,
        getMinimumPriceForDeliveryMethod,
        getNextDateWithinOpeningHours,
    };
};

/**
 * Use this hook to get information about the delivery methods and their configuration.
 */
export const useDeliveryConfigurationMethods = (params: DeliveryConfigurationParams) => {
    const { status, data: deliveryMethods } = useDeliveryMethodsForListing(params);

    const isDeliveryMethodEnabled = useCallback(
        (deliveryMethod: string) => {
            if (!deliveryMethods) return false;

            return deliveryMethods[deliveryMethod as keyof DeliveryMethods].enabled;
        },
        [deliveryMethods],
    );

    const getNextReturnDateAndTime = useCallback(
        (deliveryMethod: string, booking?: Booking | BookingDraft) => {
            if (!deliveryMethods) return null;

            if (
                booking &&
                isBooking(booking) &&
                booking.deliveryMethod !== 'faceToFace' &&
                (booking.status === 'awaits-confirmation' || booking.status === 'payment-confirmed' || booking.status === 'payment-pending')
            ) {
                assertIsDefined(booking.renterReturnDate, 'renterReturnDate should be defined for confirmed bookings');

                return booking.renterReturnDate;
            }

            return deliveryMethods[deliveryMethod as keyof DeliveryMethods].renterReturnDate;
        },
        [deliveryMethods],
    );

    const getPickupDateWithinOpeningHours = useCallback(
        (deliveryMethod: string, booking?: Booking | BookingDraft) => {
            if (!deliveryMethods) return null;

            if (
                booking &&
                isBooking(booking) &&
                booking.deliveryMethod !== 'faceToFace' &&
                (booking.status === 'awaits-confirmation' || booking.status === 'payment-confirmed' || booking.status === 'payment-pending')
            ) {
                assertIsDefined(booking.renterDeliveryDate, 'renterDeliveryDate should be defined for confirmed bookings');

                return booking.renterDeliveryDate;
            }

            const parsed = parseDateStringUTC(deliveryMethods[deliveryMethod as keyof DeliveryMethods].renterDeliveryDate);
            return parsed || null;
        },
        [deliveryMethods],
    );

    return {
        status,
        isDeliveryMethodEnabled,
        deliveryMethods,
        getNextReturnDateAndTime,
        getPickupDateWithinOpeningHours,
    };
};

export type DeliveryConfigurationMethods = ReturnType<typeof useDeliveryConfigurationMethods>;
export type OfficeConfigurationMethods = ReturnType<typeof useOfficeConfigurationMethods>;
export type Methods = DeliveryConfigurationMethods & OfficeConfigurationMethods;
