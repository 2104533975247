import { Toll, MarkChatUnread } from '@mui/icons-material';
import { AdminActionsState, AdminActionsTarget } from '../../context/admin';
import { Permission } from '../../types/apiTypes';

export type AdminAction = {
    label: string;
    target: AdminActionsTarget;
    permission: Permission;
    icon: JSX.Element;
    disabled?: boolean;
};

export const mapPermissionsToActions = (permissions: Permission[], state: AdminActionsState): AdminAction[] => {
    if (!state.target) {
        return [];
    }

    const isMarkAsUnreadEnabled = (state: AdminActionsState) =>
        Boolean(
            state.target === 'conversation' &&
                state.conversation.lastMessage.senderId !== state.currentUserId &&
                state.conversation.lastMessage.readAt,
        );

    const availableActions = permissions
        .map((permission): AdminAction | null => {
            switch (permission) {
                case 'issue_credits':
                    return {
                        label: 'Issue credits to this user',
                        permission: 'issue_credits',
                        target: 'user',
                        icon: <Toll />,
                    };
                case 'mark_conversation_as_unread':
                    return {
                        label: 'Mark conversation as unread',
                        permission: 'mark_conversation_as_unread',
                        target: 'conversation',
                        icon: <MarkChatUnread />,
                        disabled: !isMarkAsUnreadEnabled(state),
                    };
                default:
                    return null;
            }
        })
        .filter((action): action is AdminAction => action !== null && action.target === state.target);

    return availableActions;
};
