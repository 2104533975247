import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryClient, InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { useSocket } from '../../context/socket';
import { useCurrentUserId } from '../../user/hooks/useUser';
import { ConversationHistory, Message, MessageHistory } from '../apiTypes';
import { ConversationsOverview, useConversationsOverview } from './useConversationsOverview';
import { getUpdatedMessages } from './useSendMessage';
import { ChatState, useChatWindow } from '../../context/chat';
import { useLatestValue } from '../../hooks/useLatestValue';
import { assertIsDefined, invariant } from '../../helpers/commonHelpers';

/**
 * Unread messages are tracked in the conversations-overview query cache and used to show a badge on the conversations icon (if there are unread messages).
 */
const addMessageToUnreadMessages = (queryClient: QueryClient, chatState: ChatState | null, conversationId: string) => {
    const currentOverview = queryClient.getQueryData<ConversationsOverview | undefined>(['conversations-overview']);
    const chatIsOpen = chatState && chatState.conversationId === conversationId;

    if (!currentOverview || chatIsOpen) {
        return;
    }

    queryClient.setQueryData<ConversationsOverview | undefined>(['conversations-overview'], (overview) => {
        assertIsDefined(overview, 'Conversations overview is undefined');

        const isUnreadAlready = overview.unreadMessages.includes(conversationId);

        if (!isUnreadAlready) {
            overview.unreadMessages.push(conversationId);
        }

        return overview;
    });
};

export const addMessageToConversationHistory = (queryClient: QueryClient, conversationId: string, message: Message) => {
    const currentHistory = queryClient.getQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history']);

    if (!currentHistory) {
        return;
    }

    queryClient.setQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history'], (history) => {
        assertIsDefined(history, 'Conversation history is undefined');

        for (const page of history.pages || []) {
            const idx = page.data.findIndex((conversation) => conversation.conversationId === conversationId);

            if (idx > -1) {
                const conversation = page.data[idx];

                // If the message was sent before the last message in the conversation, don't update the last message
                const checkWasLastMessageSentBefore = () => {
                    if (!conversation.lastMessage) {
                        return false;
                    }

                    const d1 = new Date(conversation.lastMessage.createdAt);
                    const d2 = new Date(message.createdAt);

                    return d1 > d2;
                };

                if (checkWasLastMessageSentBefore()) {
                    break;
                }

                conversation.lastMessage = message;

                if (!message.listingData) {
                    break;
                }

                const hasNoTopic = !conversation.topic;
                const hasDifferentTopic = conversation.topic && conversation.topic.listingId !== message.listingData.id;

                if (hasNoTopic || hasDifferentTopic) {
                    conversation.topic = { listingId: message.listingData.id };
                }

                break;
            } else {
                // Not in cache, likely because the conversation was newly added.
                queryClient.invalidateQueries(['conversation-history']);
            }
        }

        return history;
    });
};

export const handleAddMessageToQueryCache = (queryClient: QueryClient, message: Message, chatState: ChatState | null, queryKey: QueryKey) => {
    invariant(Array.isArray(queryKey), 'Query key must be an array');

    const conversationId = queryKey[1].conversationId as string;

    addMessageToUnreadMessages(queryClient, chatState, conversationId);
    addMessageToConversationHistory(queryClient, conversationId, message);

    const qData = queryClient.getQueryData<InfiniteData<MessageHistory>>(queryKey);

    // Conversation not in cache yet -> no need to add to message history because the conversation will be fetched anyway
    if (!qData) {
        return;
    }

    const alreadyInCache = Boolean(qData.pages.find((page) => page?.data.find((msg) => msg._id === message._id)));

    if (alreadyInCache) {
        return;
    }

    queryClient.setQueryData(queryKey, (prevData: InfiniteData<MessageHistory> | undefined) => {
        assertIsDefined(prevData, 'Message history is undefined');

        const newData = getUpdatedMessages(message, prevData, conversationId);

        return {
            pageParams: prevData.pageParams,
            pages: newData,
        };
    });
};

/**
 * Replaces the dummy message ID with the persisted ID from the server
 */
export const applyPersistedMessageId = (queryClient: QueryClient, queryKey: QueryKey, message: Message) => {
    const messageHistory = queryClient.getQueryData<InfiniteData<MessageHistory>>(queryKey);

    if (messageHistory && messageHistory.pages && messageHistory.pages[0]) {
        const optimisticUpdateIdx = messageHistory.pages[0].data.findIndex((msg) => msg.createdAt === message.createdAt);
        if (optimisticUpdateIdx > -1) {
            messageHistory.pages[0].data[optimisticUpdateIdx]._id = message._id;

            queryClient.setQueryData(queryKey, messageHistory);
        }
    } else {
        // First message of the conversation
        queryClient.setQueryData(queryKey, { pages: [{ data: [message], nextPage: undefined }] });
    }
};

export const useWebSockets = (): void => {
    const { socket, connected } = useSocket();
    const userId = useCurrentUserId();
    const queryClient = useQueryClient();

    const { state: chatState } = useChatWindow();

    const { data: overviewData } = useConversationsOverview();

    // Ref to keep track of the conversation IDs that have listeners
    const listenersRef = useRef<string[]>([]);

    const conversationIds = overviewData?.conversationIds;

    const chatStateRef = useLatestValue(chatState);

    const setupListenersForConversation = useCallback(
        (conversationId: string) => {
            if (!userId || !socket || listenersRef.current.includes(conversationId)) return;

            const messageHistoryKey = ['message-history', { conversationId }] as QueryKey;

            socket.on(conversationId, (message: Message) => {
                handleAddMessageToQueryCache(queryClient, message, chatStateRef.current, messageHistoryKey);
            });

            socket.emit('joinChannel', conversationId);

            listenersRef.current.push(conversationId);
        },
        [socket, userId, queryClient],
    );

    const removeListenerForConversation = useCallback(
        (conversationId: string) => {
            if (!socket || !listenersRef.current.includes(conversationId)) return;

            socket.off(conversationId);
            socket.emit('leaveChannel', conversationId);

            listenersRef.current = listenersRef.current.filter((id) => id !== conversationId);
        },

        [socket],
    );

    // Effect for conversation listeners
    useEffect(() => {
        if (!socket || !connected || !conversationIds) return;

        const newConversations = conversationIds.filter((id) => !listenersRef.current.includes(id));
        const conversationsToRemove = listenersRef.current.filter((id) => !conversationIds.includes(id));

        newConversations.forEach(setupListenersForConversation);
        conversationsToRemove.forEach(removeListenerForConversation);

        return () => {
            listenersRef.current.forEach((conversationId) => {
                socket.off(conversationId);
            });
            listenersRef.current = [];
        };
    }, [connected, conversationIds, setupListenersForConversation, socket]);

    // Separate effect for the user's personal channel
    useEffect(() => {
        if (!socket || !connected || !userId) return;

        const personalChannelListener = (message: string) => {
            const parsed = JSON.parse(message);
            const { type, payload } = parsed;

            if (type === 'conversation-created') {
                setupListenersForConversation(payload);
                queryClient.invalidateQueries(['conversations-overview']);
            }

            if (type === 'credits-balance-changed') {
                queryClient.invalidateQueries(['credits-balance']);
            }
        };

        socket.on(userId, personalChannelListener);
        socket.emit('joinChannel', userId);

        return () => {
            socket.off(userId, personalChannelListener);
        };
    }, [connected, userId, setupListenersForConversation, socket, queryClient]);
};
