export enum FetchStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum SubmitStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export enum IdpLoginId {
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
}

export enum ThemeEnum {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum Language {
    FI = 'fi',
    EN = 'en',
}

export enum TagTypeEnum {
    BRAND = 'brand',
    CATEGORY = 'category',
}
