import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { listingsPerPage } from '../constants';
import { getNextPageParam } from '../helpers/commonHelpers';
import { listingsApiClient } from '../services/sharetribe/apiClients';
import { ListingsResponse } from '../types/apiTypes';
import { useAppSelector } from '../store/hooks';
import { selectToken } from '../store/userReducer';

export const useFavoritedListings = (startingPage = 1): UseInfiniteQueryResult<ListingsResponse> => {
    const token = useAppSelector(selectToken);

    const favoritedListingsFn = async ({ pageParam = startingPage }) => {
        const params = `?page=${pageParam}&perPage=${listingsPerPage}`;
        const { data } = await listingsApiClient.get<ListingsResponse>(`/favourites${params}`);

        return {
            ...data,
            nextPage: pageParam + 1,
        };
    };

    return useInfiniteQuery(['favorited-listings'], favoritedListingsFn, { getNextPageParam, enabled: !!token });
};
