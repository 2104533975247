import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { useTranslation } from 'react-i18next';

import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { ErrorSVG } from '../../components/ErrorSVG/ErrorSVG';

export const IdentityVerificationError: React.FC<{ title: string }> = ({ title }) => {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '30px',
            }}
        >
            <StaggerList custom={{ stagger: 0.8 }} style={{ width: '80%', display: 'flex', gap: '32px', flexDirection: 'column' }}>
                <StaggerListElement
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '40px',
                    }}
                >
                    <RobesLogoFull height={70} />
                    <ErrorSVG variant="xs" />
                </StaggerListElement>

                <StaggerListElement style={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant="body1" fontWeight="bold">
                        {title}
                    </Typography>
                </StaggerListElement>
            </StaggerList>
        </Box>
    );
};
