import { useQuery } from '@tanstack/react-query';
import { userApiClient } from '../../services/sharetribe/apiClients';
import { Permission } from '../../types/apiTypes';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { AdminActionsTarget, hasActionPermissionsForTarget } from '../../context/admin';

export const usePermissions = () => {
    const token = useAppSelector(selectToken);

    return useQuery(
        ['permissions', { token }],
        async () => {
            if (!token) {
                return [];
            }

            const { data } = await userApiClient.get<Permission[]>('/permissions');
            return data;
        },
        { enabled: !!token },
    );
};

export const useHasPermission = (permission: Permission) => {
    const { data: permissions } = usePermissions();

    return (permissions || []).indexOf(permission) > -1;
};

export const useHasPermissions = (permissions: Permission[]) => {
    const { data: userPermissions } = usePermissions();

    return permissions.every((permission) => (userPermissions || []).indexOf(permission) > -1);
};

export const useHasAnyPermission = (permissions: Permission[]) => {
    const { data: userPermissions } = usePermissions();

    return permissions.some((permission) => (userPermissions || []).indexOf(permission) > -1);
};

export const useHasPermissionForActionTarget = (target: AdminActionsTarget | undefined) => {
    const { data: userPermissions } = usePermissions();

    return hasActionPermissionsForTarget(userPermissions || [], target);
};
