import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { authApiClient } from '../../services/sharetribe/apiClients';

export const useSendVerificationEmail = (onSuccess?: () => void, showToast = true) => {
    const { t } = useTranslation();

    const resendFn = async () => {
        const url = '/send-verification-email';
        await authApiClient.post(url);

        return null;
    };

    return useMutation(resendFn, {
        onSuccess: () => {
            if (showToast) {
                const msg = t('emailResent');
                toast.success(msg, { toastId: 'email-sent' });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg, { toastId: 'email-error' });
        },
    });
};
