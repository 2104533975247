import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Location } from 'react-router-dom';
import { AnimatedOutlet } from './animations/components/AnimatedOutlet';
import { PageWrapper } from './animations/components/PageWrapper';
import { RouteTransitionVariant } from './types/types';

interface MobileOutletProps {
    location: Location;
    transitionVariant: RouteTransitionVariant;
}

/**
 * This component is used to animate the routes on mobile using a stable reference to the outlet
 * and forcing pagewrapper to re-render on route changes by changing its key.
 */
export const MobileOutlet: React.FC<MobileOutletProps> = ({ location, transitionVariant }) => {
    return (
        <AnimatePresence mode="popLayout" initial={false} custom={transitionVariant}>
            <PageWrapper key={location.pathname} transitionVariant={transitionVariant}>
                <AnimatePresence initial>
                    <AnimatedOutlet />
                </AnimatePresence>
            </PageWrapper>
        </AnimatePresence>
    );
};
