import { NewspaperRounded } from '@mui/icons-material';
import { Variants } from 'framer-motion';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';

const variants: Variants = {
    initial: {
        scale: 0,
        opacity: 0,
    },
    in: {
        scale: 1,
        opacity: 1,
    },
};

export const ArticleIndicator: React.FC = () => (
    <AnimatedContainer
        variants={variants}
        transition={{ delay: 0.5 }}
        style={{
            height: '28px',
            width: '28px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#656464b8',
            borderRadius: '50%',
            color: 'white',
        }}
    >
        <NewspaperRounded fontSize="small" />
    </AnimatedContainer>
);
