import { Button, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export const TabContainer = styled('ul')(() => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
}));

export const TabListItem = styled(motion.li)(() => ({
    position: 'relative',
    borderRadius: '5px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    height: '100%',
    padding: '10px 15px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1',
    minWidth: 0,
    userSelect: 'none',
}));

export const TabLabel = styled(Typography)<{
    selected: boolean;
}>(({ theme, selected }) => ({
    ...(selected && { color: theme.palette.primary.main }),
    transition: 'color 500ms',
}));

export const Underline = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    bottom: '0px',
    left: '0',
    right: '0',
    height: '2px',
    background: theme.palette.primary.main,
}));

export const TabButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
}));
