import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import { headerHeightPX } from '../../../../constants';

export const PrimaryHeaderRow = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
}));

export const SecondaryHeaderRow = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
}));

export const StyledAppHeader = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    height: `${headerHeightPX}px`,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
}));
export const HeaderText = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    color: theme.palette.text.primary,
    height: '56px',
    display: 'flex',
    alignItems: 'center',
}));
