import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { compactModeCardHeight, defaultModeCardHeight, cardSpacingX } from '../constants';

export const useListingItemStyle = (compactMode = true) => {
    const isDesktop = useBreakpoint('sm');

    if (isDesktop) {
        return { width: '100%', maxWidth: '212px', aspectRatio: '1/1.4', padding: '15px', maxHeight: 280 };
    }

    return {
        height: compactMode ? compactModeCardHeight : defaultModeCardHeight,
        width: '100%',
        padding: compactMode ? `0px ${cardSpacingX}px` : `0px ${cardSpacingX * 2}px`,
    };
};
