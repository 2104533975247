import { motion } from 'framer-motion';
import { RobesLogoFull } from '../../../components/RobesLogo/RobesLogoFull';
import { StyledTypography } from '../Home.components';
import { ArticleIndicator } from './ArticleIndicator';

interface ContentOverlayProps {
    title: string;
    subtitle: string;
    hasArticle: boolean;
    textColor: string;
    showLogo: boolean;
}

export const ContentOverlay: React.FC<ContentOverlayProps> = ({ title, subtitle, hasArticle, textColor, showLogo }) => (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden', top: 0, cursor: 'pointer', zIndex: 0, position: 'absolute' }}>
        <div
            style={{
                zIndex: 2,
                position: 'absolute',
                left: '0',
                right: '0',
                top: '0',
                bottom: '0',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 0,
                        borderRadius: '20px',
                        backdropFilter: 'blur(1px)',
                    }}
                />
                {showLogo ? (
                    <motion.div
                        initial={{ x: 70, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1, type: 'spring', stiffness: 70, damping: 20 }}
                        style={{ zIndex: 1 }}
                    >
                        <RobesLogoFull height={100} {...(textColor && { modeOverride: textColor === 'white' ? 'dark' : 'light' })} />
                    </motion.div>
                ) : (
                    <>
                        <motion.div
                            initial={{ x: 70, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.1, type: 'spring', stiffness: 70, damping: 20 }}
                            style={{ zIndex: 1 }}
                        >
                            <StyledTypography
                                variant="h4"
                                sx={{
                                    color: textColor,
                                    textAlign: 'center',
                                    fontFamily: 'Italiana',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {title}
                            </StyledTypography>
                        </motion.div>

                        <motion.div
                            initial={{ x: 70, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.35, type: 'spring', damping: 20, stiffness: 50 }}
                            style={{ zIndex: 1 }}
                        >
                            <StyledTypography variant="subtitle2" sx={{ color: textColor, fontWeight: '400', fontFamily: 'Montserrat' }}>
                                {subtitle}
                            </StyledTypography>
                        </motion.div>
                    </>
                )}
            </div>
        </div>

        <div style={{ position: 'absolute', zIndex: 2, top: '2%', right: '2%' }}>{hasArticle && <ArticleIndicator />}</div>
    </div>
);
