import React from 'react';
import { UserOnboarding } from '../../components/NewUserOnboarding/UserOnboarding';
import { useCurrentUser } from '../../user/hooks/useUser';
import { useOnboardingState } from '../../user/hooks/useOnboardingState';
import useLocationParams from '../../hooks/useLocationParams';
import { IdentityVerificationState } from '../IdentityVerification/IdentityVerification';
import { useAppDispatch } from '../../store/hooks';
import { setIsOnboarding } from '../../store/appStateReducer';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export const OnboardingView: React.FC = () => {
    const { data: user } = useCurrentUser();
    const { identification } = useLocationParams();
    const onboardingState = useOnboardingState();
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();

    const handleFinishOnboarding = () => {
        dispatch(setIsOnboarding(false));

        if (location.pathname !== '/') {
            navigate('/', { replace: true });
        }
    };

    if (!user) {
        return null;
    }

    return (
        <UserOnboarding
            onboardingState={onboardingState}
            onEnd={handleFinishOnboarding}
            user={user}
            identification={identification as IdentityVerificationState}
        />
    );
};
