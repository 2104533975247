import { Skeleton } from '@mui/material';

export const ListingFooterSkeleton = () => (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Skeleton variant="text" width={90} height={12} sx={{ animationDuration: '1.3s' }} />
            <Skeleton variant="text" width={110} height={24} sx={{ animationDuration: '1.3s' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Skeleton variant="text" width={80} height={10} sx={{ animationDuration: '1.3s' }} />
            <Skeleton variant="rounded" width={100} height={18} sx={{ animationDuration: '1.3s' }} />
        </div>
    </div>
);
