import React, { MutableRefObject } from 'react';
import { useTheme } from '@mui/material';
import { motion, usePresence } from 'framer-motion';
import { routeVariants } from '../constants';

export const PageWrapper = React.forwardRef<HTMLDivElement, { children: JSX.Element; transitionVariant: string }>(
    ({ children, transitionVariant }, containerRef) => {
        const theme = useTheme();
        const ref = containerRef as MutableRefObject<HTMLDivElement>;

        return (
            <motion.div
                ref={ref}
                custom={transitionVariant}
                initial="initial"
                animate="in"
                exit="out"
                layoutScroll
                variants={routeVariants}
                style={{
                    zIndex: 1,
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    background: theme.palette.background.paper,
                }}
            >
                {children}
            </motion.div>
        );
    },
);
