import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { identifyApiClient } from '../../../services/sharetribe/apiClients';
import { Session } from './types';
import { makeQueryString } from '../../../helpers/urlHelpers';
import { Capacitor } from '@capacitor/core';
import { openNativeBrowserWithCloseCallback } from '../../../helpers/browser';
import { useSafeNavigate } from '../../useSafeNavigate';

/**
 * @param params returned in the callbackUrl as query params after signicat authentication flow.
 */
export const useStartRedirectFlow = (params?: Record<string, string>) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const connectFn = async () => {
        const isNative = String(Capacitor.isNativePlatform());
        const resolvedParams = params ? { ...params, isNative } : { isNative };
        const url = `/start?${makeQueryString(resolvedParams)}`;
        const { data } = await identifyApiClient.post<Session>(url);

        return data;
    };

    return useMutation(connectFn, {
        onSuccess: async (session: Session) => {
            const beforeClose = async () => await queryClient.refetchQueries({ queryKey: ['current-user'] });
            openNativeBrowserWithCloseCallback(session.authenticationUrl, navigate, beforeClose);
        },
        onError: () => {
            toast.error<string>(t('unexpectedError'));
        },
    });
};
