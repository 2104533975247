import {
    useSensors,
    useSensor,
    PointerSensor,
    TouchSensor,
    DndContext,
    closestCenter,
    DragOverlay,
    DragEndEvent,
    DragStartEvent,
    UniqueIdentifier,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item, imageDimensionsPX } from './Item';
import { UnifiedImage } from './ImageUploader.types';
import FullScreenImage from '../FullScreenImage/FullScreenImage';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';
import { usePrevious } from '../../hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { AddButtonIcon } from '../AddImage/AddImagesButton.components';
import { isMobile } from 'react-device-detect';
import smoothscroll from 'smoothscroll-polyfill';

// scrollTo "behavior" polyfill for WKWebView which does not seem to support smooth scrolling on
smoothscroll.polyfill();

const IMAGE_HEIGHT = 150;
const IMAGE_WIDTH = 150;

interface SortableItemProps {
    id: string;
    children: React.ReactNode;
    itemStyle?: React.CSSProperties;
}

const UploadButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <Box sx={{ padding: '10px' }}>
            <Button onClick={onClick} variant="contained" sx={{ borderRadius: '15px', p: 0, minWidth: 'unset', height: '100px', width: '100px' }}>
                <motion.div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    initial={{ opacity: 0, scale: 0, rotate: -45 }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        rotate: 270,
                    }}
                    transition={{ type: 'spring', stiffness: 200, damping: 30, bounce: 0 }}
                >
                    <AddButtonIcon />
                </motion.div>
            </Button>
        </Box>
    );
};

export const SortableItem: React.FC<SortableItemProps> = ({ id, itemStyle, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        flexShrink: 0,
        ...itemStyle,
        ...(isDragging && { visibility: 'hidden' }),
    };

    return (
        <Item ref={setNodeRef} id={id} style={style} {...attributes} {...listeners}>
            {children}
        </Item>
    );
};

interface ImageUploaderProps {
    images: UnifiedImage[];
    limit: number;
    setImages: React.Dispatch<React.SetStateAction<UnifiedImage[]>>;
    onRemove: (id: string) => void;
    onClickAdd: () => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({ images, limit, onClickAdd, setImages, onRemove }) => {
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const previousImagesRef = usePrevious(images);
    const { t } = useTranslation();

    const sensor = isMobile ? TouchSensor : PointerSensor;

    const sensors = useSensors(
        useSensor(sensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5,
            },
        }),
    );

    const activeItem = activeId ? images.find((item) => item.id === activeId) : undefined;

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setImages((prev) => {
                const oldIndex = prev.findIndex((item) => item.id === active.id);
                const newIndex = prev.findIndex((item) => item.id === over.id);

                return arrayMove(prev, oldIndex, newIndex);
            });
        }

        setActiveId(null);
    };

    useEffect(() => {
        const removedPhotos = images.length && previousImagesRef && previousImagesRef.length > images.length;

        if (removedPhotos) {
            if (scrollContainerRef.current) {
                const scrollTo = scrollContainerRef.current.scrollWidth - imageDimensionsPX;

                if (scrollTo >= 0) {
                    scrollContainerRef.current.scrollTo({ left: scrollContainerRef.current.scrollLeft - imageDimensionsPX, behavior: 'smooth' });
                }
            }

            return;
        }

        const timeout = setTimeout(() => {
            const addedPhotos = scrollContainerRef.current && previousImagesRef && images.length - previousImagesRef.length;

            if (addedPhotos) {
                scrollContainerRef.current.scrollTo({ left: scrollContainerRef.current.scrollWidth, behavior: 'smooth' });
            }
        }, 150);

        return () => clearTimeout(timeout);
    }, [images.length]);

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <SortableContext items={images} strategy={horizontalListSortingStrategy}>
                {images.length ? (
                    <motion.div
                        ref={scrollContainerRef}
                        layout
                        layoutScroll
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            paddingLeft: '20px',
                            gap: '10px',
                            height: IMAGE_HEIGHT,
                        }}
                    >
                        <AnimatePresence>
                            {images.map((item, i) => (
                                <SortableItem key={item.id} id={item.id} itemStyle={{ position: 'relative', height: '100%', width: IMAGE_WIDTH }}>
                                    <FullScreenImage
                                        src={item.url}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                            ...(item.uploadError && { opacity: 0.3, border: '2px solid red' }),
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => onRemove(item.id)}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            p: '3px',
                                            background: (theme) => theme.palette.common.white,
                                            boxShadow: (ThemeContext) => ThemeContext.palette.boxShadow['elevation-3'],
                                            borderRadius: '50%',
                                        }}
                                    >
                                        <Clear fontSize="small" />
                                    </IconButton>

                                    {item.uploadError && (
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: '100%',
                                                textAlign: 'center',
                                                color: 'error.main',
                                            }}
                                        >
                                            {t(item.uploadError)}
                                        </Typography>
                                    )}
                                </SortableItem>
                            ))}
                            {images.length <= limit && (
                                <motion.div layout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px' }}>
                                    <Button onClick={onClickAdd} sx={{ width: 40, height: 40, minWidth: 'unset' }} variant="contained">
                                        <Add sx={{ p: 1, width: 40, height: 40 }} />
                                    </Button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                ) : (
                    <UploadButton onClick={onClickAdd} />
                )}
            </SortableContext>

            <DragOverlay>
                {activeItem ? (
                    <Item id={activeItem.id} isDragging style={{ height: IMAGE_HEIGHT, width: IMAGE_WIDTH }}>
                        <img
                            src={activeItem.url}
                            style={{ height: '100%', width: '100%', objectFit: 'cover', WebkitTouchCallout: 'none', WebkitUserSelect: 'none' }}
                        />
                    </Item>
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};
