import { cloneDeep } from 'lodash';
import { InfiniteData, QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { userApiClient } from '../../services/sharetribe/apiClients';
import { ListingsResponse, User } from '../../types/apiTypes';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const updateUserFavorites = (queryClient: QueryClient, favorites: string[]) => {
    queryClient.setQueryData<User | undefined>(['current-user'], (user: User | undefined) => {
        if (!user || !user.profile) {
            return undefined;
        }

        user.profile.privateData.favorites = favorites;

        return user;
    });
};

const getUserFavorites = (queryClient: QueryClient) => {
    return queryClient.getQueryData<User>(['current-user'])?.profile.privateData?.favorites || [];
};

const removeFromFavoritedListings = (queryClient: QueryClient, listingId: string) => {
    queryClient.setQueryData<InfiniteData<ListingsResponse>>(['favorited-listings'], (favoritedListings) => {
        if (!favoritedListings) {
            return { pages: [], pageParams: [] };
        }

        favoritedListings?.pages.forEach((page) => {
            const favoriteIdx = page.data.findIndex((listing) => listing.id === listingId);
            if (favoriteIdx > -1) {
                page.data = page.data.filter((listing) => listing.id !== listingId);
            }
        });

        return favoritedListings;
    });
};

export const useToggleFavorite = () => {
    const queryClient = useQueryClient();

    const toggleFavoriteFn = async (listingId: string): Promise<any> => {
        // Directly use the optimistically updated favorites, onMutate is called before this function and thus we already have the updated state.
        const updatedFavorites = getUserFavorites(queryClient);

        const { data } = await userApiClient.put('/', {
            privateData: {
                favorites: updatedFavorites,
            },
        });

        return data;
    };

    return useMutation(toggleFavoriteFn, {
        onMutate: async (listingId: string) => {
            await queryClient.cancelQueries(['favorited-listings']);

            const currentFavorites = getUserFavorites(queryClient);
            const isSelected = currentFavorites.includes(listingId);

            const optimisticFavorites = isSelected ? currentFavorites.filter((id) => id !== listingId) : [...currentFavorites, listingId];

            updateUserFavorites(queryClient, optimisticFavorites);

            if (isSelected) {
                removeFromFavoritedListings(queryClient, listingId);
            }

            const originalListings = cloneDeep(queryClient.getQueryData(['favorited-listings']));

            Haptics.impact({ style: ImpactStyle.Light });

            return { originalFavorites: currentFavorites, originalListings, listingId };
        },
        onError: (_err, _listingId, context) => {
            const { originalFavorites, originalListings } = context || {};

            updateUserFavorites(queryClient, originalFavorites || []);

            queryClient.setQueryData(['favorited-listings'], originalListings);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['favorited-listings']);
        },
    });
};
