import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { logger } from '../helpers/logger';

const useLocationParams = (): Record<string, string> => {
    const location = useLocation();

    const params = useMemo(() => {
        const search = location.search?.substring(1) || undefined;
        if (!search) return {};

        try {
            return JSON.parse(`{"${location.search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) =>
                key === '' ? value : decodeURIComponent(value).replace(/\+/g, ' '),
            );
        } catch (e) {
            logger.error('Failed to parse location params', e, location);
            return {};
        }
    }, [location]);

    return params;
};

export default useLocationParams;
