import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { transactionsApiClient } from '../../services/sharetribe/apiClients';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';

/**
 * How many transactions currently require confirmation from the logged in user?
 */
export const useActionRequiredTxCount = (): UseQueryResult<number> => {
    const token = useAppSelector(selectToken);

    const getTransaction = async () => {
        const url = `/own/action-needed`;

        const { data } = await transactionsApiClient.get<number>(url);

        return data;
    };

    return useQuery(['own-transactions', { type: 'action-needed' }], getTransaction, { enabled: !!token });
};
