import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    CheckboxProps,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    MenuItem,
    Skeleton,
    SxProps,
    TextField,
    useTheme,
} from '@mui/material';
import List from '@mui/material/List/List';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { ListElement } from './types';
import { Check, Close, Search } from '@mui/icons-material';
import { isNil } from '../../helpers/commonHelpers';
import { AnimatePresence } from 'framer-motion';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { scale } from '../../animations/constants';
import { ReactFCWithChildren } from '../../types/types';
import { CustomCheckbox } from './SelectList.components';
import { useVirtualizer } from '@tanstack/react-virtual';

const ListSkeleton = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        {Array.from(Array(6))
            .fill(true)
            .map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                (<Skeleton key={i} variant="rounded" width={100} height={20} sx={{ margin: '25px 10px 5px' }} />)
            ))}
    </div>
);

interface SelectListBaseProps {
    options: ListElement[];
    loading?: boolean;
    hideSearch?: boolean;
    selectedVariant?: 'highlight' | 'checkmark';
    // If given, the local text value state is ignored in favor of this. Use if you need to control how the text value affects shown options
    inputValue?: string;
    onSelectOption: (value: string) => void;
    onInputChange?: (value: string) => void;
    onClear?: () => void;
    selectedFn?: (value: string) => boolean;
    autofocus?: boolean;
    sx?: SxProps;
}

interface SelectListMultiProps extends SelectListBaseProps {
    multiSelect?: true;
    selectedValue: string[];
}

interface SelectListSingleProps extends SelectListBaseProps {
    multiSelect?: false;
    selectedValue: string;
}

type SelectListProps = SelectListSingleProps | SelectListMultiProps;

export const SelectList = (props: SelectListProps): JSX.Element => {
    const [textValue, setTextValue] = useState('');
    const {
        options,
        selectedValue,
        loading,
        multiSelect = false,
        autofocus = false,
        inputValue,
        hideSearch,
        onInputChange,
        onClear,
        onSelectOption,
        selectedFn,
        sx,
    } = props;

    const parentRef = React.useRef<HTMLDivElement>(null);
    const resolvedValue = inputValue || textValue;

    const isSelectedFn = (value: string) => {
        if (selectedFn) {
            return selectedFn(value);
        }
        if (multiSelect) {
            return selectedValue.includes(value);
        }

        return selectedValue === value;
    };

    const filteredOptions = options.filter((opt) => {
        if (!isNil(inputValue) || !textValue) {
            return opt;
        }
        return opt.label.toLocaleUpperCase().includes(resolvedValue.toLocaleUpperCase());
    });

    const virtualizer = useVirtualizer({
        count: filteredOptions.length,
        overscan: 4,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 64,
    });

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {!hideSearch && (
                <Box sx={{ position: 'sticky', top: '-5px', height: '50px', zIndex: 3, backgroundColor: (t) => t.palette.background.paper }}>
                    <TextField
                        autoComplete="off"
                        autoFocus={autofocus}
                        variant="standard"
                        value={resolvedValue}
                        sx={{
                            width: 'calc(100% - 20px)',
                            margin: '10px 10px 0',
                        }}
                        onChange={(ev) => {
                            if (!isNil(inputValue)) {
                                onInputChange?.(ev.target.value);
                            } else {
                                setTextValue(ev.target.value);
                            }
                        }}
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {resolvedValue && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    if (!isNil(inputValue)) {
                                                        onClear?.();
                                                    } else {
                                                        setTextValue('');
                                                    }
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                </>
                            ),
                            style: { padding: '3px 0' },
                        }}
                    />
                </Box>
            )}

            <Box sx={{ marginTop: '15px', height: '100%', position: 'relative', overflowY: 'auto', ...sx }} ref={parentRef}>
                {loading ? (
                    <ListSkeleton />
                ) : (
                    <List sx={{ height: `${virtualizer.getTotalSize()}px`, width: '100%', position: 'relative' }}>
                        {virtualizer.getVirtualItems().map((virtualItem) => {
                            const option = filteredOptions[virtualItem.index];
                            const isSelected = isSelectedFn(option.value);
                            const boldSelection = isSelected && !multiSelect;

                            return (
                                <ListItem
                                    key={virtualItem.key}
                                    aria-selected={isSelected}
                                    sx={{
                                        pl: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: `${virtualItem.size}px`,
                                        transform: `translateY(${virtualItem.start}px)`,
                                    }}
                                    secondaryAction={
                                        <>
                                            {multiSelect ? (
                                                <Checkbox
                                                    edge="end"
                                                    checked={isSelected}
                                                    onChange={() => onSelectOption(option.value)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            ) : (
                                                <AnimatePresence mode="popLayout">
                                                    {isSelected && (
                                                        <AnimatedContainer key={option.value} variants={scale}>
                                                            <Check />
                                                        </AnimatedContainer>
                                                    )}
                                                </AnimatePresence>
                                            )}
                                        </>
                                    }
                                >
                                    <ListItemButton onClick={() => onSelectOption(option.value)}>
                                        {option.icon && <ListItemAvatar>{option.icon}</ListItemAvatar>}
                                        <ListItemText
                                            primary={option.label}
                                            primaryTypographyProps={{ sx: { ...(boldSelection && { fontWeight: 'bold' }) } }}
                                            sx={{ textTransform: 'capitalize' }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </Box>
        </Box>
    );
};
