import Button from '@mui/material/Button/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography/Typography';

export const ProfileRowContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

export const ProfileTextContainer = styled('div')(() => ({
    flex: 1,
    marginLeft: '12px',
    display: 'flex',
    justifyContent: 'space-between',
}));

export const ProfileNameText = styled(Typography)(() => ({
    fontSize: '16px',
    textAlign: 'left',
}));

export const ProfileLocationText = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.text.secondary,
    textAlign: 'left',
}));

export const ProfileImageContainer = styled('div')(() => ({
    background: 'rgba(32, 33, 37, 0.08)',
    borderRadius: '50%',
    overflow: 'hidden',
}));

export const ProfileBioContainer = styled('div')(() => ({
    width: '100%',
    padding: '10px 0',
}));

export const Image = styled('img')(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
}));

export const NameButton = styled(Button)(() => ({
    padding: '0',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
}));
