import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { LineItems } from '../../components/LineItems/LineItems';
import { LineItem } from '../../transactions/types';

interface BookingBreakdownSectionProps {
    lineItems: LineItem[];
    initialLoadingLineItems: boolean;
}

const LineItemsSkeleton = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: 1 }}>
        <Skeleton variant="rounded" sx={{ width: '100%' }} height={40} />
        <Skeleton variant="rounded" sx={{ width: '100%' }} height={40} />
    </Box>
);

export const BookingBreakdownSection: React.FC<BookingBreakdownSectionProps> = ({ lineItems, initialLoadingLineItems }) => {
    const { t } = useTranslation();

    const hasReversals = lineItems.some((item) => item.reversal);

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h6" fontWeight="600">
                {t('bookingBreakdown')}
            </Typography>

            <ContentLoader status={initialLoadingLineItems ? 'loading' : 'idle'} style={{ height: 'unset' }} skeleton={<LineItemsSkeleton />}>
                {hasReversals && (
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <Typography variant="overline" color="error">
                            {t('transactionCancelled')}
                        </Typography>
                    </Box>
                )}

                {lineItems && !!lineItems.length && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ width: '100%' }}>
                        <LineItems lineItems={lineItems} />
                    </motion.div>
                )}
            </ContentLoader>
        </Box>
    );
};
