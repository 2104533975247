import { useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';

const keysWithExpiration = ['accountDeletionStatus'];

const intervalTime = 1000 * 60;

/**
 * This hook clears expired preferences from the device.
 * Expects the value set to keysWithExpiration to be a JSON string with an expiresAt property.
 */
export const useClearExpiredPreferences = () => {
    useEffect(() => {
        const interval = setInterval(() => {
            keysWithExpiration.forEach(async (key) => {
                const value = await Preferences.get({ key });

                if (!value.value) {
                    return;
                }

                const parsedValue = JSON.parse(value.value);

                if (typeof parsedValue.expiresAt === 'number' && parsedValue.expiresAt < new Date().getTime()) {
                    await Preferences.remove({ key });
                }
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, []);
};
