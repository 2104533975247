import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SuccessSVG } from '../../SuccessSvg/SuccessSvg';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { StepContent, StepContentContainer } from '../UserOnboarding.components';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { fadeIn } from '../../../animations/constants';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface FinishStepProps {
    onComplete: () => void;
}

export const FinishStep: React.FC<FinishStepProps> = ({ onComplete }) => {
    const { t } = useTranslation();

    return (
        <StepContent sx={{ p: 2 }}>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.7 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                >
                    <StaggerListElement>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, mt: 4 }}>
                            <SuccessSVG variant="sm" />
                        </Box>
                    </StaggerListElement>
                    <StaggerListElement>
                        <Typography variant="h5" fontWeight="bold">
                            {t('finishStepTitle')}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="subtitle1">{t('finishStepDescription')}</Typography>
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 2 }}>
                <Button variant="contained" onClick={onComplete}>
                    {t('startExploring')}
                </Button>
            </motion.div>
        </StepContent>
    );
};
