import { DependencyList, useEffect, useRef } from 'react';

export const useDelayedEffect = (effect: () => void, deps: DependencyList, delay = 100) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const clearTimer = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    useEffect(() => {
        clearTimer();
        timeoutRef.current = setTimeout(effect, delay);

        return clearTimer;
    }, deps);
};
