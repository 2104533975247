import React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export const Success: React.FC<any> = ({ onClick }) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    return (
        <StaggerList
            style={{ height: '100%', padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            custom={{ stagger: 0.4, delay: 0.3 }}
            layout="preserve-aspect"
        >
            <StaggerListElement key="robes-logo" style={{ display: 'flex', justifyContent: 'center' }}>
                <RobesLogoFull height={100} />
            </StaggerListElement>

            <StaggerListElement
                key="body-text"
                style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', gap: 20, textAlign: 'center' }}
            >
                <Typography variant="h6" fontWeight="bold">
                    {t('addListingSuccessHeader')}
                </Typography>
                <Typography variant="h6">{t('addListingSuccessSubheader')}</Typography>
                <Typography variant="body2" sx={{ marginTop: '5px' }}>
                    {t('addListingSuccessBody')}
                </Typography>
            </StaggerListElement>

            <StaggerListElement
                key="action-buttons"
                style={{
                    paddingTop: '24px',
                    justifyContent: 'space-evenly',
                    flexDirection: 'column',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Button fullWidth variant="contained" onClick={onClick}>
                        {t('addListingSuccessPrimaryAction')}
                    </Button>
                    <Button fullWidth variant="outlined" onClick={() => navigate('/wardrobe')}>
                        {t('goToWardrobe')}
                    </Button>
                </div>
            </StaggerListElement>
        </StaggerList>
    );
};
