import { Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ChipTabs } from '../../components/ChipTabs/ChipTabs';
import { useFavoritedListings } from '../../queries/useFavoritedListings';
import { useOwnListings } from '../../queries/useListings';
import { useActionRequiredTxCount } from '../../transactions/hooks/useActionRequiredTxCount';
import { ListingItemsGrid } from './ListingItemsGrid';
import { TransactionCardContainer } from './TransactionCards/TransactionCardContainer';
import { StyledTabs } from './Wardrobe.components';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import useLocationParams from '../../hooks/useLocationParams';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

const gridStyle = { overflow: 'hidden', display: 'unset', marginBottom: '56px' };

const initTab = (tab: string | undefined) => {
    if (!tab) {
        return 0;
    }

    const parsed = parseInt(tab);
    return Number.isFinite(parsed) ? parsed : 0;
};

export const PersonalWardrobe: React.FC = () => {
    const params = useLocationParams();
    const { tab } = params;
    const [selectedTab, setSelectedTab] = useState(initTab(tab));

    useEffect(() => {
        if (tab !== String(selectedTab)) {
            navigate(`/wardrobe?tab=${selectedTab}`, { replace: true });
        }
    }, [selectedTab]);

    useEffect(() => {
        const parsed = parseInt(tab);
        if (Number.isFinite(parsed) && selectedTab !== parsed) {
            setSelectedTab(parsed);
        }
    }, [tab]);

    const { t } = useTranslation();
    const { data: count } = useActionRequiredTxCount();
    const ownItems = useOwnListings();
    const navigate = useSafeNavigate();
    const isDesktop = useBreakpoint('sm');
    const itemsPerRow = isDesktop ? 4 : 2;

    const handleSelectTab = (i: number) => {
        setSelectedTab(i);
    };

    const chipTabs = [
        {
            label: t('allItems'),
            content: (
                <ListingItemsGrid own itemsPerRow={itemsPerRow} queryData={ownItems} gridStyle={gridStyle} noItemsText={t('uploadYourFirstItem')} />
            ),
        },
        {
            label: t('lending'),
            content: <TransactionCardContainer type="sale" />,
            badgeCount: count,
        },
        {
            label: t('renting'),
            content: <TransactionCardContainer type="order" />,
            badgeCount: 0,
        },
    ];

    return <ChipTabs tabs={chipTabs} selectedTab={selectedTab} onClickTab={handleSelectTab} />;
};

export const FavoritesWardrobe: React.FC = () => {
    const favoritedListings = useFavoritedListings();
    const isDesktop = useBreakpoint('sm');
    const itemsPerRow = isDesktop ? 4 : 2;

    return <ListingItemsGrid itemsPerRow={itemsPerRow} queryData={favoritedListings} showFavoriteButton gridStyle={gridStyle} />;
};

export const WardrobeLayout: React.FC = () => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(location.pathname === '/wardrobe/saved' ? 1 : 0);
    const { t } = useTranslation();

    return (
        <div>
            <StyledTabs
                variant="fullWidth"
                onChange={(_ev, newVal) => setSelectedTab(newVal)}
                value={selectedTab}
                sx={{
                    width: '100%',
                    height: '56px',
                    '& .MuiTabs-flexContainer': {
                        height: '56px',
                        justifyContent: 'center',
                    },
                }}
            >
                <Tab label={t('mine')} value={0} component={Link} to="/wardrobe" sx={{ maxWidth: '200px' }} />
                <Tab label={t('favorites')} value={1} component={Link} to="/wardrobe/saved" sx={{ maxWidth: '200px' }} />
            </StyledTabs>

            <Outlet />
        </div>
    );
};
