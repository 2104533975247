import React from 'react';
import { CollectionContainer, CollectionHeader, OverlaySlideLabel, OverlaySlideOverlay } from './Collection.components';
import { StyledHeader } from '../../../components/Styled/Styled.components';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { Carousel } from '@mantine/carousel';
import { Card, getStylesRef, createStyles } from '@mantine/core';
import { ButtonBase, IconButton, SxProps, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { SlideSettings } from '../../types';
import { ImageLoader } from '../../../components/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';

interface CollectionProps {
    title: string;
    onClickTitle?: () => void;
    onClickItem?: (idx: number) => void;
    onClickMore?: () => void;
    slideSettings: SlideSettings;
    slideSx?: SxProps;
    hasMore?: boolean;
    data: {
        id: string | number;
        image: string;
        blurhash?: null | string;
        label?: string;
    }[];
}

interface SlideProps {
    slideSettings: SlideSettings;
    data: CollectionProps['data'][number];
}

const OverlaySlide: React.FC<SlideProps> = ({ data, slideSettings }) => {
    const { image, blurhash, label } = data;
    const { height, showLabel, borderRadius, labelVariant } = slideSettings;

    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder sx={{ height: height, position: 'relative' }}>
            <Card.Section sx={{ height: '100%' }}>
                <ImageLoader
                    url={image}
                    hash={blurhash}
                    borderRadius={borderRadius}
                    style={{
                        width: '100%',
                        height: height,
                        objectFit: 'cover',
                    }}
                />
                <OverlaySlideOverlay />
            </Card.Section>

            {showLabel && label && <OverlaySlideLabel variant={labelVariant}>{label}</OverlaySlideLabel>}
        </Card>
    );
};

const DefaultSlide: React.FC<SlideProps> = ({ data, slideSettings }) => {
    const { image, blurhash, label } = data;
    const { height, showLabel, borderRadius, labelVariant } = slideSettings;

    return (
        <div>
            <ImageLoader
                url={image}
                hash={blurhash}
                borderRadius={borderRadius}
                style={{
                    width: '100%',
                    height: height,
                    objectFit: 'cover',
                }}
            />

            {showLabel && label && (
                <Typography
                    variant={labelVariant}
                    sx={{ width: '100%', marginTop: '2px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
                >
                    {label}
                </Typography>
            )}
        </div>
    );
};

const useStyles = createStyles(() => ({
    controls: {
        ref: getStylesRef('controls'),
        transition: 'opacity 150ms ease',
        opacity: 0,
    },
    control: {
        '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
        },
    },

    root: {
        '&:hover': {
            [`& .${getStylesRef('controls')}`]: {
                opacity: 1,
            },
        },
    },
}));

export const Collection: React.FC<CollectionProps> = ({ title, data, hasMore, slideSettings, onClickTitle, onClickItem, onClickMore }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const { width, height, variant, gap, paddingX, paddingY } = slideSettings;

    const getSlideSx = (i: number) => {
        return {
            ...(i === 0 && { marginLeft: paddingX }),
            ...(!hasMore && i === data.length - 1 && { marginRight: '30px' }),
        };
    };

    return (
        <CollectionContainer style={{ padding: `${paddingY}px 0` }}>
            <CollectionHeader onClick={onClickTitle} style={{ width: '100%', padding: `0 ${paddingX}px` }}>
                <StyledHeader sx={{ fontSize: '1.15rem' }}>{title}</StyledHeader>

                {onClickMore && (
                    <ButtonBase onClick={onClickMore}>
                        <Typography color="primary" variant="caption">
                            {t('seeAll')}
                        </Typography>
                    </ButtonBase>
                )}
            </CollectionHeader>

            <Carousel
                withControls={false}
                draggable
                slideSize={width}
                slideGap={gap}
                dragFree
                classNames={classes}
                align="start"
                sx={{ width: '100%' }}
                containScroll="keepSnaps"
            >
                {data.map((el, i) => {
                    return (
                        <Carousel.Slide key={el.id} onClick={() => onClickItem?.(i)} sx={{ '&:hover': { cursor: 'pointer' }, ...getSlideSx(i) }}>
                            {variant === 'overlay' && <OverlaySlide data={el} slideSettings={slideSettings} />}
                            {variant === 'default' && <DefaultSlide data={el} slideSettings={slideSettings} />}
                        </Carousel.Slide>
                    );
                })}

                {hasMore && (
                    <Carousel.Slide sx={{ width: '100%', height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton onClick={onClickMore} size="large" sx={{ boxShadow: '0 3px 6px rgb(0 0 0 / 10%)' }}>
                            <ArrowForward />
                        </IconButton>
                    </Carousel.Slide>
                )}
            </Carousel>
        </CollectionContainer>
    );
};
