import { Box, Typography, useTheme } from '@mui/material';
import { ExtendedListing } from '../../types/apiTypes';
import { useTranslation } from 'react-i18next';
import { Check, InfoOutlined, LocalShipping } from '@mui/icons-material';
import { IconSection } from '../../components/IconSection.tsx/IconSection';
import { useOfficeConfigurationMethods } from '../../queries/useDeliveryTiming';
import { formatMoney } from '../../helpers/commonHelpers';

interface DeliveryOptionsSectionProps {
    listing: ExtendedListing;
    onClick: () => void;
}

export const DeliveryOptionsSection: React.FC<DeliveryOptionsSectionProps> = ({ listing, onClick }) => {
    const { t } = useTranslation();
    const { getMinimumPriceForDeliveryMethod } = useOfficeConfigurationMethods();

    const deliveryOptions = ['wolt', 'showroom', 'faceToFace'] as const;

    const listItems = deliveryOptions.map((option) => {
        if (listing.publicData.deliveryOptions?.includes(option)) {
            const price = formatMoney(getMinimumPriceForDeliveryMethod(option));

            return (
                <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }} key={option}>
                    <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                        {t(`${option}Delivery`)}

                        {option === 'wolt' && <i style={{ marginLeft: '5px' }}>{t('woltDeliveryArea')}</i>}
                    </Typography>
                    <Typography variant="subtitle2">{price}</Typography>
                </span>
            );
        }
    });

    return (
        <IconSection
            icon={<LocalShipping color="primary" sx={{ mt: '1px' }} />}
            title={
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }} onClick={onClick}>
                    <Typography variant="body1" fontWeight="bold">
                        {t('deliveryOptionsSectionTitle')}
                    </Typography>
                    <InfoOutlined sx={{ opacity: 0.7, fontSize: '1rem' }} />
                </Box>
            }
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>{listItems}</Box>
        </IconSection>
    );
};
