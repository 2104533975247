import React, { useMemo } from 'react';
import { useListings } from '../../queries/useListings';
import { ListingItemsGrid } from './ListingItemsGrid';

export const UserListings: React.FC<{ userId: string; itemsPerRow: number }> = ({ userId, itemsPerRow }): JSX.Element => {
    const queryParams = useMemo(() => {
        const params = new URLSearchParams({ authorId: userId }).toString();
        return `?${params}`;
    }, [userId]);
    const queryData = useListings(queryParams);

    return <ListingItemsGrid itemsPerRow={itemsPerRow} queryData={queryData} gridStyle={{ marginBottom: '56px', height: 'unset' }} />;
};
