import { Skeleton } from '@mui/material';
import { compactModeCardHeight, defaultModeCardHeight, cardSpacingX } from '../constants';

export const ListingCardsSkeleton: React.FC<{ compactMode: boolean; paddingStart: number }> = ({ compactMode, paddingStart }) => {
    const cardHeight = compactMode ? 200 : 400;
    const textHeight = 22;

    const renderListingCardSkeleton = (i: number) => (
        <div
            key={i}
            style={{
                height: compactMode ? compactModeCardHeight : defaultModeCardHeight,
                width: compactMode ? '50%' : '100%',
                padding: compactMode ? `0px ${cardSpacingX}px` : `0px ${cardSpacingX * 2}px`,
                borderRadius: '10px',
            }}
        >
            <Skeleton
                variant="rounded"
                sx={{ width: '100%', height: cardHeight, transform: 'none', animationDuration: '1.2s', borderRadius: '10px' }}
            />
            <Skeleton variant="text" height={textHeight} width={'70%'} />
            <Skeleton variant="text" height={textHeight} width={'80%'} />
            <Skeleton variant="text" height={textHeight} width={'60%'} />
        </div>
    );

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                maxHeight: '100%',
                padding: `${paddingStart}px 2px`,
            }}
        >
            {Array.from({ length: compactMode ? 8 : 4 }).map((_, i) => renderListingCardSkeleton(i))}
        </div>
    );
};
