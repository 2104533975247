import { Button, ButtonBase, SxProps } from '@mui/material';
import Drawer from '@mui/material/Drawer/Drawer';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import { LinkBehavior } from '../..';

export const ListingsContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
}));

export const LoadMoreButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
}));

export const StyledDrawer = styled(Drawer)(() => ({
    maxHeight: '300px',
    borderTopLeftRadius: '20px',
}));

export const NoMoreItemsButton: React.FC<{ sx?: SxProps }> = ({ sx }) => {
    const { t } = useTranslation();

    return (
        <Button LinkComponent={LinkBehavior} variant="contained" href="/listings" sx={sx}>
            {t('browseAll')}
        </Button>
    );
};
