import React, { useState } from 'react';
import { useOutlet } from 'react-router-dom';

/**
 * Can be used to animate child routes when coupled with AnimatePresence
 */
export const AnimatedOutlet: React.FC = () => {
    const o = useOutlet();
    const [outlet] = useState(o);

    return <>{outlet}</>;
};
