import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography/Typography';
import isArray from 'lodash/isArray';
import React, { useEffect, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { maxPriceFilter } from '../../constants';

export interface Props {
    onChange: (values: number[]) => void;
    onChangeCommitted: (value: number[]) => void;
    min?: number;
    max?: number;
    label?: string;
    labelSuffix?: string;
    value: number[];
}

export const SliderRange: ReactFCWithChildren<Props> = ({ onChange, onChangeCommitted, label, value, min = 0, max = maxPriceFilter }) => {
    const { t } = useTranslation();
    const [lower, upper] = value;

    const onValueChange = (_event: Event, values: number | number[]) => {
        if (isArray(values)) {
            onChange(values);
        }
    };

    /**
     * For performance reasons, the value is emitted to parent only when
     * slide range change has been committed (user stopped dragging the slider).
     * If we emit the change to the parent on every onChange event emission,
     * the view lags considerably due to the parent rendering constantly.
     */
    const onCommittedChange = (_event: any, values: number | number[]) => {
        if (isArray(values)) {
            onChangeCommitted(values);
        }
    };

    return (
        <>
            <Typography id="input-slider" variant="h6" gutterBottom>
                {label}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ opacity: 0.6 }} variant="subtitle2" id="range-value" gutterBottom>
                    {lower}
                </Typography>

                <Typography sx={{ opacity: 0.6 }} variant="subtitle2" id="range-value" gutterBottom>
                    {upper === max ? `${t('over')} ${upper}` : upper}
                </Typography>
            </Box>

            <Slider
                size="small"
                getAriaLabel={() => `Selected range: ${lower} - ${upper}`}
                min={min}
                max={max}
                value={value}
                onChange={onValueChange}
                onChangeCommitted={onCommittedChange}
            />
        </>
    );
};
