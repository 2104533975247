import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { CategorySkeleton } from '../../animations/components/CategorySkeleton';

export const ArticleSkeleton: React.FC = () => {
    return (
        <Box>
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 16px)', height: '235px', margin: '20px 8px' }}
                mainCardStyle={{ borderRadius: '10px' }}
                variant="promoted"
            />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 80px)' }} />

            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 40px)', height: '235px', margin: '20px 20px' }}
                mainCardStyle={{ borderRadius: '10px' }}
                variant="category"
            />

            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 40px)' }} />
            <Skeleton variant="text" style={{ height: '20px', margin: '0px 20px', width: 'calc(100% - 80px)' }} />
            <CategorySkeleton
                containerStyle={{ width: 'calc(100% - 40px)', height: '235px', margin: '20px 20px' }}
                mainCardStyle={{ borderRadius: '10px' }}
                variant="category"
            />
        </Box>
    );
};
