import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomBackButton } from '../../../../animations/components/CustomBackButton';
import { StyledHeader } from '../../../Styled/Styled.components';
import { RobesLogo } from '../../../RobesLogo/RobesLogo';

interface TopbarPrefixProps {
    showSearchbar: boolean;
    backButtonVisible: boolean;
    routeLabel: string;
    mode: 'category' | 'route' | 'article' | 'logo' | 'back-button-only';
}

export const TopbarPrefix: React.FC<TopbarPrefixProps> = ({ backButtonVisible, showSearchbar, mode, routeLabel }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', ...(!showSearchbar && { flexGrow: 1, maxWidth: '100%' }) }}
        >
            <CustomBackButton isBackButtonVisible={backButtonVisible} />
            {(mode === 'route' || mode === 'article' || mode === 'category') && <StyledHeader variant="h6">{t(routeLabel)}</StyledHeader>}

            {mode === 'logo' && <RobesLogo height={30} />}
        </div>
    );
};
