import React, { useState } from 'react';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { ButtonBase, FormControl, SxProps, Typography } from '@mui/material';
import { StyledButton } from './DrawerMultiSelect.components';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { MultipleSelect } from '../MultipleSelect/MultipleSelect';
import { ArrowDropDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SelectList } from '../SelectList/SelectList';
import { ApplyFiltersButton } from '../../views/Listings/Search/Filters/ApplyFiltersButton';
import { isEqual } from 'lodash';

interface SelectOption {
    value: string;
    label: string;
    icon?: JSX.Element;
}

interface DrawerMultiSelectProps {
    label: string;
    selectedValues: string[];
    options: SelectOption[];
    totalItems: number;
    placeholder?: string;
    loading?: boolean;
    buttonLabel?: React.ReactNode;
    matchValues?: boolean;
    iconPlacement?: 'prefix' | 'suffix';
    hideSearch?: boolean;
    fillHeight?: boolean;
    ModalStyle?: React.CSSProperties;
    onInputChange?: (value: string) => void;
    onChange: (value: string[]) => void;
}

export const DrawerMultiSelect: React.FC<DrawerMultiSelectProps> = ({
    label,
    options,
    buttonLabel,
    selectedValues,
    matchValues = true,
    fillHeight,
    hideSearch,
    onChange,
    totalItems,
    loading = false,
    ModalStyle,
}) => {
    const [initialValues] = useState(selectedValues);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');

    const { t } = useTranslation();
    const filteredOptions = options.filter((el) => el.label.toLowerCase().includes(filter.toLowerCase()));

    const handleChange = (value: string) => {
        const isSelected = selectedValues.indexOf(value) > -1;

        if (isSelected) {
            onChange(selectedValues.filter((el) => el !== value));
        } else {
            onChange(selectedValues.concat(value));
        }
    };

    const isDesktop = useBreakpoint('sm');

    if (isDesktop) {
        return <MultipleSelect label={label} showLabel options={filteredOptions} selectedValues={selectedValues} onChange={onChange} />;
    }

    const getButtonLabel = () => {
        if (buttonLabel) {
            return buttonLabel;
        }

        return selectedValues.length
            ? selectedValues
                  .map((val) => {
                      if (matchValues) {
                          const match = options.find((el) => el.value === val);
                          return match?.label;
                      } else {
                          return val;
                      }
                  })
                  .filter((el) => !!el)
                  .join(', ')
            : `${t('select')} ${label}`;
    };

    return (
        <FormControl>
            <Typography variant="h6" sx={{ px: 2 }}>
                {label}
            </Typography>

            <StyledButton onClick={() => setOpen(true)}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ flexGrow: 1, textAlign: 'start' }}>
                        <Typography variant="caption" sx={{ opacity: 0.7, ...(selectedValues.length && { fontWeight: 'bold', opacity: 1 }) }}>
                            {getButtonLabel()}
                        </Typography>
                    </div>
                    <div style={{ width: '20px', opacity: 0.7 }}>
                        <ArrowDropDown />
                    </div>
                </div>
            </StyledButton>

            <SwipeableDrawer
                open={open}
                onClose={() => setOpen(false)}
                fillHeight={fillHeight}
                headerText={label}
                ModalStyle={ModalStyle}
                headerSuffix={<ButtonBase onClick={() => onChange([])}>{t('clear')}</ButtonBase>}
            >
                <SelectList
                    hideSearch={hideSearch}
                    options={options}
                    selectedValue={selectedValues}
                    onSelectOption={handleChange}
                    multiSelect
                    sx={{ paddingBottom: '70px' }}
                />
                <ApplyFiltersButton
                    onClick={() => setOpen(false)}
                    loading={loading}
                    totalItems={totalItems}
                    style={{ bottom: 'calc(10px + env(safe-area-inset-bottom) / 2)' }}
                />
            </SwipeableDrawer>
        </FormControl>
    );
};
