import React, { useState } from 'react';
import { SelectList } from '../../../../components/SelectList/SelectList';
import { DrawerConfig } from '../../../../components/FilterBar/Filterbar.types';
import { ListElement } from '../../../../components/SelectList/types';
import { ApplyFiltersButton } from './ApplyFiltersButton';

interface MultiSelectFilterProps {
    options: ListElement[];
    selectedValues: string[];
    totalItems: number;
    loading: boolean;
    onApplyFilters: () => void;
    onSelectOption: (option: any) => void;
}

export const MultiSelectFilterOptions: React.FC<MultiSelectFilterProps> = ({
    options,
    selectedValues,
    totalItems,
    loading,
    onApplyFilters,
    onSelectOption,
}) => {
    const hideSearch = options.length < 20;

    return (
        <>
            <SelectList
                hideSearch={hideSearch}
                options={options}
                selectedValue={selectedValues}
                onSelectOption={onSelectOption}
                multiSelect
                sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))', height: '100%' }}
            />
            <ApplyFiltersButton
                onClick={onApplyFilters}
                totalItems={totalItems}
                loading={loading}
                style={{ bottom: 'calc(10px + env(safe-area-inset-bottom) / 2)' }}
            />
        </>
    );
};
