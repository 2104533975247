import React, { useState } from 'react';
import { Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import ShowCase from '../../../assets/images/onboarding/Showcase.png';
import { StepBackButton, StepContent, StepContentContainer } from '../UserOnboarding.components';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { itemVariantsScale } from '../../../animations/constants';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { BaseStepProps } from '../UserOnboarding.types';
import { ArrowBack } from '@mui/icons-material';
import { User } from '../../../types/apiTypes';

interface LendingDescriptionStepProps extends BaseStepProps {
    user: User;
}

export const LendingDescriptionStep: React.FC<LendingDescriptionStepProps> = ({ onComplete, loading, onGoBack, user }) => {
    const [interestedInLending, setInterestedInLending] = useState(Boolean(user.profile.privateData.interestedInLending));
    const { t } = useTranslation();

    return (
        <StepContent sx={{ p: 2 }}>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.3, delay: 0.3 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                >
                    <StaggerListElement variants={itemVariantsScale} transition={{ type: 'spring', damping: 9, stiffness: 40 }}>
                        <img src={ShowCase} style={{ objectFit: 'contain', width: '100%', maxWidth: '250px' }} />
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="h5" fontWeight="bold">
                            {t('lendingStepTitle')}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="subtitle1">{t('lendingStepDescription')}</Typography>
                    </StaggerListElement>

                    <StaggerListElement>
                        <FormControlLabel
                            label={t('interestedInLending')}
                            control={<Checkbox checked={interestedInLending} onChange={(ev) => setInterestedInLending(ev.target.checked)} />}
                        />
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            <LoadingButton variant="contained" onClick={() => onComplete(interestedInLending)} loading={loading}>
                {t('continue')}
            </LoadingButton>

            <StepBackButton onClick={onGoBack}>
                <ArrowBack />
            </StepBackButton>
        </StepContent>
    );
};
