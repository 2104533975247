import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Tab, Tabs, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import { CustomTextField } from './SearchBar.components';
import { createSearchParams, useLocation } from 'react-router-dom';
import { useSearchContext } from './SearchBar';
import { useDebounce } from '../../../hooks/useDebounce';
import { useSearch } from './useSearch';
import { ListingSearchResults } from './ListingSearchResults';
import { UserSearchResults } from './UserSearchResults';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectFilters, updateFilters } from '../../../store/listingsReducer';
import { filtersToQueryString } from '../../../helpers/urlHelpers';

const ResultsContainer = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    overflowY: 'auto',
    maxHeight: '600px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.boxShadow['elevation-3'],
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
}));

const SearchContainer = styled(Box)({
    position: 'relative',
    width: '100%',
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
}

const WebSearchBar: React.FC = () => {
    const { isOpen, setIsOpen } = useSearchContext();

    const { t } = useTranslation();

    const [tab, setTab] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const navigate = useSafeNavigate();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);

    const { data: listingSearchResults, isLoading: listingSearchLoading } = useSearch(debouncedSearchTerm, 'listing');
    const { data: userSearchResults, isLoading: userSearchLoading } = useSearch(debouncedSearchTerm, 'user');

    const isSearching = debouncedSearchTerm !== searchTerm && !!searchTerm;

    const { pathname } = useLocation();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    const handleSearchListings = (searchTerm: string) => {
        const queryString = filtersToQueryString({ ...filters, keywords: searchTerm });
        navigate(`/listings?${queryString}`);

        setIsOpen(false);
    };

    const handleClear = () => {
        setSearchTerm('');
        dispatch(updateFilters({ type: 'keywords', payload: '' }));

        setIsOpen(false);
    };

    return (
        <SearchContainer ref={containerRef}>
            <CustomTextField
                value={searchTerm}
                fullWidth
                size="small"
                onChange={handleSearchChange}
                placeholder="Search..."
                onFocus={() => setIsOpen(true)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchListings(searchTerm);
                    }
                }}
                InputProps={{
                    startAdornment: <Search />,
                    endAdornment: (
                        <IconButton onClick={handleClear} sx={{ visibility: searchTerm ? 'visible' : 'hidden', height: '40px' }}>
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
            />
            {isOpen && (
                <ResultsContainer>
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        sx={{ backgroundColor: (theme) => theme.palette.background.paper, position: 'sticky', top: 0, zIndex: 1 }}
                    >
                        <Tab label={t('listings')} value={0} />
                        <Tab label={t('users')} value={1} />
                    </Tabs>

                    <TabPanel value={tab} index={0}>
                        <ListingSearchResults
                            results={listingSearchResults || []}
                            searchTerm={searchTerm}
                            loading={listingSearchLoading || isSearching}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={1}>
                        <UserSearchResults results={userSearchResults || []} searchTerm={searchTerm} loading={userSearchLoading || isSearching} />
                    </TabPanel>
                </ResultsContainer>
            )}
        </SearchContainer>
    );
};

export default WebSearchBar;
