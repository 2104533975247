import { useQuery } from '@tanstack/react-query';
import { listingsApiClient } from '../services/sharetribe/apiClients';
import { ExtendedListing } from '../types/apiTypes';

interface UseListingConfig {
    own?: boolean;
    enabled?: boolean;
}

export const useListing = (listingId: string | undefined, { own, enabled }: UseListingConfig = {}) => {
    const getListing = async () => {
        if (!listingId) {
            return null;
        }
        const url = own ? `/own/${listingId}` : `/${listingId}`;
        const { data } = await listingsApiClient.get<ExtendedListing>(url);
        return data;
    };

    return useQuery(['listing', { listingId }], getListing, {
        staleTime: 2 * 60 * 1000,
        enabled,
    });
};
