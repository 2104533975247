import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { LoginLayoutContainer, LoginLayoutContent } from './Login.components';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { AnimatedOutlet } from '../../animations/components/AnimatedOutlet';
import { AnimatePresence, motion } from 'framer-motion';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Link, useTheme } from '@mui/material';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { swap } from '../../animations/constants';
import { LanguageSelector } from '../UserProfile/routes/Language';

export const LoginLayout = (): JSX.Element => {
    const isDesktop = useBreakpoint('sm');
    const { pathname } = useLocation();
    const isRootLoginPath = pathname === '/login';
    const theme = useTheme();

    const desktopStyles = {
        position: 'relative',
        background: theme.palette.mode === 'dark' ? 'rgb(24, 26, 27)' : theme.palette.background.paper,
    };

    return (
        <LoginLayoutContainer>
            <LoginLayoutContent elevation={isDesktop ? 8 : 0} sx={isDesktop ? desktopStyles : null}>
                <AnimatePresence>
                    <Box sx={{ position: 'absolute', top: 'env(safe-area-inset-top)', right: 0, padding: '14px', zIndex: 2 }}>
                        <LanguageSelector displayIcon inputProps={{ sx: { fontSize: '13px' } }} />
                    </Box>

                    {!isRootLoginPath && (
                        <AnimatedContainer
                            key="login-back-button"
                            style={{ position: 'absolute', top: 'env(safe-area-inset-top)', left: 0, padding: '14px' }}
                            variants={swap}
                        >
                            <IconButton LinkComponent={Link} href="/login" sx={{ zIndex: 2 }}>
                                <ArrowBack />
                            </IconButton>
                        </AnimatedContainer>
                    )}
                </AnimatePresence>

                <motion.div layoutId="robes-logo" style={{ paddingTop: '56px' }}>
                    <RobesLogoFull height={pathname === '/login' ? 70 : 100} />
                </motion.div>

                {isDesktop ? <Outlet /> : <AnimatedOutlet />}
            </LoginLayoutContent>
        </LoginLayoutContainer>
    );
};
