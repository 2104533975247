import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { FilterItem } from './Filterbar.types';
import { StyledSvgIcon } from '../Styled/Styled.components';
import { TFunction } from 'react-i18next';
import { Badge } from '@mui/material';

export const getFilterBarItems = (compactMode: boolean, selectedFilters: string[], t: TFunction): FilterItem[] => {
    const baseItems: FilterItem[] = [
        {
            value: 'category',
            label: t('category'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'size',
            label: t('size'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'delivery',
            label: t('deliveryOption'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'brand',
            label: t('brand'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'price',
            label: t('price'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'bodytype',
            label: t('bodytype'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
        {
            value: 'color',
            label: t('color'),
            type: 'button',
            endIcon: <ArrowDropDownIcon fontSize="small" />,
        },
    ];

    if (selectedFilters.length > 0) {
        // Move selected filters to the start of array
        baseItems.sort((a, b) => {
            if (a.type === 'divider' || b.type === 'divider') {
                return 0;
            }

            if (selectedFilters.includes(a.value)) {
                return -1;
            }

            if (selectedFilters.includes(b.value)) {
                return 1;
            }

            return 0;
        });

        const lastSelectedItemIdx = baseItems.reduce(
            (acc, item, idx) => (item.type !== 'divider' && selectedFilters.includes(item.value) ? idx : acc),
            -1,
        );

        // Add divider after last selected item
        if (lastSelectedItemIdx > -1) {
            baseItems.splice(lastSelectedItemIdx + 1, 0, { type: 'divider' });
        }
    }

    const compactModeIcon = (
        <StyledSvgIcon fontSize="small">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M184-528v-248h248v248H184Zm0 344v-248h248v248H184Zm344-344v-248h248v248H528Zm0 344v-248h248v248H528ZM216-560h184v-184H216v184Zm344 0h184v-184H560v184Zm0 344h184v-184H560v184Zm-344 0h184v-184H216v184Zm344-344Zm0 160Zm-160 0Zm0-160Z" />
            </svg>
        </StyledSvgIcon>
    );

    const defaultModeIcon = (
        <StyledSvgIcon fontSize="small">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M240.615-543.385q-23.615 0-40.115-16.15Q184-575.685 184-600v-94.77q0-23.615 16.5-40.115 16.5-16.5 40.115-16.5h478.77q24.315 0 40.465 16.5Q776-718.385 776-694.77V-600q0 24.315-16.15 40.465-16.15 16.15-40.465 16.15h-478.77Zm0-32h478.77q9.23 0 16.923-7.692Q744-590.769 744-600v-94.77q0-9.23-7.692-16.923-7.693-7.692-16.923-7.692h-478.77q-9.23 0-16.923 7.692Q216-704 216-694.77V-600q0 9.231 7.692 16.923 7.693 7.692 16.923 7.692Zm0 366.77q-23.615 0-40.115-16.15-16.5-16.15-16.5-40.465V-360q0-23.615 16.5-40.115 16.5-16.5 40.115-16.5h478.77q24.315 0 40.465 16.5Q776-383.615 776-360v94.77q0 24.315-16.15 40.465-16.15 16.15-40.465 16.15h-478.77Zm0-32h478.77q9.23 0 16.923-7.692Q744-256 744-265.23V-360q0-9.231-7.692-16.923-7.693-7.692-16.923-7.692h-478.77q-9.23 0-16.923 7.692Q216-369.231 216-360v94.77q0 9.23 7.692 16.923 7.693 7.692 16.923 7.692ZM216-719.385v144-144Zm0 334.77v144-144Z" />
            </svg>
        </StyledSvgIcon>
    );

    baseItems.push({
        value: 'sort',
        label: t('sort'),
        type: 'button',
        startIcon: (
            <StyledSvgIcon fontSize="small">
                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                    <path d="M356.307-459.692v-302.462L230.538-636.385 208-659.692 372.308-824l164.307 164.308-22.538 23.307-125.769-125.769v302.462h-32.001ZM586.923-136 422.615-300.308l22.539-23.307 125.769 125.769v-302.462h32v302.462l125.769-125.769 22.539 23.307L586.923-136Z" />
                </svg>
            </StyledSvgIcon>
        ),
    });

    baseItems.push({
        value: 'layout',
        label: t('layout'),
        type: 'button',
        startIcon: compactMode ? compactModeIcon : defaultModeIcon,
    });

    baseItems.unshift({
        type: 'icon',
        value: 'allFilters',
        icon: (
            <Badge variant="dot" color="primary" invisible={!selectedFilters.length}>
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                        <path d="M476-164v-200h32v84h288v32H508v84h-32Zm-312-84v-32h200v32H164Zm144-132v-84H164v-32h144v-84h32v200h-32Zm144-84v-32h344v32H452Zm144-132v-200h32v84h168v32H628v84h-32Zm-432-84v-32h344v32H164Z" />
                    </svg>
                </StyledSvgIcon>
            </Badge>
        ),
    });

    return baseItems;
};
