import { Skeleton } from '@mui/material';
import React from 'react';

export const TransactionCardsSkeleton: React.FC = () => {
    const style = { height: '205px', borderRadius: '10px' };

    return (
        <div style={{ width: '100%', display: 'flex', padding: '15px 15px', gap: '20px', flexDirection: 'column' }}>
            <Skeleton variant="rounded" style={style} />
            <Skeleton variant="rounded" style={{ ...style, height: '120px' }} />
            <Skeleton variant="rounded" style={{ ...style, height: '120px' }} />
            <Skeleton variant="rounded" style={style} />
        </div>
    );
};
