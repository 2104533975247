import { Box, Typography } from '@mui/material';
import React from 'react';

interface FormInputWithLabelProps {
    label: React.ReactNode;
    value: React.ReactNode;
}

export const FormInputWithLabel: React.FC<FormInputWithLabelProps> = ({ label, value }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="caption" sx={{ fontWeight: '300', fontSize: 12, opacity: 0.7 }}>
            {label}
        </Typography>
        <Typography variant="subtitle2">{value}</Typography>
    </Box>
);
