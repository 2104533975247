import { useEffect } from 'react';
import { ListenerEvent } from '../store/listeners';

type ListenerEventPayloads = {
    [K in ListenerEvent['type']]: Extract<ListenerEvent, { type: K }>['payload'];
};

/**
 * Listeners defined in the store/listeners.ts can be listened to using this hook.
 */
export const useDispatchListener = <T extends ListenerEvent['type']>(
    listener: T,
    callback: (event: CustomEvent<ListenerEventPayloads[T]>) => void,
) => {
    useEffect(() => {
        const handler = (event: Event) => callback(event as CustomEvent);
        document.addEventListener(listener, handler);

        return () => {
            document.removeEventListener(listener, handler);
        };
    }, [listener, callback]);
};
