import React, { useId } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ListElement } from '../SelectList/types';

interface SimpleSelectProps<T> {
    onChange: (value: T) => void;
    selectedValue: string | string[];
    options: ListElement[];
    multiSelect?: boolean;
    label?: string;
}

export const SimpleSelect = <T extends string>(props: SimpleSelectProps<T>): JSX.Element => {
    const id = useId();
    const { selectedValue, multiSelect, onChange, options, label } = props;

    const isSelectedFn = (value: string) => {
        if (multiSelect) {
            return selectedValue.includes(value);
        }

        return selectedValue === value;
    };

    return (
        <FormControl sx={{ border: 'none' }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                label={label}
                labelId={id}
                multiple={multiSelect}
                id="simple-select"
                value={selectedValue}
                onChange={(event) => onChange(event.target.value as T)}
                sx={{ fontSize: 14, fontWeight: '300' }}
                MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
            >
                {options.map((item) => (
                    <MenuItem selected={isSelectedFn(item.value)} key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
