import React, { useState, useRef, useEffect, useCallback, useMemo, createContext, useContext } from 'react';
import { io, Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import { App, AppState } from '@capacitor/app';
import { ReactFCWithChildren } from '../types/types';
import { logger } from '../helpers/logger';
import { PluginListenerHandle } from '@capacitor/core';
import { assertIsDefined } from '../helpers/commonHelpers';

const SERVER_BASE_URL = `${process.env.REACT_APP_API_URL}` || '';

interface SocketContextValue {
    socket: Socket | null;
    connected: boolean;
    emit: (channel: string, payload: string) => void;
}

const SocketContext = createContext<SocketContextValue>({
    socket: null,
    connected: false,
    emit: () => null,
});

export const SocketProvider: ReactFCWithChildren = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [connected, setConnected] = useState(false);

    // If the client was diconnected from the server, we need to queue up any messages that were sent while dconnected and send them once the client reconnects
    const queueRef = useRef<{ channel: string; payload: string }[]>([]);

    useEffect(() => {
        // Initialize socket
        const newSocket = io(SERVER_BASE_URL, {
            autoConnect: false, // We're manually handling reconnections
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
        });

        setSocket(newSocket);
        newSocket.connect();

        // Handle connection events
        newSocket.on('connect', () => {
            setConnected(true);
        });

        newSocket.on('disconnect', () => {
            setConnected(false);
        });

        newSocket.on('connect_error', (error) => {
            setConnected(false);
            console.error('Connection Error:', error);
        });

        return () => {
            newSocket.close();
        };
    }, []);

    // Handle app visibility
    useEffect(() => {
        const handleAppResume = () => {
            if (socket && !socket.connected) {
                socket.connect();
            }
        };

        let appStateListener: PluginListenerHandle;

        const addListener = async () => {
            appStateListener = await App.addListener('appStateChange', handleAppResume);
        };

        addListener();

        return () => {
            appStateListener?.remove();
        };
    }, [socket]);

    const emit = useCallback(
        (channel: string, payload: string) => {
            assertIsDefined(socket, 'Socket is not defined');

            if (!connected) {
                logger.debug('Client not connected to websocket, attempting to reconnect');
                queueRef.current.push({ channel, payload });
                socket.connect();
                return;
            }

            socket.emit(channel, payload);
        },
        [connected],
    );

    return <SocketContext.Provider value={{ socket, connected, emit }}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
    return useContext(SocketContext);
};
