import { styled } from '@mui/material';
import { motion } from 'framer-motion';
import { StyledSvgIcon } from '../../Styled/Styled.components';

export const FooterContainer = styled(motion.div, { shouldForwardProp: (prop) => prop !== 'showShadow' })<{ showShadow: boolean }>(
    ({ theme, showShadow }) => ({
        position: 'fixed',
        width: '100%',
        left: 0,
        right: 0,
        zIndex: 999,
        paddingBottom: 'env(safe-area-inset-bottom)',
        background: theme.palette.background.paper,
        ...(showShadow && { boxShadow: theme.palette.boxShadow['elevation-12'] }),
    }),
);

export const HomeIcon: React.FC = () => {
    return (
        <StyledSvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M264-216h123.692v-235.385h184.616V-216H696v-348L480-727.538 264-564v348Zm-32 32v-396l248-187.539L728-580v396H540.308v-235.384H419.692V-184H232Zm248-287.769Z" />
            </svg>
        </StyledSvgIcon>
    );
};

export const SearchIcon: React.FC = () => {
    return (
        <StyledSvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M760.385-177.846 522.923-415.307q-30 23.538-65.792 36.038-35.792 12.5-71.558 12.5-87.135 0-148.2-61.029-61.066-61.029-61.066-148.077t61.029-148.202q61.029-61.154 148.077-61.154 87.049 0 148.202 61.065 61.154 61.065 61.154 148.201 0 38.073-13.269 73.865-13.269 35.792-35.269 63.485l237.462 237.461-23.308 23.308ZM385.538-398.769q74.616 0 125.923-51.308Q562.769-501.385 562.769-576q0-74.615-51.308-125.923-51.307-51.308-125.923-51.308-74.615 0-125.923 51.308Q208.308-650.615 208.308-576q0 74.615 51.307 125.923 51.308 51.308 125.923 51.308Z" />
            </svg>
        </StyledSvgIcon>
    );
};

export const WardrobeIcon: React.FC = () => {
    return (
        <StyledSvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M389.441-465.231q-10.595 0-18.787-7.982-8.193-7.981-8.193-18.576 0-10.596 7.982-18.788t18.577-8.192q10.595 0 18.788 7.982Q416-502.806 416-492.211q0 10.596-7.982 18.788t-18.577 8.192Zm181.539 0q-10.595 0-18.788-7.982Q544-481.194 544-491.789q0-10.596 7.982-18.788t18.577-8.192q10.595 0 18.787 7.982 8.193 7.981 8.193 18.576 0 10.596-7.982 18.788t-18.577 8.192ZM204-184v-32h48v-503.385q0-24.315 16.162-40.465Q284.323-776 308.656-776h343.012Q676-776 692-759.85t16 40.465V-216h48v32H204Zm80-32h180v-528H308.615q-9.231 0-16.923 7.692Q284-728.615 284-719.385V-216Zm212 0h180v-503.385q0-9.23-7.692-16.923Q660.616-744 651.385-744H496v528Zm-16-256Z" />
            </svg>
        </StyledSvgIcon>
    );
};

export const AddListingIcon: React.FC = () => {
    return (
        <StyledSvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                <path d="M464-308h32v-156h156v-32H496v-156h-32v156H308v32h156v156Zm16.409 172q-70.948 0-133.685-26.839-62.737-26.84-109.865-73.922-47.127-47.082-73.993-109.716Q136-409.111 136-480.325q0-71.214 26.839-133.451 26.84-62.237 73.922-109.365 47.082-47.127 109.716-73.993Q409.111-824 480.325-824q71.214 0 133.451 26.839 62.237 26.84 109.365 73.922 47.127 47.082 73.993 109.482Q824-551.357 824-480.409q0 70.948-26.839 133.685-26.84 62.737-73.922 109.865-47.082 47.127-109.482 73.993Q551.357-136 480.409-136ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
            </svg>
        </StyledSvgIcon>
    );
};

export const ProfileIcon: React.FC = () => {
    return (
        <StyledSvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M480-504.846q-49.5 0-82.442-32.942-32.943-32.943-32.943-82.827 0-49.885 32.943-82.443Q430.5-735.616 480-735.616q49.5 0 82.443 32.558 32.942 32.558 32.942 82.443 0 49.884-32.942 82.827Q529.5-504.846 480-504.846ZM200-215.384v-57.847q0-26.461 15.154-47.307 15.153-20.847 39.769-32.539 59.308-26.154 115.015-39.615 55.707-13.462 110.038-13.462 54.332 0 109.947 13.577Q645.539-379 704.425-352.726q25.447 11.497 40.511 32.266Q760-299.692 760-273.231v57.847H200Zm30.769-30.77h498.462v-27.077q0-15.23-10.269-29.346-10.27-14.115-28.116-23.423-54-26.385-105.605-37.885-51.605-11.5-105.241-11.5t-105.856 11.5q-52.221 11.5-105.221 37.885-17.846 9.308-28 23.423-10.154 14.116-10.154 29.346v27.077ZM480-535.615q35.923 0 60.269-24.347 24.346-24.346 24.346-60.269T540.269-680.5Q515.923-704.846 480-704.846T419.731-680.5q-24.346 24.346-24.346 60.269t24.346 60.269q24.346 24.347 60.269 24.347Zm0-84.616Zm0 374.077Z" />
            </svg>
        </StyledSvgIcon>
    );
};
