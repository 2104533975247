import { useCapacitorStripe } from '@capacitor-community/stripe/dist/esm/react/provider';
import { AxiosError } from 'axios';
import { addMinutes } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { assertNever, invariant, sendToSentry } from '../../../helpers/commonHelpers';
import { addToOngoing, Booking, BookingDraft, updateOngoing } from '../../../store/bookingReducer';
import { useAppDispatch } from '../../../store/hooks';
import { PaymentIntent, PaymentMethod, TransactionFlowParams, UsePaymentParams } from './types';
import { useStripe } from '@stripe/react-stripe-js';
import {
    isBookingDraftWithRequiredProperties,
    isBooking,
    isBookingDraft,
    composeAsync,
    isBookingExpired,
    updateUserDeliveryDetailsMaybe,
    checkShouldRecreateTransaction,
} from './helpers';
import { applePayFlow, cardPaymentFlow, googlePayFlow } from './paymentFlows';
import { initiateTransaction, confirmPayment, declineTransaction, getEphemeralKey } from './paymentApi';

export const ERROR_GOOGLE_PAY = 'googlePayError';
export const ERROR_APPLE_PAY = 'applePayError';
export const ERROR_CARD_PAYMENT = 'cardPaymentError';
export const ERROR_PAYMENT_CANCELLED = 'paymentCancelledError';

type FnInitiateFullCreditPaymentTransactionParams = Omit<TransactionFlowParams, 'booking'> & { booking: BookingDraft };

const fnInitiateFullCreditPaymentTransaction = async (params: FnInitiateFullCreditPaymentTransactionParams) => {
    const { dispatch, queryClient, listingId, booking } = params;

    const { customer, transaction } = await initiateTransaction(listingId, booking);

    const updatedBookingData = {
        ...booking,
        listingId,
        customer,
        status: 'payment-confirmed' as const,
        transaction,
        expiresAt: addMinutes(new Date(transaction.attributes.createdAt), 15).toISOString(),
    };

    dispatch(addToOngoing(updatedBookingData));

    await updateUserDeliveryDetailsMaybe(booking, queryClient);

    // At this point the booking has been made -> refetch listing data to display updated available booking range
    queryClient.invalidateQueries(['listing', { listingId }]);
    queryClient.invalidateQueries(['timeslots', { listingId }]);

    return null;
};

type FnInitiateTransactionParams = Omit<TransactionFlowParams, 'booking'> & { booking: BookingDraft };

const fnInitiateTransaction = async (params: FnInitiateTransactionParams) => {
    const { dispatch, queryClient, listingId, booking, shouldUpdateOngoingBooking, isApplePayAvailable, isGooglePayAvailable } = params;

    const ensurePaymentMethod = (booking: Booking | BookingDraft) => {
        if (booking.paymentMethod) {
            return booking;
        }

        let paymentMethod: PaymentMethod = 'card';

        if (isGooglePayAvailable) {
            paymentMethod = 'google_pay';
        }
        if (isApplePayAvailable) {
            paymentMethod = 'apple_pay';
        }

        sendToSentry(new Error('Payment method was not set for transaction'), {
            booking: JSON.stringify(booking),
            listingId,
            paymentMethod,
            isApplePayAvailable,
            isGooglePayAvailable,
        });

        return { ...booking, paymentMethod };
    };

    const { paymentIntentClientSecret, ephemeralKey, customer, transaction } = await initiateTransaction(listingId, ensurePaymentMethod(booking));

    invariant(isBookingDraftWithRequiredProperties(booking), 'booking data is malformed');

    const updatedBookingData: Booking = {
        ...booking,
        listingId,
        customer,
        paymentIntentClientSecret,
        status: 'payment-pending',
        transaction,
        expiresAt: addMinutes(new Date(transaction.attributes.createdAt), 15).toISOString(),
    };

    // Transaction was started before, but the payment was cancelled and the data changed after that -> we already have an ongoing booking in the state
    if (shouldUpdateOngoingBooking) {
        dispatch(updateOngoing(updatedBookingData));
    } else {
        dispatch(addToOngoing(updatedBookingData));
    }

    // At this point the booking has been made -> refetch listing data to display updated available booking range
    queryClient.invalidateQueries(['listing', { listingId }]);
    queryClient.invalidateQueries(['timeslots', { listingId }]);

    invariant(isBooking(updatedBookingData), 'booking data is malformed');

    return { ...params, booking: updatedBookingData, ephemeralKey };
};

type FnPaymentFlowParams = Awaited<ReturnType<typeof fnInitiateTransaction>>;

const fnPaymentFlow = async (params: FnPaymentFlowParams) => {
    const { stripe, capacitorStripe, dispatch, listingId, booking, ephemeralKey, ev, queryClient } = params;
    const { customer, paymentMethod, paymentIntentClientSecret, transaction } = booking;
    const paymentIntent = {
        customer,
        paymentIntentClientSecret,
        ephemeralKey,
    };

    const { payinTotal } = transaction.attributes;

    const { productLabel } = booking;

    const paymentFlow = (): Promise<PaymentIntent> => {
        if (paymentMethod === 'card') {
            return cardPaymentFlow(capacitorStripe, paymentIntent);
        }
        if (paymentMethod === 'google_pay') {
            return googlePayFlow(capacitorStripe, paymentIntent, productLabel, payinTotal);
        }
        if (paymentMethod === 'apple_pay') {
            return applePayFlow(capacitorStripe, stripe, paymentIntent, productLabel, payinTotal, ev);
        }

        return assertNever(paymentMethod);
    };

    // Present payment sheet and await for (potential) required authentication steps required by Stripe
    await paymentFlow().catch((err) => {
        // In the web implementation, capacitor plugin doesn't throw any useful error when user cancels,
        // so we assume if error = undefined -> user cancelled payment.
        throw new Error(err || ERROR_PAYMENT_CANCELLED);
    });

    dispatch(updateOngoing({ status: 'awaits-confirmation', listingId }));

    return { queryClient, dispatch, booking, listingId };
};

type FnConfirmPaymentParams = Awaited<ReturnType<typeof fnPaymentFlow>>;

export const fnConfirmPayment = async (params: FnConfirmPaymentParams) => {
    const { queryClient, dispatch, booking, listingId } = params;
    const { uuid: transactionId } = booking.transaction.id;

    // After stripe has registered and captured the charge, transition the transaction further
    await confirmPayment(transactionId).catch((err) => {
        throw new Error(err);
    });

    dispatch(updateOngoing({ status: 'payment-confirmed', listingId }));

    await updateUserDeliveryDetailsMaybe(booking, queryClient);

    return null;
};

export const fnTransactionFlow = async (params: TransactionFlowParams) => {
    const { booking, fullCreditPayment } = params;

    // Full credit payment -> skip presenting payment sheet and instead transition the transaction to payment-confirmed immediately after success
    if (isBookingDraft(booking) && fullCreditPayment) {
        return await fnInitiateFullCreditPaymentTransaction(params);
    }

    // Transaction not started -> full transaction flow
    if (isBookingDraft(booking)) {
        const composedFunc = composeAsync(fnInitiateTransaction, fnPaymentFlow, fnConfirmPayment);

        return composedFunc(params);
    }

    // Transaction was started previously but booking data has changed -> decline the previous transaction and begin a new, full transaction flow
    if (isBooking(booking) && checkShouldRecreateTransaction(booking)) {
        await declineTransaction(booking.transaction.id.uuid);
        params.shouldUpdateOngoingBooking = true;

        const composedFunc = composeAsync(fnInitiateTransaction, fnPaymentFlow, fnConfirmPayment);

        return composedFunc(params);
    }

    // Transaction was started previously but payment did not succeed -> partial transaction flow.
    // Fetch new ephemeral key from Stripe.
    if (booking.status === 'payment-pending') {
        invariant(isBooking(params.booking), 'attempting to continue malformed booking transaction');

        const ephemeralKey = await getEphemeralKey(booking.customer);
        const composedFunc = composeAsync(fnPaymentFlow, fnConfirmPayment);

        return composedFunc({ ...params, booking: params.booking, ephemeralKey });
    }

    // Payment is complete but not confirmed -> confirm payment
    if (booking.status === 'awaits-confirmation') {
        invariant(isBooking(params.booking), 'attempting to continue malformed booking transaction');
        const composedFunc = composeAsync(fnConfirmPayment);

        return composedFunc({ ...params, booking: params.booking });
    }

    // Should not be called with this booking state
    if (booking.status === 'payment-confirmed') {
        throw new Error('Payment is already confirmed');
    }

    if (!booking.status) {
        throw new Error('Booking status is not defined');
    }

    return Promise.reject(assertNever(booking.status));
};

export const usePaymentFlow = () => {
    const { stripe: capacitorStripe, ...paymentProps } = useCapacitorStripe();

    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const queryClient = useQueryClient();

    return useMutation(
        async (params: UsePaymentParams) => {
            const { listingId, onExpired, booking, ev, fullCreditPayment } = params;

            if (!booking) {
                throw new Error('No booking found');
            }

            if (isBookingExpired(booking)) {
                onExpired();
            } else {
                await fnTransactionFlow({
                    stripe,
                    capacitorStripe,
                    dispatch,
                    booking,
                    listingId,
                    fullCreditPayment,
                    queryClient,
                    ev,
                    ...paymentProps,
                });
            }
        },
        {
            onError: (err: AxiosError, variables) => {
                if (err.response?.data && 'error' in err.response.data) {
                    variables.onConflict(err.response.data.error);
                } else {
                    variables.onConflict(err);
                }
            },

            // Transaction complete, next steps will be for the lender to accept or decline the booking
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries(['own-transactions']);
                queryClient.invalidateQueries(['credits-balance']);

                variables.onSuccess();
            },
        },
    );
};
