import { FocusTrap } from '@mui/base';
import { Close } from '@mui/icons-material';
import { Backdrop, IconButton, useTheme } from '@mui/material';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import React, { CSSProperties, useEffect } from 'react';
import { ReactFCWithChildren } from '../../types/types';
import { Portal } from '../Portal/Portal';
import { MODAL_ANIMATION_DURATION_S } from './Modal.constants';
import { useKey } from '../../hooks/useKey';
import { useHashChange } from '../../hooks/useHashChange';
import { noop } from '../../helpers/commonHelpers';

const sizes = {
    auto: {
        width: 'auto',
        height: 'auto',
    },
    autoHeight: {
        width: '90%',
        height: 'auto',
        maxWidth: '430px',
    },
    sm: {
        width: '80%',
        height: '50%',
        maxWidth: '430px',
    },
    md: {
        width: '85%',
        height: '65%',
        maxWidth: '430px',
    },
    lg: {
        width: '90%',
        height: '75%',
        maxWidth: '430px',
    },
    full: {
        width: '100%',
        height: '100%',
        maxWidth: '430px',
    },
};

type Variant = keyof typeof sizes;

interface ModalProps {
    open: boolean;
    variant?: Variant;
    onClose?: () => void;
    disableFocusTrap?: boolean;
    hideCloseBtn?: boolean;
    animationDuration?: number;
    modalStyle?: CSSProperties;
    motionProps?: MotionProps;
}

export const Modal: ReactFCWithChildren<ModalProps> = ({
    open,
    children,
    onClose = noop,
    modalStyle,
    variant = 'md',
    animationDuration = MODAL_ANIMATION_DURATION_S,
    disableFocusTrap,
    hideCloseBtn,
    motionProps,
}) => {
    const style = sizes[variant];
    const theme = useTheme();

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const handleClose = () => {
        window.history.back();
        onClose?.();
    };

    useHashChange(open, onClose);

    useKey('Escape', handleClose, { event: 'keyup' });

    return (
        <Portal>
            <Backdrop open={open} sx={{ zIndex: (t) => t.zIndex.drawer + 1 }} onClick={handleClose}>
                <AnimatePresence>
                    {open && (
                        <FocusTrap open={disableFocusTrap ? false : open}>
                            <motion.div
                                tabIndex={-1}
                                style={{
                                    ...style,
                                    background: theme.palette.background.paper,
                                    borderRadius: '10px',
                                    margin: '32px',
                                    position: 'relative',
                                    outline: 'none',
                                    overflow: 'hidden',
                                    ...modalStyle,
                                }}
                                key="modal-content"
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1, transition: { scale: { duration: animationDuration } } }}
                                exit={{ scale: 0, opacity: 0, transition: { opacity: { duration: 0.15 } } }}
                                onClick={(event) => event.stopPropagation()}
                                {...motionProps}
                            >
                                <>
                                    {!hideCloseBtn && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '0px',
                                                right: '0px',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                zIndex: 1,
                                            }}
                                        >
                                            <IconButton onClick={handleClose} sx={{ padding: '2px' }}>
                                                <Close />
                                            </IconButton>
                                        </div>
                                    )}

                                    {children}
                                </>
                            </motion.div>
                        </FocusTrap>
                    )}
                </AnimatePresence>
            </Backdrop>
        </Portal>
    );
};
