import { motion, MotionStyle, MotionValue, PanInfo } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactFCWithChildren } from '../../types/types';

interface PageProps {
    index: number;
    currentPage: number;
    x: MotionValue;
    pageTotal: number;
    infinite: boolean;
    renderPage: (props: { index: number }) => JSX.Element;
    onDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void;
}

type DragConstraints = { left?: number; right?: number };

const pageStyle: MotionStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
};

export const Page: ReactFCWithChildren<PageProps> = ({ currentPage, index, pageTotal, renderPage, x, onDragEnd, infinite }) => {
    const [width, setWidth] = useState(0);

    const child = useMemo(() => renderPage({ index }), [index, renderPage]);
    const ref = useRef<HTMLDivElement>(null);

    let dragConstraints: DragConstraints = {};

    if (!infinite) {
        if (currentPage === 0) {
            dragConstraints.right = 0;
        }
        if (currentPage === pageTotal - 1) {
            dragConstraints.left = -currentPage * width;
        }
    }

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
        }
    }, [ref]);

    return (
        <motion.div
            ref={ref}
            style={{
                ...pageStyle,
                x,
                zIndex: currentPage === index ? 2 : 1,
                left: `${index * 100}%`,
                right: `${index * 100}%`,
            }}
            draggable
            drag="x"
            dragElastic={0}
            dragConstraints={dragConstraints}
            onDragEnd={onDragEnd}
        >
            {child}
        </motion.div>
    );
};
