/* eslint-disable no-console */

const debugEnabled = (process.env.REACT_APP_DEBUG || '') === 'true';

const info = (...params: any[]) => {
    console.log(...params);
};

const debug = (...params: any[]) => {
    if (debugEnabled) {
        console.log(...params);
    }
};

const warn = (...params: any[]) => {
    console.warn(...params);
};

const error = (...params: any[]) => {
    console.error(...params);
};

export const logger = {
    info,
    debug,
    warn,
    error,
};
