import { useEffect, useState } from 'react';
import { useCurrentUser } from '../../user/hooks/useUser';
import ReactGA from 'react-ga4';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

export const useUserIdTracking = () => {
    const [isTracking, setIsTracking] = useState(false);
    const { data: currentUser } = useCurrentUser();

    useEffect(() => {
        if (isTracking || !currentUser) return;

        const setUserId = async () => {
            if (Capacitor.isNativePlatform()) {
                await FirebaseAnalytics.setUserId({ userId: currentUser.id });
            } else {
                ReactGA.set({ userId: currentUser.id });
            }

            setIsTracking(true);
        };

        setUserId();
    }, [currentUser]);
};
