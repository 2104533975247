import ReactGA from 'react-ga4';
import { ListingsState } from '../../views/Listings/types';
import { isEmpty, isEqual } from 'lodash';
import { defaultPriceRange } from '../../views/Listings/constants';
import { HomePageAction } from './types';

const genericEvent = (eventName: string) => ReactGA.event(eventName);

const homePageAction = (action: HomePageAction, params: Record<string, unknown>) => ReactGA.event('home_page_action', { action, ...params });

const listingClick = (id: string, title: string, source: 'home-page' | 'listings-page' | 'profile-page') =>
    ReactGA.event('listing_click', {
        action: 'Clicked listing',
        listing_id: id,
        listing_title: title,
    });

const filtersReset = () => ReactGA.event('filters_reset', { action: 'Filters reset' });
const filtersChanged = (actionType: keyof ListingsState['filters'], filters: ListingsState['filters']) => {
    const keys = Object.keys(filters) as (keyof typeof filters)[];
    const activeFilters = keys.reduce((acc, curr) => {
        if (!isEmpty(filters[curr])) {
            const filterValue = filters[curr] as typeof filters[typeof curr];
            if (typeof filterValue === 'string') {
                acc[`filter_${curr}`] = filterValue;
            } else if (curr === 'price') {
                if (isEqual(filterValue, defaultPriceRange)) {
                    return acc;
                }

                const [min, max] = filterValue;
                acc[`filter_${curr}_min`] = min;
                acc[`filter_${curr}_max`] = max;
            } else {
                acc[`filter_${curr}`] = filterValue.join(',');
            }
        }

        return acc;
    }, {} as Record<string, unknown>);

    ReactGA.event('filters_changed', { action: 'Modified filters', ...activeFilters, type: actionType });
};

const onboardingStepComplete = (step: string) => ReactGA.event('onboarding-step', { step });
const postalCodeCheck = (postalCode: string, available: boolean) => ReactGA.event('wolt_availability_check', { postalCode, available });

export const webAnalytics = {
    genericEvent,
    homePageAction,
    listingClick,
    filtersChanged,
    filtersReset,
    postalCodeCheck,
    onboardingStepComplete,
};
