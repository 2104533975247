/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/apiTypes';
import { RootState } from './types';

export interface UserState {
    user: User | null;
    token: string | null;
}

const initialState: UserState = {
    user: null,
    token: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        storeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        resetToken: (state) => {
            state.token = null;
        },
    },
});

export const { storeToken, resetToken } = userSlice.actions;
export const selectToken = (state: RootState): string => state.user.token ?? '';

export default userSlice.reducer;
