import React from 'react';
import { User } from './useSearch';
import {
    ListItem,
    Typography,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    ListItemText,
    IconButton,
    Box,
    List,
    ListItemSecondaryAction,
} from '@mui/material';
import { LinkBehavior } from '../../..';
import { capitalizeEachWord } from '../../../helpers/commonHelpers';
import { ChevronRightOutlined } from '@mui/icons-material';
import { AnimatePresence } from 'framer-motion';
import { fadeIn, fadeInOut } from '../../../animations/constants';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { useTranslation } from 'react-i18next';
import { useSearchConfiguration } from './useSearchConfiguration';
import { SearchResultsSkeleton } from './SearchResultsSkeleton';
import { renderStrapiComponent } from '../../../strapi/helpers';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

interface UserSearchResultsProps {
    searchTerm: string;
    loading: boolean;
    results: User[];
}

interface UserListItemProps {
    user: User;
}

const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
    return (
        <ListItem disableGutters>
            <ListItemButton disableGutters LinkComponent={LinkBehavior} href={`/user/${user.userId}`}>
                <ListItemAvatar sx={{ mr: 2 }}>
                    <Avatar src={user.profileImage} sx={{ width: 56, height: 56 }} variant="circular" />
                </ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}
                    secondaryTypographyProps={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}
                    primary={capitalizeEachWord(user.displayName)}
                    secondary={user.bio}
                />
                <ListItemSecondaryAction>
                    <IconButton>
                        <ChevronRightOutlined />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};

export const UserSearchResults: React.FC<UserSearchResultsProps> = ({ results, searchTerm, loading }) => {
    const { data: configuration, isLoading: configurationLoading } = useSearchConfiguration('user');
    const { t } = useTranslation();
    const hasSearchResults = results.length > 0;
    const isDesktop = useBreakpoint('sm');

    if (loading || configurationLoading) {
        return <SearchResultsSkeleton />;
    }

    if (!searchTerm) {
        if (isDesktop) {
            return (
                <Typography variant="subtitle2" textAlign="center" sx={{ pb: 2 }}>
                    {t('searchWardrobes')}
                </Typography>
            );
        }

        return (
            <AnimatedContainer
                variants={fadeIn}
                style={{
                    paddingBottom: 'env(safe-area-inset-bottom)',
                }}
            >
                {configuration?.components.map((component) => (
                    <React.Fragment key={`${component.type}-${component.id}`}>
                        {renderStrapiComponent(component, { translate: t, isDesktop })}
                    </React.Fragment>
                ))}
            </AnimatedContainer>
        );
    }

    const paddingBottom = isDesktop ? 'env(safe-area-inset-bottom)' : 'calc(70px + env(safe-area-inset-bottom))';

    return (
        <AnimatePresence>
            <AnimatedContainer style={{ display: 'flex', flexDirection: 'column' }} variants={fadeInOut}>
                {hasSearchResults && (
                    <List sx={{ px: 2, paddingBottom }}>
                        {results.map((user) => (
                            <UserListItem key={user.userId} user={user} />
                        ))}
                    </List>
                )}

                {!hasSearchResults && (
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">{t('noSearchResultsFor', { searchTerm })}</Typography>
                    </Box>
                )}
            </AnimatedContainer>
        </AnimatePresence>
    );
};
