import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import { useTransaction } from '../../../transactions/hooks/useTransaction';
import { ListingDetailsSection } from '../../Checkout/ListingDetailsSection';
import { Divider } from '@mui/material';
import { BookingBreakdownSection } from '../../Checkout/BookingBreakdownSection';
import { TransitionTimeline } from './TransitionTimeline';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { DeliveryInformationSection } from '../TransactionCards/DeliveryDetails';
import { useOfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';
import { getDisplayLastTransition } from '../TransactionCards/helpers';

export const TransactionDetails: React.FC = () => {
    const { id } = useParams();
    const { data: currentUser } = useCurrentUser();
    const { data, status } = useTransaction(id);
    const officeConfigurationMethods = useOfficeConfigurationMethods();
    const isProvider = data && data.provider.id === currentUser?.id;
    const transactionType = isProvider ? 'sale' : 'order';

    const lineItems = data?.transaction.attributes.lineItems || [];

    return (
        <ContentLoader status={status} style={{ padding: '0 20px', height: 'unset', display: 'block' }}>
            {data && (
                <>
                    <ListingDetailsSection
                        listingCoverImage={data.listing.imageUrl}
                        listingTitle={data.listing.title}
                        bookingStart={data.booking.attributes.displayStart}
                        bookingEnd={data.booking.attributes.displayEnd}
                    />
                    <Divider sx={{ width: '100%', margin: '20px 0' }} />
                    <DeliveryInformationSection
                        officeConfigurationMethods={officeConfigurationMethods}
                        transactionProtectedData={data.transaction.attributes.protectedData}
                        transactionType={transactionType}
                    />
                    <BookingBreakdownSection lineItems={lineItems} initialLoadingLineItems={false} />
                    <Divider sx={{ width: '100%', margin: '20px 0' }} />

                    <TransitionTimeline
                        transitions={data.transaction.attributes.transitions}
                        lastTransition={getDisplayLastTransition(data.transaction, transactionType, officeConfigurationMethods)}
                        bookingStart={data.booking.attributes.displayStart}
                        bookingEnd={data.booking.attributes.displayEnd}
                    />
                </>
            )}
        </ContentLoader>
    );
};
