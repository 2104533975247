import React, { CSSProperties } from 'react';
import { Box, useTheme } from '@mui/material';
import { ReactFCWithChildren } from '../../types/types';
import RobesLogoBlack from '../../assets/images/ROBES-black.png';
import RobesLogoWhite from '../../assets/images/ROBES-white.png';
import { motion } from 'framer-motion';
import { fadeInOutScale } from '../../animations/constants';

interface RobesLogoProps {
    containerStyle?: CSSProperties;
    height: number;
}

export const RobesLogo: ReactFCWithChildren<RobesLogoProps> = ({ containerStyle, height }) => {
    const theme = useTheme();

    return (
        <motion.div style={{ display: 'flex', ...containerStyle }} variants={fadeInOutScale} initial="initial" animate="in">
            <img src={theme.palette.mode === 'light' ? RobesLogoBlack : RobesLogoWhite} height={height} alt="robes-logo" />
        </motion.div>
    );
};
