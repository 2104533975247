import { maxPriceFilter } from '../../constants';

export enum Filter {
    PRICE = 'price',
    SIZE = 'size',
    COLOR = 'color',
    KEYWORDS = 'keywords',
    BRAND = 'brand',
    CATEGORY = 'category',
    BODYTYPE = 'bodytype',
    DELIVERY = 'delivery',
}

export const IsoDate = 'yyyy-MM-dd';

export const defaultPriceRange: [number, number] = [0, maxPriceFilter];

export const sortByOptions: { value: string; label: string }[] = [
    {
        value: 'createdAt',
        label: 'newest',
    },
    {
        value: 'price',
        label: 'priceHighestFirst',
    },
    {
        value: '-price',
        label: 'priceLowestFirst',
    },
];

export const desktopCardHeight = 350;
export const compactModeCardHeight = 300;
export const defaultModeCardHeight = 520;
export const cardSpacingX = 15;

export const pageMaxWidth = 600;

export const MAX_LISTINGS_FOR_CATEGORY = 10;

export const MAXIMUM_FILE_SIZE = 20 * 1024 * 1024;
