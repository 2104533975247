import { Controller, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { get } from 'lodash';
import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { FormFieldInput } from '../FormRenderer.types';
import { TFunction } from 'i18next';

interface UnControlledPhoneFieldProps {
    t: TFunction;
    field: FormFieldInput;
    isControlled: boolean;
    control: any;
    fieldName?: string;
    errors: FieldErrors<FieldValues>;
    renderErrorMessage: (t: TFunction, errors: FieldErrors<FieldValues>, controlName: string) => string | false | undefined;
}

interface ControlledPhoneFieldProps extends UnControlledPhoneFieldProps {
    register: UseFormRegister<FieldValues>;
    handleFieldChange: (fieldName: string, value: string) => void;
    tempFormState: Record<string, unknown>;
}

type FormPhoneFieldProps = UnControlledPhoneFieldProps | ControlledPhoneFieldProps;

export const FormPhoneField: React.FC<FormPhoneFieldProps> = (props) => {
    const { t, isControlled, field, fieldName, errors, renderErrorMessage } = props;
    const resolvedControlName = fieldName ? `${fieldName}.${field.controlName}` : field.controlName;
    const resolvedErrors = (fieldName ? errors[fieldName] : errors) as FieldErrors<FieldValues>;
    const hasError = resolvedErrors && !!resolvedErrors[field.controlName];

    if (isControlled) {
        const { register, handleFieldChange, tempFormState } = props as ControlledPhoneFieldProps;
        const resolvedValue = (get(tempFormState, resolvedControlName) as string) || '';

        return (
            <MuiTelInput
                key={resolvedControlName}
                {...register(resolvedControlName, field.registerOptions)}
                value={resolvedValue}
                onChange={(e) => handleFieldChange(resolvedControlName, e)}
                onlyCountries={['FI']}
                defaultCountry="FI"
                label={field.label}
                fullWidth
                autoComplete="off"
                inputProps={{ autoComplete: 'no', form: { autoComplete: 'off' } }}
                error={hasError}
                helperText={hasError && renderErrorMessage(t, resolvedErrors, field.controlName)}
            />
        );
    } else {
        return (
            <Controller
                key={field.controlName}
                name={field.controlName}
                control={props.control}
                rules={{ ...field.registerOptions }}
                render={({ field: fieldRenderer, fieldState }) => (
                    <MuiTelInput
                        {...fieldRenderer}
                        onlyCountries={['FI']}
                        defaultCountry="FI"
                        label={field.label}
                        fullWidth
                        autoComplete="off"
                        inputProps={{ autoComplete: 'no', form: { autoComplete: 'off' } }}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? renderErrorMessage(t, errors, field.controlName) : ''}
                    />
                )}
            />
        );
    }
};
