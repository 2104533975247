import { useEffect, useState } from 'react';
import { useUpdateProfile } from '../../views/UserProfile/hooks/useUpdateProfile';
import { Capacitor } from '@capacitor/core';
import { useCurrentUser } from '../../user/hooks/useUser';
import { noop, sleep } from '../../helpers/commonHelpers';
import { App } from '@capacitor/app';

export const useInitialiseUserMetadata = () => {
    const [initialised, setInitialised] = useState(false);
    const { mutate: updateProfile } = useUpdateProfile(noop, false);
    const { data: currentUser } = useCurrentUser();

    useEffect(() => {
        if (initialised || !Capacitor.isNativePlatform() || !currentUser) {
            return;
        }

        const initialiseMetadata = async () => {
            await sleep(5000);
            const { version } = await App.getInfo();

            updateProfile({ privateData: { appVersion: version } });
            setInitialised(true);
        };

        initialiseMetadata();
    }, [currentUser]);
};
