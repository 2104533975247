import { Box, styled } from '@mui/material';

export const OutletContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
}));

export const OutletContent = styled(Box)(() => ({
    height: '100%',
    width: '100%',
}));
