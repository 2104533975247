import { Box, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { CSSProperties } from 'react';

const buttonStyle: CSSProperties = {
    top: 'calc(50% - 12.5px)',
    position: 'absolute',
    borderRadius: '30px',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '18px',
    zIndex: '2',
};

export const Next = styled('div')(({ theme }) => ({ ...buttonStyle, background: theme.palette.background.default, right: '7px' }));
export const Previous = styled('div')(({ theme }) => ({ ...buttonStyle, background: theme.palette.background.default, left: '7px' }));

export const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    '&hover': { cursor: 'pointer' },
}));

export const IndicatorContainer = styled('div')(() => ({
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: '20px',
    zIndex: 3,
}));

export const DotContainer = styled(motion.div)(() => ({
    padding: '3px',
}));

export const Dot = styled(motion.div)(() => ({
    width: '4px',
    height: '4px',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    position: 'relative',
}));

export const DotHighlight = styled(motion.div)(() => ({
    background: 'white',
    borderRadius: '50%',
    width: '6px',
    height: '6px',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    zIndex: 9,
}));
