import { motion, MotionValue, useMotionValue, useTransform } from 'framer-motion';
import React from 'react';

const variants = {
    xss: {
        width: 45,
        height: 45,
    },
    xs: {
        width: 75,
        height: 75,
    },
    sm: {
        width: 100,
        height: 100,
    },
    md: {
        width: 258,
        height: 258,
    },
};

type CircularProgressVariant = keyof typeof variants;

export const CircularProgress: React.FC<{
    progress: MotionValue<number>;
    variant: CircularProgressVariant;
    transparentBg?: boolean;
    showOutline?: boolean;
}> = ({ progress, variant = 'md', transparentBg, showOutline }) => {
    const circleLength = useTransform(progress, [0, 100], [0, 1]);
    const checkmarkPathLength = useTransform(progress, [0, 95, 100], [0, 0, 1]);
    const circleColor = useTransform(progress, [0, 95, 100], ['#FFCC66', '#FFCC66', '#66BB66']);
    const bgColor = useTransform(progress, [0, 100], ['#ffffff00', '#ffffff']);
    const { width, height } = variants[variant];

    return (
        <motion.svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 258 258">
            {/* Circle */}
            <motion.path
                d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
                fill={transparentBg ? 'transparent' : bgColor}
                strokeWidth="8"
                stroke={showOutline ? circleColor : 'transparent'}
                style={{
                    pathLength: circleLength,
                }}
            />
            {/* Check mark  */}
            <motion.path
                transform="translate(60 85)"
                d="M3 50L45 92L134 3"
                fill="transparent"
                stroke="#7BB86F"
                strokeWidth={8}
                style={{ pathLength: checkmarkPathLength }}
            />
        </motion.svg>
    );
};

export const SuccessSVG: React.FC<{ variant?: CircularProgressVariant; transparentBg?: boolean; showOutline?: boolean }> = ({
    variant = 'sm',
    transparentBg = true,
    showOutline = true,
}) => {
    const progress = useMotionValue(90);

    return (
        <>
            <motion.div initial={{ x: 0 }} animate={{ x: 100 }} style={{ x: progress }} transition={{ duration: 1 }} />

            <CircularProgress progress={progress} variant={variant} transparentBg={transparentBg} showOutline={showOutline} />
        </>
    );
};
