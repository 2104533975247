import { Capacitor } from '@capacitor/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClearExpiredPreferences } from '../../../hooks/auth/useClearExpiredPreferences';
import { useInit3rdPartyLogins } from '../../../hooks/auth/useInit3rdPartyLogins';
import { useUserIdTracking } from '../../../hooks/auth/useUserIdTracking';
import { useAppUrlListener } from '../../../hooks/useAppUrlListener';
import { useExpireBookings } from '../../../hooks/useExpireBookings';
import { useIsFirstMount } from '../../../hooks/useIsFirstMount';
import { useLatestValue } from '../../../hooks/useLatestValue';
import { useWebSockets } from '../../../messages/hooks/useSocketMessages';
import { App } from '@capacitor/app';
import { useNativePageTracking } from '../../../hooks/auth/usePageTracking';
import { useInitializeSettings } from '../../../hooks/useInitialiseSettings';
import { useInitialiseUserMetadata } from '../../../hooks/auth/useInitialiseUserMetadata';

export const Effects: React.FC = () => {
    const navigate = useNavigate();
    const firstMount = useIsFirstMount();
    const location = useLocation();
    const latestLocationValue = useLatestValue(location);

    useWebSockets();
    useInitialiseUserMetadata();
    useExpireBookings();
    useUserIdTracking();
    useAppUrlListener();
    useInit3rdPartyLogins();
    useClearExpiredPreferences();
    useNativePageTracking();
    useInitializeSettings();

    if (firstMount && Capacitor.isPluginAvailable('App')) {
        App.addListener('backButton', ({ canGoBack }) => {
            const { pathname } = latestLocationValue.current;

            if (!canGoBack && pathname !== '/') {
                navigate('/');
            }

            if (pathname === '/') {
                App.minimizeApp();
            } else if (canGoBack) {
                navigate(-1);
            }
        });
    }

    return null;
};
