import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography, Button, Switch, ListItemText, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateRangePickerComponent } from '../../components/DateRangePicker/DateRangePicker';
import { calculateDiscountedTotal, getDateRangeConstraints, isDayBlockedFn, isRangeValid, rangeLengthInDays } from './ListingFooter.helpers';
import { useTimeslots } from '../../queries/useTimeslots';
import { formatPrice } from '../../helpers/commonHelpers';
import { useToggleAvailability } from './useToggleAvailability';
import { ExtendedListing } from '../../types/apiTypes';
import { DateRange } from '../../components/DateRangePicker/DateRangePicker.types';
import { RangePosition } from '@mui/x-date-pickers-pro';
import { isSameDay } from 'date-fns';
import { ListingFooterSkeleton } from './ListingFooter.skeleton';
import { DisplayTotal } from './ListingPriceDisplayTotal';
import { SignupLink } from '../../components/SignUpLink/SignUpLink';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { ListingFooterContainer } from './ListingFooter.components';

const availabilityToggleContainer: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

interface ListingFooterProps {
    isOwnListing: boolean;
    isLoading: boolean;
    showDateRangePicker: boolean;
    selectedRange: DateRange;
    listing: ExtendedListing;
    onRentClick: () => void;
    onRangeSelected: (selection: DateRange) => void;
    setShowDateRangePicker: (val: boolean) => void;
}

export const ListingFooter: React.FC<ListingFooterProps> = ({
    isOwnListing,
    isLoading,
    showDateRangePicker,
    setShowDateRangePicker,
    onRentClick,
    onRangeSelected,
    selectedRange,
    listing,
}) => {
    const [focusedInput, setFocusedInput] = useState<RangePosition>('start');
    const [rangeConstraints, setRangeConstraints] = useState<DateRange>([null, null]);
    const { state, price } = listing;
    const isAvailable = state === 'published';

    const { t } = useTranslation();

    const { mutate: toggleAvailability } = useToggleAvailability();

    const { data: timeslots } = useTimeslots(listing.id);
    const userToken = useAppSelector(selectToken);

    const isDayBlocked = isDayBlockedFn(timeslots);
    const allowSameDaySelection = listing.publicData?.presentInShowroom;
    const rangeLength = rangeLengthInDays(selectedRange[0], selectedRange[1]);
    const priceTotal = rangeLength ? price.amount * rangeLength : undefined;
    const discountedTotal = calculateDiscountedTotal(rangeLength, listing);

    useEffect(() => {
        const constraints = getDateRangeConstraints(timeslots, selectedRange);
        setRangeConstraints(constraints);
    }, [focusedInput, selectedRange, timeslots]);

    if (!userToken) {
        return (
            <ListingFooterContainer>
                <SignupLink fullWidth label={t('signUpToRent')} />
            </ListingFooterContainer>
        );
    }

    const handleConfirm = () => {
        // Remove hash from url manually.
        // UseHashChange relies on window.location instead of useLocation, if it is changed please change this as well.
        window.history.replaceState(null, '', window.location.pathname + window.location.search);
        setShowDateRangePicker(false);
    };

    return (
        <ListingFooterContainer>
            {isLoading ? (
                <ListingFooterSkeleton />
            ) : (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1', alignItems: 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%', gap: '10px' }}>
                            {priceTotal ? (
                                <DisplayTotal total={priceTotal} discountedTotal={discountedTotal} currency={price.currency} />
                            ) : (
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 'bold' }}>{formatPrice(price)}</span>

                                    <span style={{ opacity: 0.7 }}>{` / ${t('day')}`}</span>
                                </Typography>
                            )}
                        </Box>
                        {!isOwnListing && (
                            <DateRangePickerComponent
                                allowSameDaySelection={allowSameDaySelection}
                                selectedRange={selectedRange}
                                onFocusChange={(focus) => {
                                    // Prevents focus from jumping back to start after selecting full range
                                    const shouldNotSetFocus = focus === 'start' && selectedRange[0] && !selectedRange[1];
                                    if (shouldNotSetFocus) {
                                        return;
                                    }

                                    setFocusedInput(focus);
                                }}
                                rangePosition={focusedInput}
                                isDateDisabledFn={isDayBlocked}
                                rangeConstraints={rangeConstraints}
                                onChange={(selection: DateRange) => {
                                    if (selectedRange[0] && selectedRange[1]) {
                                        const isEndSameAsSelection = selection[1] && isSameDay(selectedRange[1], selection[1]);
                                        const newStartDate = isEndSameAsSelection || !selection[1] ? selection[0] : selection[1];
                                        const newRange = [newStartDate, null] as DateRange;
                                        onRangeSelected(newRange);

                                        setFocusedInput('end');
                                    } else {
                                        onRangeSelected(selection);
                                    }
                                }}
                                isVisible={showDateRangePicker}
                                setVisible={setShowDateRangePicker}
                                onConfirm={handleConfirm}
                            />
                        )}
                    </Box>

                    {isOwnListing ? (
                        <>
                            {listing.state !== 'pendingApproval' && (
                                <div style={availabilityToggleContainer}>
                                    <ListItemText id="settings-compact-mode-switch" primary={t('available')} />
                                    <Switch
                                        edge="end"
                                        onChange={() => toggleAvailability(listing)}
                                        checked={isAvailable}
                                        inputProps={{
                                            'aria-labelledby': 'settings-compact-mode-switch',
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {!isRangeValid(selectedRange) ? (
                                <Button variant="contained" onClick={() => setShowDateRangePicker(true)} sx={{ px: 2 }}>
                                    {t('selectRentingPeriod')}
                                </Button>
                            ) : (
                                <Button variant="contained" type="submit" onClick={onRentClick} sx={{ px: 2 }}>
                                    {t('continue')}
                                </Button>
                            )}
                        </Box>
                    )}
                </>
            )}
        </ListingFooterContainer>
    );
};
