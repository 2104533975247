import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Nil, ReactFCWithChildren } from '../types/types';
import { useCurrentUserId } from '../user/hooks/useUser';
import { ChatWindow } from '../components/ChatWindow/ChatWindow';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer/ResponsiveDrawer';
import { ExtendedListing } from '../types/apiTypes';
import { closeDrawer } from '../components/ResponsiveDrawer/drawerUtils';
import { useLocation } from 'react-router-dom';

export interface ChatParticipant {
    id: string;
    displayName: string;
    profileImage: string | undefined;
}

export interface ChatState {
    participant: ChatParticipant;
    pathname: string;
    listing?: ExtendedListing | Nil;
    conversationId?: string;
    onClickUser?: (userId: string) => void;
}

const ChatContext = createContext<{
    openChat: (props: Omit<ChatState, 'pathname'>) => void;
    closeChat: () => void;
    addConversationIdMaybe: (conversationId: string) => void;
    state: ChatState | null;
}>({
    openChat: () => null,
    closeChat: () => null,
    addConversationIdMaybe: () => null,
    state: null,
});

export const ChatProvider: ReactFCWithChildren = ({ children }) => {
    const [state, setState] = useState<ChatState | null>(null);

    const currentUserId = useCurrentUserId();
    const location = useLocation();

    const openChat = useCallback(
        (props: Omit<ChatState, 'pathname'>) => {
            setState({ ...props, pathname: location.pathname });
        },
        [location.pathname],
    );

    const addConversationIdMaybe = useCallback(
        (conversationId: string) => {
            if (state && !state.conversationId) {
                setState({ ...state, conversationId });
            }
        },
        [state, setState],
    );

    const closeChat = useCallback(() => {
        closeDrawer();
        setState(null);
    }, []);

    useEffect(() => {
        if (state && location.pathname !== state?.pathname) {
            closeChat();
        }
    }, [location.pathname]);

    return (
        <ChatContext.Provider value={{ openChat, closeChat, state, addConversationIdMaybe }}>
            <>{children}</>
            {currentUserId && (
                <ResponsiveDrawer open={!!state} onClose={() => setState(null)} fillHeight>
                    {state && <ChatWindow userId={currentUserId} onClose={closeChat} {...state} />}
                </ResponsiveDrawer>
            )}
        </ChatContext.Provider>
    );
};

export const useChatWindow = () => useContext(ChatContext);
