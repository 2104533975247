import { ChevronLeft } from '@mui/icons-material';
import { Box, Fab, Typography, useTheme } from '@mui/material';
import { useTransform, motion, MotionValue } from 'framer-motion';
import React from 'react';

import { FavoriteButton } from '../../components/FavoriteButton/FavoriteButton';
import { ImageSlider } from '../../components/ImageSlider/ImageSlider';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useToggleFavorite } from '../../messages/hooks/useToggleFavorite';
import { useFavorites } from '../../queries/useFavorites';
import { FavoriteCountContainer, ImageContainer } from './ViewListing.components';
import { ExtendedListing, Listing, ListingPreview } from '../../types/apiTypes';
import { isListingPreview } from './ViewListing.helpers';
import { useTranslation } from 'react-i18next';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

interface ListingImageSectionProps {
    listing: ExtendedListing | Listing | ListingPreview;
    scrollY: MotionValue<number>;
    imageHeight?: number;
}

export const ListingImageSection: React.FC<ListingImageSectionProps> = ({ listing, imageHeight = 400, scrollY }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const { images } = listing;

    const isDesktop = useBreakpoint('sm');
    const listingPreview = isListingPreview(listing);

    const opacity = useTransform(scrollY, [0, imageHeight], [1, 0]);
    const favoriteButtonY = useTransform(scrollY, [0, imageHeight / 3, imageHeight], [0, 0, -100]);
    const favorites = useFavorites();

    const { mutate: toggleFavorite } = useToggleFavorite();

    return (
        <>
            {!isDesktop && !listingPreview && (
                <Fab
                    size="small"
                    sx={{
                        zIndex: 12,
                        margin: '10px',
                        position: 'absolute',
                        background: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeft />
                </Fab>
            )}

            <ImageContainer
                style={{
                    height: imageHeight,
                    opacity,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    ...(isDesktop ? { marginTop: '30px' } : { position: 'sticky', top: 0 }),
                }}
            >
                <motion.div
                    style={{ position: 'absolute', zIndex: 3, top: '10px', right: '10px', y: favoriteButtonY, opacity, transformOrigin: 'center' }}
                >
                    {!listingPreview && (
                        <FavoriteCountContainer>
                            <FavoriteButton onClick={() => toggleFavorite(listing.id)} selected={favorites.includes(listing.id)} />
                        </FavoriteCountContainer>
                    )}
                </motion.div>
                {images.length ? (
                    <ImageSlider allowFullScreen multiple={!listingPreview} images={images} height={imageHeight} borderRadius={isDesktop ? 10 : 0} />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <Typography variant="h6">{t('previewNoImages')}</Typography>
                    </Box>
                )}
            </ImageContainer>
        </>
    );
};
