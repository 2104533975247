import Card from '@mui/material/Card/Card';
import CardActionArea from '@mui/material/CardActionArea/CardActionArea';
import CardMedia from '@mui/material/CardMedia/CardMedia';
import styled from '@mui/material/styles/styled';

export const StyledCard = styled(Card)(() => ({
    aspectRatio: '1/1',
    width: '100%',
    borderRadius: 0,
    position: 'relative',
}));

export const FavoriteButtonContainer = styled('div')(() => ({
    position: 'absolute',
    borderRadius: '50%',
    top: 0,
    right: 0,
    zIndex: 3,
    padding: '3px',
}));

export const StyledCardActionArea = styled(CardActionArea)(() => ({
    height: '100%',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledCardMedia = styled(CardMedia)<{ component: string; alt: string }>(({ component, alt, theme }) => ({
    height: '100%',
}));
