import { Box, Skeleton, SxProps } from '@mui/material';
import React from 'react';

const skeletonSx: SxProps = {
    animationDuration: '1.2s !important',
    animationDelay: '0.1s',
    '&:after': {
        animationDuration: '1.2s !important',
        animationDelay: '0.1s',
    },
};

const SkeletonItem: React.FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Skeleton animation="wave" variant="circular" width={50} height={50} sx={skeletonSx} />
            <Box sx={{ display: 'flex', ml: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton animation="wave" variant="text" height={20} width={200} sx={skeletonSx} />
                <Skeleton animation="wave" variant="text" height={20} width={200} sx={skeletonSx} />
            </Box>
        </Box>
    );
};

export const DynamicComponentSkeleton: React.FC = () => {
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Skeleton animation="wave" variant="text" height={200} width="100%" sx={{ ...skeletonSx }} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={{ ...skeletonSx, mt: 1 }} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="60%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={{ ...skeletonSx, mt: 1 }} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="20%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={200} width="100%" sx={{ ...skeletonSx }} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="100%" sx={skeletonSx} />
            <Skeleton animation="wave" variant="text" height={25} width="60%" sx={skeletonSx} />
        </Box>
    );
};
