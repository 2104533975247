import Icon from '@mui/material/Icon/Icon';
import { InputProps } from '@mui/material/Input/Input';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFCWithChildren } from '../../types/types';
import { IconInputField } from './iconTextField.components';
import { TextFieldProps } from '@mui/material';

export interface Props {
    iconName: string;
    label: string;
    iconPosition?: 'start' | 'end';
    variant?: 'standard' | 'filled' | 'outlined';
    type?: 'password' | 'number' | 'search';
    size?: 'medium' | 'small';
    TextFieldProps?: TextFieldProps;
    FieldInputProps?: InputProps;
    onChange: (value: any) => void;
}

export const IconTextField: ReactFCWithChildren<Props> = ({
    iconName,
    label,
    type,
    iconPosition = 'start',
    variant = 'standard',
    size = 'medium',
    onChange,
    TextFieldProps,
    FieldInputProps,
}) => {
    const { t } = useTranslation();
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    return (
        <IconInputField
            id={label}
            label={t(label)}
            variant={variant}
            type={type}
            onChange={handleInputChange}
            size={size}
            color="primary"
            {...TextFieldProps}
            InputProps={{
                startAdornment: (
                    <InputAdornment position={iconPosition}>
                        <Icon>{iconName}</Icon>
                    </InputAdornment>
                ),
                ...FieldInputProps,
            }}
        />
    );
};
