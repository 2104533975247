import { styled } from '@mui/material';
import { Blurhash } from 'react-blurhash';

export const CustomBlurhash = styled(Blurhash, { shouldForwardProp: (prop) => prop !== 'borderRadius' })<{
    borderRadius: any;
}>(({ borderRadius }) => ({
    borderRadius,
    '& canvas': {
        borderRadius,
    },
}));
