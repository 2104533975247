import { Paper } from '@mantine/core';
import { Typography, styled } from '@mui/material';

export const CollectionContainer = styled('div')(() => ({
    width: '100%',
    borderRadius: '10px',
}));

export const CollectionHeader = styled('div')(() => ({
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': { cursor: 'pointer' },
}));

export const CollectionTitle = styled(Typography)(() => ({
    fontSize: '0.8rem',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const CollectionDescription = styled(Typography)(() => ({
    marginTop: '10px',
    fontSize: '0.7rem',
    width: '100%',
    opacity: 0.7,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const OverlaySlideLabel = styled(Typography)(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

export const OverlaySlideOverlay = styled(Paper)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
}));
