import { Box, styled } from '@mui/material';

export const FullScreenDiv = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 'env(safe-area-inset-top)',
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 100000,
}));

export const SearchBar = styled(Box)(({ theme }) => ({
    margin: '14px 0px',
    padding: '7px',
    width: '100%',
    display: 'flex',
    gap: '10px',
    borderRadius: '20px',
    opacity: 0.8,
    border: `1px solid ${theme.palette.border.light}`,
}));

const searchBarHeight = '57px';

export const SearchBarContainer = styled(Box)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    bgcolor: theme.palette.background.paper,
    height: searchBarHeight,
}));

export const TabsContainer = styled(Box)({
    height: `calc(100% - ${searchBarHeight})`,
    px: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 'env(safe-area-inset-bottom)',
});

export const EndAdornmentWrapper = styled('div')(() => ({
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
