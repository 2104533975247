import { UniqueIdentifier } from '@dnd-kit/core';
import { useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInOut } from '../../animations/constants';
import { MotionProps, motion } from 'framer-motion';

export const imageDimensionsPX = 150;

interface ItemProps extends MotionProps {
    id: UniqueIdentifier;
    children?: React.ReactNode;
    isDragging?: boolean;
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(({ id, children, style, isDragging, ...props }, ref) => {
    const theme = useTheme();

    return (
        <motion.div
            {...props}
            variants={isDragging ? undefined : fadeInOut}
            ref={ref}
            initial="initial"
            animate="in"
            exit="out"
            layout
            style={{
                ...style,
                ...(isDragging && { boxShadow: theme.palette.boxShadow['elevation-12'], opacity: 0.5 }),
            }}
        >
            {children}
        </motion.div>
    );
});
