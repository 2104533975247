import React from 'react';
import { Typography, ButtonBase, Avatar, Box } from '@mui/material';
import { LinkBehavior } from '../..';
import { DragContainer } from '../../components/DragContainer/DragContainer';
import { UserCarousel as StrapiUserCarousel } from '../types';

interface UserCarouselProps {
    component: {
        label: string;
        description?: string;
        users: StrapiUserCarousel['users'];
    };
    labelTypographyProps?: React.ComponentProps<typeof Typography>;
    descriptionTypographyProps?: React.ComponentProps<typeof Typography>;
    carouselStyles?: React.CSSProperties;
}

export const UserCarousel: React.FC<UserCarouselProps> = ({ component, labelTypographyProps, descriptionTypographyProps, carouselStyles }) => {
    const { label, users, description } = component;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, overflowX: 'auto', width: '100%' }}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ px: '16px', mb: 1 }} {...labelTypographyProps}>
                {label}
            </Typography>

            {description && (
                <Typography variant="subtitle2" sx={{ px: '16px', mb: 1 }} {...descriptionTypographyProps}>
                    {description}
                </Typography>
            )}

            <DragContainer gap={20} carouselStyles={{ padding: '16px', ...carouselStyles }} containerStyles={{ alignItems: 'flex-start' }}>
                {users.map((user) => (
                    <ButtonBase
                        LinkComponent={LinkBehavior}
                        href={`/user/${user.userId}`}
                        key={user.userId}
                        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}
                    >
                        <Avatar src={user.profileImage} sx={{ height: 80, width: 80 }} />
                        <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            {user.displayName}
                        </Typography>
                    </ButtonBase>
                ))}
            </DragContainer>
        </Box>
    );
};
