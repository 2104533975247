import { useCapacitorStripe } from '@capacitor-community/stripe/dist/esm/react/provider';
import { Check, CreditCard } from '@mui/icons-material';
import { Box, Divider, LinearProgress, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { Puller } from './PaymentOptionsDrawer.components';
import useLocationParams from '../../hooks/useLocationParams';

interface PaymentOptionsDrawerProps {
    open: boolean;
    isLoading: boolean;
    selectedItem: string | undefined;
    onClose: () => void;
    onClick: (value: string) => void;
}

const getOptions = (isGooglePayAvailable: boolean, isApplePayAvailable: boolean) => {
    const items = [];

    if (isGooglePayAvailable) {
        items.push({ label: 'Google Pay', image: '/google-pay.png', value: 'google_pay' });
    }
    if (isApplePayAvailable) {
        items.push({ label: 'Apple Pay', image: '/apple-pay.svg', value: 'apple_pay' });
    }
    items.push({ label: 'Credit Card', icon: <CreditCard fontSize="large" />, value: 'card' });

    return items;
};

export const PaymentOptionsDrawer: React.FC<PaymentOptionsDrawerProps> = ({ open, isLoading, onClose, onClick, selectedItem }) => {
    const { isGooglePayAvailable, isApplePayAvailable } = useCapacitorStripe();

    const paymentMethods = getOptions(isGooglePayAvailable, isApplePayAvailable);

    return (
        <SwipeableDrawer open={open} onClose={onClose} fillHeight={false}>
            {isLoading && <LinearProgress variant="indeterminate" sx={{ position: 'absolute', top: 0, width: '100%' }} />}

            <Puller />

            <Box sx={{ padding: '60px 15px 20px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Payment Methods
                </Typography>
            </Box>

            <List sx={{ pb: 4 }}>
                {paymentMethods.map((option, i) => (
                    <React.Fragment key={option.value}>
                        <ListItemButton sx={{ height: '70px' }} key={option.label} onClick={() => onClick(option.value)}>
                            <ListItemAvatar sx={{ width: '56px', display: 'flex', justifyContent: 'center' }}>
                                {option.icon ? <>{option.icon}</> : <img src={option.image} alt={`${option.label} Logo`} style={{ width: '100%' }} />}
                            </ListItemAvatar>
                            <ListItemText primary={option.label} sx={{ marginLeft: '20px' }} />

                            {selectedItem === option.value && (
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                            )}
                        </ListItemButton>
                        {i < paymentMethods.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </SwipeableDrawer>
    );
};
