import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import ItemShowcase1 from '../../../assets/images/onboarding/ItemShowcase-1.jpeg';
import ItemShowcase2 from '../../../assets/images/onboarding/ItemShowcase-2.jpeg';
import ItemShowcase3 from '../../../assets/images/onboarding/ItemShowcase-3.jpeg';
import ItemShowcase4 from '../../../assets/images/onboarding/ItemShowcase-4.jpeg';
import ItemShowcase5 from '../../../assets/images/onboarding/ItemShowcase-5.jpeg';
import { StepContent, StepContentContainer } from '../UserOnboarding.components';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { itemVariantsFadeRight } from '../../../animations/constants';
import { Carousel } from '@mantine/carousel';
import { EmblaCarouselType } from 'embla-carousel-react';
import { flushSync } from 'react-dom';
import Autoplay from 'embla-carousel-autoplay';
import { useTranslation } from 'react-i18next';
import { BaseStepProps } from '../UserOnboarding.types';
import { LoadingButton } from '@mui/lab';

type RentingDescriptionStepProps = BaseStepProps;

const TWEEN_FACTOR = 0.9;

const numberWithinRange = (number: number, min: number, max: number): number => Math.min(Math.max(number, min), max);

const Images = [ItemShowcase1, ItemShowcase2, ItemShowcase3, ItemShowcase4, ItemShowcase5];

export const RentingDescriptionStep: React.FC<RentingDescriptionStepProps> = ({ onComplete, loading }) => {
    const [emblaApi, setEmblaApi] = useState<EmblaCarouselType>();
    const [tweenValues, setTweenValues] = useState<number[]>([]);

    const autoplay = useRef(Autoplay({ delay: 1800 }));

    const onScroll = useCallback(() => {
        if (!emblaApi) return;

        const engine = emblaApi.internalEngine();
        const scrollProgress = emblaApi.scrollProgress();

        const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
            let diffToTarget = scrollSnap - scrollProgress;

            if (engine.options.loop) {
                engine.slideLooper.loopPoints.forEach((loopItem) => {
                    const target = loopItem.target() as unknown as number;
                    if (index === loopItem.index && target !== 0) {
                        const sign = Math.sign(target);
                        if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
                        if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
                    }
                });
            }
            const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
            return numberWithinRange(tweenValue, 0, 1);
        });
        setTweenValues(styles);
    }, [emblaApi, setTweenValues]);

    useEffect(() => {
        if (!emblaApi) return;

        onScroll();
        emblaApi.on('scroll', () => {
            flushSync(() => onScroll());
        });
        emblaApi.on('reInit', onScroll);
    }, [emblaApi, onScroll]);

    const { t } = useTranslation();

    return (
        <StepContent>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.3, delay: 0.3 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginTop: '20px' }}
                >
                    <StaggerListElement variants={itemVariantsFadeRight} transition={{ type: 'spring', damping: 9, stiffness: 40 }}>
                        <Carousel
                            getEmblaApi={setEmblaApi}
                            withControls={false}
                            dragFree
                            slideSize="50%"
                            slideGap="md"
                            height={240}
                            plugins={[autoplay.current]}
                            onMouseEnter={autoplay.current.stop}
                            onMouseLeave={autoplay.current.reset}
                        >
                            {Images.map((img, index) => (
                                <Carousel.Slide key={img} style={{ width: '100%', height: '100%' }}>
                                    <img
                                        src={img}
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                            ...(tweenValues.length && {
                                                transform: `scale(${tweenValues[index]})`,
                                            }),
                                        }}
                                    />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </StaggerListElement>

                    <StaggerListElement style={{ padding: '16px' }}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('rentingStepTitle')}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement style={{ padding: '16px' }}>
                        <Typography variant="subtitle1">{t('rentingStepDescription')}</Typography>
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            <LoadingButton loading={loading} variant="contained" onClick={onComplete} sx={{ m: 2 }}>
                {t('continue')}
            </LoadingButton>
        </StepContent>
    );
};
