import React from 'react';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useHomePage } from '../../queries/useHomePage';
import { HomeViewSkeleton } from './HomeViewSkeleton';
import { renderStrapiComponent } from '../../strapi/helpers';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../hooks/useBreakpoint';

export const HomeView: React.FC = () => {
    const { t } = useTranslation();
    const { data, status } = useHomePage();
    const isDesktop = useBreakpoint('sm');

    return (
        <div>
            <ContentLoader status={status} skeleton={<HomeViewSkeleton />}>
                {data?.components.map((component) => (
                    <React.Fragment key={`${component.type}-${component.id}`}>
                        {renderStrapiComponent(component, { translate: t, isDesktop })}
                    </React.Fragment>
                ))}
            </ContentLoader>
        </div>
    );
};
