import { uniqueId } from 'lodash';
import { InfiniteData, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { messagesPerPage } from '../../constants';
import { messageApiClient } from '../../services/sharetribe/apiClients';
import { Conversation, MutationResult } from '../../types/apiTypes';
import { ConversationHistory, Message, MessageHistory } from '../apiTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { applyPersistedMessageId, handleAddMessageToQueryCache } from './useSocketMessages';
import { useChatWindow } from '../../context/chat';
import { assertIsDefined } from '../../helpers/commonHelpers';

interface SendMessageParams {
    conversationId: string;
    senderId: string;
    participantId: string;
    content: string;
    createdAt: string;
    listingData?: {
        id: string;
        title: string;
        imageUrl: string;
    };
}

const sendMessageFn = async (params: SendMessageParams) => {
    const { data } = await messageApiClient.post<Message>('/', params);

    return data;
};

/**
 *
 * Adds a message to the current page data structure, and shifts the rest of the pages' messages index by one.
 * If e.g a message was at index 25 and messagesPerPage is 25, that message will be moved to the next page so that
 * each page has equal amount of messages (except the last page)
 *
 * @param newMessage message that will be added to the first page of the message history
 * @param qData current page data
 * @param conversationId currently open conversationId
 */
export const getUpdatedMessages = (
    newMessage: Message,
    qData: InfiniteData<MessageHistory> | undefined,
    conversationId: string | undefined,
): MessageHistory[] => {
    const messageData = {
        _id: newMessage._id || uniqueId('optimistic-update'),
        createdAt: newMessage.createdAt,
        content: newMessage.content,
        senderId: newMessage.senderId,
        conversationId,
        ...(newMessage.listingData && { listingData: newMessage.listingData }),
    };

    const messages = qData?.pages.flatMap((page) => page?.data) || [];

    messages.unshift(messageData);

    const newData: MessageHistory[] = [];
    for (let i = 0; i < messages.length; i += messagesPerPage) {
        const currentPage = messages.slice(i, i + messagesPerPage);
        const currentPageNum = i / messagesPerPage;
        const nextPage = qData?.pages[currentPageNum]?.nextPage;

        newData.push({ data: currentPage, nextPage });
    }

    return newData;
};

const handleUpdateConversationTopic = (queryClient: ReturnType<typeof useQueryClient>, conversationQueryKey: QueryKey, message: Message) => {
    const listingId = message.listingData?.id;

    if (!listingId) {
        return;
    }

    queryClient.setQueryData<Conversation>(conversationQueryKey, (conversation) => {
        assertIsDefined(conversation, 'Conversation data is undefined');

        const hasNoTopic = !conversation.topic;
        const hasDifferentTopic = conversation.topic && conversation.topic.listingId !== listingId;

        if (hasNoTopic || hasDifferentTopic) {
            conversation.topic = { listingId };
        }

        return conversation;
    });
};

export const useSendMessage = (
    onMessageSent: (msg: Message) => void,
    conversationId: string | undefined,
): MutationResult<Message, SendMessageParams> => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { state: chatState } = useChatWindow();

    const messageQueryKey = ['message-history', { conversationId }];
    const conversationHistoryQueryKey = ['conversation-history'];

    return useMutation(sendMessageFn, {
        /**
         * When the message is sent, immediately update the query cache with the sent information and a dummy ID.
         */
        onMutate: async (newMessage: SendMessageParams) => {
            await queryClient.cancelQueries(messageQueryKey);

            const conversationQueryKey = ['conversation', { participantId: newMessage.participantId }];
            const previousMessages = queryClient.getQueryData<InfiniteData<MessageHistory>>(messageQueryKey);
            const previousConversation = queryClient.getQueryData<Conversation>(conversationQueryKey);
            const previousConversationHistory = queryClient.getQueryData<InfiniteData<ConversationHistory>>(conversationHistoryQueryKey);

            const optimisticUpdate: Message = { ...newMessage, _id: uniqueId('optimistic-update') };

            handleAddMessageToQueryCache(queryClient, optimisticUpdate, chatState, messageQueryKey);

            if (previousConversation) {
                handleUpdateConversationTopic(queryClient, conversationQueryKey, optimisticUpdate);
            }

            return { previousMessages, previousConversation, previousConversationHistory };
        },
        onSuccess: (response) => {
            applyPersistedMessageId(queryClient, messageQueryKey, response);

            onMessageSent(response);
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (_err, newMessage, context) => {
            const msg = t('msgSendError');
            toast.error(msg);

            const conversationQueryKey = ['conversation', { participantId: newMessage.participantId }];

            if (context) {
                queryClient.setQueryData(messageQueryKey, context.previousMessages);
                queryClient.setQueryData(conversationQueryKey, context.previousConversation);
                queryClient.setQueryData(conversationHistoryQueryKey, context.previousConversationHistory);
            }
        },
    });
};
