import { FormControlLabel, Checkbox, FormControl, FormHelperText } from '@mui/material';
import { get } from 'lodash';
import { UseFormRegister, FieldValues, Controller, Field, FieldErrors } from 'react-hook-form';
import { FormFieldCheckbox } from '../FormRenderer.types';
import { TFunction } from 'i18next';
import React from 'react';

interface CheckboxFieldProps {
    field: FormFieldCheckbox;
    control: any;
    t: TFunction;
    isControlled: boolean;
    fieldName?: string;
    errors: FieldErrors<FieldValues>;
    tempFormState: Record<string, unknown>;
    handleFieldChange: (fieldName: string, value: boolean) => void;
    renderErrorMessage: (t: any, errors: FieldErrors<FieldValues>, controlName: string) => string | false | undefined;
    register: UseFormRegister<FieldValues>;
}

export const FormCheckbox: React.FC<CheckboxFieldProps> = ({
    isControlled,
    field,
    t,
    control,
    errors,
    fieldName,
    tempFormState,
    handleFieldChange,
    renderErrorMessage,
    register,
}) => {
    const { registerOptions, controlName, ...restCeckbox } = field;
    const resolvedErrors = (fieldName ? errors[fieldName] : errors) as FieldErrors<FieldValues>;
    const hasError = resolvedErrors && !!resolvedErrors[field.controlName];

    if (isControlled) {
        const resolvedControlName = fieldName ? `${fieldName}.${field.controlName}` : field.controlName;
        const resolvedValue = (get(tempFormState, resolvedControlName) as boolean) || false;

        return (
            <React.Fragment key={field.controlName}>
                <FormControlLabel
                    label={field.label}
                    key={field.controlName}
                    control={
                        <Checkbox
                            {...restCeckbox}
                            {...register(resolvedControlName, field.registerOptions)}
                            checked={resolvedValue}
                            onChange={(e) => handleFieldChange(resolvedControlName, e.target.checked)}
                        />
                    }
                />
                {hasError && (
                    <FormHelperText error sx={{ m: 0 }}>
                        {hasError ? renderErrorMessage(t, resolvedErrors, field.controlName) : ''}
                    </FormHelperText>
                )}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment key={field.controlName}>
                <FormControlLabel
                    label={field.label}
                    key={field.controlName}
                    control={
                        <Controller
                            name={field.controlName}
                            control={control}
                            rules={{ ...field.registerOptions }}
                            render={({ field: fieldRenderer }) => <Checkbox {...restCeckbox} {...fieldRenderer} />}
                        />
                    }
                />
                {hasError && (
                    <FormHelperText error sx={{ m: 0 }}>
                        {hasError ? renderErrorMessage(t, resolvedErrors, field.controlName) : ''}
                    </FormHelperText>
                )}
            </React.Fragment>
        );
    }
};
