import { Toll } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { useCreditsConfiguration } from '../hooks/useCreditsConfiguration';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import { useTranslation } from 'react-i18next';
import { formatMoney, formatMoneyShort, sleep, useCombinedQueryStatus } from '../../../helpers/commonHelpers';
import { defaultCurrency } from '../../../constants';
import { AddedCredits, useRedeemPromotionalCode, useUpdateCreditsBalance } from '../hooks/useRedeemPromotionalCode';
import { PromotionalCodeDrawer } from '../../../components/PromotionalCodeDrawer/PromotionalCodeDrawer';
import { PromotionalCodeSubmitSuccess } from '../../../components/PromotionalCodeDrawer/PromotionalCodeSubmitSuccess';
import { LinkBehavior } from '../../..';
import { AnimatePresence } from 'framer-motion';
import { fadeInOut } from '../../../animations/constants';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { useCreditsBalance } from '../hooks/useCreditsBalance';

const CreditsPageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <AnimatedContainer
        variants={fadeInOut}
        style={{
            width: '100%',
            padding: '100px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '30px',
        }}
    >
        {children}
    </AnimatedContainer>
);

const CreditsSkeleton: React.FC = () => {
    return (
        <CreditsPageContainer>
            <Toll sx={{ fontSize: '100px', opacity: 0.7 }} />

            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
                <Skeleton variant="text" height={28} width={290} />
                <Skeleton variant="text" height={28} width={250} />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
                <Skeleton variant="text" height={20} width={290} />
                <Skeleton variant="text" height={20} width={150} />
            </Box>

            <Skeleton sx={{ mt: 5 }} variant="rounded" height={40} width="100%" />
        </CreditsPageContainer>
    );
};

interface FormData {
    code: string;
}

export const Credits: React.FC = () => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [addedCredits, setAddedCredits] = useState<AddedCredits | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const { t } = useTranslation();

    const updateCreditsBalance = useUpdateCreditsBalance();

    const handleSubmitSuccess = async (data: AddedCredits) => {
        setDrawerOpen(false);

        await sleep(200);
        setSubmitError(null);
        setAddedCredits(data);

        // Wait for the animation to finish before updating the credits balance
        await sleep(1000);
        await updateCreditsBalance(data);
    };

    const handleSubmitError = (err: string) => {
        setSubmitError(err);
    };

    const { data: configuration, status: configurationStatus } = useCreditsConfiguration();
    const { data: credits, status: creditsBalanceStatus } = useCreditsBalance();

    const combinedQueryStatus = useCombinedQueryStatus([configurationStatus, creditsBalanceStatus]);
    const { mutate: redeemCode, isLoading: isRegisteringCode } = useRedeemPromotionalCode(handleSubmitSuccess, handleSubmitError);
    const availableCredits = Math.max((credits?.balance || 0) - (credits?.reserved || 0), 0);

    const submitHandler = (formData: FormData) => {
        redeemCode(formData.code);
    };

    const handleClickBackToCredits = () => {
        setAddedCredits(null);
    };

    return (
        <ContentLoader status={combinedQueryStatus} skeleton={<CreditsSkeleton />} flex={false}>
            <AnimatePresence>
                {addedCredits ? (
                    <PromotionalCodeSubmitSuccess addedCredits={addedCredits} currency={defaultCurrency}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: 3 }}>
                            <Button variant="contained" color="primary" LinkComponent={LinkBehavior} href="/listings">
                                {t('startExploring')}
                            </Button>

                            <Button variant="outlined" onClick={handleClickBackToCredits}>
                                {t('backToCredits')}
                            </Button>
                        </Box>
                    </PromotionalCodeSubmitSuccess>
                ) : (
                    <CreditsPageContainer key="content">
                        <Toll sx={{ fontSize: '100px', opacity: 0.7 }} />

                        {availableCredits ? (
                            <Typography variant="h6" textAlign="center" sx={{ fontVariationSettings: "'wght' 600" }}>
                                {t('youHaveCredits', { amount: formatMoney(availableCredits, credits?.currency) })}
                            </Typography>
                        ) : (
                            <Typography variant="h6" textAlign="center" sx={{ fontVariationSettings: "'wght' 600" }}>
                                {t('creditsEnterCode')}
                            </Typography>
                        )}

                        <Typography variant="body2" textAlign="center">
                            {t('creditsMinimumAmountDisclaimer', { amount: formatMoneyShort(configuration?.minimumPurchase!, defaultCurrency) })}
                        </Typography>

                        <Button variant="contained" color="primary" sx={{ width: '100%', mt: 5 }} onClick={() => setDrawerOpen(true)}>
                            {t('enterYourCode')}
                        </Button>
                    </CreditsPageContainer>
                )}
            </AnimatePresence>

            <PromotionalCodeDrawer
                open={drawerOpen}
                configuration={configuration}
                onClose={() => setDrawerOpen(false)}
                onSubmit={submitHandler}
                isLoading={isRegisteringCode}
                submitError={submitError}
                addedCredits={addedCredits}
            />
        </ContentLoader>
    );
};
