import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { listingsPerPage } from '../constants';
import { getNextPageParam } from '../helpers/commonHelpers';
import { listingsApiClient } from '../services/sharetribe/apiClients';
import { ListingsResponse } from '../types/apiTypes';
import { useAppSelector } from '../store/hooks';
import { selectToken } from '../store/userReducer';

export type ListingQueryResult = InfiniteData<ListingsResponse>;

/**
 *
 * @param params queryParam string formed from the filters chosen on the listings page
 * @param startingPage sharetribe pagination argument
 */
export const useListings = (params: string, startingPage = 1, queryKey = 'listings'): UseInfiniteQueryResult<ListingsResponse> => {
    const getListings = async ({ pageParam = startingPage }) => {
        const defaultParams = `page=${pageParam}&perPage=${listingsPerPage}`;
        const queryParams = params ? `/${params}&${defaultParams}` : `/?${defaultParams}`;

        const { data } = await listingsApiClient.get<ListingsResponse>(queryParams);
        return {
            ...data,
            nextPage: pageParam + 1,
        };
    };

    return useInfiniteQuery([queryKey, { params }], getListings, {
        staleTime: 2 * 60 * 1000,
        getNextPageParam,
    });
};

export const useListingsCount = (params: string, startingPage = 1): UseQueryResult<number> => {
    const getListings = async ({ pageParam = startingPage }) => {
        const defaultParams = `/?page=${pageParam}&perPage=${listingsPerPage}`;
        const queryParams = params ? `${defaultParams}&${params}` : defaultParams;

        const { data } = await listingsApiClient.get<ListingsResponse>(queryParams);

        return data.meta.totalItems || 0;
    };

    return useQuery(['listings', { params }], getListings, {
        staleTime: 2 * 60 * 1000,
    });
};

export const useOwnListings = (startingPage = 1, perPage = 24): UseInfiniteQueryResult<ListingsResponse> => {
    const token = useAppSelector(selectToken);

    const getListings = async ({ pageParam = startingPage }) => {
        const params = new URLSearchParams({ page: pageParam.toString(), perPage: perPage.toString() }).toString();

        const { data } = await listingsApiClient.get<ListingsResponse>(`own?${params}`);

        return {
            ...data,
            nextPage: pageParam + 1,
        };
    };

    return useInfiniteQuery(['own-listings'], getListings, {
        staleTime: 2 * 60 * 1000,
        getNextPageParam,
        enabled: !!token,
    });
};
