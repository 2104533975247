import { motion, MotionValue, useMotionValue, useTransform } from 'framer-motion';
import React from 'react';

const variants = {
    xs: {
        width: 50,
        height: 50,
    },
    sm: {
        width: 100,
        height: 100,
    },
    md: {
        width: 140,
        height: 140,
    },
    lg: {
        width: 258,
        height: 258,
    },
};

export const CircularProgress: React.FC<{
    progress: MotionValue<number>;
    variant: 'xs' | 'sm' | 'md';
    transparentBg?: boolean;
}> = ({ progress, variant = 'md', transparentBg }) => {
    const crossPathA = useTransform(progress, [50, 100], [0, 1]);
    const crossPathB = useTransform(progress, [0, 50], [0, 1]);
    const circleColor = '#e70663';
    const bgColor = useTransform(progress, [0, 100], ['#ffffff00', '#ffffff']);
    const { width, height } = variants[variant];

    return (
        <motion.svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50">
            {/* Circle */}
            <motion.path
                d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                fill={transparentBg ? 'transparent' : bgColor}
                stroke={circleColor}
                strokeWidth="2"
                style={{
                    translateX: 5,
                    translateY: 5,
                }}
            />
            <motion.path fill="red" strokeWidth="2" stroke={circleColor} d="M17,17 L33,33" strokeDasharray="0 1" style={{ pathLength: crossPathA }} />
            <motion.path
                fill="none"
                strokeWidth="2"
                stroke={circleColor}
                d="M33,17 L17,33"
                strokeDasharray="0 1"
                style={{ pathLength: crossPathB }}
            />
        </motion.svg>
    );
};

export const ErrorSVG: React.FC<{ variant?: 'xs' | 'sm' | 'md'; transparentBg?: boolean }> = ({ variant = 'sm', transparentBg = true }) => {
    const progress = useMotionValue(90);

    return (
        <>
            <motion.div initial={{ x: 0 }} animate={{ x: 100 }} style={{ x: progress }} transition={{ duration: 1 }} />

            <CircularProgress progress={progress} variant={variant} transparentBg={transparentBg} />
        </>
    );
};
