import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export const takePhoto = async (): Promise<string> => {
    const image = await Camera.getPhoto({
        quality: 100,
        width: 800,
        height: 800,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Uri,
    });

    return image.webPath as string;
};

export const presentPhotoPrompt = async (): Promise<string> => {
    const image = await Camera.getPhoto({
        quality: 100,
        width: 800,
        height: 800,
        allowEditing: false,
        resultType: CameraResultType.Uri,
    });

    return image.webPath as string;
};

export const pickPhoto = async () => {
    const image = await Camera.getPhoto({
        quality: 100,
        width: 800,
        height: 800,
        source: CameraSource.Photos,
        resultType: CameraResultType.Uri,
    });

    return image.webPath as string;
};
