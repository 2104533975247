import { Typography, styled } from '@mui/material';
import { Listing } from '../../../types/apiTypes';
import { Carousel } from '@mantine/carousel';
import { ImageLoader } from '../../../components/ImageLoader/ImageLoader';

export const CategoryContainer = styled('div')(() => ({
    width: '100%',
    padding: '10px 0',
    borderRadius: '10px',
}));

export const CategoryHeader = styled('div')(() => ({
    marginLeft: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': { cursor: 'pointer' },
}));

export const CategoryTitle = styled(Typography)(() => ({
    fontSize: '0.8rem',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const CategoryDescription = styled(Typography)(() => ({
    marginTop: '10px',
    fontSize: '0.7rem',
    width: '100%',
    opacity: 0.7,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

interface ListingCarouselProps {
    imageDimensions: number;
    draggable: boolean;
    listings: Listing[];
    onClick: (idx: number) => void;
}

export const ListingCarousel: React.FC<ListingCarouselProps> = ({ imageDimensions, draggable, listings, onClick }) => {
    return (
        <Carousel
            height={imageDimensions}
            withControls={false}
            draggable={draggable}
            slideSize={imageDimensions}
            slideGap={20}
            align="start"
            dragFree
            containScroll="keepSnaps"
        >
            {listings.map((listing, i) => {
                const { url } = listing.images[0];
                const { blurhash } = listing.publicData;
                return (
                    <Carousel.Slide key={url} gap={5} onClick={() => onClick(i)} sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <ImageLoader
                            hash={blurhash}
                            url={url}
                            borderRadius={5}
                            style={{
                                width: '100%',
                                height: '100%',
                                margin: '0 10px',
                                objectFit: 'cover',
                                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                            }}
                        />
                    </Carousel.Slide>
                );
            })}
        </Carousel>
    );
};
