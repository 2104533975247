import { Box, SxProps, useTheme } from '@mui/material';
import { MotionValue, useTransform, motion, useMotionValue, animate, useSpring, useMotionValueEvent } from 'framer-motion';
import { useState, useEffect, useMemo } from 'react';
import { sleep } from '../../helpers/commonHelpers';

interface LinearGradientProps {
    sx: SxProps;
    direction: 'up' | 'down';
}

const LinearGradient: React.FC<LinearGradientProps> = ({ sx, direction }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                pointerEvents: 'none',
                zIndex: 3,
                background:
                    direction === 'up'
                        ? `linear-gradient(to bottom, ${theme.palette.background.paper} 50%, rgba(255,255,255,0) 100%)`
                        : `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${theme.palette.background.paper} 50%)`,
                ...sx,
            }}
        />
    );
};

interface NumberProps {
    mv: MotionValue<number>;
    number: number;
    height: number;
    formatFn: (value: number) => string;
    gap: number;
}

const Number: React.FC<NumberProps> = ({ mv, number, height, formatFn, gap }) => {
    const y = useTransform(mv, (latest) => {
        const placeValue = latest % 10;
        const offset = (10 + number - placeValue) % 10;

        let memo = offset * (height + gap);

        if (offset > 5) {
            memo -= 10 * (height + gap);
        }

        return memo;
    });

    return <motion.span style={{ display: 'block', position: 'absolute', y, height }}>{formatFn(number)}</motion.span>;
};

const defaultFormatFn = (value: number) => value.toString();

interface AnimatedCounterProps {
    from: number;
    to: number;
    step?: number;
    formatFn?: (value: number) => string;
    sx?: SxProps;
    delay?: number;
}

export const AnimatedCounter: React.FC<AnimatedCounterProps> = ({ from, to, formatFn = defaultFormatFn, step = 1, delay, sx }) => {
    const [currentAnimatedValue, setCurrentAnimatedValue] = useState(from);

    const animatedValue = useSpring(from, { stiffness: 145, damping: 30, mass: 1.5 });

    useEffect(() => {
        const handleStartAnimation = async () => {
            if (delay) {
                await sleep(delay);
            }
            animatedValue.set(to);
        };

        handleStartAnimation();
    }, [to]);

    useMotionValueEvent(animatedValue, 'change', (latest) => {
        setCurrentAnimatedValue(latest);
    });

    const height = 24;
    const gap = 5; // Vertical gap between numbers
    const range = 5; // Display numbers within this range of the current value

    const visibleNumbers = [];
    for (let i = currentAnimatedValue - range * step; i <= currentAnimatedValue + range * step; i += step) {
        if (i >= from && i <= to) {
            visibleNumbers.push(i);
        }
    }

    const widestNumber = useMemo(() => {
        const numbers = Array.from({ length: Math.floor((to - from) / step) + 1 }, (_, i) => from + i * step);
        // Map the numbers to their formatted string lengths
        const lengths = numbers.map((num) => formatFn(num).length);

        // Find the maximum length
        const maxLength = Math.max(...lengths);

        // Find the number with the maximum length
        const widestNumber = numbers[lengths.indexOf(maxLength)];

        return widestNumber;
    }, []);

    return (
        <Box
            component="span"
            sx={{ display: 'inline-flex', zIndex: 4, position: 'relative', height: `${height + gap * 2}px`, overflow: 'hidden', ...sx }}
        >
            <span style={{ visibility: 'hidden' }}>{formatFn(widestNumber)}</span>

            <LinearGradient direction="up" sx={{ bottom: height, height }} />
            <LinearGradient direction="down" sx={{ top: height, bottom: 0, height }} />

            {visibleNumbers.map((val) => (
                <Number mv={animatedValue} number={val} key={val} height={height} formatFn={formatFn} gap={gap} />
            ))}
        </Box>
    );
};
