import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Language } from '../types/enums';
import { getTranslation } from './translations';

const options: InitOptions = {
    fallbackLng: Language.EN,
    debug: false,
    supportedLngs: ['en', 'fi'],
    resources: {
        fi: getTranslation(Language.FI),
        en: getTranslation(Language.EN),
    },
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
};

// Detect users preferred language from browser:
i18next.use(LanguageDetector).use(initReactI18next).init(options);

export default i18next;
