import React from 'react';
import { IconButton } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { MessageSvgIcon } from './MessageSvgIcon';

export const MessageIconButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    const userToken = useAppSelector(selectToken);

    if (!userToken) {
        return null;
    }

    return (
        <IconButton onClick={onClick} size="small">
            <MessageSvgIcon />
        </IconButton>
    );
};
