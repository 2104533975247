import React, { CSSProperties } from 'react';
import { FavoriteButtonContainer, StyledCard } from './ProfileListingItem.components';
import { Listing } from '../../types/apiTypes';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { ButtonBase, Typography } from '@mui/material';
import { ReactFCWithChildren } from '../../types/types';
import { ImageLoader } from '../ImageLoader/ImageLoader';
import { useIsListingFavorited } from '../../queries/useIsListingFavorited';
import { OverlayContainer } from '../../views/Wardrobe/Wardrobe.components';
import { useTranslation } from 'react-i18next';

interface ListingItemProps {
    listing: Listing;
    showFavoriteIcon: boolean;
    onToggleFavorite?: (listingId: string) => void;
    onClick: (listing: Listing) => void;
    style?: CSSProperties;
}

export const ProfileListingItem: ReactFCWithChildren<ListingItemProps> = ({ listing, showFavoriteIcon, onToggleFavorite, onClick, style }) => {
    const { t } = useTranslation();
    const imageSrc = listing.images[0]?.url;
    const isFavorited = useIsListingFavorited(listing.id);
    const isClosed = listing.state === 'closed';
    const isPendingApproval = listing.state === 'pendingApproval';

    return (
        <StyledCard elevation={4} style={style}>
            {showFavoriteIcon && (
                <FavoriteButtonContainer>
                    <FavoriteButton selected={isFavorited} size="small" onClick={() => onToggleFavorite && onToggleFavorite(listing.id)} />
                </FavoriteButtonContainer>
            )}

            <ButtonBase onClick={() => onClick(listing)} style={{ height: '100%', width: '100%' }}>
                <ImageLoader hash={listing.publicData.blurhash} url={imageSrc} style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
            </ButtonBase>

            {isClosed && (
                <OverlayContainer>
                    <Typography variant="h6" style={{ color: 'white', textAlign: 'center', fontWeight: 'lighter' }}>
                        {t('closed')}
                    </Typography>
                </OverlayContainer>
            )}
            {isPendingApproval && (
                <OverlayContainer style={{ borderRadius: 'inherit' }}>
                    <Typography variant="h6" style={{ color: 'white', textAlign: 'center', fontWeight: 'lighter' }}>
                        {t('pendingApproval')}
                    </Typography>
                </OverlayContainer>
            )}
        </StyledCard>
    );
};
