import { TFunction } from 'react-i18next';
import { getDiscountForCategory } from '../../strapi/helpers';
import { DiscountDefinition } from '../../strapi/hooks/useDiscountDefinition';
import { SuggestedPrices } from './PricingModelForm.types';

export const MAX_CURRENCY_VALUE = 20000;
export const DAYS_IN_MONTH = 28;
export const DAYS_IN_WEEK = 7;

export const validatePriceFn = (value: string | number | undefined) => {
    const isValid = parseFloat(`${value}`.replace(',', '.')) > 0;
    return isValid ? true : `Invalid price value.`;
};

export const parseFloatString = (value: string | number | undefined) => {
    return parseFloat(`${value}`.replace(',', '.'));
};

export const validatePercentageFn = (value: string | number | undefined) => {
    const num = parseFloat(`${value}`.replace(',', '.'));
    const isValid = num > 0 && num < 100;
    return isValid ? true : `Must be between 1 and 100`;
};

export const validateLessThanOrEqual = (value: string | number | undefined, compareVal: string | number | undefined, msg: string) => {
    if (!value) {
        return false;
    }

    const ensureNumValue = typeof value === 'string' ? parseFloat(value) : value;
    const ensureNumCompareValue = typeof compareVal === 'string' ? parseFloat(compareVal) : compareVal;

    const isValid = (ensureNumValue || 0) <= (ensureNumCompareValue || 0);

    return isValid ? true : msg;
};

export const validateMoreThanOrEqual = (value: string | number | undefined, compareVal: string | number | undefined, msg: string) => {
    if (!value) {
        return false;
    }

    const ensureNumValue = typeof value === 'string' ? parseFloat(value) : value;
    const ensureNumCompareValue = typeof compareVal === 'string' ? parseFloat(compareVal) : compareVal;

    const isValid = (ensureNumValue || 0) >= (ensureNumCompareValue || 0);

    return isValid ? true : msg;
};

export const validateMinMax = (value: string | undefined, min: number | undefined, max: string | number | undefined, t: TFunction) => {
    if (!value) {
        return false;
    }

    const valueNum = parseFloat(value);
    const ensureNumMin = typeof min === 'string' ? parseFloat(min) : min;
    const ensureNumMax = typeof max === 'string' ? parseFloat(max) : max;

    const isValid = valueNum >= (ensureNumMin || 0) && valueNum <= (ensureNumMax || MAX_CURRENCY_VALUE);

    return isValid ? true : t('amountBetween', { min: min || 0, max: ensureNumMax || MAX_CURRENCY_VALUE });
};

export const calculateSuggestedPrices = (
    originalPrice: string | undefined,
    category: string,
    discountDef: DiscountDefinition | undefined,
): SuggestedPrices | null => {
    if (!originalPrice || !validatePriceFn(originalPrice) || !discountDef) {
        return null;
    }

    const discountDefinition = discountDef;

    const { weekly, monthly } = getDiscountForCategory(category, discountDefinition);

    const originalPriceNum = parseFloat(originalPrice);
    const dailySuggestion = Math.round(originalPriceNum * 100 * 0.02);
    const weeklySuggestion = Math.floor(dailySuggestion * DAYS_IN_WEEK * ((100 - weekly) / 100));
    const monthlySuggestion = Math.floor(dailySuggestion * DAYS_IN_MONTH * ((100 - monthly) / 100));

    return {
        daily: { value: dailySuggestion },
        weekly: {
            value: weeklySuggestion,
            percentage: weekly,
        },
        monthly: {
            value: monthlySuggestion,
            percentage: monthly,
        },
    };
};
