import { Grid, Typography, CircularProgress, styled, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CenteredContainer from '../../components/CenteredContainer/CenteredContainer';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { usePrevious } from '../../hooks/usePrevious';
import { useCurrentUser } from '../../user/hooks/useUser';
import { useSendVerificationEmail } from './useSendVerificationEmail';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { getOnboardingFlowUrl } from '../../helpers/authHelpers';

const ResendText = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

export const VerificationPendingView: React.FC = () => {
    const { data: user } = useCurrentUser();
    const previousVerificationState = usePrevious(user?.emailVerified);
    const { mutate: resendVerificationEmail, isLoading: isResending } = useSendVerificationEmail();
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    useEffect(() => {
        if (user && user.emailVerified && !previousVerificationState) {
            navigate(getOnboardingFlowUrl(user), { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, t, user]);

    return (
        <CenteredContainer>
            <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
                        {t('emailVerificationPending')}
                    </Typography>

                    <Box sx={{ height: '50px', width: '100%' }}>
                        {isResending ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                <Trans i18nKey="didNotReceiveEmail">
                                    Did not receive the email? Press
                                    <ResendText
                                        role="button"
                                        aria-disabled={isResending}
                                        onClick={() => {
                                            if (isResending) {
                                                return;
                                            }

                                            resendVerificationEmail();
                                        }}
                                    >
                                        here
                                    </ResendText>
                                    to re-send
                                </Trans>
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </CenteredContainer>
    );
};
