import { Box, styled } from '@mui/material';

export const CenteredBox = styled(Box)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    textAlign: 'center',
    position: 'relative',
}));
