import React, { createContext, useState, useContext, ReactNode } from 'react';

export type OpenDrawersState = { [key: string]: boolean };
interface DrawerContextData {
    openDrawersState: OpenDrawersState;
    openDrawer: (drawerName: string, single?: boolean) => void;
    closeDrawers: (names: string | string[]) => void;
}

const DrawerContext = createContext<DrawerContextData | undefined>(undefined);

interface DrawerProviderProps {
    children: ReactNode;
}

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
    const [openDrawersState, setOpenDrawersState] = useState<{ [key: string]: boolean }>({});

    const openDrawer = (drawerName: string, single?: boolean) => {
        if (single) {
            setOpenDrawersState({ [drawerName]: true });
        } else {
            setOpenDrawersState((prevState) => ({ ...prevState, [drawerName]: true }));
        }
    };

    const closeDrawers = (names: string | string[]) => {
        let drawersToClose: string[];

        if (!Array.isArray(names)) {
            drawersToClose = [names];
        } else {
            drawersToClose = names;
        }

        setOpenDrawersState((prevState) => {
            const newState = { ...prevState };
            drawersToClose.forEach((name) => {
                newState[name] = false;
            });

            return newState;
        });
    };

    return <DrawerContext.Provider value={{ openDrawersState, openDrawer, closeDrawers }}>{children}</DrawerContext.Provider>;
};

export const useDrawer = (): DrawerContextData => {
    const context = useContext(DrawerContext);
    if (!context) {
        throw new Error('useDrawer must be used within a DrawerProvider');
    }
    return context;
};
