import React from 'react';
import { StepBackButton, StepContent, StepContentContainer } from '../UserOnboarding.components';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { BaseStepProps } from '../UserOnboarding.types';
import { ArrowBack } from '@mui/icons-material';
import { IdentityVerification, IdentityVerificationState } from '../../../views/IdentityVerification/IdentityVerification';
import { useStartRedirectFlow } from '../../../hooks/auth/signicat/useStartRedirectFlow';
import { useLocation } from 'react-router-dom';

interface VerificationStepProps extends BaseStepProps {
    identificationState: IdentityVerificationState;
}

export const VerificationStep: React.FC<VerificationStepProps> = ({ onComplete, loading, onGoBack, hasPreviousStep, identificationState }) => {
    const { t } = useTranslation();
    const type = identificationState || 'initial';
    const location = useLocation();

    const redirectParams = {
        path: location.pathname === '/' ? 'index' : location.pathname,
    };

    const { mutate: startRedirectFlow, isLoading } = useStartRedirectFlow(redirectParams);

    return (
        <StepContent>
            <StepContentContainer>
                <IdentityVerification type={type} />
            </StepContentContainer>

            {hasPreviousStep && (
                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            )}

            {(type === 'abort' || type === 'error') && (
                <>
                    <LoadingButton variant="contained" onClick={() => startRedirectFlow()}>
                        {t('tryAgain')}
                    </LoadingButton>
                    <LoadingButton variant="text" onClick={() => onComplete()}>
                        {t('skip')}
                    </LoadingButton>
                </>
            )}

            {type === 'initial' && (
                <>
                    <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                        {t('verifyIdentityShort')}
                    </LoadingButton>
                    <LoadingButton variant="text" onClick={() => onComplete()} loading={loading}>
                        {t('later')}
                    </LoadingButton>
                </>
            )}

            {type === 'success' && (
                <>
                    <LoadingButton variant="contained" onClick={onComplete} loading={loading}>
                        {t('continue')}
                    </LoadingButton>
                </>
            )}
        </StepContent>
    );
};
