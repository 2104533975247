import React from 'react';
import { Typography } from '@mui/material';

export const SectionLabel: React.FC<{ text: React.ReactNode; textAlign?: string; required?: boolean; px?: number }> = ({
    text,
    textAlign = 'start',
    required = false,
    px = 2,
}) => (
    <Typography variant="body2" sx={{ textAlign, fontWeight: 'bold', px, mt: 3, width: '100%' }}>
        {text}
        {required && <span style={{ color: 'red', fontWeight: '100', marginLeft: '1px' }}>*</span>}
    </Typography>
);
