import { AdminPanelSettings } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { OpenAdminActionProps, useAdminControlsDrawer } from '../../context/admin';
import { useHasPermissionForActionTarget } from '../../user/hooks/usePermissions';

export const AdminDrawerButton: React.FC<{ props: OpenAdminActionProps }> = ({ props }) => {
    const { openAdminControls } = useAdminControlsDrawer();

    const hasPermissionForActionTarget = useHasPermissionForActionTarget(props.target);

    if (!hasPermissionForActionTarget) {
        return null;
    }

    const handleOpenAdminControls = () => {
        openAdminControls(props);
    };

    return (
        <IconButton onClick={handleOpenAdminControls}>
            <AdminPanelSettings />
        </IconButton>
    );
};
