import { Box, SxProps, useTheme } from '@mui/material';
import React from 'react';

export const HTMLContainer: React.FC<{ children: React.ReactNode; sx?: SxProps }> = ({ children, sx }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                color: theme.palette.text.primary,
                '& p,h1,h2,h3,h4,h5,h6': { margin: '0 16px' },
                'p:has(> img:only-child) ': {
                    display: 'block',
                    margin: 0,
                },
                '& ul': {
                    paddingInlineEnd: '10px',
                },
                '& figure': { margin: 'auto' },
                '& hr': {
                    flexShrink: '0',
                    borderWidth: '0',
                    borderStyle: 'solid',
                    borderColor: 'rgba(255, 255, 255, 0.12)',
                    borderBottomWidth: 'thin',
                },

                '& img': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                },
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
