import { DoNotDisturb } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconSection } from '../../components/IconSection.tsx/IconSection';

export const CancellationPolicySection: React.FC = () => {
    const { t } = useTranslation();

    return (
        <IconSection
            icon={<DoNotDisturb color="primary" sx={{ mt: '1px' }} />}
            title={
                <Typography variant="body1" fontWeight="bold">
                    {t('cancellationPolicySectionTitle')}
                </Typography>
            }
        >
            <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                {t('cancellationPolicySectionDescription')}
            </Typography>
        </IconSection>
    );
};
