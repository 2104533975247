import React, { useContext, useMemo } from 'react';
import { StepperContainer } from './StepperContainer';

export type Direction = 'left' | 'right' | undefined;

interface StepperContext {
    activeStep: number;
    stepsCount: number;
    direction: Direction;
}

interface StepperProps {
    children: React.ReactNode;
    activeStep: number;
    hideIndicators?: boolean;
    // controls the animation direction. undefined for no animation
    direction: Direction;
}

const StepperContext = React.createContext<StepperContext>({ activeStep: 0, stepsCount: 0, direction: undefined });

export const Stepper: React.FC<StepperProps> = ({ children, activeStep, direction, hideIndicators }) => {
    const childrenArray = useMemo(() => React.Children.toArray(children).filter(Boolean), [children]);

    const value = useMemo(() => {
        const stepsCount = childrenArray.length;

        return { activeStep, stepsCount, direction };
    }, [children, activeStep, direction]);

    return (
        <StepperContext.Provider value={value}>
            <StepperContainer hideIndicators={hideIndicators}>{children}</StepperContainer>
        </StepperContext.Provider>
    );
};

export const useStepperContext = () => useContext(StepperContext);
