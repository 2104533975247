import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { Transaction } from '../../../transactions/apiTypes';
import { formatDayMonthAndDate, formatHHMMTimeRange } from '../../../helpers/dateAndTimeHelpers';
import { OfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';
import { deliveryTimeInterval, pickupTimeInterval, returnTimeInterval, showroomAddressCity, showroomAddressStreet } from '../../../constants';

interface DeliveryDetailsProps {
    transactionType: 'sale' | 'order';
    transactionProtectedData: Transaction['data']['attributes']['protectedData'];
    officeConfigurationMethods: OfficeConfigurationMethods;
}

export const DeliveryInformationSection: React.FC<DeliveryDetailsProps> = (props) => {
    const { t } = useTranslation();
    const { transactionType } = props;
    const {
        deliveryDetailsStreet,
        deliveryDetailsCity,
        deliveryTime,
        deliveryMethod,
        renterReturnDate,
        returnMethod,
        renterDeliveryDate,
        renterDeliveryDateOpeningHoursString,
        renterReturnDateOpeningHoursString,
    } = props.transactionProtectedData;

    const getAddressString = () => {
        return [deliveryDetailsStreet, deliveryDetailsCity].filter(Boolean).join(', ');
    };

    if (transactionType === 'sale') {
        return null;
    }

    const deliveryTimeString =
        deliveryTime && renterDeliveryDate
            ? `${formatDayMonthAndDate(new Date(renterDeliveryDate))}, ${formatHHMMTimeRange(deliveryTime, deliveryTimeInterval)}`
            : '';

    const resolvedDeliveryMethod = deliveryMethod ? deliveryMethod : 'faceToFace';

    const getReturnMethodString = () => {
        if (returnMethod === 'showroom') {
            return deliveryMethod === 'showroom' ? ` & ${t('dropoff')}` : ` & ${t('showroomDropoff')}`;
        }

        return '';
    };

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '3px' }}>
                <Typography variant="caption">{t('deliveryInformation')}</Typography>

                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                    <Typography variant="body2" fontWeight="bold">
                        {t('method')}
                    </Typography>

                    <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                        {t(`${resolvedDeliveryMethod}Delivery`) + getReturnMethodString()}
                    </Typography>
                </Box>

                {resolvedDeliveryMethod === 'wolt' && (
                    <>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                            <Typography variant="body2" fontWeight="bold">
                                {t('deliveryAddress')}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                                {getAddressString()}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                            <Typography variant="body2" fontWeight="bold">
                                {t('deliveryTime')}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                                {deliveryTimeString}
                            </Typography>
                        </Box>
                        <br />
                    </>
                )}

                {resolvedDeliveryMethod === 'showroom' && (
                    <>
                        <>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('showroomAddress')}
                                </Typography>
                                <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                                    {`${showroomAddressStreet}, ${showroomAddressCity}`}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('pickupTime')}
                                </Typography>
                                <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                                    {renterDeliveryDateOpeningHoursString}
                                </Typography>
                            </Box>
                            <br />
                        </>
                    </>
                )}

                {renterReturnDate && (
                    <>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                            <Typography variant="body2" fontWeight="bold">
                                {t('itemReturn')}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: '3px' }}>
                                {t('itemReturnDescription', {
                                    renterReturnDate: renterReturnDateOpeningHoursString,
                                })}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Divider sx={{ width: '100%', margin: '20px 0' }} />
        </>
    );
};
