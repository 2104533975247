import { alpha, styled } from '@mui/material';
import { motion } from 'framer-motion';

export const IndicatorContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 3,
}));

export const DotContainer = styled(motion.div)(() => ({
    padding: '3px',
}));

export const Dot = styled(motion.div)(({ theme }) => ({
    width: '6px',
    height: '6px',
    background: alpha(theme.palette.primary.dark, 0.5),
    borderRadius: '50%',
    position: 'relative',
}));

export const DotHighlight = styled(motion.div)(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    zIndex: 9,
}));
