import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { LoadingButton } from '@mui/lab';
import { CSSProperties } from 'react';

interface ApplyFiltersButtonProps {
    totalItems: number;
    loading: boolean;
    onClick: () => void;
    style?: CSSProperties;
}

export const ApplyFiltersButton: React.FC<ApplyFiltersButtonProps> = ({ onClick, totalItems, loading, style }) => {
    const { t } = useTranslation();

    return (
        <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'center', ...style }}>
            <AnimatePresence>
                <motion.div
                    key="apply-button"
                    initial={{ y: 80, scale: 0.9 }}
                    animate={{ y: 0, scale: 1 }}
                    exit={{ y: 80, scale: 0.9 }}
                    style={{ height: '100%', width: '100%', margin: '0px 10px' }}
                    transition={{ type: 'spring', damping: 20, stiffness: 170 }}
                >
                    <LoadingButton variant="contained" fullWidth onClick={onClick} loading={loading}>
                        {totalItems ? t('showXResults', { amount: totalItems }) : t('noResults')}
                    </LoadingButton>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
