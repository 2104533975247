import { styled } from '@mui/material';

export const StrikeThrough = styled('div')(({ theme }) => ({
    '&:before': {
        content: '" "',
        position: 'absolute',
        top: '50%',
        marginLeft: '35%',
        borderBottom: theme.palette.mode === 'dark' ? '1px solid #6e7070' : '1px solid #6e7070',
        width: '30%',
    },
}));
