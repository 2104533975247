import styled from '@mui/material/styles/styled';
import { motion } from 'framer-motion';

export const AnimatedDiv = styled(motion.div, { shouldForwardProp: (prop) => prop !== 'centered' && prop !== 'flex' })<{
    centered?: boolean;
    flex?: boolean;
}>(({ centered, flex = true }) => ({
    width: '100%',
    height: '100%',
    ...(flex && { display: 'flex', flexWrap: 'wrap', justifyContent: 'center', ...(centered && { alignItems: 'center' }) }),
}));

export const SpinnerBackground = styled('div')(({ theme }) => ({
    width: '38px',
    height: '38px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1,
    marginLeft: '1px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)',
}));

export const CustomSpinner = styled(motion.circle)(({ theme }) => ({
    strokeWidth: '2px',
    fill: 'transparent',
    strokeLinecap: 'round',
    stroke: theme.palette.primary.main,
}));
