import { Alert, AlertTitle, Box, FormHelperText, Typography } from '@mui/material';
import WoltLogo from '../../assets/images/wolt-logo.png';
import { LocationCity, People } from '@mui/icons-material';
import { FormField, FormSelectItem, SelectItem } from '../../components/FormRenderer/FormRenderer.types';
import { phoneNumberValidation } from '../../helpers/regex';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDrawer } from '../../context/drawer';
import { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { isEmpty, isEqual } from 'lodash';
import { formatDayMonthAndDate, formatDateAndTimeRange } from '../../helpers/dateAndTimeHelpers';
import { deliveryTimeInterval, returnTimeInterval, woltDeliveryAcceptedPostalCodes } from '../../constants';
import { Timeslot } from '../ViewListing/ListingFooter.helpers';
import { assertNever, formatMoney, invariant } from '../../helpers/commonHelpers';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { Methods } from '../../queries/useDeliveryTiming';
import { ShowMore } from '../../components/ShowMore/ShowMore';
import { AlertDrawer } from '../../components/AlertDrawer/AlertDrawer';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInScaleZoom } from '../../animations/constants';

const renderDeliveryMethodItem = (item: SelectItem) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'minmax(50px, 1.5fr) 4fr minmax(100px, 1.5fr)',
                alignItems: 'center',
                gap: '10px',
            }}
        >
            <span style={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.prefix}</span>

            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body1">{item.title}</Typography>
                <Typography variant="caption" sx={{ opacity: 0.7 }}>
                    {item.content}
                </Typography>
            </span>
            <span style={{ textAlign: 'end' }}>{item.suffix}</span>
        </Box>
    );
};

export const renderDeliveryMethodValue = () => {
    return function DeliveryMethodValueComponent() {
        const { t } = useTranslation();
        const {
            watch,
            formState: { errors },
        } = useFormContext();
        const value = watch('deliveryMethod');

        const showHelperText = errors && !isEmpty(errors.deliveryMethod);

        if (!value) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('selectDeliveryMethod')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingDeliveryMethod')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                <Typography variant="body2">{t(`${value}Delivery`)}</Typography>

                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingDeliveryMethod')}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export const renderReturnMethodValue = () => {
    return function DeliveryMethodValueComponent() {
        const { t } = useTranslation();
        const {
            watch,
            formState: { errors },
        } = useFormContext();
        const value = watch('returnMethod');

        const showHelperText = errors && !isEmpty(errors.returnMethod);

        if (!value) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('selectReturnMethod')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingReturnMethod')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                <Typography variant="body2">{t(`${value}Return`)}</Typography>

                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingReturnMethod')}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export const renderTimeValue = (deliveryMethod: string, methods: Methods, booking: BookingDraft | Booking, controlName: string, interval: number) => {
    return function DeliveryTimeValueComponent() {
        const { t } = useTranslation();
        const {
            control,
            formState: { errors },
        } = useFormContext();

        const value = useWatch({ control, name: controlName });
        const { getPickupDateWithinOpeningHours } = methods;

        const renterDeliveryDate = getPickupDateWithinOpeningHours(deliveryMethod, booking);

        const showHelperText = errors && !isEmpty(errors.deliveryTime);
        const formatString = (str: string) => `${str}${controlName.charAt(0).toUpperCase() + controlName.slice(1)}`;

        if (!value || !renterDeliveryDate) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t(formatString('select'))}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t(formatString('missing'))}
                        </FormHelperText>
                    )}
                </>
            );
        }

        const displayDate = new Date(renterDeliveryDate);

        displayDate.setHours(value.getHours());
        displayDate.setMinutes(value.getMinutes());

        const formattedDate = formatDateAndTimeRange(displayDate, interval);

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '30px', gap: '10px' }}>
                <Typography variant="body2" sx={{ textAlign: 'start' }}>
                    {formattedDate}
                </Typography>
                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t(formatString('missing'))}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export const renderDeliveryDetailsValue = () => {
    return function DeliveryDetailsValueComponent() {
        const { t } = useTranslation();
        const {
            control,
            formState: { errors },
        } = useFormContext();
        const { openDrawersState } = useDrawer();
        const value = useWatch({ control, name: 'deliveryDetails' });

        const [committedValue, setCommittedValue] = useState(value);
        const [tempValue, setTempValue] = useState(value);

        const deliveryDetailsDrawerOpen = openDrawersState['deliveryDetails'];
        const previousOpen = usePrevious(deliveryDetailsDrawerOpen);

        useEffect(() => {
            if (deliveryDetailsDrawerOpen) {
                setTempValue(value);
            } else if (previousOpen && !isEqual(tempValue, committedValue)) {
                setCommittedValue(tempValue);
            }
        }, [deliveryDetailsDrawerOpen, value, previousOpen]);

        const showHelperText = errors && !isEmpty(errors.deliveryDetails);

        const allValuesEmpty =
            !committedValue ||
            Object.entries(committedValue)
                .filter(([key]) => {
                    const ignoredValues = ['rememberForLater'];

                    return !ignoredValues.includes(key);
                })
                .every(([key, val]) => {
                    if (key === 'phone') {
                        return val === '+358' || !val;
                    }

                    return !val;
                });

        if (allValuesEmpty) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('enterDeliveryDetails')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingOrInvalidDetails')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        const { street, postalCode, city, phone } = committedValue;

        const addressString = [street, postalCode, city]
            .filter(Boolean)
            .map((el) => el.trim())
            .join(', ');

        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '30px', gap: '10px' }}>
                    {addressString && (
                        <Typography variant="body2" sx={{ textAlign: 'start' }}>
                            {addressString}
                        </Typography>
                    )}
                    {phone && <Typography variant="body2">{value.phone}</Typography>}
                </Box>
                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingOrInvalidDetails')}
                    </FormHelperText>
                )}
            </>
        );
    };
};
export type BookingDetails = {
    booking: BookingDraft | Booking;
    woltEnabled: boolean;
    showroomEnabled: boolean;
    faceToFaceEnabled: boolean;
    deliveryMethod: string;
    timeslots: Timeslot[] | undefined;
};

export const getDeliveryMethodFormFields = (
    t: TFunction,
    parentControlName: string,
    bookingDetails: BookingDetails,
    methods: Methods,
): FormField[] => {
    const { isDeliveryMethodEnabled, getAvailabilityHoursString, getMinimumPriceForDeliveryMethod } = methods;
    const {
        woltEnabled: woltEnabledForListing,
        showroomEnabled: showroomEnabledForListing,
        faceToFaceEnabled: faceToFaceEnabledForListing,
    } = bookingDetails;

    const showroomEnabledForSelectedDates = isDeliveryMethodEnabled('showroom');
    const woltEnabledForSelectedDates = isDeliveryMethodEnabled('wolt');
    const faceToFaceEnabledForSelectedDates = isDeliveryMethodEnabled('faceToFace');

    const isShowRoomDisabled = !showroomEnabledForSelectedDates || !showroomEnabledForListing;
    const isWoltDisabled = !woltEnabledForSelectedDates || !woltEnabledForListing;
    const isFaceToFaceDisabled = !faceToFaceEnabledForSelectedDates || !faceToFaceEnabledForListing;

    let selectItems: FormSelectItem[] = [];

    if (faceToFaceEnabledForListing) {
        selectItems.push({
            type: 'item',
            value: 'faceToFace',
            title: t('meetWithLender'),
            disabled: isFaceToFaceDisabled,
            prefix: <People />,
            content: t('meetWithLenderDescription'),
            suffix: (
                <Typography variant="body2" fontWeight="bold" textAlign="end">
                    {formatMoney(getMinimumPriceForDeliveryMethod('faceToFace'))}
                </Typography>
            ),
        });
    }

    if (showroomEnabledForListing) {
        selectItems.unshift({
            type: 'item',
            value: 'showroom',
            title: t('showRoomPickup'),
            prefix: <LocationCity />,
            disabled: isShowRoomDisabled, // Showroom can be enabled on listing level, but still be disabled if e.g option is not available specifically for the selected dates
            content: t('retrieveFromShowRoom'),
            suffix: (
                <Typography variant="body2" fontWeight="bold" textAlign="end">
                    {formatMoney(getMinimumPriceForDeliveryMethod('showroom'))}
                </Typography>
            ),
        });
    }

    if (woltEnabledForListing) {
        selectItems.unshift({
            type: 'item',
            value: 'wolt',
            title: t('woltDelivery'),
            disabled: isWoltDisabled, // Wolt can be enabled on listing level, but still be disabled if e.g option is not available specifically for the selected dates
            content: t('woltDeliveryDescription'),
            prefix: <img src={WoltLogo} alt="Wolt Logo" style={{ height: '15px' }} />,
            suffix: (
                <Typography variant="body2" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                    {t('from')} {formatMoney(getMinimumPriceForDeliveryMethod('wolt'))}
                </Typography>
            ),
        });
    }

    selectItems = selectItems.sort((a, b) => {
        if (a.type === 'alert' || b.type === 'alert') {
            return 0;
        }

        if (a.disabled && !b.disabled) {
            return 1;
        }
        if (!a.disabled && b.disabled) {
            return -1;
        }
        return 0;
    });

    if (isShowRoomDisabled || isWoltDisabled) {
        const getAlertText = () => {
            // Wolt / showroom is enabled on listing level, but not for the selected dates
            const showLimitedOptionsAlert =
                (woltEnabledForListing || showroomEnabledForListing) && (!woltEnabledForSelectedDates || !showroomEnabledForSelectedDates);

            if (showLimitedOptionsAlert) {
                const noOptionsEnabled = !woltEnabledForSelectedDates && !showroomEnabledForSelectedDates && !faceToFaceEnabledForListing;
                const i18nKey = noOptionsEnabled ? 'noOptionsAvailable' : 'limitedOptionsAvailable';

                return (
                    <Trans
                        i18nKey={i18nKey}
                        components={{ b: <b />, i: <i />, h3: <h3 style={{ marginTop: '0' }} />, h4: <h4 />, h6: <h6 /> }}
                        values={{ openingHours: getAvailabilityHoursString(bookingDetails.deliveryMethod) }}
                    />
                );
            }
        };

        const alertText = getAlertText();

        if (alertText) {
            selectItems.push({
                type: 'alert',
                severity: 'info',
                maxHeight: 120,
                rows: 5,
                content: alertText,
            });
        }
    }

    return [
        {
            type: 'select',
            controlName: 'deliveryMethod',
            label: t('deliveryMethod'),
            closeDrawerOnSelect: true,
            parentControl: parentControlName,
            renderItem: renderDeliveryMethodItem,
            registerOptions: { required: true },
            items: selectItems,
        },
    ];
};

export const getDeliveryDetailsFormFields = (t: TFunction, parentControlName: string): FormField[] => {
    return [
        { type: 'text', controlName: 'street', label: t('street'), registerOptions: { required: true, shouldUnregister: true } },
        {
            type: 'text',
            controlName: 'postalCode',
            label: t('postalCode'),
            registerOptions: {
                required: true,
                pattern: /^\d{5}$/,
                validate: (val) => (woltDeliveryAcceptedPostalCodes.includes(val) ? true : t('woltDeliveryNotAcceptedPostalCode')),
                shouldUnregister: true,
            },
        },
        { type: 'text', controlName: 'city', label: t('city'), registerOptions: { required: true, shouldUnregister: true } },
        {
            type: 'text',
            controlName: 'additionalInformation',
            label: t('instructionsForTheCourier'),
            multiline: true,
            rows: 3,
            registerOptions: { shouldUnregister: true },
        },
        {
            type: 'phone',
            controlName: 'phone',
            label: t('phoneNumber'),
            registerOptions: {
                required: true,
                shouldUnregister: true,
                validate: (val) => {
                    return phoneNumberValidation.test(val) ? true : t('invalidPhoneNumber');
                },
            },
        },
        {
            type: 'checkbox',
            controlName: 'rememberForLater',
            registerOptions: { shouldUnregister: true },
            label: t('rememberForLater'),
        },
        {
            type: 'actions',
            parentControl: parentControlName,
            sx: { mt: 4, paddingBottom: 'env(safe-area-inset-bottom)' },
            actions: [
                { type: 'cancel', label: t('cancel'), variant: 'outlined' },
                { type: 'confirm', label: t('confirm'), variant: 'contained' },
            ],
        },
    ];
};

export const getDeliveryTimeFormFields = (t: TFunction, bookingDetails: BookingDetails, methods: Methods): FormField[] => {
    const { getPickupDateWithinOpeningHours, getDeliveryInformation, getAvailabilityHoursDatesString } = methods;

    const deliveryDate = getPickupDateWithinOpeningHours('wolt', bookingDetails.booking);
    const deliveryInformation = getDeliveryInformation(deliveryDate, bookingDetails.booking.range.bookingStart, 'wolt');

    if (!deliveryInformation) {
        return [];
    }

    const { min, max, isDeliveryDateDifferent } = deliveryInformation;

    return [
        {
            type: 'time',
            controlName: 'deliveryTime',
            label: t('deliveryTime'),
            registerOptions: { required: true, shouldUnregister: true },
            range: deliveryTimeInterval,
            minTime: min,
            maxTime: max,
            parentControl: 'deliveryTime',
            closeDrawerOnSelect: true,
            alert: isDeliveryDateDifferent ? (
                <Alert severity="info" sx={{ borderRadius: '15px', mt: 1, mb: 2 }}>
                    <AlertTitle sx={{ fontWeight: 'bold' }}>{t('deliveryDateNote')}</AlertTitle>
                    <ShowMore
                        maxHeight={50}
                        value={
                            <Trans
                                i18nKey="deliveryOnDate"
                                components={{ b: <b />, i: <i />, br: <br /> }}
                                values={{
                                    date: formatDayMonthAndDate(deliveryDate),
                                    openingHourDates: getAvailabilityHoursDatesString(bookingDetails.deliveryMethod),
                                }}
                            />
                        }
                    />
                </Alert>
            ) : null,
        },
    ];
};

export const getReturnMethodFormFields = (t: TFunction, bookingDetails: BookingDetails, methods: Methods): FormField[] => {
    const selectItems: FormSelectItem[] = [
        {
            type: 'item',
            value: 'showroom',
            title: t('showroomDropoffTitle'),
            prefix: <LocationCity />,
            content: t('showroomDropoffDescription'),
            suffix: (
                <Typography variant="body2" fontWeight="bold" textAlign="end">
                    {formatMoney(0)}
                </Typography>
            ),
        },
        {
            type: 'alert',
            severity: 'info',
            rows: 5,
            content: (
                <Trans
                    i18nKey="moreReturnMethodsComingSoon"
                    components={{ b: <b />, i: <i />, h3: <h3 style={{ marginTop: '0' }} />, h4: <h4 />, h6: <h6 /> }}
                />
            ),
        },
    ];

    return [
        {
            type: 'select',
            controlName: 'returnMethod',
            label: t('returnMethod'),
            closeDrawerOnSelect: true,
            renderItem: renderDeliveryMethodItem,
            registerOptions: { required: true, shouldUnregister: true },
            items: selectItems,
        },
    ];
};
