import { cloneDeep, debounce } from 'lodash';
import { MutableRefObject, useCallback, useRef } from 'react';
import { InfiniteData, QueryClient, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { conversationApiClient, messageApiClient } from '../../services/sharetribe/apiClients';
import { ConversationHistory, MessageHistory } from '../apiTypes';
import { ConversationsOverview } from './useConversationsOverview';
import { assertIsDefined } from '../../helpers/commonHelpers';

const markAsSeenFn = async (queueRef: MutableRefObject<string[]>) => {
    const { data } = await messageApiClient.post<string>('/mark-as-seen', {
        messageIds: queueRef.current,
    });
    const ref = queueRef;
    ref.current = [];

    return data;
};

const markAsSeenInMessageHistory = (queryClient: QueryClient, queryKey: QueryKey, messageIds: string[], timeStamp: string) => {
    const messageHistory = queryClient.getQueryData<InfiniteData<MessageHistory>>(queryKey);

    if (messageHistory) {
        queryClient.setQueryData<InfiniteData<MessageHistory>>(queryKey, (history) => {
            assertIsDefined(history, 'Message history is undefined');

            for (const page of history.pages || []) {
                for (const message of page.data) {
                    if (messageIds.includes(message._id)) {
                        message.readAt = timeStamp;
                    }
                }
            }

            return history;
        });
    }
};

export const updateReadStatusInConversationsOverview = (queryClient: QueryClient, conversationId: string, markAsRead = true) => {
    const conversationsOverview = queryClient.getQueryData<ConversationsOverview>(['conversations-overview']);

    if (conversationsOverview) {
        queryClient.setQueryData<ConversationsOverview>(['conversations-overview'], (overview) => {
            assertIsDefined(overview, 'Conversations overview is undefined');

            const getUpdatedUnreadMessages = (unreadMessages: ConversationsOverview['unreadMessages']) => {
                const idx = unreadMessages.indexOf(conversationId);
                const copy = [...unreadMessages];

                if (markAsRead && idx > -1) {
                    return copy.filter((id) => id !== conversationId);
                } else if (!markAsRead && idx === -1) {
                    return [...unreadMessages, conversationId];
                }

                return copy;
            };

            return {
                ...overview,
                unreadMessages: getUpdatedUnreadMessages(overview.unreadMessages),
            };
        });
    }
};

export const updateReadStatusInConversationHistory = (queryClient: QueryClient, conversationId: string, timeStamp: string) => {
    const conversationHistory = queryClient.getQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history']);

    if (conversationHistory) {
        queryClient.setQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history'], (history) => {
            assertIsDefined(history, 'Conversation history is undefined');

            const clonedHistory = cloneDeep(history);

            const pageIndex = clonedHistory.pages.findIndex((page) =>
                page?.data.some((conversation) => conversation.conversationId === conversationId),
            );

            if (pageIndex > -1) {
                const page = clonedHistory.pages[pageIndex];
                const conversation = page?.data.find((conversation) => conversation.conversationId === conversationId);
                if (conversation) {
                    conversation.lastMessage.readAt = timeStamp;
                }
            }

            return clonedHistory;
        });
    }
};

const useMarkAsSeenMutation = (queryKey: [string, { conversationId: string | undefined }]) => {
    const queryClient = useQueryClient();
    const conversationId = queryKey[1].conversationId;

    return useMutation(markAsSeenFn, {
        onSuccess: (timeStamp: string | null, queueRef: MutableRefObject<string[]>) => {
            const messageIds = queueRef.current;

            if (!timeStamp || !conversationId) {
                return;
            }

            markAsSeenInMessageHistory(queryClient, queryKey, messageIds, timeStamp);
            updateReadStatusInConversationsOverview(queryClient, conversationId);
            updateReadStatusInConversationHistory(queryClient, conversationId, timeStamp);
        },
    });
};

const debouncedMark = debounce((queueRef: MutableRefObject<string[]>, cb: (messageIds: MutableRefObject<string[]>) => void) => cb(queueRef), 1000);

/**
 *
 * Marks messages as read, whenever they appear on screen. To avoid multiple subsequent requests, adds the messageIds to a queue
 * and marks all at once after the debounce time.
 *
 * @param queryKey the currently visible message history's queryKey
 */
export const useMarkAsSeen = (queryKey: [string, { conversationId: string | undefined }]): ((messageId: string) => void) => {
    const queue = useRef<string[]>([]);

    const { mutate: markAsSeen } = useMarkAsSeenMutation(queryKey);

    const addMessageToQueue = useCallback(
        (messageId: string) => {
            if (!queue.current.includes(messageId)) {
                queue.current.push(messageId);
            }
            debouncedMark(queue, markAsSeen);
        },
        [queue, markAsSeen],
    );

    return addMessageToQueue;
};
